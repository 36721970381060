import React, { useEffect, useMemo, useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Card, ACPTable, ACPFilterPicklist } from 'components'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { Form, Formik } from 'formik'
import { uniq } from 'lodash'
import {
  dateFormatter,
  formatNumberAsCurrency,
  dateComparator,
  numberComparator,
  createStatusOptions,
  createStatusList,
} from 'utils'
import FilterIcon from 'images/filter-icon.svg'
import { selectors } from '../reducer'
import { Spinner } from 'lp-components'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import PastDueIcon from 'images/expired.svg'
import InReviewIcon from 'images/inReview.svg'
import UpdateRequiredIcon from 'images/updateRequired.svg'
import UpToDateIcon from 'images/upToDate.svg'
import AlertIcon from 'images/alertBanner.svg'

const propTypes = {
  investors: PropTypes.arrayOf(PropTypes.object),
  fetchInvestors: PropTypes.func.isRequired,
  assetID: PropTypes.string.isRequired,
  isPricePerShare: PropTypes.bool.isRequired,
  assetCusip: PropTypes.string.isRequired,
  assetName: PropTypes.string.isRequired,
}

const defaultProps = {
  investors: [],
}

const loadingOverlay = `<div class="search-results-helper">
<div class="overlay-text-title med-18">No results found</div>
<span class="overlay-text reg-14">It looks like you don't have any investors yet.</span>
<span class="overlay-text reg-14">To get started, please add investor for this asset.</span>
</div>`

function InvestorDetailsTable({
  investors,
  fetchInvestors,
  assetID,
  assetCusip,
  assetName,
  isPricePerShare,
}) {
  const [picklistVisible, setPicklistVisible] = useState(false)
  const [filterInvestorName, setFilterInvestorName] = useState('')
  const [filterTypes, setFilterTypes] = useState([])
  const [selectedInvestors, setSelectedInvestors] = useState([])

  const history = useHistory()

  const filterCount = filterTypes?.length

  const gridRef = useRef()
  const gridStyle = { height: '518px', width: '100%' }

  useEffect(() => {
    fetchInvestors(assetID)
  }, [assetID])

  const uniqAccountTypes = uniq(
    investors?.filter((t) => t.accountType).map((t) => t.accountType)
  )
  const uniqAccountTypeList = createStatusList(uniqAccountTypes)
  const accountTypeOptions = createStatusOptions(uniqAccountTypeList)
  const hasPastDue = investors.some(
    (investor) =>
      moment(investor.lastValueUpdatedDate) < moment().subtract(365, 'days') &&
      (investor.valuationDate === '' ||
        moment(investor.lastValueUpdatedDate) > moment(investor.valuationDate))
  )
  const hasUpdateRequired = investors.some(
    (investor) =>
      moment(investor.lastValueUpdatedDate).diff(moment, 'days') < 92 &&
      (investor.valuationDate === '' ||
        moment(investor.lastValueUpdatedDate) > moment(investor.valuationDate))
  )

  const columnDefs = [
    {
      field: 'clientFBOName',
      headerName: 'Investor Name',
      minWidth: 250,
      wrapText: true,
      sortable: true,
      cellClass: 'investor-name-cell',
      headerClass: 'investor-name-header',
      headerTooltip: 'Investor Name: The name of the Investor.',
      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    {
      field: 'accountType',
      headerName: 'Account Type',
      headerTooltip: 'Account Type: The type of account the investor holds.',
    },
    {
      field: 'shares',
      headerName: 'Number of Shares',
      headerClass: 'ag-right-aligned-header',
      cellClass: 'table-right-align',
      headerTooltip:
        'Number of Shares: The number of shares held by the investor in this asset.',
      hide: !isPricePerShare,
      comparator: numberComparator,
    },
    {
      field: 'marketValue',
      headerName: 'Total Market Value',
      cellClass: 'table-right-align',
      headerTooltip:
        'Total Market Value: The total value held by the investor in this asset.',
      valueFormatter: ({ value }) => {
        return formatNumberAsCurrency(parseInt(value), '-')
      },
      type: 'numericColumn',
      comparator: numberComparator,
    },
    {
      field: 'lastValueUpdatedDate',
      headerName: 'Last Value Updated Date',
      sortable: true,
      minWidth: 100,
      headerTooltip:
        'Last Value Updated Date: The last date the value was updated.',
      valueFormatter: dateFormatter,
      comparator: dateComparator,
    },
    {
      field: 'valuationDate',
      headerName: 'Valuation Status',
      sort: 'asc',
      comparator: numberComparator,
      valueGetter: (params) => {
        const current = moment()
        const target = moment(params.data.lastValueUpdatedDate)
        const yearAgo = current.subtract(365, 'days')

        if (params.data.lastValueUpdatedDate) {
          if (
            params.data.valuationDate &&
            target < moment(params.data.valuationDate)
          ) {
            return 3 //This will never be true.
          } else if (target < yearAgo) {
            return 1
          } else if (target.diff(current, 'days') < 92) {
            return 2
          } else {
            return 4
          }
          //If lastValueUpdatedDate is blank we need to look at valuationDate.
        } else if (
          !params.data.lastValueUpdatedDate &&
          params.data.valuationDate
        ) {
          if (moment(params.data.valuationDate) > yearAgo) {
            return 3
          } else {
            return 2
          }
          // If both or blank or if valuationDate is blank then we need an update.
        } else {
          return 2
        }
      },
      cellRenderer: (params) => {
        const current = moment()
        const target = moment(params.data.lastValueUpdatedDate)
        const yearAgo = current.subtract(365, 'days')

        const InReview = () => {
          return (
            <div className="statusTag">
              <img src={InReviewIcon} alt="In Reivew" />
              <span>In Review</span>
            </div>
          )
        }

        const PastDue = () => {
          return (
            <div className="statusTag">
              <img src={PastDueIcon} alt="Past Due" />
              <span>Past Due</span>
            </div>
          )
        }

        const UpdateRequired = () => {
          return (
            <div className="statusTag">
              <img src={UpdateRequiredIcon} alt="Update Required" />
              <span>Update Required</span>
            </div>
          )
        }

        const UpToDate = () => {
          return (
            <div className="statusTag">
              <img src={UpToDateIcon} alt="Up to Date" />
              <span>Up To Date</span>
            </div>
          )
        }

        //If both are not blank we can check both dates for correct values.
        if (params.data.lastValueUpdatedDate) {
          if (
            params.data.valuationDate &&
            target < moment(params.data.valuationDate)
          ) {
            return InReview() //This will never be true.
          } else if (target < yearAgo) {
            return PastDue()
          } else if (target.diff(current, 'days') < 92) {
            return UpdateRequired()
          } else {
            return UpToDate()
          }
          //If lastValueUpdatedDate is blank we need to look at valuationDate.
        } else if (
          !params.data.lastValueUpdatedDate &&
          params.data.valuationDate
        ) {
          if (moment(params.data.valuationDate) > yearAgo) {
            return InReview()
          } else {
            return UpdateRequired()
          }
          // If both or blank or if valuationDate is blank then we need an update.
        } else {
          return UpdateRequired()
        }
      },
    },
  ]

  const filteredInvestors = useMemo(() => {
    if (!investors) return []
    return investors.filter((data) => {
      return (
        (filterCount > 0
          ? filterTypes.some(
              (type) => data.accountType.toLowerCase() === type.toLowerCase()
            )
          : true) &&
        (filterInvestorName
          ? data.clientFBOName
              .toLowerCase()
              .includes(filterInvestorName.toLowerCase())
          : true)
      )
    })
  }, [investors, filterTypes, filterInvestorName])

  const handleExternalFilterChange = (newValue) => {
    setFilterTypes(newValue)
  }

  const onFilterTextBoxChanged = (e) => {
    setFilterInvestorName(e.target.value)
  }

  const applyFilters = (values) => {
    handleExternalFilterChange(values.filter)
    setPicklistVisible(false)
  }

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows()
    setSelectedInvestors(selectedRows)
  }, [])

  const investorsTableActions = (
    <div className="investors-table">
      <div className="table-actions">
        <div className="table-actions-left">
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search by Investor Name"
            onInput={onFilterTextBoxChanged}
          />
        </div>
        <div className="table-actions-right">
          <button
            className={classnames('button-primary', {
              'is-disabled': selectedInvestors.length < 1,
            })}
            disabled={selectedInvestors.length < 1}
            onClick={() => {
              history.push({
                pathname: `/assets/${assetID}/updateValuations`,
                state: {
                  target: `/assets/${assetID}/investors`,
                  goBackDashboard: true,
                  backTo: 'Assets Details',
                  selectedInvestors: selectedInvestors,
                  assetCusip: assetCusip,
                  assetName: assetName,
                },
              })
            }}
          >
            Submit Valuation
          </button>
        </div>
        <div className="table-actions-right">
          <button
            onClick={() => {
              setPicklistVisible(!picklistVisible)
            }}
          >
            <img src={FilterIcon} alt="" />
            Filters: {filterCount > 0 ? 'Account Types ' + filterCount : 'None'}
          </button>
        </div>
      </div>
    </div>
  )

  if (!filteredInvestors) {
    return <Spinner />
  }

  return (
    <div className="container investor-details-table">
      <div className="column twelve">
        <Card label="Investor Details" actions={investorsTableActions}>
          {(hasPastDue || hasUpdateRequired) && (
            <>
              <div className="bannerContainer">
                {hasPastDue && (
                  <span className="pastDueBanner">
                    <img src={AlertIcon} alt="Alert" />
                    The asset's valuation update is past due for one or more of
                    your investors. See status below and update today.
                  </span>
                )}
                {!hasPastDue && hasUpdateRequired && (
                  <span className="updateRequiredBanner">
                    <img src={UpdateRequiredIcon} alt="Update Required" />
                    It's time to update the asset's market value for one or more
                    of your investors. See status below and update soon.
                  </span>
                )}
              </div>
            </>
          )}
          <ACPTable
            columnDefs={columnDefs}
            additionalDefaultColDef={{ sortable: true }}
            rowData={filteredInvestors}
            isAnyFilterPresent={true}
            paginationPageSize="10"
            overrideGridStyle={gridStyle}
            overrideGridRef={gridRef}
            overlayLoadingTemplate={loadingOverlay}
            rowSelection={'multiple'}
            onSelectionChanged={onSelectionChanged}
          />
        </Card>
        <Formik
          onSubmit={applyFilters}
          initialValues={{
            filter: [],
          }}
        >
          {({ values, setFieldValue }) => (
            <>
              {picklistVisible && (
                <Form>
                  <ACPFilterPicklist
                    handleExternalFilterChange={handleExternalFilterChange}
                    setPicklistVisible={setPicklistVisible}
                    currentFilter={filterTypes}
                    values={values}
                    setFieldValue={setFieldValue}
                    options={accountTypeOptions}
                    onClose={applyFilters}
                    title={'Account Type'}
                  />
                </Form>
              )}
            </>
          )}
        </Formik>
      </div>
    </div>
  )
}

InvestorDetailsTable.propTypes = propTypes
InvestorDetailsTable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    investors: selectors.investors(state),
  }
}

const mapDispatchToProps = {
  fetchInvestors: apiActions.fetchInvestors,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InvestorDetailsTable
)
