import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  setConfirmFundingMethod: PropTypes.func.isRequired,
  setFieldValueOnCancel: PropTypes.func.isRequired,
}

const defaultProps = {}

function FundingMethodModal({
  onClose,
  fieldName,
  setFieldValue,
  initialValue,
  currentValue,
  setConfirmFundingMethod,
  setFieldValueOnCancel,
}) {
  const cancelModal = () => {
    setFieldValueOnCancel(fieldName, initialValue)
    onClose()
  }

  const confirmModal = () => {
    setFieldValue(fieldName, currentValue)
    setConfirmFundingMethod()
    onClose()
  }

  return (
    <Modal id="funding-method-modal" onClose={onClose}>
      <button
        className="modal-close"
        onClick={() => {
          setFieldValue(fieldName, initialValue)
          onClose()
        }}
      >
        ×
      </button>
      <div className="funding-method-container">
        <h2>Funding Method</h2>
        <p>
          Changing the funding method will affect all investors of this asset.
          Are you sure you want to continue?
        </p>
      </div>

      <div className="button-block--inline fund-method-modal">
        <button
          className="button-primary"
          onClick={() => confirmModal()}
          type="button"
        >
          Continue
        </button>
        <button
          className="button-secondary"
          onClick={() => cancelModal()}
          type="button"
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

FundingMethodModal.propTypes = exact(propTypes)
FundingMethodModal.defaultProps = defaultProps

export default React.memo(FundingMethodModal)
