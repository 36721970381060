import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import Layout from './Layout'
import { ADD_ASSET_STAGES } from 'flow-stages'
import { flatMap } from 'lodash'

const propTypes = {
  match: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path } }) {
  // Layout for add-asset-flow includes the breadcrumb status tracker, need an additional call at the Routes level to get status
  return (
    <Switch>
      {flatMap(ADD_ASSET_STAGES, (stage) =>
        stage.routes.map((route) => {
          const { pathname, component: Component } = route
          return (
            <Route
              exact
              key={pathname}
              path={
                pathname === '/new'
                  ? path + pathname
                  : path + '/:assetID' + pathname
              }
              render={(routeProps) => (
                <Layout currentStage={stage.name}>
                  <Component {...routeProps} />
                </Layout>
              )}
            />
          )
        })
      )}
      <Redirect path="*" to="/home" />
    </Switch>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
