import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState, setState } from 'lp-redux-utils'
import { setOnSuccess, handleSuccess } from 'lp-redux-api'
import { set } from 'lodash/fp'
import * as apiActions from 'api-actions'
import * as capitalCallActions from '../capital-commitments-flow/actions'
import { convertStringToFixedFloat } from 'utils'

const reducerKey = 'capitalCommitments'
const slice = 'root.capitalCommitments'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchClientAccounts]: handleSuccess((state, action) => {
      let deformattedAccounts = action.payload.data.map((acct) => {
        acct.balanceAmount = convertStringToFixedFloat(acct.balanceAmount, 0)
        acct.processedAmount = convertStringToFixedFloat(
          acct.processedAmount,
          0
        )
        acct.totalAmount = convertStringToFixedFloat(acct.totalAmount, 0)
        return acct
      })
      return set('clientAccounts', deformattedAccounts, state)
    }),
    [capitalCallActions.clearClientAccounts]: unsetState('clientAccounts'),
    [apiActions.createCapitalCommitment]: setOnSuccess('capitalCommitment'),
    [capitalCallActions.clearCapitalCommitment]: unsetState(
      'capitalCommitment'
    ),
    [capitalCallActions.storeCapitalCommitment]: setState('capitalCommitment'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  clientAccounts: select('clientAccounts'),
  capitalCommitment: select('capitalCommitment'),
}

export { reducer, selectors, reducerKey }
