import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { FlowActions } from '../components'
import { useLocation, useHistory } from 'react-router-dom'
import { flattenDeep, isEmpty, uniqBy } from 'lodash'
import { Redirect } from 'react-router'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'global-reducer'
import { DownloadDocumentContent } from 'components'

const propTypes = {
  fetchDocument: PropTypes.func.isRequired,
  fetchInvestorsData: PropTypes.func.isRequired,
  investorsData: PropTypes.array.isRequired,
  storePurchaseIDs: PropTypes.func.isRequired,
  clearInvestorsData: PropTypes.func.isRequired,
  purchaseIDs: PropTypes.array,
}

const defaultProps = {}

function Templates({
  fetchDocument,
  fetchInvestorsData,
  investorsData,
  storePurchaseIDs,
  purchaseIDs,
  clearInvestorsData,
}) {
  const location = useLocation()
  const history = useHistory()
  const selectedInvestors = location.state?.selectedInvestors
  const [isExpandedShared, setIsExpandedShared] = React.useState(true)
  const [isExpandedLegal, setIsExpandedLegal] = React.useState(true)

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  useEffect(() => {
    fetchInvestorsData(investorPurchaseIDs.toString(), 'download')
    return () => {
      clearInvestorsData()
    }
  }, [])

  // if you have no selected investors get sent back to selection table
  if (isEmpty(selectedInvestors) && isEmpty(purchaseIDs))
    return <Redirect to="/transactions/pending" />

  const investorPurchaseIDs = selectedInvestors
    ? selectedInvestors.map((investor) => investor.transactionID)
    : purchaseIDs
    ? purchaseIDs
    : ''

  if (!investorsData || !investorsData[0].downloadDocuments) return <Spinner />

  const purchaseID = investorsData[0].purchaseID
  const arrayOfSharedDocuments = uniqBy(
    flattenDeep(
      investorsData.map((investorData) => investorData.downloadDocuments)
    ).filter((doc) => doc.isSharedDocument),
    'requiredDocumentID'
  )

  return (
    <>
      <DownloadDocumentContent
        isExpandedShared={isExpandedShared}
        setIsExpandedShared={setIsExpandedShared}
        arrayOfSharedDocuments={arrayOfSharedDocuments}
        fetchDocument={fetchDocument}
        purchaseID={purchaseID}
        investorsData={investorsData}
        isExpandedLegal={isExpandedLegal}
        setIsExpandedLegal={setIsExpandedLegal}
        storePurchaseIDs={storePurchaseIDs}
        investorPurchaseIDs={investorPurchaseIDs}
      />
      <FlowActions
        onContinue={() => {
          storePurchaseIDs(investorPurchaseIDs)
          history.push('/bulk-confirmations/uploads')
        }}
      />
    </>
  )
}

Templates.propTypes = propTypes
Templates.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    investorsData: selectors.investorsData(state),
    purchaseIDs: globalSelectors.purchaseIDs(state),
  }
}

const mapDispatchToProps = {
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
  fetchInvestorsData: apiActions.fetchInvestorsData,
  clearInvestorsData: actions.clearInvestorsData,
  storePurchaseIDs: actions.storePurchaseIDs,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Templates)
