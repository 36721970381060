import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  initialValue: PropTypes.string.isRequired,
  currentValue: PropTypes.string.isRequired,
  setConfirmValMethod: PropTypes.func.isRequired,
  setFieldValueOnCancel: PropTypes.func.isRequired,
  isPurchaseConfirm: PropTypes.bool,
}

const defaultProps = {}

function ValuationMethodModal({
  onClose,
  fieldName,
  setFieldValue,
  initialValue,
  currentValue,
  setConfirmValMethod,
  setFieldValueOnCancel,
  isPurchaseConfirm,
}) {
  const cancelModal = () => {
    setFieldValueOnCancel(fieldName, initialValue)
    onClose()
  }

  const confirmModal = () => {
    setConfirmValMethod(true)
    setFieldValue(fieldName, currentValue)
    onClose()
  }

  const modalId = () => {
    if (isPurchaseConfirm) {
      return 'valuation-method-modal-purchase'
    } else {
      return 'valuation-method-modal'
    }
  }

  return (
    <Modal id={modalId()} onClose={onClose}>
      <button
        className="modal-close valuation-modal-close-button"
        onClick={() => {
          setFieldValue(fieldName, initialValue)
          onClose()
        }}
      >
        ×
      </button>
      {isPurchaseConfirm ? (
        <div className="valuation-method-container">
          <h2 className="valuation-method-header">Valuation Method</h2>
          <p className="valuation-method-text">
            Changing the valuation method will affect all investors of this
            asset. If you confirm this change, we ask that you must submit the
            current valuation statement(s) reflecting this new valuation method
            for all Inspira investors within five business days so we can
            process this change.
            <div className="valuation-upload-link-container">
              <a
                className="valuation-upload-link"
                href="mailto:sponsordocs@inspirafinancial.com"
              >
                You can upload the documents online.{' '}
              </a>
            </div>
          </p>
        </div>
      ) : (
        <div className="valuation-method-container">
          <h2>Valuation Method</h2>
          <p>
            By changing the valuation method, you are confirming that this
            change will affect all holders of this asset. We ask that you submit
            the current valuation statement(s) reflecting this new valuation
            method for all Inspira investors within 5 business days so we can
            process this change. Please email the valuation statement to{' '}
            <a href="mailto:sponsordocs@inspirafinancial.com">
              sponsordocs@inspirafinancial.com
            </a>
            .
          </p>
          <hr />
        </div>
      )}
      <div className="button-block--inline valuation-modal-buttons">
        <button
          className="button-primary valuation-confirm-button"
          onClick={() => confirmModal()}
          type="button"
        >
          Confirm
        </button>
        <button
          className="button-secondary valuation-cancel-button"
          onClick={() => cancelModal()}
          type="button"
        >
          Cancel
        </button>
      </div>
    </Modal>
  )
}

ValuationMethodModal.propTypes = exact(propTypes)
ValuationMethodModal.defaultProps = defaultProps

export default React.memo(ValuationMethodModal)
