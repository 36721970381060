import React, { useEffect, useState } from 'react'
import { CollapsableCardContainer, Tooltip, Card } from 'components'
import { Spinner } from 'lp-components'
import { copyToClipboard, scrollToTop } from 'utils'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import CopyIcon from 'images/copy-icon.svg'
import CheckIcon from 'images/utility-icons/checkmark-green.svg'

const propTypes = {
  referralLink: PropTypes.object,
  fetchReferralLink: PropTypes.func.isRequired,
  clearReferralLink: PropTypes.func.isRequired,
}

const defaultProps = {}

function Invitation({ referralLink, fetchReferralLink, clearReferralLink }) {
  const [isExpandedEmail, setIsExpandedEmail] = useState(true)
  const [isExpandedLegal, setIsExpandedLegal] = useState(true)
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    fetchReferralLink()
    scrollToTop()
    return () => clearReferralLink()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleCopy = () => {
    setCopied(true)
  }

  const invitationTemplateActions = (
    <div className="invitation-template-tooltip">
      <Tooltip
        showIcon={true}
        showText={!copied}
        visible={true}
        imageAlt={`Copy Body Text`}
        customIcon={copied ? CheckIcon : CopyIcon}
        customTrigger="click"
        customOnClick={() => {
          copyToClipboard('invitation-template', 'text/html', handleCopy)
        }}
      >
        Copied!
      </Tooltip>
    </div>
  )

  if (!referralLink) {
    return <Spinner />
  }

  return (
    <>
      <h1 className="title">Invite Details</h1>
      <div className="invitation-acknowledgement">
        <span>
          Below you will find an email template for you to invite investors to
          open an account at Inspira. Please click the copy icon{' '}
          <img src={CopyIcon} alt="Copy" /> below to copy the email template,
          and send it from your own email address.
        </span>
      </div>
      <Card
        label={'Invitation Template'}
        isExpanded={isExpandedEmail}
        setIsExpanded={setIsExpandedEmail}
        actions={invitationTemplateActions}
        className="invitation-card"
      >
        <div className="invitation-template">
          <p>
            Subject: Access [insert fund name] by opening an account at Inspira
          </p>
          <div className="body-wrapper">
            <div className="body-text">
              <p>Hi [insert first name], </p>
              <p>
                [Firm name] currently has assets under custody with Inspira
                Financial, a self-directed IRA (Individual Retirement Accounts)
                custodian. An Inspira IRA allows you to invest in a wide range
                of alternative and traditional investments. The [insert fund
                name] can accept funds from various types of retirement
                accounts, including self-directed IRAs. You can invest in this
                asset today by opening an account at Inspira Financial.
              </p>
              <p>
                We have an Institutional pricing model at Inspira. Use the
                custom account opening link below to begin Inspira’s secure
                online account opening process.
              </p>
              <p>
                <a
                  className="open-account"
                  href={referralLink?.referralLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {referralLink?.referralLink}
                </a>
              </p>
              <p>
                If you have questions about opening your account at Inspira,
                please contact 800-258-7878.
              </p>
            </div>
          </div>
        </div>
      </Card>
      <CollapsableCardContainer
        label={isExpandedLegal ? 'Conditions of Use:' : 'Conditions of Use'}
        isExpanded={isExpandedLegal}
        onToggle={() => setIsExpandedLegal(!isExpandedLegal)}
      >
        <p className="invitation-legal">
          The information in this email template shall be used to convey
          Inspira’s account opening process to potential account holders. Your
          use of this instructional email does not create any agency or
          affiliation between Inspira and your company or asset(s). Inspira does
          not provide due diligence to third parties on prospective investments,
          sponsors, or service providers, and does not offer, sell, recommend or
          endorse any investment or sponsor or provide investment advice, and
          thus you may not convey or imply such to any third party.
        </p>
      </CollapsableCardContainer>
    </>
  )
}

Invitation.propTypes = propTypes
Invitation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    referralLink: selectors.referralLink(state),
  }
}

const mapDispatchToProps = {
  fetchReferralLink: apiActions.fetchReferralLink,
  clearReferralLink: actions.clearReferralLink,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Invitation)
