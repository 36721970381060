import {
  createRequest,
  createPostRequest,
  createPutRequest,
} from 'lp-redux-api'
import { getSessionToken } from 'auth'
import { parseJSONValues } from 'utils'

export const fetchUserProfile = createRequest('FETCH_USER_PROFILE', () => ({
  url: '/userProfiles',
}))

export const updateUserProfile = createPutRequest(
  'UPDATE_USER_PROFILE',
  (params) => ({
    url: '/contacts',
    body: params,
    decamelizeBody: false,
  })
)

export const updateLastLogin = createPutRequest('SET_LAST_LOGIN', () => ({
  url: '/userProfiles/logins',
  body: {
    loginDate: '',
  },
}))

// ASSETS

export const fetchAssets = createRequest(
  'FETCH_ASSETS',
  (multipleEntityAccount, contextCompanyId) => ({
    url: multipleEntityAccount
      ? '/assets?contextCompany=' + contextCompanyId
      : '/assets',
  })
)

export const fetchReferralLink = createRequest('FETCH_REFERRAL_LINK', () => ({
  url: '/referralLinks',
}))

export const fetchAsset = createRequest(
  'FETCH_ASSET',
  (assetId, currentStage) => ({
    url: `/assets/${assetId}`,
    query: { currentStage },
  })
)

export const createAsset = createPostRequest('CREATE_ASSET', (name) => ({
  url: '/assets',
  body: {
    status: 'Initial',
    name: name,
  },
}))

export const fetchAssetDetails = createRequest(
  'FETCH_ASSET_DETAILS',
  (assetID) => ({
    url: `/assets/${assetID}/assetDetails`,
  })
)

export const fetchInvestors = createRequest('FETCH_INVESTORS', (assetID) => ({
  url: `/assets/${assetID}/investors`,
}))

export const updateAsset = createPutRequest('UPDATE_ASSET', (params) => ({
  url: '/assets',
  body: params,
  decamelizeBody: false,
  // return params so the asset selector can be updated in redux (api does not return the updated fields..)
  onSuccess: () => params,
}))

export const updateDocument = createPostRequest(
  'UPDATE_DOCUMENT',
  (assetID, doc, formData) => ({
    url: `/assets/${assetID}/requiredDocuments/${doc.documentID}`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
        documentID: doc.documentID,
        fileName: doc.fileName,
        fileType: doc.fileType,
      }
    },
  })
)

export const uploadValuationDocument = createPostRequest(
  'UPDATE_DOCUMENT',
  (doc, formData) => ({
    url: `/assets/${doc.assetID}/valuations`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
        documentID: doc.documentID,
        fileName: doc.fileName,
        fileType: doc.fileType,
      }
    },
  })
)

export const uploadPurchaseDocument = createPostRequest(
  'UPLOAD_PURCHASE_DOCUMENT',
  (purchaseID, document, formData) => ({
    url: `/purchases/${purchaseID}/requiredDocuments/${document.documentID}`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
        documentID: document.documentID,
        fileName: document.fileName,
        fileType: document.fileType,
      }
    },
  })
)

export const uploadResolutionDocument = ({
  id,
  document,
  formData,
  subType,
}) => {
  let apiAction
  if (subType === 'asset') {
    apiAction = updateDocument
    return apiAction(id, document, formData)
  }
  if (subType === 'purchase') {
    apiAction = uploadPurchaseDocument
    return apiAction(id, document, formData)
  }
}

// DOCUMENTS
export const createSummaryDocument = createPostRequest(
  'CREATE_SUMMARY_DOCUMENT',
  (assetID, formData) => ({
    url: `/assets/${assetID}/summaryDocuments`,
    body: formData,
    // Override headers so Content-Type does _not_ get set to application/json
    overrideHeaders: true,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
  })
)

export const downloadUploadedDocuments = createRequest(
  'DOWNLOAD_UPLOADED_DOCUMENTS',
  (laserFicheId, documentName) =>
    fetch(`${process.env.API_URL}/downloadDocument/${laserFicheId}`, {
      method: 'GET',
      headers: {
        Accept: 'binary/octet-stream',
        Authorization: `Bearer ${getSessionToken()}`,
      },
    })
      .then((res) => res.arrayBuffer())
      .then((res) => {
        const blob = new Blob([res], { type: 'application/pdf' })
        const fileURL = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style = 'display: none'
        document.body.appendChild(a)
        a.href = fileURL
        a.download = documentName
        a.click()
        window.URL.revokeObjectURL(fileURL)
        a.remove()
      })
)

export const fetchDocumentFormIdNumbers = createRequest(
  'FETCH_DOCUMENT_FORM_ID_NUMBERS',
  (documentType) => ({
    url: `/formNumbers?documentType=${documentType}`,
  })
)

export const fetchUploadedDocuments = createRequest(
  'FETCH_UPLOADED_DOCUMENTS',
  () => ({
    url: '/uploadedDocuments',
  })
)

export const uploadExchangeDocument = createPostRequest(
  'UPLOAD_EXCHANGE_DOCUMENT',
  (formData) => ({
    url: `/forms`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
      }
    },
  })
)

// COMPLETED TRANSACTIONS

export const fetchRequiredDocumentsByTransactionID = createRequest(
  'FETCH_REQUIRED_DOCUMENTS_BY_TRANSACTION_ID',
  (transactionID) => ({
    url: `requiredDocuments/${transactionID}`,
  })
)

export const downloadRequiredDocumentsByTransactionID = (
  transactionType,
  transactionID,
  requiredDocumentID,
  requiredDocumentName
) => {
  fetch(
    `${process.env.API_URL}/${transactionType}/${transactionID}/requiredDocuments/${requiredDocumentID}/contents`,
    {
      method: 'GET',
      headers: {
        Accept: 'binary/octet-stream',
        Authorization: `Bearer ${getSessionToken()}`,
      },
    }
  )
    .then((res) => res.arrayBuffer())
    .then((res) => {
      const blob = new Blob([res], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style = 'display: none'
      document.body.appendChild(a)
      a.href = fileURL
      a.download = requiredDocumentName
      a.click()
      window.URL.revokeObjectURL(fileURL)
      a.remove()
    })
}

// PURCHASE

export const fetchPurchase = createRequest('FETCH_PURCHASE', (purchaseID) => ({
  url: `/purchases/${purchaseID}`,
}))

export const updatePurchase = createPutRequest('UPDATE_PURCHASE', (params) => ({
  url: '/purchases',
  body: params,
  decamelizeBody: false,
  // return params so the purchase selector can be updated in redux (api does not return the updated fields..)
  onSuccess: () => params,
}))

export const fetchRequiredDocumentTemplate = (
  purchaseID,
  requiredDocumentID,
  requiredDocumentName
) => {
  fetch(
    `${process.env.API_URL}/purchases/${purchaseID}/requiredDocuments/${requiredDocumentID}/contents`,
    {
      method: 'GET',
      headers: {
        Accept: 'binary/octet-stream',
        Authorization: `Bearer ${getSessionToken()}`,
      },
    }
  )
    .then((res) => res.arrayBuffer())
    .then((res) => {
      const blob = new Blob([res], { type: 'application/pdf' })
      const fileURL = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.style = 'display: none'
      document.body.appendChild(a)
      a.href = fileURL
      a.download = requiredDocumentName
      a.click()
      window.URL.revokeObjectURL(fileURL)
      a.remove()
    })
}

export const createRequiredDocumentForPurchase = createPostRequest(
  'CREATE_REQUIRED_DOCUMENT_FOR_PURCHASE',
  (document, formData) => {
    return {
      url: `/purchases/${document.purchaseID}/requiredDocuments/${document.requiredDocumentID}`,
      body: formData,
      headers: {
        Authorization: `Bearer ${getSessionToken()}`,
      },
      overrideHeaders: true,
      decamelizeBody: false,
      // include additional fields to identify and update the document in the reducer
      onSuccess: (res) => ({
        ...res,
        requiredDocumentID: document.requiredDocumentID,
        fileName: document.fileName,
        fileSize: document.fileSize,
      }),
      onFailure: (res) => {
        return {
          ...parseJSONValues(res),
          requiredDocumentID: document.requiredDocumentID,
          documentID: document.documentID,
          fileName: document.fileName,
          fileType: document.fileType,
        }
      },
    }
  }
)

// BULK CONFIRMATIONS-PURCHASES

export const fetchAllBulkPurchaseConfirmations = createRequest(
  'FETCH_ALL_BULK_PURCHASE_CONFIRMATIONS',
  () => ({
    url: '/bulkConfirmations/purchases',
  })
)

export const fetchBulkPurchaseConfirmationByAsset = createRequest(
  'FETCH_BULK_PURCHASE_CONFIRMATION_BY_ASSET',
  (selectedAssetId) => ({
    url: `bulkConfirmations/purchases/${selectedAssetId}`,
  })
)

//Duplicate of above function but deviated to avoid reducer problem.
export const createRequiredDocumentForBulkPurchase = createPostRequest(
  'CREATE_REQUIRED_DOCUMENT_FOR_BULK_PURCHASE',
  (document, formData) => {
    return {
      url: `/purchases/${document.purchaseID}/requiredDocuments/${document.requiredDocumentID}`,
      body: formData,
      headers: {
        Authorization: `Bearer ${getSessionToken()}`,
      },
      overrideHeaders: true,
      decamelizeBody: false,
      // include additional fields to identify and update the document in the reducer
      onSuccess: (res) => ({
        ...res,
        requiredDocumentID: document.requiredDocumentID,
        fileName: document.fileName,
        fileSize: document.fileSize,
      }),
      onFailure: (res) => {
        return {
          ...parseJSONValues(res),
          documentID: document.purchaseID,
          fileName: document.fileName,
          fileType: document.fileType,
        }
      },
    }
  }
)

// LIQUIDATIONS
export const fetchLiquidation = createRequest(
  'FETCH_LIQUIDATION',
  (liquidationID) => ({
    url: `/liquidations/${liquidationID}`,
  })
)

export const updateLiquidation = createPutRequest(
  'UPDATE_LIQUIDATION',
  (params) => ({
    url: '/liquidations',
    body: params,
    decamelizeBody: false,
    // return params so the liquidations selector can be updated in redux (api does not return the updated fields..)
    onSuccess: () => params,
  })
)

export const updateLiquidationDocument = createPostRequest(
  'UPDATE_LIQUIDATION_DOCUMENT',
  (liquidationID, document, formData) => ({
    url: `/liquidations/${liquidationID}/requiredDocuments/${document.documentID}`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
        documentID: document.documentID,
        fileName: document.fileName,
        fileType: document.fileType,
      }
    },
  })
)

// TASKS

export const fetchTasks = createRequest(
  'FETCH_TASKS',
  (multipleEntityAccount, contextCompanyId) => ({
    url: multipleEntityAccount
      ? '/tasks?contextCompany=' + contextCompanyId
      : '/tasks',
  })
)

export const updateTask = createPutRequest('UPDATE_TASK', (params) => ({
  url: `/resolutionTasks/completed`,
  body: params,
  decamelizeBody: false,
}))

// TRANSACTION
export const fetchTransactions = createRequest(
  'FETCH_TRANSACTIONS',
  (multipleEntityAccount, contextCompanyId) => ({
    url: multipleEntityAccount
      ? '/transactions?contextCompany=' + contextCompanyId
      : '/transactions',
  })
)

export const fetchCompletedTransactions = createRequest(
  'FETCH_COMPLETED_TRANSACTIONS',
  () => ({
    url: '/transactions/completed',
  })
)

export const fetchResolutionItems = createRequest(
  'FETCH_RESOLUTION_ITEMS',
  ({ subType, id }) => ({
    url: `/getResolutionItems/${subType}/${id}`,
  })
)

// NOTIFICATIONS

export const fetchNotifications = createRequest('FETCH_NOTIFICATIONS', () => ({
  url: '/notifications',
}))

export const updateNotification = createPutRequest(
  'UPDATE_NOTIFICATION',
  (params) => ({
    url: '/notifications',
    body: params,
    decamelizeBody: false,
  })
)

export const removeNotification = createPutRequest(
  'REMOVE_NOTIFICATION',
  (params) => ({
    url: '/notifications',
    body: params,
    decamelizeBody: false,
  })
)

// CAPITAL COMMITMENTS

export const fetchCapitalCommitments = createRequest(
  'FETCH_CAPITAL_COMMITMENTS',
  () => ({
    url: '/capitalCommitments',
  })
)

export const fetchClientAccounts = createRequest(
  'FETCH_CLIENT_ACCOUNTS',
  (assetID) => ({
    url: `/capitalCommitments/clientAccounts/${assetID}`,
  })
)

export const fetchClients = createRequest('FETCH_CLIENTS', () => ({
  url: '/clients',
}))

export const createCapitalCommitment = createPutRequest(
  'CREATE_CAPITAL_COMMITMENT',
  (params) => ({
    url: '/capitalCommitments',
    body: params,
    decamelizeBody: false,
  })
)

export const fetchCapitalCommitment = createRequest(
  'FETCH_CAPITAL_COMMITMENT',
  (capitalCommitmentID) => ({
    url: `/capitalCommitments/${capitalCommitmentID}`,
    onSuccess: (res) => res[0],
  })
)

export const uploadCapitalCommitmentDocument = createPostRequest(
  'UPLOAD_CAPITAL_COMMITMENT_DOCUMENT',
  (document, formData) => ({
    url: `/capitalCommitments/${document.documentID}`,
    body: formData,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
    overrideHeaders: true,
    onSuccess: (res) => ({
      ...res,
      requiredDocumentID: document.requiredDocumentID,
      fileName: document.fileName,
      fileSize: document.fileSize,
    }),
    onFailure: (res) => {
      return {
        ...parseJSONValues(res),
        documentID: document.clientAccountID,
        fileName: document.fileName,
        fileSize: document.fileSize,
      }
    },
  })
)

// EXCHANGE CONFIRMATIONS
export const fetchExchangeConfirmation = createRequest(
  'FETCH_EXCHANGE_CONFIRMATION',
  (exchangeID) => ({
    url: `/exchangeConfirmations/${exchangeID}`,
  })
)

export const updateExchangeConfirmation = createPutRequest(
  'UPDATE_EXCHANGE_CONFIRMATION',
  (exchangeConfirmation) => ({
    url: `/exchangeConfirmations`,
    body: {
      ...exchangeConfirmation,
    },
    decamelizeBody: false,
  })
)

export const createRequiredDocumentForExchange = createPostRequest(
  'CREATE_REQUIRED_DOCUMENT_FOR_EXCHANGE',
  (document, formData) => {
    return {
      url: `/exchangeConfirmations/${document.exchangeID}/requiredDocuments/${document.requiredDocumentID}`,
      body: formData,
      headers: {
        Authorization: `Bearer ${getSessionToken()}`,
      },
      overrideHeaders: true,
      decamelizeBody: false,
      // include additional fields to identify and update the document in the reducer
      onSuccess: (res) => ({
        ...res,
        requiredDocumentID: document.requiredDocumentID,
        fileName: document.fileName,
        fileSize: document.fileSize,
      }),
      onFailure: (res) => {
        return {
          ...parseJSONValues(res),
          requiredDocumentID: document.requiredDocumentID,
          documentID: document.documentID,
          fileName: document.fileName,
          fileType: document.fileType,
        }
      },
    }
  }
)

export const fetchContactsList = createRequest(
  'FETCH_CONTACTS_LIST',
  (multipleEntityAccount, contextCompanyId) => ({
    url: multipleEntityAccount
      ? '/contactManagement?contextCompany=' + contextCompanyId
      : '/contactManagement',
  })
)

export const fetchContactProfile = createRequest(
  'FETCH_CONTACT_PROFILE',
  (contactID) => ({
    url: `/contacts/${contactID}`,
  })
)

export const createContact = createPostRequest('CREATE_CONTACT', (payload) => {
  return {
    url: `/contacts`,
    body: payload,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    // overrideHeaders: true,
    decamelizeBody: false,
  }
})

export const sendAuthInvitation = createPostRequest(
  'SEND_AUTH_INVITATION',
  (payload) => {
    return {
      url: `/contacts/${payload.contactID}/sendInvitation`,
      headers: {
        Authorization: `Bearer ${getSessionToken()}`,
      },
      decamelizeBody: false,
    }
  }
)

export const updateContactAssignments = createPutRequest(
  'UPDATE_CONTACT_ASSIGNMENTS',
  (assignmentUpdatePayload) => ({
    url: `/relationships`,
    body: assignmentUpdatePayload,
    decamelizeBody: false,
  })
)

export const fetchSponsorTPACompanies = createRequest(
  'FETCH_SPONSOR_TPA_COMPANIES',
  () => ({
    url: `/authorizedCompanies`,
  })
)

export const deleteContact = createPostRequest('DELETE_CONTACT', (payload) => {
  return {
    url: `/contacts/deactivate`,
    body: payload,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
  }
})

export const validateContact = createPostRequest(
  'VALIDATE_CONTACT',
  (payload) => {
    return {
      url: `/contacts/validateContact`,
      body: payload,
      headers: {
        Authorization: `Bearer ${getSessionToken()}`,
      },
      decamelizeBody: false,
    }
  }
)

export const replaceContact = createPostRequest(
  'REPLACE_CONTACT',
  (payload) => {
    return {
      url: `/contacts/replaceContact`,
      body: payload,
      decamelizeBody: false,
    }
  }
)

export const inviteLegacyContact = createPostRequest(
  'INVITE_LEGACY_CONTACT',
  (contactID) => {
    return {
      url: `/contacts/${contactID}/sendInvitation`,
    }
  }
)

export const fetchInvestorsData = createRequest(
  'FETCH_INVESTORS_DATA',
  (ids, stage) => ({
    url: `/purchases?purchaseIDs=${ids}&stage=${stage}`,
  })
)

export const fetchResources = createRequest('FETCH_RESOURCES', () => ({
  url: '/helpResources',
}))

export const fetchRelatedEntities = createRequest(
  'FETCH_RELATED_ENTITIES',
  () => ({
    url: `/relatedEntities`,
  })
)

export const updateRelatedEntity = createPutRequest(
  'UPDATE_RELATED_ENTITY',
  (payload) => ({
    url: '/relatedEntities',
    body: payload,
    decamelizeBody: false,
  })
)

export const giactVerify = createPostRequest('GIACT_VERIFY', (payload) => {
  return {
    url: `/giactVerify`,
    body: payload,
    headers: {
      Authorization: `Bearer ${getSessionToken()}`,
    },
    decamelizeBody: false,
  }
})

export const fetchAssetFundingInstructions = createRequest(
  'GET_FUNDING_INSTRUCTIONS',
  (assetID) => ({
    url: `/assets/${assetID}/fundingInstructions`,
  })
)

export const updateAssetFundingInstructions = createPutRequest(
  'UPDATE_FUNDING_INSTRUCTIONS',
  (params) => ({
    url: `/assets/${params.assetID}/fundingInstructions`,
    body: params,
    decamelizeBody: false,
    // return params so the purchase selector can be updated in redux (api does not return the updated fields..)
    onSuccess: () => params,
  })
)

export const fetchTaskItem = createRequest('FETCH_TASK_ITEM', (id) => ({
  url: `/tasks/${id}`,
}))

export const validateEmail = createRequest('VALIDATE_EMAIL', (email) => ({
  url: `/emailAddresses/${email}`,
}))

export const setInitialLoginTrue = createPutRequest(
  'UPDATE_INITIAL_LOGIN',
  (params) => ({
    url: `/userProfiles/initialLogin`,
    body: params,
    decamelizeBody: false,
    onSuccess: () => params,
  })
)

export const generateToken = createPutRequest('GENERATE_TOKEN', () => ({
  url: `/generateToken`,
}))

export const verifyToken = createPutRequest('VERIFY_TOKEN', (params) => ({
  url: `/verifyToken`,
  body: params,
  decamelizeBody: false,
  onSuccess: (res) => res,
}))
