import React from 'react'
import PropTypes from 'prop-types'
import { FlowActions } from '../components'
import { ACPInputField } from 'components'
import { Checkbox as BaseCheckbox } from 'lp-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { withFormikAdapter } from 'utils'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function SignatureForm({ handleSubmit }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    doesAcknowledge: Yup.bool().when('requireAll', {
      is: true,
      then: Yup.bool().oneOf(
        [true],
        'Please check this box if you want to proceed'
      ),
    }),
  })
  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          title: '',
          doesAcknowledge: false,
        }}
        enableReinitialize={true}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <div>
              <div className="funding-signature-content">
                <h4 className="funding-signature-content-header">
                  E-Signature
                </h4>
                <div className="row">
                  <div className="one-half column">
                    <ACPInputField label="First Name" name="firstName" />
                  </div>
                  <div className="one-half column">
                    <ACPInputField label="Last Name" name="lastName" />
                  </div>
                </div>
                <div className="row">
                  <div className="one-half column">
                    <ACPInputField label="Title" name="title" />
                  </div>
                </div>
                <div className="row">
                  <div className="checkbox-line-item funding-checkbox-attestation twelve columns">
                    <Checkbox
                      name="doesAcknowledge"
                      label="By checking this box, I confirm that I understand and agree to the acknowledgments listed above. I intend this to be my legally binding signature on behalf of the Sponsor/Issuer. I also certify that the Issuer acknowledges and agrees to the items about. I understand that my signature also binds the Issuer to the terms of the acknowledgments."
                    />
                  </div>
                </div>
              </div>
            </div>

            <FlowActions
              prevStep="details"
              onContinue={() => setFieldValue('requireAll', true, false)}
              submitting={isSubmitting}
              submitContent="Submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

SignatureForm.propTypes = propTypes
SignatureForm.defaultProps = defaultProps

export default SignatureForm
