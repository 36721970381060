import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SignatureForm } from '../forms'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { useHistory, useLocation } from 'react-router-dom'
import { mapKeys } from 'lodash'

const propTypes = {
  updateLiquidation: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  liquidation: PropTypes.object.isRequired,
}

const defaultProps = {}

function ESignAndSubmit({
  liquidation,
  updateLiquidation,
  flashSuccessMessage,
  flashErrorMessage,
}) {
  const history = useHistory()
  const location = useLocation()
  const lastPathName = location.pathname.split('/').pop()
  const isPartTwo = lastPathName === 'sign2'

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <SignatureForm
      prevStep={isPartTwo ? 'details' : 'confirmation-form'}
      isPartTwo={isPartTwo}
      handleSubmit={async ({ firstName, lastName, title }) => {
        const formatPayloadByStep = (payload) => {
          if (!isPartTwo) return payload
          const mappedPayload = mapKeys(payload, (_, key) => `${key}2`)
          return {
            ...mappedPayload,
            status: 'Confirmed',
          }
        }

        const payload = formatPayloadByStep({
          eSignFirstName: firstName,
          eSignLastName: lastName,
          eSignTitle: title,
          eSignDate: Date.now(),
        })

        try {
          await updateLiquidation({
            ...liquidation,
            ...payload,
          })
          flashSuccessMessage('We received your liquidation confirmation.')
          history.push('/home')
        } catch (e) {
          flashErrorMessage(
            e.errors.message || 'Something went wrong, please try again.'
          )
        }
      }}
    />
  )
}

ESignAndSubmit.propTypes = propTypes
ESignAndSubmit.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {
  updateLiquidation: apiActions.updateLiquidation,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ESignAndSubmit
)
