import { each } from 'lodash'
function convertOptionsToDisplayOptions(options) {
  let convertedArr = []
  each(options, (value) => {
    convertedArr.push({ key: value, value: value })
  })
  return convertedArr
}

export default convertOptionsToDisplayOptions
