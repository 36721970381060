import React from 'react'
import exact from 'prop-types-exact'
import { Link, useLocation } from 'react-router-dom'
import arrow from 'images/expand-less.svg'

const propTypes = {}
const defaultProps = {}

function BackToDashboard() {
  const location = useLocation()
  const backTo = location?.state?.backTo
  const target = location?.state?.target || '/'
  const innerText = backTo ? `Back to ${backTo}` : `Back`

  return (
    <Link
      className="back-to-dashboard"
      to={{
        pathname: target,
        state: { ...location?.state, goBackDashboard: false },
      }}
    >
      <img src={arrow} alt="back-arrow" className="back-arrow" />
      {innerText}
    </Link>
  )
}

BackToDashboard.propTypes = exact(propTypes)
BackToDashboard.defaultProps = defaultProps

export default React.memo(BackToDashboard)
