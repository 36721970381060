import { handleActions } from 'redux-actions'

const reducerKey = 'onboarding'

const initialState = {}

const reducer = handleActions({}, initialState)

const selectors = {}

export { reducer, selectors, reducerKey }
