import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import * as apiActions from 'api-actions'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router'
import { selectors } from '../reducer'
import { bulkPromiseUploads, createFileErrors } from 'utils'
import * as Yup from 'yup'
import { IsProcessableForm, IsNotProcessableForm } from '../forms'
import { FlowActions } from '../components'
import moment from 'moment'
import { IS_NOT_PROCESSABLE_OPTIONS } from 'config'
import { DocumentUploadInput, Card, ACPInputField } from 'components'

const propTypes = {
  updateLiquidation: PropTypes.func.isRequired,
  updateLiquidationDocument: PropTypes.func.isRequired,
  liquidation: PropTypes.object.isRequired,
}

const defaultProps = {}

function Confirmation({
  liquidation,
  updateLiquidation,
  updateLiquidationDocument,
}) {
  const history = useHistory()
  const { liquidationID } = useParams()
  const formRef = useRef()
  const [error, setError] = useState()
  const [submitting, setSubmitting] = useState(false)

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const sponsorRedemptionFormDocument = liquidation?.requiredDocuments?.find(
    (doc) => doc.requiredDocumentName === 'Sponsor Redemption Form Upload'
  )
  const initialValues = {
    isLiquidationProcessable: '',
    isProcessable: {
      investorRegistration: liquidation?.clientFBOName,
      assetName: liquidation?.assetName,
      liquidationType: liquidation?.liquidationType,
      liquidationAmountRequested: liquidation?.liquidationAmountRequested,
      requestDate: liquidation?.requestDate
        ? new Date(liquidation.requestDate)
        : '',
      effectiveDate: '',
      expectedFundsRemittanceDate: liquidation?.sponsorExpectedFundsDate,
      anticipatedPaymentMethod: '',
    },
    isNotProcessable: {
      reason: '',
      otherSummary: '',
      notProcessableSponsorDoc: '',
    },
  }

  const handleSubmit = async (values, { setErrors }) => {
    setSubmitting(true)
    const shouldUploadDocument =
      values.isNotProcessable.reason ===
      IS_NOT_PROCESSABLE_OPTIONS.additionalDocumentRequired

    const payload = {
      ...liquidation,
      isAbletoLiquidate:
        values.isLiquidationProcessable === 'yes' ? true : false,
      //  if you can liquidate
      effectiveDate: values.isProcessable.effectiveDate
        ? moment(values.isProcessable.effectiveDate).format('MM/DD/YYYY')
        : '',
      sponsorExpectedFundsDate: values.isProcessable.expectedFundsRemittanceDate
        ? moment(values.isProcessable.expectedFundsRemittanceDate).format(
            'MM/DD/YYYY'
          )
        : '',
      sponsorPaymentMethod: values.isProcessable.anticipatedPaymentMethod || '',
      // if you can't liquidate
      reasonUnableToLiquidate: values.isNotProcessable.reason || '',
      sponsorComments: values.isNotProcessable.otherSummary || '',
      // if you can't liquidate and need a document there is a separate updateDocument call below
    }

    if (shouldUploadDocument) {
      const fileUploadPromises = Object.entries(values.isNotProcessable)
        .filter((e) => e[1].fileName && e[1].documentID)
        .map((e) => ({
          fileName: e[1].fileName,
          fileContents: e[1].fileContents,
          fileType: e[1].fileType,
          documentID: e[1].documentID,
        }))
        .map((doc) => {
          const formData = new FormData()
          formData.append(
            'fileContents',
            new Blob([doc.fileContents], {
              type: doc.fileType,
            }),
            doc.fileName
          )
          return updateLiquidationDocument(liquidationID, doc, formData)
        })
      const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)
      if (rejectedUploads.length) {
        const errors = createFileErrors(rejectedUploads)
        setErrors(errors)
      } else {
        try {
          await updateLiquidation({ ...payload })
          history.push(`/confirm-liquidation/${liquidationID}/sign`)
        } catch (e) {
          setError(e)
        }
      }
    } else {
      try {
        await updateLiquidation({
          ...payload,
        })
        history.push(`/confirm-liquidation/${liquidationID}/sign`)
      } catch (e) {
        setError(e)
      }
    }
    setSubmitting(false)
  }

  const processableConfirmationValidationSchema = Yup.object().shape({
    isLiquidationProcessable: Yup.string().required('Required'),
    // isProcessableForm
    isProcessable: Yup.object().when('isLiquidationProcessable', {
      is: 'yes',
      then: Yup.object({
        effectiveDate: Yup.date().required('Required'),
        expectedFundsRemittanceDate: Yup.date()
          .min(
            Yup.ref('effectiveDate'),
            'Remittance Date must be on or after the Effective Date.'
          )
          .required('Required'),
        anticipatedPaymentMethod: Yup.string().required('Required'),
      }),
    }),
    isNotProcessable: Yup.object().when('isLiquidationProcessable', {
      is: 'no',
      then: Yup.object()
        .shape({
          reason: Yup.string().required('Required'),
        })
        .when('isNotProcessable.reason', {
          is: IS_NOT_PROCESSABLE_OPTIONS.other,
          then: Yup.object().shape({
            otherSummary: Yup.string().required('Required'),
          }),
          otherwise: Yup.object().when('isNotProcessable.reason', {
            is: IS_NOT_PROCESSABLE_OPTIONS.additionalDocumentRequired,
            then: Yup.object().shape({
              notProcessableSponsorDoc: Yup.object()
                .required('Required')
                .nullable(),
            }),
          }),
        }),
    }),
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={processableConfirmationValidationSchema}
            initialValues={initialValues}
            enableReinitialize={false} // because we purposefully not pre-filling values, we should not have form re-render on initialValue updates
            innerRef={formRef}
          >
            {({
              values,
              touched,
              errors,
              setErrors,
              setTouched,
              setFieldValue,
              resetForm,
              isSubmitting,
              validateForm,
            }) => {
              return (
                <Form className="liquidation-form liquidation-confirmation-form">
                  <div className="container">
                    <h4>Liquidation Details</h4>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          name="isLiquidationProcessable"
                          radioOptions={[
                            { key: 'Yes', value: 'yes' },
                            { key: 'No', value: 'no' },
                          ]}
                          onChange={(e) => {
                            resetForm({
                              values: {
                                isProcessable: {
                                  effectiveDate: '',
                                  anticipatedPaymentMethod: '',
                                  expectedFundsRemittanceDate:
                                    initialValues.isProcessable
                                      .expectedFundsRemittanceDate,
                                  requestDate:
                                    initialValues.isProcessable.requestDate,
                                },
                                isNotProcessable: {
                                  reason: '',
                                  otherSummary: '',
                                  notProcessableSponsorDoc: '',
                                },
                                isLiquidationProcessable: e.target.value,
                              },
                            })
                          }}
                          label="Is the liquidation able to be processed?"
                        />
                      </div>
                      <div className="one-half column">
                        {values.isLiquidationProcessable === 'no' && (
                          <IsNotProcessableForm
                            namespace="isNotProcessable"
                            values={values}
                            setErrors={setErrors}
                            setTouched={setTouched}
                            isSubmitting={isSubmitting}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {values.isLiquidationProcessable === 'yes' && (
                    <IsProcessableForm
                      namespace="isProcessable"
                      values={values}
                      liquidation={liquidation}
                      isSubmitting={isSubmitting}
                    />
                  )}
                  {values.isNotProcessable?.reason ===
                    IS_NOT_PROCESSABLE_OPTIONS.additionalDocumentRequired && (
                    <>
                      <div className="mtc-flow-line">
                        <hr />
                      </div>
                      <div className="row">
                        <div className="twelve column">
                          <DocumentUploadInput
                            keyName="isNotProcessable.notProcessableSponsorDoc"
                            document={sponsorRedemptionFormDocument}
                            setFieldValue={setFieldValue}
                            error={
                              touched?.isNotProcessable
                                ?.notProcessableSponsorDoc &&
                              errors?.isNotProcessable?.notProcessableSponsorDoc
                                ? errors?.isNotProcessable
                                    ?.notProcessableSponsorDoc
                                : errors[
                                    values.isNotProcessable
                                      ?.notProcessableSponsorDoc?.documentID
                                  ]
                            }
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {values.isNotProcessable?.reason ===
                    IS_NOT_PROCESSABLE_OPTIONS.other && (
                    <div className="row">
                      <div className="twelve column">
                        <ACPInputField
                          name={`isNotProcessable.otherSummary`}
                          label="Please provide an explanation as to why the liquidation is unable to be processed at this time"
                          type="text"
                          placeholder="Enter explanation here..."
                          disabled={isSubmitting}
                          textarea
                        />
                      </div>
                    </div>
                  )}
                  <FlowActions
                    onContinue={() => formRef.current.handleSubmit()}
                    validateForm={validateForm}
                    continueCondition={
                      moment(values?.isProcessable?.requestDate).add(
                        365,
                        'days'
                      ) > moment(values?.isProcessable?.effectiveDate) ||
                      values?.isLiquidationProcessable === 'no'
                    }
                    prevStep="templates"
                    submitting={submitting}
                    setTouched={setTouched}
                  />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}

Confirmation.propTypes = propTypes
Confirmation.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {
  updateLiquidation: apiActions.updateLiquidation,
  updateLiquidationDocument: apiActions.updateLiquidationDocument,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  Confirmation
)
