import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import 'ag-grid-community'
import FilterIcon from 'images/filter-icon.svg'
import {
  Card,
  RoleDefinitionModal,
  ACPTable,
  ACPFilterPicklist,
} from 'components'
import {
  stringArrayComparator,
  dateFormatter,
  dateComparator,
  ascendingAlphaSort,
} from 'utils'
import { useHistory, Link } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { Spinner } from 'lp-components'
import { ROLE_DEFINITION, PROFILE_ROLES } from 'config'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { isEmpty } from 'lodash'
import * as Types from 'types'
import WarningIcon from 'images/warning.svg'
import moment from 'moment'

const propTypes = {
  clearContacts: PropTypes.func.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact),
  fetchUserProfile: PropTypes.func.isRequired,
  fetchContactsList: PropTypes.func.isRequired,
}

const defaultProps = {}

const concatenateContactName = (contact) => {
  return (
    contact.contactDetails?.firstName + ' ' + contact.contactDetails?.lastName
  )
}

function fullNameValueGetter(params) {
  return (
    params.data.contactDetails?.firstName +
    ' ' +
    params.data.contactDetails?.lastName
  )
}

const ReviewAndConfirmLinkCellRenderer = (params) => {
  const cellContents =
    !params.data.dateAdded ||
    !params.data.lastUpdated ||
    moment().subtract(6, 'months') > moment(params.data.lastUpdated) ? (
      <Link
        className="table-cell-link"
        to={{
          pathname: `/profiles/${params.data.contactDetails?.contactID}`,
        }}
      >
        Review And Confirm
      </Link>
    ) : (
      params.valueFormatted
    )
  return cellContents
}

const roleSortPriority = Object.values(PROFILE_ROLES)
const gridStyle = { height: '518px', width: '100%' }

const loadingOverlay = `<div class="search-results-helper">
  <div class="overlay-text-title med-18">No results found</div>
  <span class="overlay-text reg-14">It looks like you don't have any contacts yet.</span>
  <span class="overlay-text reg-14">To get started, please select "Add Contacts".</span>
  </div>`

function ContactsTable({
  clearContacts,
  contactsList,
  fetchUserProfile,
  fetchContactsList,
}) {
  const [picklistVisible, setPicklistVisible] = useState(false)
  const [filterContactName, setFilterContactName] = useState('')
  const [filterRoles, setFilterRoles] = useState([])
  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)

  const contactsListReviewRequired = contactsList?.filter(
    (contact) => !contact.dateAdded
  )

  const contacstListNotReviewRequired = contactsList?.filter(
    (contact) => contact.dateAdded
  )

  const needsReviewOld = contactsList?.find(
    (c) =>
      !c.lastUpdated || moment().subtract(6, 'months') > moment(c.lastUpdated)
  )

  useEffect(() => {
    fetchUserProfile()
    fetchContactsList()
    return () => {
      clearContacts()
    }
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const filtersInclude = contactsList?.reduce((acc, currentValue) => {
    currentValue.roles?.forEach((value) => {
      if (!acc.includes(value)) {
        acc.push(value)
      }
    })
    return acc
  }, [])

  const roleOptions = [
    PROFILE_ROLES.MANAGING_MEMBER,
    ...Object.values(PROFILE_ROLES)
      .filter(
        (role) =>
          role !== PROFILE_ROLES.MANAGING_MEMBER &&
          filtersInclude?.includes(role)
      )
      ?.sort(ascendingAlphaSort),
  ]

  const filterCount = filterRoles?.length

  const filteredContacts = useMemo(() => {
    const listToFilter = contacstListNotReviewRequired
    if (isEmpty(listToFilter)) return []

    return listToFilter.filter((contact) => {
      return (
        (filterCount > 0
          ? filterRoles.some((role) => contact.roles.includes(role))
          : true) &&
        (filterContactName
          ? concatenateContactName(contact)
              .toLowerCase()
              .includes(filterContactName.toLowerCase())
          : true)
      )
    })
  }, [contacstListNotReviewRequired, filterRoles, filterContactName])

  const history = useHistory()

  const columnDefs = [
    {
      field: 'name',
      valueGetter: fullNameValueGetter,
      cellRenderer: (params) => {
        return (
          <Link to={`/profiles/${params.data.contactDetails?.contactID}`}>
            {params.value}
          </Link>
        )
      },
      sortable: true,
      wrapText: true,
      headerTooltip: 'Name: The first and last name of the contact.',
      sort: needsReviewOld ? '' : 'asc',
    },
    {
      field: 'role(s)',
      valueGetter: (params) => {
        return params.data.roles?.sort(function(a, b) {
          return roleSortPriority.indexOf(a) - roleSortPriority.indexOf(b)
        })
      },
      comparator: stringArrayComparator,
      cellRenderer: (params) => {
        return (
          <ul className="cell-list">
            {params.data.roles?.slice(0, 2).map((role, index) => {
              return (
                <li className="cell-list-item" key={index}>
                  {role}
                </li>
              )
            })}
            {params.data.roles?.length > 2 && (
              <li>
                <Link
                  className="show-more-link"
                  to={`/profiles/${params.data.contactDetails?.contactID}`}
                >
                  {`Show +${params.data.roles?.length - 2} more`}
                </Link>
              </li>
            )}
          </ul>
        )
      },
      wrapText: true,
      autoHeight: true,
      sortable: true,
      minWidth: 210,
      cellClass: 'contact-table-role-cell',
      headerTooltip: 'Roles: The role Inspira has assigned to the contact.',
    },
    {
      field: 'assetAssignments',
      headerName: 'Asset Assignment(s)',
      cellRenderer: (params) => {
        return (
          <ul className="cell-list">
            {params.data.assets?.slice(0, 2).map((asset, index) => {
              return (
                <li className="cell-list-item" key={index}>
                  {asset}
                </li>
              )
            })}
            {params.data.assets?.length > 2 && (
              <li>
                <Link
                  className="show-more-link"
                  to={`/profiles/${params.data.contactDetails?.contactID}`}
                >
                  {`Show +${params.data.assets?.length - 2} more`}
                </Link>
              </li>
            )}
          </ul>
        )
      },
      valueGetter: (params) => {
        return params.data.assets?.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      },
      comparator: stringArrayComparator,
      autoHeight: true,
      minWidth: 225,
      headerClass: 'contact-table-asset-assignment-header',
      cellClass: 'contact-table-asset-assignment-cell',
      sortable: true,
      headerTooltip: 'Asset Assignments: The asset assigned to this contact.',
    },
    {
      field: 'lastAccessed',
      sortable: true,
      headerTooltip:
        'Last Accessed: The date the employee last logged into the platform.',
      valueFormatter: dateFormatter,
      comparator: dateComparator,
    },
    {
      field: 'lastUpdated',
      sortable: true,
      headerTooltip:
        'Last Updated: The date the contact record was last updated.',
      valueFormatter: dateFormatter,
      cellRenderer: ReviewAndConfirmLinkCellRenderer,
      comparator: dateComparator,
      sort: needsReviewOld ? 'asc' : '', //Set default sort of name as ascending.
    },
    {
      field: 'dateAdded',
      sortable: true,
      headerTooltip:
        'Date Added: The date the contact was added to the platform.',
      valueFormatter: dateFormatter,
      comparator: dateComparator,
    },
  ]

  const reviewColumnDefs = [
    {
      field: 'name',
      valueGetter: fullNameValueGetter,
      cellRenderer: (params) => {
        return (
          <Link to={`/profiles/${params.data.contactDetails?.contactID}`}>
            {params.value}
          </Link>
        )
      },
      sortable: true,
      wrapText: true,
      headerTooltip: 'Name: The first and last name of the contact.',
      sort: 'asc', //Set default sort of name as ascending.
    },
    {
      field: 'role(s)',
      valueGetter: (params) => {
        return params.data.roles?.sort(function(a, b) {
          return roleSortPriority.indexOf(a) - roleSortPriority.indexOf(b)
        })
      },
      comparator: stringArrayComparator,
      cellRenderer: (params) => {
        return (
          <ul className="cell-list">
            {params.data.roles?.slice(0, 2).map((role, index) => {
              return (
                <li className="cell-list-item" key={index}>
                  {role}
                </li>
              )
            })}
            {params.data.roles?.length > 2 && (
              <li>
                <Link
                  className="show-more-link"
                  to={`/profiles/${params.data.contactDetails?.contactID}`}
                >
                  {`Show +${params.data.roles?.length - 2} more`}
                </Link>
              </li>
            )}
          </ul>
        )
      },
      wrapText: true,
      autoHeight: true,
      sortable: true,
      minWidth: 210,
      cellClass: 'contact-table-role-cell',
      headerTooltip: 'Roles: The role Inspira has assigned to the contact.',
    },
    {
      field: 'assetAssignments',
      headerName: 'Asset Assignment(s)',
      cellRenderer: (params) => {
        return (
          <ul className="cell-list">
            {params.data.assets?.slice(0, 2).map((asset, index) => {
              return (
                <li className="cell-list-item" key={index}>
                  {asset}
                </li>
              )
            })}
            {params.data.assets?.length > 2 && (
              <li>
                <Link
                  className="show-more-link"
                  to={`/profiles/${params.data.contactDetails?.contactID}`}
                >
                  {`Show +${params.data.assets?.length - 2} more`}
                </Link>
              </li>
            )}
          </ul>
        )
      },
      valueGetter: (params) => {
        return params.data.assets?.sort((a, b) => {
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      },
      comparator: stringArrayComparator,
      autoHeight: true,
      minWidth: 225,
      headerClass: 'contact-table-asset-assignment-header',
      cellClass: 'contact-table-asset-assignment-cell',
      sortable: true,
      headerTooltip: 'Asset Assignments: The asset assigned to this contact.',
    },
    {
      field: 'dateAdded',
      sortable: true,
      headerName: '',
      headerTooltip:
        'Date Added: The date the contact was added to the platform.',
      valueFormatter: dateFormatter,
      cellRenderer: ReviewAndConfirmLinkCellRenderer,
      comparator: dateComparator,
    },
  ]

  const onFilterTextBoxChanged = (e) => {
    setFilterContactName(e.target.value)
  }

  const handleExternalFilterChange = (newValue) => {
    setFilterRoles(newValue)
  }

  const applyFilters = (values) => {
    handleExternalFilterChange(values.filter)
    setPicklistVisible(false)
  }

  const contactsTableActions = (
    <div className="contacts-table">
      <div className="table-actions">
        <div className="table-actions-left">
          <input
            type="text"
            id="filter-text-box"
            placeholder="Search Contact Name"
            onInput={onFilterTextBoxChanged}
          />
        </div>
        <div className="table-actions-right">
          <div className="add-contact-button-container">
            <button
              className="button-primary"
              onClick={() => {
                history.push('/contacts/add-contact/contact-information')
              }}
            >
              Add Contact
            </button>
          </div>
          <button
            onClick={() => {
              setPicklistVisible(!picklistVisible)
            }}
          >
            <img src={FilterIcon} alt="" />
            Filters: {filterCount > 0 ? 'Roles ' + filterCount : 'None'}
          </button>
        </div>
      </div>
    </div>
  )

  if (!contacstListNotReviewRequired) {
    return <Spinner />
  }

  return (
    <>
      {contactsListReviewRequired?.length > 0 && (
        <Card
          label={
            <span>
              <img
                className="urgent-icon med-14"
                src={WarningIcon}
                alt="Warning"
              />
              Contacts Pending Review
            </span>
          }
          className="review-contacts-card"
        >
          <div className="content-span">
            {
              'Review and confirm if these contacts are correct. You can edit or deactivate, if needed. Once you confirm a contact is correct, we’ll send them an invite to this platform.'
            }
          </div>
          <div className="content-span">
            {
              'If a Third-Party Administrator (TPA) needs to access subscription documents and confirm transactions, make sure their information is correct or add them as a contact below so they can receive an invite to this platform.'
            }
          </div>
          <ACPTable
            columnDefs={reviewColumnDefs}
            rowData={contactsListReviewRequired}
            overrideGridStyle={gridStyle}
            paginationPageSize="10"
            additionalDefaultColDef={{ sortable: true }}
            overlayLoadingTemplate={loadingOverlay}
          />
        </Card>
      )}
      <br />
      <Card
        label="Contacts and Asset Assignment(s) Details"
        actions={contactsTableActions}
        className="contacts-card"
      >
        <div className="contacts-roles-span">
          To learn more about roles,{' '}
          <button
            className="modal-button-link"
            type="button"
            onClick={() => setShowRoleDefinitionModal(true)}
          >
            click here.
          </button>
        </div>
        {needsReviewOld && (
          <div className="banner-icon-medium">
            <img
              className="urgent-icon med-14"
              src={WarningIcon}
              alt="Urgent"
            />
            <span>
              It's time to review your contact list. Please review and confirm
              each contact's information.
            </span>
          </div>
        )}
        <hr />
        <Formik
          onSubmit={applyFilters}
          initialValues={{
            filter: [],
          }}
        >
          {({ values, setFieldValue }) => (
            <ACPTable
              columnDefs={columnDefs}
              rowData={filteredContacts}
              paginationPageSize="10"
              overrideGridStyle={gridStyle}
              additionalDefaultColDef={{ sortable: true }}
              overlayLoadingTemplate={loadingOverlay}
            >
              {picklistVisible && (
                <Form>
                  <ACPFilterPicklist
                    handleExternalFilterChange={handleExternalFilterChange}
                    setPicklistVisible={setPicklistVisible}
                    values={values}
                    setFieldValue={setFieldValue}
                    currentFilter={filterRoles}
                    options={roleOptions}
                    onClose={applyFilters}
                    title={'Role'}
                    overrideClassName={
                      contactsListReviewRequired?.length > 0
                        ? 'contacts-input-wrapper'
                        : undefined
                    }
                  />
                </Form>
              )}
            </ACPTable>
          )}
        </Formik>
      </Card>
      {showRoleDefinitionModal && (
        <RoleDefinitionModal
          onClose={() => setShowRoleDefinitionModal(false)}
          title="Definition of Roles"
          roles={ROLE_DEFINITION}
        />
      )}
    </>
  )
}

ContactsTable.propTypes = propTypes

ContactsTable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  fetchUserProfile: apiActions.fetchUserProfile,
  fetchContactsList: apiActions.fetchContactsList,
  clearContacts: actions.clearContacts,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactsTable
)
