import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Tooltip } from 'components'

const propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

const defaultProps = {
  title: '',
  content: '',
}

function TitleWithToolTip({ title, content }) {
  return (
    <div className="tool-tip-block">
      <h4>{`${title} Details`}</h4>
      {content && (
        <Tooltip
          showIcon={true}
          showText={true}
          visible={true}
          imageAlt={`${title} Tooltip`}
        >
          {content}
        </Tooltip>
      )}
    </div>
  )
}

TitleWithToolTip.propTypes = exact(propTypes)
TitleWithToolTip.defaultProps = defaultProps

export default React.memo(TitleWithToolTip)
