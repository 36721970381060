import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { FlowActions } from '../components'
import { isEmpty } from 'lodash'
import { DownloadIconButton } from 'components'

const propTypes = {
  fetchExchangeConfirmation: PropTypes.func.isRequired,
  fetchDocument: PropTypes.func.isRequired,
  purchase: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

function Templates({ purchase, fetchDocument, fetchExchangeConfirmation }) {
  const history = useHistory()
  const { exchangeID } = useParams()

  useEffect(() => {
    if (isEmpty(purchase)) fetchExchangeConfirmation(exchangeID)
  }, [purchase, exchangeID])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content">
          <div className="flow-header-help-text">
            <h4 className="flow-section-headers">
              Exchange Confirmation Instructions
            </h4>
            <p>
              Executed subscription and other documents related to this exchange
              request are provided below, as required. Please download for your
              review and records. These documents have also been mailed to you
              if you indicated that you need original documents. Please note
              that these documents supersede any subscription documents you may
              have received directly from the investor. If you have already
              received and processed sub docs from the investor, please update
              your records accordingly. In particular, please review investor
              registration, wire instructions and contact information as
              Inspira’s may be different, and the accuracy of this information
              is critical to future processing and our ability to custody this
              asset.
            </p>
            <p>
              Upon completing this exchange, please return to this platform to
              confirm this exchange confirmation in the next sections.
            </p>
          </div>

          <h4 className="flow-section-headers">Documents to Download</h4>

          <div className="document-content">
            {purchase.downloadDocuments.map((doc) => {
              return (
                <div
                  className="document-content-item"
                  key={doc.requiredDocumentID}
                >
                  <p>{doc.requiredDocumentName}</p>
                  <DownloadIconButton
                    onClick={() =>
                      fetchDocument(
                        exchangeID,
                        doc.requiredDocumentID,
                        doc.requiredDocumentName
                      )
                    }
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <FlowActions
        prevStep="exchange-authorization"
        onContinue={() =>
          history.push(`/confirm-exchange/${exchangeID}/document-upload`)
        }
      />
    </div>
  )
}

Templates.propTypes = propTypes
Templates.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    purchase: selectors.exchangeConfirmation(state),
  }
}

const mapDispatchToProps = {
  fetchExchangeConfirmation: apiActions.fetchExchangeConfirmation,
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Templates)
