import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button, SubmitButton } from 'lp-components'
import { useHistory } from 'react-router-dom'
import { noop } from 'lodash'
import { ExitWithoutSavingModal } from 'components'
import RightArrow from 'images/right-arrow-icon.svg'

const propTypes = {
  onContinue: PropTypes.func,
  submitting: PropTypes.bool,
  submitContent: PropTypes.node,
}

const defaultProps = {
  onContinue: noop,
  submitting: false,
  submitContent: 'Continue',
}

function FlowActions({ onContinue, submitting, submitContent }) {
  const [modalShown, setModalShown] = useState(false)
  const history = useHistory()

  return (
    <div className="flow-button-container">
      <div className="flow-button-inner">
        <Button
          className="button-secondary quit-button"
          onClick={() => setModalShown(true)}
        >
          Quit & Exit
        </Button>
        <SubmitButton onClick={onContinue} submitting={submitting}>
          {submitContent}{' '}
          {!submitting && (
            <img
              src={RightArrow}
              className="right-arrow-icon"
              alt="Right Arrow"
            />
          )}
        </SubmitButton>
        {modalShown && (
          <ExitWithoutSavingModal
            onClose={() => setModalShown(false)}
            onExit={() => history.push('/home')}
            workFlowName="contact"
          />
        )}
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
