const PERMISSION_FOR_COMPONENTS = {
  AddAssetRoutes: ['addAssets'],
  AddNewAssetButton: ['addAssets'],
  'add-asset': ['addAssets'],
  'confirm-liquidation': ['editLiquidations'],
  'confirm-exchange': ['editExchanges'],
  'add-capital-call': ['addCapitalCall'],
}

const getUserPermissions = () => {
  if (!window.store) return {}
  const permissions =
    window.store.getState()?.root?.global?.currentUser?.permissions || {}
  return permissions
}

const getActiveUserPermissions = () => {
  const activeUserPermissions = []
  const userPermissions = getUserPermissions()
  const permissionNames = Object.keys(userPermissions)
  for (let i = 0; i < permissionNames.length; i++) {
    if (userPermissions[permissionNames[i]])
      activeUserPermissions.push(permissionNames[i])
  }
  return activeUserPermissions
}

const hasPermission = ({ componentName }) => {
  const activeUserPermissions = getActiveUserPermissions()
  const permissions = PERMISSION_FOR_COMPONENTS[componentName] || []
  return permissions.some((permission) =>
    activeUserPermissions.includes(permission)
  )
}

function withPermission(componentName) {
  const formattedName = componentName.replace('/', '')
  const shouldRender = hasPermission({ componentName: formattedName })
  return shouldRender
}

export default withPermission
