import React from 'react'
import PropTypes from 'prop-types'
import { FlowActions } from '../components'
import { DocumentUploadInput } from 'components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

const propTypes = {
  exchangeConfirmation: PropTypes.object.is,
  handleSubmit: PropTypes.func.isRequired,
}
const defaultProps = {}

function DocumentUploadForm({ exchangeConfirmation, handleSubmit }) {
  const lazyValidation = Yup.lazy(() => {
    return Yup.object(
      exchangeConfirmation.requiredDocuments.reduce((acc, doc) => {
        if (doc.isDocumentRequired) {
          acc[doc.requiredDocumentID] = Yup.object().required(
            'Please attach the required document'
          )
          return acc
        }
        return acc
      }, {})
    )
  })

  const initialValues = exchangeConfirmation.requiredDocuments.reduce(
    (acc, doc) => {
      if (doc.documentID) {
        acc[doc.requiredDocumentID] = doc
      }
      return acc
    },
    {}
  )

  return (
    <div className="form-card-content">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={lazyValidation}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ errors, setFieldValue, isSubmitting }) => {
          return (
            <Form className="asset-form asset-details-form">
              <div className="card">
                <h4>Documents To Upload</h4>
                <p>
                  Upload any required document(s) that Inspira Financial must
                  complete related to this exchange. Only required documents for
                  this transaction should be uploaded here. Any other documents
                  will not be reviewed or processed.
                </p>
                {exchangeConfirmation.requiredDocuments.map((doc) => {
                  return (
                    <DocumentUploadInput
                      key={doc.requiredDocumentID}
                      keyName={doc.requiredDocumentID}
                      document={{
                        ...doc,
                        name: doc.requiredDocumentName,
                      }}
                      setFieldValue={setFieldValue}
                      error={errors[doc.requiredDocumentID]}
                    />
                  )
                })}
              </div>
              <FlowActions
                prevStep="transaction-documents"
                submitting={isSubmitting}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
DocumentUploadForm.propTypes = propTypes
DocumentUploadForm.defaultProps = defaultProps
export default DocumentUploadForm
