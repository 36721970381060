import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import {
  SearchableSelect,
  ACPInputField,
  LabelWithTooltip,
  DocumentUploadInput,
} from 'components'
import { Spinner, SubmitButton } from 'lp-components'
import { useFormikContext } from 'formik'

const propTypes = {
  fetchClients: PropTypes.func.isRequired,
  fetchDocumentFormIdNumbers: PropTypes.func.isRequired,
  normalizedClients: PropTypes.arrayOf(PropTypes.object),
  normalizeFormIdNumbers: PropTypes.arrayOf(PropTypes.object),
  documentType: PropTypes.string.isRequired,
}

const defaultProps = {}

function MTCFormFields({
  fetchClients,
  fetchDocumentFormIdNumbers,
  normalizedClients,
  normalizeFormIdNumbers,
  documentType,
}) {
  const {
    values,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isSubmitting,
    errors,
    touched,
  } = useFormikContext()

  useEffect(() => {
    fetchDocumentFormIdNumbers(documentType)
  }, [documentType])

  useEffect(() => {
    fetchClients()
  }, [])

  if (!normalizeFormIdNumbers) return <Spinner />

  return (
    <div className="sponsor-form-fields">
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name="isExistingClient"
            label="Is the Inspira Document for an existing or new client?"
            radioOptions={[
              { key: 'Existing Client', value: true },
              { key: 'New Client', value: false },
            ]}
            onChange={() => {
              setFieldValue('accountNumber', '', false)
              setFieldError('accountNumber', '')
              setFieldTouched('accountNumber', false, false)

              setFieldValue('clientFirstName', '', false)
              setFieldError('clientFirstName', '')
              setFieldTouched('clientFirstName', false, false)

              setFieldValue('clientLastName', '', false)
              setFieldError('clientLastName', '')
              setFieldTouched('clientLastName', false, false)

              setFieldValue('formNumber', '', false)
              setFieldError('formNumber', '')
              setFieldTouched('formNumber', false, false)

              setFieldValue('addSubscriptionDocument', false)
              setFieldValue('addPrivatePlacementDocument', false)
              setFieldValue('subscriptionDocument', [])
            }}
          />
        </div>
      </div>
      {values.isExistingClient && (
        <div className="row">
          <div className="one-half column">
            {normalizedClients && (
              <SearchableSelect
                classNamePrefix="filter"
                isClearable
                closeMenuOnSelect
                label="Search by Client Name *"
                name="accountNumber"
                options={normalizedClients}
                placeholder="Enter Client Name"
              />
            )}
          </div>
        </div>
      )}
      {!values.isExistingClient && (
        <div className="row">
          <div className="one-half column">
            <ACPInputField
              name="clientFirstName"
              label="First Name"
              placeholder="Enter First Name"
            />
          </div>
          <div className="one-half column">
            <ACPInputField
              name="clientLastName"
              label="Last Name"
              placeholder="Enter Last Name"
            />
          </div>
        </div>
      )}
      {(values.accountNumber ||
        (values.clientFirstName && values.clientLastName)) && (
        <div className="row">
          <div className="one-half column">
            <ACPInputField
              name="formNumber"
              label="Document Type"
              selectOptions={normalizeFormIdNumbers}
              enablePlaceholderOption={true}
              placeholder="Select Document Name"
              onChange={() => setFieldValue('files', [])}
            />
          </div>
        </div>
      )}
      <>
        {values.formNumber === 'ALT-020' && (
          <LabelWithTooltip label="Private Placement Purchase/Sale/Exchange Direction" />
        )}
        {values.formNumber === 'ALT-020' && !values.addSubscriptionDocument && (
          <p className="documentUploadHelpText">
            If you have an accompanying Subscription Document for this client,
            you can add it by clicking the button below.
          </p>
        )}
        {!values.addSubscriptionDocument && (
          <DocumentUploadInput
            keyName="documents.uploadNewDocument"
            setFieldValue={setFieldValue}
            label="Browse Files"
            multiple={false}
            document={{
              isDocumentRequired: true,
            }}
            error={
              touched?.documents && errors?.documents
                ? errors?.documents['uploadNewDocument']
                : errors['myUploadsError']
            }
          />
        )}
        {values.formNumber === 'ALT-020' && !values.addSubscriptionDocument && (
          <>
            <button
              type="button"
              className="button-primary-outline"
              onClick={() => {
                setFieldValue('documents.uploadNewDocument', '')
                setFieldValue('addSubscriptionDocument', true)
              }}
            >
              + Add Subscription Document
            </button>
            <br />
            <br />
          </>
        )}
        {values.addSubscriptionDocument && (
          <>
            <DocumentUploadInput
              keyName="documents.uploadNewDocument"
              setFieldValue={setFieldValue}
              label="Browse Files"
              multiple={false}
              acceptedFileTypes={['application/pdf']}
              acceptedFileTypeNames={['PDF']}
              document={{
                isDocumentRequired: true,
              }}
              error={
                touched?.documents && errors?.documents
                  ? errors?.documents['uploadNewDocument']
                  : errors['myUploadsError']
              }
            />
            <LabelWithTooltip label="Subscription Document" />
            <DocumentUploadInput
              keyName="documents.subscriptionDocument"
              setFieldValue={setFieldValue}
              label="Browse Files"
              multiple={false}
              acceptedFileTypes={['application/pdf']}
              acceptedFileTypeNames={['PDF']}
              document={{
                ...values.documents?.subscriptionDocument,
                isDocumentRequired: false,
              }}
              error={errors['myUploadsError']}
            />
          </>
        )}
        <div className="document-upload-flow-button-container">
          <SubmitButton submitting={isSubmitting}>
            Submit Document(s)
          </SubmitButton>
        </div>
      </>
    </div>
  )
}

MTCFormFields.propTypes = propTypes
MTCFormFields.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    normalizeFormIdNumbers: selectors.normalizeFormIdNumbers(state),
    normalizedClients: selectors.normalizedClients(state),
  }
}

const mapDispatchToProps = {
  fetchDocumentFormIdNumbers: apiActions.fetchDocumentFormIdNumbers,
  fetchClients: apiActions.fetchClients,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  MTCFormFields
)
