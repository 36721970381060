import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { SubmitButton } from 'lp-components'
import { Link } from 'react-router-dom'
import { noop } from 'lodash'

const propTypes = {
  onContinue: PropTypes.func,
  submitting: PropTypes.bool,
  submitContent: PropTypes.node,
}

const defaultProps = {
  onContinue: noop,
  submitting: false,
  submitContent: 'Continue',
}

function FlowActions({ onContinue, submitting, submitContent }) {
  return (
    <div className="flow-button-container">
      <div className="flow-button-inner">
        <div>
          <Link
            className="button-secondary"
            to={{
              pathname: '/home',
            }}
          >
            Quit & Exit
          </Link>
          <SubmitButton onClick={onContinue} submitting={submitting}>
            {submitContent}
          </SubmitButton>
        </div>
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
