import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'
import { useHistory } from 'react-router-dom'
import AssetSpecificForm from './AssetSpecificForm'
import { assets } from 'config'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
}
const defaultProps = {}

function AddAssetModal({ onClose, path }) {
  const [selectedAsset, setSelectedAsset] = useState(null)
  const history = useHistory()

  return (
    <Modal className="modal-inner modal-new-asset" onClose={onClose}>
      <AssetSpecificForm
        onClose={onClose}
        onChange={({ assetType }) => {
          const asset = assets[assetType]
          if (asset === selectedAsset) return
          setSelectedAsset(asset)
        }}
        onSubmit={(params) => {
          history.push({
            pathname: path,
            state: { params: params },
          })
        }}
        asset={selectedAsset}
      />
    </Modal>
  )
}

AddAssetModal.propTypes = exact(propTypes)
AddAssetModal.defaultProps = defaultProps

export default AddAssetModal
