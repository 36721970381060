import React from 'react'
import StatusClockIcon from 'images/status-time.svg'
import StatusFailedIcon from 'images/status-failed.svg'
import StatusSuccessIcon from 'images/status-confirmed.svg'
import StatusUrgentIcon from 'images/status-urgent.svg'
import {
  PENDING_MTC_TRANSACTION_STATUSES,
  PENDING_USER_TRANSACTION_STATUSES,
  FAILED_TRANSACTION_STATUSES,
  COMPLETED_TRANSACTION_STATUSES,
} from 'config'

function StatusRenderer(props) {
  const cellValue = props.value
  let statusIcon

  if (PENDING_MTC_TRANSACTION_STATUSES.includes(cellValue)) {
    statusIcon = StatusClockIcon
  } else if (PENDING_USER_TRANSACTION_STATUSES.includes(cellValue)) {
    statusIcon = StatusUrgentIcon
  } else if (FAILED_TRANSACTION_STATUSES.includes(cellValue)) {
    statusIcon = StatusFailedIcon
  } else if (COMPLETED_TRANSACTION_STATUSES.includes(cellValue)) {
    statusIcon = StatusSuccessIcon
  } else {
    return cellValue
  }

  return (
    <div className="status-cell-wrapper">
      <span className="status-icon">
        <img
          style={{ height: '16px', width: '16px', marginBottom: '-4px' }}
          src={statusIcon}
          alt="Status Icon"
        ></img>
      </span>
      <span>{cellValue}</span>
    </div>
  )
}

export default React.memo(StatusRenderer)
