import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { DocumentUploadInput, Card } from 'components'
import { SubmitButton, Spinner } from 'lp-components'
import { useParams, useHistory } from 'react-router'
import { selectors } from '../reducer'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { bulkPromiseUploads, createFileErrors } from 'utils'
import * as flashActions from 'redux-flash'

const propTypes = {
  fetchResolutionItems: PropTypes.func.isRequired,
  fetchTaskItem: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  uploadResolutionDocument: PropTypes.func.isRequired,
  taskResolutionItems: PropTypes.array,
  clearResolutionItems: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
}

const defaultProps = {}

function TaskResolution({
  fetchResolutionItems,
  taskResolutionItems,
  flashSuccessMessage,
  flashErrorMessage,
  uploadResolutionDocument,
  clearResolutionItems,
  fetchTaskItem,
  updateTask,
  task,
}) {
  const params = useParams()
  const { taskId, subType, recordId } = params
  const [initialValues, setInitialValues] = useState({})
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchTaskItem(taskId)
    fetchResolutionItems({ subType, id: recordId })
    return () => clearResolutionItems()
  }, [])

  useEffect(() => {
    if (!taskResolutionItems) return
    let values = {}
    taskResolutionItems.forEach((doc) => {
      if (doc.documentID) {
        values[doc.requiredDocumentID] = {
          ...doc,
        }
      }
    })
    setInitialValues(values)
    setIsLoading(false)
  }, [taskResolutionItems])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const lazyValidation = Yup.lazy(() => {
    const validationObj = {}

    taskResolutionItems.forEach((doc) => {
      validationObj[doc.requiredDocumentID] = doc.isDocumentRequired
        ? Yup.object().required('Please attach the required document')
        : Yup.object().notRequired()
    })

    const validationObject = Yup.object(validationObj)

    return validationObject
  })

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (values, { setErrors }) => {
    setIsSubmitting(true)
    const fileUploadPromises = Object.entries(values)
      .filter((e) => e[1].fileName && e[1].documentID)
      .map((e) => ({
        fileName: e[1].fileName,
        documentID: e[1].documentID,
        fileContents: e[1].fileContents,
        fileType: e[1].fileType,
        requiredDocumentName: e[1].requiredDocumentName,
      }))
      .map((doc) => {
        const formData = new FormData()
        formData.append(
          'fileContents',
          new Blob([doc.fileContents], {
            type: doc.fileType,
          }),
          doc.fileName
        )
        formData.append(
          'metadata',
          `'${JSON.stringify({
            requiredDocumentName: doc.requiredDocumentName,
          })}'`
        )
        return uploadResolutionDocument({
          id: recordId,
          document: doc,
          formData,
          subType,
        })
      })

    const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)

    if (rejectedUploads.length) {
      const errors = createFileErrors(rejectedUploads)
      setErrors(errors)
    } else {
      try {
        await updateTask([{ taskID: taskId, status: 'Completed' }])
        flashSuccessMessage('We received your resolution documents.')
        history.push('/home')
      } catch (e) {
        flashErrorMessage(
          'We were not able to upload your documents. Please try again.'
        )
      }
    }
    setIsSubmitting(false)
  }

  return (
    <Card className="funding-card" label="Additional Documents Required">
      <div className="resolution-instructions">
        <p>{task?.description}</p>
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={lazyValidation}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({ errors, setFieldValue }) => (
            <Form>
              {taskResolutionItems?.map((doc, index) => (
                <DocumentUploadInput
                  key={index}
                  document={doc}
                  setFieldValue={setFieldValue}
                  error={errors[doc.requiredDocumentID]}
                />
              ))}
              <div className="document-upload-flow-button-container">
                <SubmitButton submitting={isSubmitting}>
                  Submit Documents
                </SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Card>
  )
}

TaskResolution.propTypes = propTypes
TaskResolution.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    taskResolutionItems: selectors.currentTaskResolutionItems(state),
    task: selectors.task(state),
  }
}

const mapDispatchToProps = {
  fetchResolutionItems: apiActions.fetchResolutionItems,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  uploadResolutionDocument: apiActions.uploadResolutionDocument,
  clearResolutionItems: actions.clearResolutionItems,
  fetchTaskItem: apiActions.fetchTaskItem,
  updateTask: apiActions.updateTask,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TaskResolution
)
