import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { CurrencyInput, ACPInputField } from 'components'
import { EXCHANGE_CONFIRMATION_REQUEST_TYPE } from 'config'
import currency from 'currency.js'

const propTypes = {
  item: PropTypes.object,
}

function isNumber(value) {
  if (value) {
    return !window.isNaN(value)
  }
  return false
}

const defaultProps = {}

const DisplayInput = (props) => {
  const { item } = props
  return item.requestedType === EXCHANGE_CONFIRMATION_REQUEST_TYPE.AMOUNT &&
    isNumber(item.requestedValue) ? (
    <CurrencyInput {...props} value={currency(item.requestedValue)} />
  ) : (
    <ACPInputField {...props} value={item.requestedValue} />
  )
}

DisplayInput.propTypes = exact(propTypes)
DisplayInput.defaultProps = defaultProps

export default React.memo(DisplayInput)
