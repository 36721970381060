import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onExit: PropTypes.func.isRequired,
  workFlowName: PropTypes.string.isRequired,
}

const defaultProps = {}

function ExitWithoutSavingModal({ onClose, onExit, workFlowName }) {
  const bodyText = `You are about to exit the ${workFlowName} workflow without saving. Are you sure
 you want to do this?`

  return (
    <Modal onClose={onClose}>
      <h2>Exit Without Saving</h2>
      <p>{bodyText}</p>
      <hr></hr>
      <div className="button-wrapper">
        <button className="button-secondary" onClick={onClose}>
          Cancel
        </button>
        <button className="button-primary" onClick={onExit}>
          Yes, Exit Without Saving
        </button>
      </div>
    </Modal>
  )
}

ExitWithoutSavingModal.propTypes = exact(propTypes)
ExitWithoutSavingModal.defaultProps = defaultProps

export default React.memo(ExitWithoutSavingModal)
