import * as moment from 'moment'

const dateFormatter = (params) => {
  const { value } = params
  return (
    value &&
    moment(params.value).isValid() &&
    moment(params.value).format('MM/DD/YYYY')
  )
}

export default dateFormatter
