import { handleActions } from 'redux-actions'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'
import * as invitationActions from './actions'

const reducerKey = 'invitation'
const slice = 'root.invitation'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchReferralLink]: setOnSuccess('referralLink'),
    [invitationActions.clearReferralLink]: unsetState('referralLink'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  referralLink: select('referralLink'),
}

export { reducer, selectors, reducerKey }
