import React, { useState, useCallback, useMemo, useRef } from 'react'
import 'ag-grid-community'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card, ACPTable } from 'components'
import { Spinner } from 'lp-components'
import { Link, useLocation } from 'react-router-dom'
import { formatNumberAsCurrency, dateComparator, numberComparator } from 'utils'
import {
  AVAILABLE_DOCUMENT_TYPES,
  TRANSACTION_TYPE,
  DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS,
} from 'config'

const propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object),
  downloadTransactionType: PropTypes.string,
}

const defaultProps = {
  downloadTransactionType: '',
}

const loadingOverlay = `<div class="search-results-helper">
  <div class="overlay-text-title med-18">No results found</div>
  <span class="overlay-text reg-14">It looks like you don't have any documents yet.</span>
  <span class="overlay-text reg-14">To get started, please select "Upload Documents" under "My Uploads".</span>
  </div>`

const typeFormatter = (params) => {
  return params.value.replace('_', ' ')
}

const LinkCellRenderer = (params, location) => {
  return (
    <Link
      className="table-cell-link"
      to={{
        pathname: `/documents/${
          DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[params.data.type]
        }/downloads/${params.data.transactionID}`,
        state: {
          transactionType: params.data.type,
          target: location.pathname,
          goBackDashboard: true,
          backTo: 'Documents',
        },
      }}
    >
      View Documents
    </Link>
  )
}

function DocumentsTable({ documents, downloadTransactionType }) {
  const location = useLocation()
  const [rowFilterSize, setRowFilterSize] = useState(null)
  const gridRef = useRef()
  const gridStyle = useMemo(() => ({ height: '518px', width: '100%' }), [])

  const selectOptions = [
    {
      label: AVAILABLE_DOCUMENT_TYPES.PURCHASE,
      value: DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[TRANSACTION_TYPE.PURCHASE],
    },
    {
      label: AVAILABLE_DOCUMENT_TYPES.CAPITAL_COMMITMENT,
      value:
        DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[
          TRANSACTION_TYPE.CAPITAL_COMMITMENT
        ],
    },
  ]

  const [selectedType, setSelectedType] = useState(
    downloadTransactionType || selectOptions[0].value
  )

  let filteredDocs = documents.filter((document) => {
    if (
      selectedType ===
      DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[TRANSACTION_TYPE.CAPITAL_COMMITMENT]
    ) {
      return document.type === TRANSACTION_TYPE.CAPITAL_COMMITMENT
    }
    return document.type !== TRANSACTION_TYPE.CAPITAL_COMMITMENT
  })

  const columnDefs = [
    {
      field: 'accountName',
      headerName: 'Investor Name',
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellClass: 'cell-wrap-text doc-table-investor-name-cell',
      sortable: true,
      lockPosition: true,
      justifyContent: 'center',
      cellStyle: {
        color: '#196664',
        fontWeight: 'bold',
      },
      headerTooltip: 'Investor Name: The name of the Investor.',
    },
    {
      field: 'assetName',
      headerName: 'Asset Name',
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellClass: 'cell-wrap-text doc-table-asset-name-cell',
      sortable: true,
      headerTooltip:
        'Asset Name: The legal name as stated in the offering document.',
      filter: 'agTextColumnFilter',
    },
    ...(selectedType ===
    DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[TRANSACTION_TYPE.CAPITAL_COMMITMENT]
      ? [
          {
            field: 'transactionAmount',
            headerName: 'Commitment Amount',
            headerTooltip:
              'Commitment Amount: The total capital commitment by the client in the asset.',
            sortable: true,
            type: 'rightAligned',
            cellClass: 'table-right-align',
            minWidth: 220,
            valueFormatter: (params) => formatNumberAsCurrency(params.value),
            comparator: numberComparator,
          },
        ]
      : []),
    ...(selectedType ===
    DOWNLOAD_TRANSACTION_TYPE_DOCUMENTS[TRANSACTION_TYPE.CAPITAL_COMMITMENT]
      ? [
          {
            field: 'transactionCompleteDate',
            headerName: 'Commitment Document Date Sent',
            minWidth: 100,
            sortable: true,
            headerTooltip:
              'Commitment Document Date Sent: The date that Inspira Financial countersigns the subscription documents and uploads to platform.',
            comparator: dateComparator,
          },
        ]
      : [
          {
            field: 'transactionCompleteDate',
            headerName: 'Date Of Completion',
            headerTooltip:
              'Date Of Completion: The date Inspira processed this transaction.',
            sortable: true,
            minWidth: 100,
            comparator: dateComparator,
          },
        ]),
    {
      field: 'type',
      headerName: 'Transaction Type',
      valueFormatter: typeFormatter,
      sortable: true,
      minWidth: 100,
      headerTooltip: 'Transaction Type: The type of completed transaction.',
    },
    {
      field: 'documents',
      headerName: 'Documents',
      cellRenderer: (params) => LinkCellRenderer(params, location),
      headerTooltip:
        'Documents: The documents related to the purchase transaction.',
      minWidth: 200,
    },
  ]

  const onFilterTextBoxChanged = useCallback(() => {
    const model = {
      assetName: {
        filterType: 'text',
        type: 'contains',
        filter: document.getElementById('filter-text-box').value,
      },
    }
    gridRef.current.api.setFilterModel(model)
    getDisplayedRowCount()
  }, [])

  const getDisplayedRowCount = useCallback(() => {
    const count = gridRef.current.api.getDisplayedRowCount()
    setRowFilterSize(count)
  }, [])

  const autoSizeColumns = (params) => {
    if (params.columnApi.bodyWidth >= params.columnApi.flexViewportWidth) {
      params.columnApi.autoSizeAllColumns()
    } else {
      params.api.sizeColumnsToFit()
    }
  }

  const handleChangeDocumentType = (ev) => {
    const newValue = ev.target.value
    setSelectedType(newValue)
  }

  const showNoResultsOverlay = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay()
  }, [])

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay()
  }, [])

  const DocumentsTableActions = (
    <div id="available-downloads" className="documents-table">
      <select onChange={handleChangeDocumentType} id="select-document-type">
        {selectOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="table-actions">
        <div className="table-actions-single">
          <span className="input-wrapper">
            <input
              type="text"
              className="search-items"
              id="filter-text-box"
              placeholder="Search Asset Name"
              onInput={onFilterTextBoxChanged}
            />
            {rowFilterSize === 0 && showNoResultsOverlay()}
            {rowFilterSize > 0 && onBtHide()}
          </span>
        </div>
      </div>
    </div>
  )

  if (!documents) {
    return (
      <Card label="Documents">
        <Spinner />
      </Card>
    )
  }

  return (
    <>
      <Card actions={DocumentsTableActions}>
        {documents && (
          <ACPTable
            isAnyFilterPresent={true}
            columnDefs={columnDefs}
            paginationPageSize="10"
            rowData={filteredDocs}
            onFirstDataRendered={autoSizeColumns}
            additionalDefaultColDef={{ sortable: true }}
            overrideGridStyle={gridStyle}
            overrideGridRef={gridRef}
            overlayLoadingTemplate={loadingOverlay}
          />
        )}
      </Card>
    </>
  )
}

DocumentsTable.propTypes = exact(propTypes)
DocumentsTable.defaultProps = defaultProps

export default DocumentsTable
