function dateComparator(firstDate, secondDate) {
  let aDate = new Date(firstDate).getTime()
  let bDate = new Date(secondDate).getTime()
  if (isNaN(aDate)) aDate = 0
  if (isNaN(bDate)) bDate = 0
  if (aDate == bDate) return 0
  return aDate > bDate ? 1 : -1
}

export default dateComparator
