import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { lpForm } from 'lp-form'
import { SubmitButton, Button, Select } from 'lp-components'
import { Field, formValues, getFormValues } from 'redux-form'
import { isEmpty, omit } from 'lodash'
import { assetOptions, StepNames } from 'config'
import { useSelector } from 'react-redux'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  asset: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  resetSection: PropTypes.func.isRequired,
  secondStepValue: PropTypes.string,
  form: PropTypes.string.isRequired,
}

const defaultProps = {
  asset: null,
  secondStepValue: null,
}

function shouldRenderThirdStep(secondStepValue, assetParams) {
  const thirdStepExists =
    !!secondStepValue && !isEmpty(assetParams?.[StepNames.THIRD_STEP])
  // if the third step does not exist, do not render
  if (!thirdStepExists) return false
  // if the third step does not require a condition, always display
  if (!assetParams?.[StepNames.THIRD_STEP].condition) return true
  // check if the second step value meets the condition to determine if the third step should be shown
  return assetParams?.[StepNames.THIRD_STEP].condition.value === secondStepValue
}

function AssetSpecificForm({
  asset,
  onClose,
  handleSubmit,
  submitting,
  resetSection,
  secondStepValue,
  form,
}) {
  const showSecondStep = !isEmpty(asset?.[StepNames.SECOND_STEP])
  const showThirdStep = shouldRenderThirdStep(secondStepValue, asset)
  const secondStepParams = asset?.[StepNames.SECOND_STEP]
  const thirdStepParams = asset?.[StepNames.THIRD_STEP]
  const formValues = useSelector((state) => getFormValues(form)(state))

  return (
    <form onSubmit={handleSubmit} noValidate className="asset-type-form">
      <h2>Select Asset Type</h2>
      <label>Asset Type *</label>
      <Field
        name="assetType"
        component={Select}
        aria-label="Select Asset"
        options={assetOptions}
        placeholder="Select asset type"
        label={false}
        onChange={() => {
          const remainingFormValues = omit(formValues, 'assetType')
          // when the asset type changes, re-set the rest of the form
          resetSection(...Object.keys(remainingFormValues))
        }}
      />
      {showSecondStep && (
        <>
          <label>{secondStepParams.label + ' *'}</label>
          <Field
            name={secondStepParams.name}
            component={Select}
            aria-label={`Select ${secondStepParams.label}`}
            options={secondStepParams.options}
            placeholder={`Select ${secondStepParams.label}`}
            label={false}
          />
        </>
      )}
      {showThirdStep && (
        <>
          <label>{thirdStepParams.label + ' *'}</label>
          <Field
            name={thirdStepParams.name}
            component={Select}
            aria-label={thirdStepParams.label}
            options={thirdStepParams.options}
            placeholder={`Select ${thirdStepParams.label}`}
            label={false}
            className="third-step"
          />
        </>
      )}
      <div className="button-block--inline">
        <SubmitButton className="button-primary" submitting={submitting}>
          Continue
        </SubmitButton>
        <Button className="button-secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

AssetSpecificForm.propTypes = propTypes
AssetSpecificForm.defaultProps = defaultProps

export default compose(
  lpForm({
    name: 'AssetSpecificForm',
    constraints: {
      assetType: { presence: true },
      securityType: { presence: { message: "^This field can't be blank" } },
      entityType: { presence: { message: "^This field can't be blank" } },
    },
  }),
  formValues(({ asset }) => ({
    secondStepValue: asset?.[StepNames.SECOND_STEP]?.name,
  }))
)(AssetSpecificForm)
