const bulkPromiseUploads = async (promises) => {
  let fulfilledUploads = []
  let rejectedUploads = []
  if (promises.length > 0) {
    const uploadedPromises = await Promise.allSettled(promises)

    uploadedPromises.forEach((promise) => {
      if (promise.status === 'rejected' || promise.status === 'Failed') {
        rejectedUploads.push(promise)
      } else {
        fulfilledUploads.push(promise)
      }
    })
  }
  return [fulfilledUploads, rejectedUploads]
}

export default bulkPromiseUploads
