import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import UploadsTable from '../components/UploadsTable'

const propTypes = {
  uploadedDocuments: PropTypes.arrayOf(PropTypes.object),
  fetchUploadedDocuments: PropTypes.func.isRequired,
}

const defaultProps = {}

function MyUploads({ uploadedDocuments, fetchUploadedDocuments }) {
  useEffect(() => {
    fetchUploadedDocuments()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!uploadedDocuments) return <Spinner />

  return <UploadsTable uploads={uploadedDocuments} />
}

MyUploads.propTypes = propTypes

MyUploads.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    uploadedDocuments: selectors.uploadedDocuments(state),
  }
}

const mapDispatchToProps = {
  fetchUploadedDocuments: apiActions.fetchUploadedDocuments,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(MyUploads)
