import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import * as addAssetActions from './actions'

const reducerKey = 'onboarding'
const slice = 'root.onboarding'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchContactsList]: setOnSuccess('contactsList'),
    [addAssetActions.clearContactsList]: unsetState('contactsList'),
    [apiActions.fetchSponsorTPACompanies]: setOnSuccess('tpaCompanies'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  contactsList: select('contactsList'),
  tpaCompanies: select('tpaCompanies'),
}

export { reducer, selectors, reducerKey }
