import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useHistory, Redirect } from 'react-router-dom'
import {
  Card,
  ScrollingContainer,
  ScrollToFieldError,
  ACPInputField,
} from 'components'
import { Checkbox as BaseCheckbox } from 'lp-components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import {
  ADD_ASSET_STAGE,
  isScreenshotDebuggingEnabled,
  isAddAssetScreenshotEnabled,
} from 'config'
import { FlowActions, AssetSummary, ReviewHeader } from '../components'
import MTCImage from 'images/MTC_Main.png'
import * as flashActions from 'redux-flash'
import ScreenshotButton, {
  createCanvasScreenshot,
  generateTimestamp,
} from '../components/ScreenshotButton'
import { withFormikAdapter } from 'utils'

// Steps
import AssetDetails from './AssetDetails'
import ContactDetails from './ContactDetails'
import FundingDetails from './FundingDetails'
import InvestmentDetails from './InvestmentDetails'
import FundingInstructions from './FundingInstructions'
import PurchaseRequirements from './PurchaseRequirements'
import DocumentUpload from './DocumentUpload'
import ValidationRequirements from './ValuationRequirements'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  createSummaryDocument: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
  storedAsset: PropTypes.object.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function ScreenshotOnly({ children, ...rest }) {
  return (
    <div
      aria-hidden="true"
      className="visually-hidden"
      {...rest}
      data-screenshot-only
    >
      {children}
    </div>
  )
}

async function createFormDataFromCanvas(canvas, key) {
  return new Promise((resolve, reject) => {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob
    canvas.toBlob((blob) => {
      if (!blob) reject(new Error('Failed to generate image from canvas'))
      const formData = new FormData()
      formData.append(key, blob)
      return resolve(formData)
    })
  })
}

function ESignAndConfirm({
  createSummaryDocument,
  storedAsset,
  updateAsset,
  flashSuccessMessage,
}) {
  const history = useHistory()
  const [fullPageError, setFullPageError] = useState()
  const [enabled, setEnabled] = useState(false)
  const pictureRef = React.useRef()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    firstName: storedAsset.eSign?.firstName || '',
    lastName: storedAsset.eSign?.lastName || '',
    title: storedAsset.eSign?.title || '',
    signature: '',
  }

  const handleSubmit = async (values) => {
    const isPartialSave = !values.requireAll
    const date = new Date()

    try {
      if (!isPartialSave && isAddAssetScreenshotEnabled()) {
        const canvas = await createCanvasScreenshot(
          pictureRef.current,
          generateTimestamp(date)
        )
        const formData = await createFormDataFromCanvas(canvas, 'fileContents')
        await createSummaryDocument(storedAsset.assetID, formData)
      }

      const eSign = {
        FirstName: values.firstName,
        LastName: values.lastName,
        Title: values.title,
        Date: isPartialSave ? null : date.getTime(),
      }

      await updateAsset({
        ...storedAsset,
        stage: isPartialSave
          ? ADD_ASSET_STAGE.E_SIGN
          : ADD_ASSET_STAGE.COMPLETED,
        saveStage: ADD_ASSET_STAGE.E_SIGN,
        eSign,
      })
      if (!isPartialSave) {
        flashSuccessMessage('We received your new asset submission.', {
          props: { dismissable: true },
        })
      }
      history.push('/home')
    } catch (e) {
      setFullPageError(e)
    }
  }

  const eSignValidationSchema = Yup.object({
    firstName: Yup.string().when('requireAll', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
    lastName: Yup.string().when('requireAll', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
    title: Yup.string().when('requireAll', {
      is: true,
      then: (schema) => schema.required('Required'),
    }),
    signature: Yup.bool().when('requireAll', {
      is: true,
      then: (schema) => schema.oneOf([true], 'Required').required('Required'),
    }),
  })

  if (fullPageError) {
    return <Redirect push={true} to="/error" />
  }

  return (
    <div className="form-flow-container">
      <div ref={pictureRef}>
        {isAddAssetScreenshotEnabled() && (
          <div className="review-flow">
            <ScreenshotOnly id="screenshot-header">
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
              >
                <img
                  src={MTCImage}
                  style={{ width: '200px' }}
                  alt="Millenium Trust Image"
                />
                <h1 style={{ margin: '0' }}>Pre-Custody Process</h1>
              </div>
            </ScreenshotOnly>
            <AssetSummary storedAsset={storedAsset} />
            <ReviewHeader stageName={ADD_ASSET_STAGE.ASSET_DETAILS} />
            <AssetDetails storedAsset={storedAsset} isNotEditable />
            <ReviewHeader stageName={ADD_ASSET_STAGE.CONTACT_DETAILS} />
            <ContactDetails storedAsset={storedAsset} isNotEditable />
            <FundingDetails storedAsset={storedAsset} isNotEditable />
            <ReviewHeader stageName={ADD_ASSET_STAGE.INVESTMENT_DETAILS} />
            <InvestmentDetails storedAsset={storedAsset} isNotEditable />
            <ReviewHeader stageName={ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS} />
            <FundingInstructions storedAsset={storedAsset} isNotEditable />
            <ReviewHeader stageName={ADD_ASSET_STAGE.REQUIREMENT_DETAILS} />
            <PurchaseRequirements storedAsset={storedAsset} isNotEditable />
            <ValidationRequirements storedAsset={storedAsset} isNotEditable />
            <ReviewHeader stageName={ADD_ASSET_STAGE.DOCUMENT_UPLOAD} />
            <DocumentUpload storedAsset={storedAsset} isNotEditable />
            <h4 className="review-header title" data-html2canvas-ignore>
              E-Sign
            </h4>
          </div>
        )}
        <div className="flow-card-container">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={eSignValidationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {() => {
              return (
                <Form className="asset-form asset-details-form">
                  <ScrollToFieldError />
                  <Card>
                    <div className="form-card-content">
                      <div className="container">
                        <h4 className="flow-section-headers">
                          {isAddAssetScreenshotEnabled()
                            ? 'Acknowledgments'
                            : 'E-Sign & Submit'}
                        </h4>
                        <h4>
                          Please review the following documents below and e-sign
                          at the bottom.
                        </h4>
                        <h4 className="flow-section-headers">
                          Private Placement Certification
                        </h4>
                        <p>
                          I, the General Partner, Managing Member, Authorized
                          Officer or Trustee, of the named Investment
                          Issuer/Sponsor ("Issuer"), certify and agree to the
                          following:
                        </p>
                        <ScrollingContainer onComplete={setEnabled}>
                          <ol>
                            <li>
                              Issuer understands that Inspira Financial, LLC as
                              custodian and nominal owner purchases/invests in
                              and holds assets in its IRAs and other custodial
                              accounts (each, an "Inspira Account") for the
                              benefit of the owners of such Inspira Accounts
                              (each, an "Account Owner"), and Inspira requires
                              that the ownership of such interests in the
                              Investment be registered in the name of Inspira as
                              custodian for the benefit of each Inspira Account,
                              and that all distributions and liquidations from
                              the Investment be delivered to Inspira for the
                              benefit of the Inspira Account.
                              <ol>
                                <li>
                                  Issuer agrees to register the ownership
                                  interest in the investment as follows:
                                  <address>
                                    Inspira Financial, LLC Custodian
                                    <br />
                                    FBO: (Account Owner Name) (Account Type)
                                    (Last 5 digits of Inspira Account No.)
                                    <br />
                                    Tax ID: (#36-4400066 for retirement
                                    accounts) (Use Account Owner's SSN or Tax ID
                                    No. for taxable accounts) 2001 Spring Road,
                                    Suite 700
                                    <br />
                                    Oak Brook, IL 60523
                                  </address>
                                </li>
                                <li>
                                  Issuer understands and agrees that it will
                                  treat Inspira, in its capacity as custodian,
                                  as the registered owner of the Investment for
                                  so long as Inspira serves as custodian for an
                                  Inspira Account.
                                </li>
                                <li>
                                  Issuer agrees that all transactions relating
                                  to the Investment will be made through the
                                  Inspira Account and will be in U.S. dollars,
                                  including withdrawals, liquidations,
                                  contributions, additional investments, and
                                  disbursements. For the avoidance of doubt,
                                  Issuer understands and agrees that it will not
                                  accept funds from or remit funds to the
                                  Account Owner directly or to other third
                                  parties with respect to the Investment.
                                </li>
                                <li>
                                  Issuer agrees to send all physical assets such
                                  as stock certificates and promissory notes to
                                  Inspira's address listed in (a) above to be
                                  held in Inspira's vault.
                                </li>
                                <li>
                                  Issuer agrees to redeem/liquidate the
                                  Investment as directed by Inspira as custodian
                                  to the extent such redemptions or liquidation
                                  is permitted pursuant to the Investment's
                                  governing documents.
                                </li>
                                <li>
                                  Issuer agrees that all funds sent to Inspira
                                  will be made payable to Inspira Financial as
                                  registered per (a) above and payments will
                                  include an explanation of the type of payment
                                  being made (interest, dividend, return of
                                  capital, return of earnings etc). Issuer
                                  understands that all payments should be
                                  remitted from an account in the name of the
                                  asset, and if any payments are remitted
                                  otherwise, Issuer will provide information to
                                  establish the connection between the remitter
                                  and the asset.
                                </li>
                              </ol>
                            </li>
                            <li>
                              Issuer agrees to notify Inspira in writing of any
                              change of purchase instructions including check or
                              wire instructions, mailing address, and e-mail
                              addresses.
                            </li>
                            <li>
                              Issuer will provide the Account Owner and Inspira
                              with any applicable, offering and governing
                              documents prior to receiving any investment for
                              purchase or an additional investment from Inspira
                              on behalf of an Inspira Account. Issuer will
                              comply with all applicable laws and regulations in
                              all material respects, including securities
                              registration requirements or exemptions.
                            </li>
                            <li>
                              Issuer will provide Inspira with a copy of
                              Issuer's account statements and any transaction
                              activity relating to each Inspira Account's
                              ownership interest in the Investment and any
                              additional information reasonably requested by
                              Inspira. Issuer agrees that it will also send all
                              statements and correspondence relating to the
                              Investment directly to each Account Owner.
                            </li>
                            <li>
                              Issuer understands and agrees that Inspira has not
                              performed and will not perform any due diligence
                              on the Investment or Issuer for the benefit of any
                              Account Owner. Furthermore, the Issuer
                              acknowledges that Inspira's signature on any
                              subscription documents is made solely in its
                              capacity as custodian to facilitate the Inspira
                              Account's ownership in/of the Investment and not
                              as a certification of Account Owner's
                              qualification to purchase the Investment or the
                              suitability of the Investment for the Account
                              Owner. Inspira makes no representations with
                              respect to the Account Owner or the Investment in
                              the subscription documents or any other
                              documentation. (Inspira does, however certify W-9
                              information regarding Inspira's tax identification
                              number.)
                            </li>
                            <li>
                              Issuer agrees to provide Inspira evidence of
                              ownership of the Investment, such as an original
                              stock certificate, or if certificates are not
                              issued, a completed confirmation of purchase form
                              provided by Inspira. Issuer further agrees that
                              any additional Investment will not be facilitated
                              by Inspira for any Inspira Account until such
                              confirmation is provided to Inspira.
                            </li>
                            <li>
                              Issuer agrees to send to Inspira the fair market
                              value of each Account Owner's Investment as of
                              December 31 of each year. Issuer acknowledges and
                              understands that if such value is sent after
                              January 20 of the following year, the value
                              represented on the Inspira year end statement will
                              represent the last prior value provided by the
                              Issuer. If an updated value is not received at
                              least annually, Inspira will notify Account Owner
                              and in keeping with its policies and procedures
                              then or later may decline to continue to custody
                              the investment.
                            </li>
                            <br />
                            <li>
                              <em></em>
                              <strong>
                                <em>Retirement</em>
                              </strong>
                              <strong>
                                <em> </em>
                              </strong>
                              <strong>
                                <em>Accounts</em>
                              </strong>
                              <strong>
                                <em> </em>
                              </strong>
                              <strong>
                                <em>only</em>
                              </strong>
                            </li>
                            <ol>
                              <li>
                                Issuer will inform the Account Owner of the
                                amount of Unrelated Business Taxable Income
                                ("UBTI") that the Account Owner must report on
                                IRS Form 990T with respect to the Investment, if
                                applicable.
                              </li>
                              <li>
                                Issuer acknowledges that retirement accounts
                                have certain limitations that restrict their and
                                the Account Owner’s ability to make any capital
                                calls or additional investments which may be
                                requested or recommended by the Issuer.
                              </li>
                              <li>
                                Issuer agrees to provide Inspira with notice if
                                Issuer becomes aware of any transactions that
                                may be deemed prohibited under Section 4975 of
                                the Internal Revenue Code. Issuer will not be
                                required to monitor for such transactions unless
                                the Issuer or the Investment is a disqualified
                                person under Section 4975.
                              </li>
                            </ol>
                            <li>
                              Issuer agrees that if directed by Inspira, it will
                              cooperate and facilitate any transfer or
                              assignment of the ownership of the Investment from
                              Inspira as custodian to the Account Owner
                              individually, to a successor custodian, or to a
                              beneficiary in the event of the owner's death. To
                              the extent any such transfer or assignment of
                              ownership is not consistent with the Investment's
                              governing documents, Issuer will cooperate and
                              facilitate the appropriate disposition of the
                              Inspira Account’s interest in the Investment
                              (e.g., in the event a beneficiary is not a
                              qualified investor).
                            </li>
                            <li>
                              Issuer certifies that it is duly organized and in
                              good standing and will notify Inspira of any
                              dissolution or bankruptcy filing.
                            </li>
                            <li>
                              Issuer agrees to notify Inspira in writing if
                              Issuer changes its name, address or other contact
                              information listed in Exhibit A.
                            </li>
                            <li>
                              Issuer certifies that (a) it has not made any
                              representations regarding Inspira as to its
                              relationship with Inspira, as to any opinion
                              Inspira may have as to the Issuer or investments
                              in the Issuer or otherwise except as required by
                              law or regulation; and (b) it will not use the
                              name of Inspira in any manner, including marketing
                              or advertising without the written permission of
                              Inspira. The Issuer further acknowledges that
                              Inspira will not undertake any actions to promote
                              the sale or distribution of any investment nor
                              does it provide any investment, tax or legal
                              advice.
                            </li>
                          </ol>
                        </ScrollingContainer>
                        <fieldset className="read-only-wrapper">
                          <legend className="visually-hidden">
                            Signature details
                          </legend>
                          <div className="row">
                            <div className="six columns">
                              <ACPInputField
                                label="First Name"
                                name="firstName"
                                readOnly={!enabled}
                              />
                            </div>
                            <div className="six columns">
                              <ACPInputField
                                label="Last Name"
                                name="lastName"
                                readOnly={!enabled}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="twelve columns">
                              <ACPInputField
                                label="Title"
                                name="title"
                                readOnly={!enabled}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="checkbox-line-item twelve columns">
                              <Checkbox
                                name="signature"
                                label="By checking this box, I agree that I am signing this Private Placement Certification, which I intend to be my legally binding signature on behalf of the Issuer. I also certify that: 1) the information provided herein is true and correct; 2) the Issuer agrees to the terms and requirements in paragraphs 1-12 above; 3) that I am authorized to and this e-signature does bind the Issuer named above to the terms of this Private Placement Certification."
                                disabled={!enabled}
                              />
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                  </Card>
                  <FlowActions
                    disabled={!enabled}
                    preventSave={false}
                    submitContent="Submit"
                  />
                </Form>
              )
            }}
          </Formik>
        </div>
      </div>
      {isAddAssetScreenshotEnabled() && isScreenshotDebuggingEnabled() && (
        <ScreenshotButton inputRef={pictureRef}>Capture</ScreenshotButton>
      )}
    </div>
  )
}

ESignAndConfirm.propTypes = propTypes

ESignAndConfirm.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
  createSummaryDocument: apiActions.createSummaryDocument,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ESignAndConfirm
)
