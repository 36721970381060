import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Card, ACPTable } from 'components'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  dateFormatter,
  formatNumberAsCurrency,
  dateComparator,
  numberComparator,
  convertStringToFixedFloat,
} from 'utils'
import { Spinner } from 'lp-components'
import NumericCellEditor from './NumericCellEditor'
import DateCellEditor from './DateCellEditor'

const propTypes = {
  investors: PropTypes.object.isRequired,
  isPricePerShare: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
}

const defaultProps = {
  investors: [],
}

function InvestorDetailsTable({
  investors,
  isPricePerShare,
  setFieldValue,
  errors,
}) {
  const [filterInvestorName, setFilterInvestorName] = useState('')
  const [rowFilterSize, setRowFilterSize] = useState()

  const decomposedInvestors = Object.keys(investors).map(
    (key) => investors[key]
  )

  const cellEditorSelector = (params) => {
    if (params.colDef.field === 'managementFee') {
      return {
        component: NumericCellEditor,
      }
    }

    if (params.colDef.field === 'effectiveDate') {
      return {
        component: DateCellEditor,
      }
    }
    return undefined
  }

  const columnDefs = [
    {
      field: 'clientFBOName',
      headerName: 'Investor Name',
      sortable: true,
      headerTooltip: 'The person/account owner investing in the asset.',
      sort: 'asc', //Set default sort of name as ascending.
      minWidth: 200,
      wrapText: true,
      autoHeight: true,
      cellClass: 'investor-name-column',
    },
    {
      field: 'shares',
      headerName: 'Number of Shares',
      headerTooltip: 'The number of shares purchased by the investor.',
      hide: !isPricePerShare,
      comparator: numberComparator,
      valueFormatter: ({ value }) => {
        return convertStringToFixedFloat(value, 4)
      },
      tooltipValueGetter: ({ value }) => {
        return convertStringToFixedFloat(value, 4)
      },
      minWidth: 140,
      cellClass: 'num-shares-column table-right-align',
    },
    {
      field: 'purchaseAmount',
      headerName: 'Purchase Amount',
      sortable: true,
      headerTooltip:
        'The dollar amount authorized by the investor for the purchase. This is before deducting any management fees or true up amount.',
      valueFormatter: ({ value }) => {
        return formatNumberAsCurrency(parseFloat(value), '-')
      },
      type: 'numericColumn',
      comparator: numberComparator,
      minWidth: 150,
      cellClass: 'purchase-amount-column table-right-align',
    },
    {
      field: 'managementFee',
      headerName: 'Management Fee',
      headerTooltip:
        'The fee or true up amount that the investment sponsor charges an investor. The amount will be deducted from the funds received (or initial purchase amount). If you need to change the amount for a specific investor, you can do that below.',
      sortable: true,
      valueFormatter: ({ value }) => {
        return formatNumberAsCurrency(parseFloat(value), '')
      },
      type: 'numericColumn',
      comparator: numberComparator,
      minWidth: 150,
      cellClass: 'management-fee-column table-right-align',
      editable: true,
      onCellValueChanged: (e) => {
        const obj = Object.keys(investors).reduce((acc, key) => {
          acc[key] =
            key == e.data.purchaseID
              ? { ...investors[key], managementFee: e.data.managementFee }
              : { ...investors[key] }
          return acc
        }, {})
        setFieldValue('investors', obj)
      },
      cellEditorSelector: cellEditorSelector,
      cellClassRules: {
        'rag-red': (params) =>
          errors.investors &&
          errors.investors[`${params.data.purchaseID}`]?.managementFee,
      },
    },
    {
      field: 'netPurchaseAmount',
      headerName: 'Net Purchase Amount',
      sortable: true,
      headerTooltip:
        'The total dollar amount purchased by the investor minus any management fees or true up amount.',
      valueFormatter: ({ value }) => {
        return formatNumberAsCurrency(parseFloat(value), '-')
      },
      valueGetter: 'data.purchaseAmount - data.managementFee',
      type: 'numericColumn',
      comparator: numberComparator,
      minWidth: 150,
      cellClass: 'net-purchase-amount-column table-right-align',
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      sortable: true,
      headerTooltip:
        'The date that the investment sponsor executed the transaction on behalf of the investor(s). If you need to change the date for a specific investor, you can do that below.',
      comparator: dateComparator,
      valueFormatter: dateFormatter,
      minWidth: 100,
      headerClass: 'effective-date-header',
      cellClass: 'effective-date-column table-right-align',
      editable: true,
      cellEditorSelector: cellEditorSelector,
      onCellValueChanged: (e) => {
        var effectiveDate = e.data.effectiveDate
        const obj = Object.keys(investors).reduce((acc, key) => {
          acc[key] =
            key == e.data.purchaseID
              ? {
                  ...investors[key],
                  effectiveDate: effectiveDate ? effectiveDate : '',
                }
              : { ...investors[key] }
          return acc
        }, {})
        setFieldValue('investors', obj)
      },
      cellClassRules: {
        'rag-red': (params) =>
          errors.investors &&
          errors.investors[`${params.data.purchaseID}`]?.effectiveDate,
      },
    },
    {
      field: 'transactionDate',
      headerName: 'Transaction Date',
      headerClass: 'transaction-date-header',
      headerTooltip: 'The date that Inspira processed the transaction.',
      sortable: true,
      valueFormatter: dateFormatter,
      comparator: dateComparator,
      minWidth: 130,
      cellClass: 'trans-date-column table-right-align',
    },
  ]

  const filteredInvestors = useMemo(() => {
    if (!decomposedInvestors) return []
    return decomposedInvestors.filter((data) => {
      return filterInvestorName
        ? data.clientFBOName
            .toLowerCase()
            .includes(filterInvestorName.toLowerCase())
        : true
    })
  }, [decomposedInvestors, filterInvestorName])

  const gridRef = useRef()

  const gridStyle = { height: '518px', width: '100%' }

  const onFilterTextBoxChanged = (e) => {
    setFilterInvestorName(e.target.value)
    getDisplayedRowCount()
  }

  const getDisplayedRowCount = useCallback(() => {
    const count = gridRef.current.api.getDisplayedRowCount()
    setRowFilterSize(count)
  }, [])

  const investorsTableActions = (
    <div className="investors-table">
      <div className="table-actions">
        <div className="table-actions-single">
          <span>
            <input
              type="text"
              id="filter-text-box"
              placeholder="Search by Investor Name"
              onInput={onFilterTextBoxChanged}
            />
            {rowFilterSize === 0 && (
              <span className="search-results-helper">
                No investors were found.
              </span>
            )}
          </span>
        </div>
      </div>
    </div>
  )

  if (!filteredInvestors) {
    return <Spinner />
  }

  return (
    <div className="container investor-details-table">
      <div className="column twelve">
        <Card label="Investors" actions={investorsTableActions}>
          <p className="investor-details-table-instructions reg-16">
            Before you continue, review the details for each investor for
            accuracy. If you need to change the fee and/or effective date for an
            investor, you can do that below.
          </p>
          <ACPTable
            columnDefs={columnDefs}
            additionalDefaultColDef={{
              sortable: true,
            }}
            rowData={filteredInvestors}
            isAnyFilterPresent={true}
            pagination={filteredInvestors.length > 1}
            paginationPageSize="5"
            overrideGridStyle={gridStyle}
            overrideGridRef={gridRef}
            rowHeight="75"
            domLayout="autoHeight"
          >
            {errors.investors && (
              <span className="search-results-helper">
                Management fees and effective dates are required for all
                investors.
              </span>
            )}
          </ACPTable>
        </Card>
      </div>
    </div>
  )
}

InvestorDetailsTable.propTypes = propTypes
InvestorDetailsTable.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InvestorDetailsTable
)
