import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import * as Yup from 'yup'
import { SubmitButton } from 'lp-components'
import { normalizeStateAndProvinceValue, normalizeCountryValue } from 'utils'
import {
  countries,
  usStates,
  canadianProvinces,
  ZIP_CODE_REGEX,
  PHONE_NUMBER_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
} from 'config'
import classnames from 'classnames'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import { ACPInputField } from 'components'

const propTypes = {
  readOnly: PropTypes.bool.isRequired,
  currentUser: PropTypes.object.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  updateUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {}

const ColumnOneHalf = ({ children }) => (
  <div className="one-half column">{children}</div>
)
const ColumnFull = ({ children }) => (
  <div className="twelve columns">{children}</div>
)

const ProfileFields = ({
  readOnly,
  currentUser,
  updateUserProfile,
  flashSuccessMessage,
  flashErrorMessage,
}) => {
  const { contactDetails } = currentUser

  const initialValues = {
    contactID: currentUser.contactID,
    firstName: contactDetails.firstName,
    lastName: contactDetails.lastName,
    email: contactDetails.email,
    phone: contactDetails.phone,
    streetAddress: contactDetails.streetAddress,
    city: contactDetails.city,
    country: normalizeCountryValue(contactDetails.country),
    state: normalizeStateAndProvinceValue(
      contactDetails.country,
      contactDetails.state
    ),
    zipcode: contactDetails.zipcode,
  }

  const profileValidation = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
      .required('Required'),
    email: Yup.string()
      .email('Must enter valid email!')
      .required('Required'),
    streetAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    contactID: Yup.string().required('Required'),
    zipcode: Yup.string()
      .required('Required')
      .test('test_zip_code', '', (value, context) => {
        const { parent, createError } = context
        if (parent.country == 'US') {
          if (!RegExp(ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be valid 5 or 9 digit US zip code!',
            })
          }
        }
        if (parent.country == 'CA') {
          if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be a valid six-character CA code!',
            })
          }
        }
        return true
      }),
  })

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await updateUserProfile({ ...values })
      flashSuccessMessage('Your profile has been updated.')
      resetForm({ values })
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
    }
  }

  return (
    <div className="profile-fields">
      <div>
        <h4>Contact Details</h4>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={profileValidation}
          initialValues={initialValues}
        >
          {({
            setFieldValue,
            values,
            isSubmitting,
            dirty,
            setFieldError,
            setFieldTouched,
            resetForm,
          }) => (
            <Form>
              <div>
                <div className="row">
                  <ColumnOneHalf>
                    <ACPInputField
                      name="firstName"
                      label="First Name"
                      placeholder={!readOnly && 'Enter first name'}
                      disabled={readOnly}
                    />
                  </ColumnOneHalf>
                  <ColumnOneHalf>
                    <ACPInputField
                      name="lastName"
                      label="Last Name"
                      placeholder={!readOnly && 'Enter last name'}
                      disabled={readOnly}
                    />
                  </ColumnOneHalf>
                </div>
                <div className="row">
                  <ColumnOneHalf>
                    <ACPInputField
                      name="email"
                      label="Email"
                      tooltipContent="The email address is used to log in to the platform. To change the email address please contact the Client Service team. They can be reached Monday - Friday 7:00 AM - 6:00 PM C.T. at (800) 258-7878."
                      placeholder={!readOnly && 'Enter email'}
                      disabled={true}
                    />
                  </ColumnOneHalf>
                  <ColumnOneHalf>
                    <ACPInputField
                      label="Phone"
                      name="phone"
                      type="tel"
                      placeholder={!readOnly && 'Enter phone'}
                      maskOptions={{
                        numericOnly: true,
                        blocks: [0, 3, 0, 3, 4],
                        delimiters: ['(', ')', ' ', '-'],
                      }}
                      disabled={readOnly}
                    />
                  </ColumnOneHalf>
                </div>
                <div>
                  <h4>Address Details</h4>
                  <div className="row">
                    <div className="one-half column">
                      <ACPInputField
                        name="country"
                        label="Country"
                        selectOptions={countries}
                        enablePlaceholderOption={true}
                        placeholder={!readOnly && 'Select country'}
                        onChange={() => {
                          setFieldValue('state', '', false)
                          setFieldError('state', '')
                          setFieldTouched('state', false, false)
                        }}
                        disabled={readOnly}
                      />
                    </div>
                    <div className="one-half column">
                      {isEmpty(values.country) ||
                      values.country === 'US' ||
                      values.country === 'CA' ? (
                        <ACPInputField
                          name="state"
                          label="State/Province/Region"
                          selectOptions={
                            values.country === 'US'
                              ? usStates
                              : canadianProvinces
                          }
                          enablePlaceholderOption={true}
                          placeholder={
                            !readOnly && 'Select State/Province/Region'
                          }
                          disabled={
                            readOnly ||
                            !(
                              values.country === 'US' || values.country === 'CA'
                            )
                          }
                        />
                      ) : (
                        <ACPInputField
                          name="state"
                          label="State/Province/Region"
                          enablePlaceholderOption={true}
                          placeholder={
                            !readOnly && 'Enter State/Province/Region'
                          }
                          disabled={
                            readOnly ||
                            isEmpty(values.country) ||
                            values.country === 'US' ||
                            values.country === 'CA'
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <ColumnFull>
                      <ACPInputField
                        name="streetAddress"
                        label="Street Address"
                        placeholder={!readOnly && 'Enter street address'}
                        disabled={readOnly}
                      />
                    </ColumnFull>
                  </div>
                  <div className="row">
                    <ColumnOneHalf>
                      <ACPInputField
                        name="city"
                        label="City"
                        disabled={readOnly}
                      />
                    </ColumnOneHalf>
                    <ColumnOneHalf>
                      <ACPInputField
                        name="zipcode"
                        label="Zip Code"
                        disabled={readOnly}
                      />
                    </ColumnOneHalf>
                  </div>
                  <div className="profile-submit-button-area">
                    {!readOnly && (
                      <>
                        <button
                          className={classnames('button-secondary', {
                            'is-disabled': !dirty,
                          })}
                          onClick={() => {
                            resetForm()
                          }}
                          type="button"
                          disabled={!dirty}
                        >
                          Cancel
                        </button>
                        <SubmitButton
                          className={classnames({
                            'is-disabled': !dirty,
                          })}
                          disabled={!dirty}
                          submitting={isSubmitting}
                        >
                          Save Changes
                        </SubmitButton>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

ProfileFields.propTypes = propTypes
ProfileFields.defaultProps = defaultProps

function mapStateToProps() {}

const mapDispatchToProps = {
  updateUserProfile: apiActions.updateUserProfile,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfileFields
)
