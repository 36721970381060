import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Field } from 'formik'
import { useClickAway } from 'react-use'
import { lowerCase } from 'lodash'
import classNames from 'classnames'

const propTypes = {
  handleExternalFilterChange: PropTypes.func.isRequired,
  setPicklistVisible: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.array.isRequired,
  currentFilter: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  overrideClassName: PropTypes.string,
}

function ACPFilterPicklist({
  handleExternalFilterChange,
  setPicklistVisible,
  values,
  setFieldValue,
  currentFilter,
  onClose,
  options,
  title,
  overrideClassName,
}) {
  const isSelectAll = options.every((role) => values?.filter.includes(role))
  const ref = useRef(null)

  useClickAway(
    ref,
    () => {
      onClose(values)
    },
    ['click']
  )

  const onCancel = () => {
    setFieldValue('filter', currentFilter)
    setPicklistVisible(false)
  }

  const handleSelectAll = () => {
    setFieldValue('filter', options)
    if (isSelectAll) {
      setFieldValue('filter', [])
    }
  }

  const handleClearFilters = () => {
    setFieldValue('filter', [])
    handleExternalFilterChange([])
    setPicklistVisible(false)
  }

  return (
    <div
      ref={ref}
      className={classNames(
        'acp-filter-picklist',
        overrideClassName ? overrideClassName : 'roles-input-wrapper'
      )}
    >
      <label className="filter-header" htmlFor="role">
        {title}
      </label>
      <div role="group" className="CheckboxGroup">
        <label className="fp-label-all">
          <input
            type="checkbox"
            className="fp-check-all"
            onChange={handleSelectAll}
            checked={isSelectAll}
          />
          Select All
        </label>
        {options.map((option) => (
          <label
            key={option}
            className={`fp-label-options fp-label-${lowerCase(option)}`}
          >
            <Field
              className={`fp-check-options fb-check-${lowerCase(option)}`}
              type="checkbox"
              name="filter"
              value={option}
            />
            {option}
          </label>
        ))}
      </div>
      <button
        className="button-secondary clear-all-button"
        type="button"
        onClick={handleClearFilters}
      >
        Clear All Filters
      </button>
      <div className="end-buttons">
        <button type="button" className="button-secondary" onClick={onCancel}>
          Cancel
        </button>
        <button className="button-primary" type="submit">
          Apply Filter(s)
        </button>
      </div>
    </div>
  )
}

ACPFilterPicklist.propTypes = exact(propTypes)

export default ACPFilterPicklist
