import React, { useRef } from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import Select from 'react-select'
import { withFormikAdapter } from 'utils'
import { ErrorMessage } from 'formik'
import { LabelWithTooltip } from 'components'

const propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  label: PropTypes.string.isRequired,
  useTooltip: PropTypes.bool,
  toolTipContent: PropTypes.string,
  withAllOption: PropTypes.bool,
  overridePlaceholder: PropTypes.object,
  placeholderText: PropTypes.string,
  wrapStyle: PropTypes.string,
}

const defaultProps = {
  closeMenuOnSelect: false,
  blurInputOnSelect: false,
  removeSelected: false,
  isSearchable: true,
  isClearable: true,
  backspaceRemovesValue: true,
  deleteRemoves: true,
  controlShouldRenderValue: false,
  hideSelectedOptions: false,
  useTooltip: false,
  toolTipContent: '',
  withAllOption: true,
  wrapStyle: 'wrap',
}

export const MultiSelect = (props) => {
  const {
    input: { name, onBlur, onChange, value },
    id,
    options,
    disabled,
    hasError,
    label,
    useTooltip,
    toolTipContent,
    withAllOption,
    overridePlaceholder,
    placeholderText,
    wrapStyle,
    ...rest
  } = props
  // isOptionSelected sees previous value after onChange
  const valueRef = useRef(value)
  valueRef.current = value

  const selectAllOption = {
    value: '*',
    label: 'Select All Assets',
  }

  const isSelectAllSelected = () => valueRef.current?.length >= options.length

  const isOptionSelected = (option) =>
    valueRef.current?.some(({ value }) => value === option.value) ||
    isSelectAllSelected()

  const getValue = () =>
    isSelectAllSelected() ? [selectAllOption.value] : value

  const customOnChange = (newValue, actionMeta) => {
    const { action, option, removedValue } = actionMeta

    if (action === 'select-option' && option.value === selectAllOption.value) {
      onChange([selectAllOption, ...options])
    } else if (
      (action === 'deselect-option' &&
        option.value === selectAllOption.value) ||
      (action === 'remove-value' &&
        removedValue.value === selectAllOption.value)
    ) {
      onChange([])
    } else if (action === 'deselect-option' && isSelectAllSelected()) {
      onChange(options.filter(({ value }) => value !== option.value))
    } else {
      onChange(newValue || [])
    }
  }

  const customStyles = {
    // For the select itself (not the options)
    control: (styles) => {
      return {
        ...styles,
        fontStyle: disabled ? 'italic' : 'normal',
        // This line disable the blue border
        border: hasError ? '1px solid red' : '1px solid #D1D1D1',
        boxShadow: 'none',
        '&:hover': {
          border: '',
        },
        '&:focus': {
          border: '',
        },
      }
    },
    // For the options
    option: (styles) => {
      return {
        ...styles,
        backgroundColor: 'transparent',
        color: disabled ? 'lightgrey' : 'black',
      }
    },
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        fontStyle: 'normal',
        color: '#646461',
      }
    },
    valueContainer: () => {
      return {
        flex: '1 1 0%',
        display: 'flex',
        borderRadius: '4px',
        border: 0,
        alignItems: 'center',
        flexWrap: { wrapStyle },
        position: 'relative',
        padding: '2px 8px',
        color: '#646461',
      }
    },
  }

  const handleBlur = () => {
    onBlur(name, true)
  }

  return (
    <div>
      {useTooltip ? (
        <LabelWithTooltip label={label} tooltipContent={toolTipContent} />
      ) : (
        <label htmlFor={id || name}>{label}</label>
      )}
      <Select
        styles={customStyles}
        inputId={id || name}
        name={name}
        options={withAllOption ? [selectAllOption, ...options] : options}
        onChange={customOnChange}
        onBlur={handleBlur}
        value={getValue()}
        isOptionSelected={isOptionSelected}
        isDisabled={disabled}
        isMulti
        placeholder={
          overridePlaceholder
            ? overridePlaceholder
            : value.length === 0 && placeholderText
        }
        {...rest}
      />
      <ErrorMessage name={name} component={'div'} className="error-message" />
    </div>
  )
}

MultiSelect.propTypes = exact(propTypes)
MultiSelect.defaultProps = defaultProps

export default withFormikAdapter()(MultiSelect)
