import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { COPYABLE_CONTACT_TYPES } from 'config'
import { ACPInputField } from 'components'
import * as Types from 'types'
import { compact } from 'lodash'
import ContactSearchAndSelect from './ContactSearchAndSelect'

const propTypes = {
  values: PropTypes.object.isRequired,
  type: PropTypes.string,
  hideBlock: PropTypes.bool,
  setFieldValue: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  touched: PropTypes.object,
  showThirdPartyAdministratorInput: PropTypes.bool.isRequired,
  showNoteServicerInput: PropTypes.bool.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact).isRequired,
}

const defaultProps = {}

function AdditionalContactFormBlock({
  values,
  type,
  hideBlock,
  setFieldValue,
  setErrors,
  touched,
  setTouched,
  showThirdPartyAdministratorInput,
  showNoteServicerInput,
  isNotEditable,
  contactsList,
}) {
  useEffect(() => {
    if (
      (!showThirdPartyAdministratorInput &&
        values[type].equalSelection ===
          COPYABLE_CONTACT_TYPES.THIRD_PARTY_ADMIN) ||
      (!showNoteServicerInput &&
        values[type].equalSelection === COPYABLE_CONTACT_TYPES.NOTE_SERVICER)
    )
      setFieldValue(`${type}[equalSelection]`, '')
  }, [showThirdPartyAdministratorInput, showNoteServicerInput])

  return (
    <Fragment>
      {!hideBlock && (
        <div
          name={`${type}.equalSelection`}
          className="contact-upload-container"
        >
          <ACPInputField
            disabled={isNotEditable}
            name={`${type}[equalSelection]`}
            onChange={(e) => {
              if (e.target.value === COPYABLE_CONTACT_TYPES.OTHER) {
                setFieldValue(`${type}[firstName]`, '')
                setFieldValue(`${type}[lastName]`, '')
                setFieldValue(`${type}[email]`, '')
                setFieldValue(`${type}[phone]`, '')
                setFieldValue(`${type}[country]`, '')
                setFieldValue(`${type}[state]`, '')
                setFieldValue(`${type}[streetAddress]`, '')
                setFieldValue(`${type}[city]`, '')
                setFieldValue(`${type}[zipcode]`, '')
                setErrors({
                  [`${type}[firstName]`]: false,
                  [`${type}[lastName]`]: false,
                  [`${type}[email]`]: false,
                  [`${type}[phone]`]: false,
                  [`${type}[country]`]: false,
                  [`${type}[state]`]: false,
                  [`${type}[streetAddress]`]: false,
                  [`${type}[city]`]: false,
                  [`${type}[zipcode]`]: false,
                })
                setTouched({
                  ...touched,
                  [type]: {},
                })
              }
            }}
            label="Please select who is responsible for receiving and confirming requests:"
            radioOptions={compact([
              {
                key: 'Administrative Contact',
                value: COPYABLE_CONTACT_TYPES.ADMIN,
              },
              showThirdPartyAdministratorInput
                ? {
                    key: 'Third Party Administrator',
                    value: COPYABLE_CONTACT_TYPES.THIRD_PARTY_ADMIN,
                  }
                : null,
              showNoteServicerInput
                ? {
                    key: 'Note Servicer',
                    value: COPYABLE_CONTACT_TYPES.NOTE_SERVICER,
                  }
                : null,
              { key: 'Other', value: COPYABLE_CONTACT_TYPES.OTHER },
            ])}
          />
          {values[type]?.equalSelection === COPYABLE_CONTACT_TYPES.OTHER && (
            <div className="container">
              <ContactSearchAndSelect
                contactType={type}
                isNotEditable={isNotEditable}
                contactsList={contactsList}
              />
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

AdditionalContactFormBlock.propTypes = exact(propTypes)
AdditionalContactFormBlock.defaultProps = defaultProps

export default React.memo(AdditionalContactFormBlock)
