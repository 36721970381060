import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Card } from 'components'
import { Link } from 'react-router-dom'
import { Spinner } from 'lp-components'
import { displayDateTime, sortByDateAttribute } from 'utils'

const propTypes = {
  transactions: PropTypes.array,
  loading: PropTypes.bool,
}

const defaultProps = {
  transactions: null,
}

const TRANSACTIONS_LIMIT = 3

function TransactionList({ transactions, loading }) {
  if (!transactions || loading) {
    return (
      <Card label="Transactions">
        <Spinner />
      </Card>
    )
  }

  if (!transactions.length) {
    return (
      <Card label="Transactions">
        <h5>There are no transactions</h5>
      </Card>
    )
  }

  const selectedTransactions = sortByDateAttribute(
    transactions,
    'transactionDate'
  ).slice(0, TRANSACTIONS_LIMIT)

  return (
    <Card
      className="truncated-list"
      label={`Transactions (${transactions.length})`}
      actions={
        <Link
          to={{
            pathname: '/transactions/view-all',
          }}
        >
          View all
        </Link>
      }
    >
      <ul>
        {selectedTransactions.map((transaction, idx) => (
          <li key={transaction.transactionID || idx}>
            <h5>{transaction.assetName}</h5>
            <p>{displayDateTime(transaction.transactionDate, 'MM/DD/YYYY')}</p>
            <p>{transaction.type}</p>
          </li>
        ))}
      </ul>
    </Card>
  )
}

TransactionList.propTypes = exact(propTypes)
TransactionList.defaultProps = defaultProps

export default React.memo(TransactionList)
