import { template, startsWith } from 'lodash'

function namedRoute(path, parameters) {
  const pathElements = path
    .split('/')
    .map((element) => {
      if (startsWith(element, ':'))
        return ['<%=', element.replace(':', ''), '%>'].join(' ')
      return element
    })
    .join('/')
  const compiled = template(pathElements)
  return compiled(parameters)
}

export default namedRoute
