// this util takes status: 'rejected' api file upload promises from Promise.allSettled and generates a Formik errors object to display error messages

const createFileErrors = (rejectedFileUploads, errorName = undefined) => {
  const errors = {}
  if (rejectedFileUploads.length)
    rejectedFileUploads.map((rejectedFile) => {
      const documentID = rejectedFile.reason.documentID
      const errorKey = errorName ? errorName : documentID
      // if rejected for virus, specify error, otherwise pass statusText
      if (rejectedFile.reason.response.hasVirus) {
        errors[
          errorKey
        ] = `${rejectedFile.reason.fileName} failed our virus scan and will not be accepted. Please submit a new file.`
      } else {
        errors[errorKey] =
          rejectedFile.reason.message ||
          'Could not upload file. Please submit a new file.'
      }
    })

  return errors
}

export default createFileErrors
