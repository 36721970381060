import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { selectors } from '../reducer'
import { FundingInstructionsContent } from 'components'
import * as Yup from 'yup'
import { Spinner } from 'lp-components'
import * as apiActions from 'api-actions'
import {
  PHONE_NUMBER_REGEX,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
} from 'config'
import { FlowActions } from '../components'
import * as actions from '../actions'

const propTypes = {
  fundingInstructions: PropTypes.object,
  storeFundingInstructionsPayload: PropTypes.func.isRequired,
}

const defaultProps = {}

function FundingInstructions({
  fundingInstructions,
  storeFundingInstructionsPayload,
}) {
  let { assetID } = useParams()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  useEffect(() => {
    if (!location.state?.hasValidToken) {
      history.push('/home')
    }
  }, [])

  const initialValues = {
    fundingType: fundingInstructions?.fundingInstructions?.isACH
      ? 'ach'
      : fundingInstructions?.fundingInstructions?.isCheck
      ? 'check'
      : 'bankTransfer',

    isBankTransfer: fundingInstructions?.fundingInstructions?.isBankTransfer,
    isACH: fundingInstructions?.fundingInstructions?.isACH,
    isCheck: fundingInstructions?.fundingInstructions?.isCheck,

    // bank transfer info
    bankTransferTransitABANumber:
      fundingInstructions?.fundingInstructions?.abaNumber || '',
    bankTransferBankAccountNumber:
      fundingInstructions?.fundingInstructions?.accountNumber || '',
    bankTransferAccountName:
      fundingInstructions?.fundingInstructions?.accountName || '',
    bankTransferBankName:
      fundingInstructions?.fundingInstructions?.bankName || '',
    bankTransferBankPhone:
      fundingInstructions?.fundingInstructions?.bankPhone || '',
    bankTransferForFurtherCreditAccountNumber:
      fundingInstructions?.fundingInstructions?.furtherCreditAccountNumber ||
      '', // optional
    bankTransferSwiftCode:
      fundingInstructions?.fundingInstructions?.swiftCode || '', // optional
    bankTransferBankAccountType: fundingInstructions?.fundingInstructions
      ?.isCheckingAccount
      ? 'checking'
      : fundingInstructions?.fundingInstructions?.isSavingsAccount
      ? 'savings'
      : '',
    bankTransferAdditionalWireInstructions:
      fundingInstructions?.fundingInstructions?.additionalWire || '', // optional

    // check info
    checkPayee: fundingInstructions?.fundingInstructions?.payee || '',
    checkAttention: fundingInstructions?.fundingInstructions?.attention || '', // optional
    checkCountry: fundingInstructions?.fundingInstructions?.country || '',
    checkAddress: fundingInstructions?.fundingInstructions?.streetAddress || '',
    checkAddressLine2:
      fundingInstructions?.fundingInstructions?.checkAddressLine2 || '', // optional
    checkCity: fundingInstructions?.fundingInstructions?.city || '',
    checkState: fundingInstructions?.fundingInstructions?.state || '',
    checkZipCode: fundingInstructions?.fundingInstructions?.zipcode || '',

    // ACH info
    ACHTransitABANumber:
      fundingInstructions?.fundingInstructions?.abaNumber || '',
    ACHAccountName: fundingInstructions?.fundingInstructions?.accountName || '',
    ACHBankAccountNumber:
      fundingInstructions?.fundingInstructions?.accountNumber || '',
    ACHBankName: fundingInstructions?.fundingInstructions?.bankName || '',
    ACHBankPhone: fundingInstructions?.fundingInstructions?.bankPhone || '',
    ACHForFurtherCreditAccountNumber:
      fundingInstructions?.fundingInstructions?.furtherCreditAccountNumber ||
      '', // optional
  }

  const handleUpdateSubmit = async (values) => {
    const payload = {
      assetInstructionID:
        fundingInstructions?.fundingInstructions?.assetInstructionID,
      instructionID: fundingInstructions?.fundingInstructions?.instructionID,
      abaNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferTransitABANumber
          : values.fundingType === 'ach'
          ? values.ACHTransitABANumber
          : undefined,
      accountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHBankAccountNumber
          : undefined,
      accountName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAccountName
          : values.fundingType === 'ach'
          ? values.ACHAccountName
          : undefined,
      additionalWire:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAdditionalWireInstructions
          : '',
      swiftCode:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferSwiftCode
          : '',
      furtherCreditAccountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferForFurtherCreditAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHForFurtherCreditAccountNumber
          : '',
      bankName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankName
          : values.fundingType === 'ach'
          ? values.ACHBankName
          : undefined,
      bankPhone:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankPhone
          : values.fundingType === 'ach'
          ? values.ACHBankPhone
          : undefined,

      payee: values.fundingType === 'check' ? values.checkPayee : '',
      streetAddress: values.fundingType === 'check' ? values.checkAddress : '',
      city: values.fundingType === 'check' ? values.checkCity : '',
      state: values.fundingType === 'check' ? values.checkState : '',
      country: values.fundingType === 'check' ? values.checkCountry : '',
      zipcode: values.fundingType === 'check' ? values.checkZipCode : '',
      attention: values.fundingType === 'check' ? values.checkAttention : '',

      isACH: values.fundingType === 'ach',
      isBankTransfer: values.fundingType === 'bankTransfer',
      isCheck: values.fundingType === 'check',
      isCheckingAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'checking'
          : undefined,
      isSavingsAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'savings'
          : undefined,
    }

    storeFundingInstructionsPayload(payload)
    history.push(`/funding-instructions/${assetID}/esign`)
  }

  const handleNewSubmit = async (values) => {
    const payload = {
      abaNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferTransitABANumber
          : values.fundingType === 'ach'
          ? values.ACHTransitABANumber
          : undefined,
      accountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHBankAccountNumber
          : undefined,
      accountName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAccountName
          : values.fundingType === 'ach'
          ? values.ACHAccountName
          : undefined,
      additionalWire:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferAdditionalWireInstructions
          : '',
      swiftCode:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferSwiftCode
          : '',
      furtherCreditAccountNumber:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferForFurtherCreditAccountNumber
          : values.fundingType === 'ach'
          ? values.ACHForFurtherCreditAccountNumber
          : '',
      bankName:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankName
          : values.fundingType === 'ach'
          ? values.ACHBankName
          : undefined,
      bankPhone:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankPhone
          : values.fundingType === 'ach'
          ? values.ACHBankPhone
          : undefined,

      payee: values.fundingType === 'check' ? values.checkPayee : '',
      streetAddress: values.fundingType === 'check' ? values.checkAddress : '',
      city: values.fundingType === 'check' ? values.checkCity : '',
      state: values.fundingType === 'check' ? values.checkState : '',
      country: values.fundingType === 'check' ? values.checkCountry : '',
      zipcode: values.fundingType === 'check' ? values.checkZipCode : '',
      attention: values.fundingType === 'check' ? values.checkAttention : '',

      isACH: values.fundingType === 'ach',
      isBankTransfer: values.fundingType === 'bankTransfer',
      isCheck: values.fundingType === 'check',
      isCheckingAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'checking'
          : undefined,
      isSavingsAccount:
        values.fundingType === 'bankTransfer'
          ? values.bankTransferBankAccountType === 'savings'
          : undefined,
    }

    /*AWAITING GIACT VERIFICATION*/
    /*const giactPayload = {
      CheckRTPEligibility: true,
      Balance: null,
      AccountNumber: null,
      Account: {
        RoutingNumber: payload.abaNumber,
        AccountType: values.bankTransferBankAccountType,
        AccountNumber: payload.accountNumber,
      },
    }*/
    /*
      if (
        values.fundingType === 'bankTransfer' ||
        values.fundingType === 'ach'
      ) {
        const {
          bankName,
          accountResponse,
          verificationResponse,
        } = await giactVerify(giactPayload)
        if (
          values.fundingType === 'check' ||
          (bankName && verificationResponse.code === 'Pass')
        ) {
          await updateAsset({
            ...storedAsset,
            stage: values.requireAll
              ? ADD_ASSET_STAGE.PURCHASE_REQUIREMENTS
              : ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
            saveStage: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
            fundingInstructions: { ...payload },
          })

          if (values.requireAll) {
            history.push(`/add-asset/${assetID}/purchase-requirements`)
          } else {
            history.push('/home')
          }
        } else {
          const errors = {}
          if (!bankName && accountResponse.code !== 'GS02') {
            errors[
              values.fundingType === 'bankTransfer'
                ? 'bankTransferTransitABANumber'
                : 'ACHTransitABANumber'
            ] = 'Please enter a valid 9-digit routing number.'
          }
          if (verificationResponse.code !== 'Pass') {
            errors[
              values.fundingType === 'bankTransfer'
                ? 'bankTransferBankAccountNumber'
                : 'ACHBankAccountNumber'
            ] = 'Please enter a valid bank account number.'
          }
          setErrors(errors)
        }
      } else {*/

    storeFundingInstructionsPayload(payload)
    history.push(`/funding-instructions/${assetID}/esign`)
  }

  const fundingInstructionsValidationSchema = Yup.object().shape({
    fundingType: Yup.string()
      .oneOf(['bankTransfer', 'check', 'ach'])
      .required('Required'),
    bankTransferTransitABANumber: Yup.string().when('fundingType', {
      is: 'bankTransfer',
      then: Yup.string()
        .min(9, 'Must be exactly 9 digits')
        .max(9, 'Must be exactly 9 digits')
        .matches(/^[0-9]*$/, 'Must be only numbers')
        .required('Required'),
    }),
    bankTransferBankAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .test('test_uniqueness', '', (value, context) => {
            const { parent, createError } = context
            if (parent.bankTransferTransitABANumber == value) {
              return createError({
                message:
                  'The Account Number must be unique from the Routing number. ',
              })
            }
            return true
          })
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .required('Required'),
      })
      .default(undefined),
    bankTransferBankName: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(80, 'Too Long')
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .required('Required'),
      })
      .default(undefined),
    bankTransferAccountName: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(80, 'Too Long')
          .matches(
            /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
            'Must be only letters'
          )
          .required('Required'),
      })
      .default(undefined),
    bankTransferBankPhone: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .matches(
            RegExp(PHONE_NUMBER_REGEX),
            'Please enter valid phone number'
          )
          .required('Required'),
      })
      .default(undefined),
    bankTransferForFurtherCreditAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .optional(),
      })
      .default(undefined),
    bankTransferSwiftCode: Yup.string()
      .matches(
        '^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$',
        'Must match Swift format.'
      )
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string().notRequired(),
      })
      .default(undefined),
    bankTransferBankAccountType: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .oneOf(['checking', 'savings'])
          .required('Required'),
      })
      .default(undefined),
    bankTransferAdditionalWireInstructions: Yup.string()
      .when('fundingType', {
        is: 'bankTransfer',
        then: Yup.string()
          .notRequired()
          .default(undefined),
      })
      .default(undefined),

    // check validations

    checkPayee: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(146, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    checkAttention: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(75, 'Too Long')
          .notRequired()
          .default(undefined),
      })
      .default(undefined),
    checkCountry: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkAddress: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkCity: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .max(75, 'Too Long')
          .required('Required'),
      })
      .default(undefined),
    checkState: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string().required('Required'),
      })
      .default(undefined),
    checkZipCode: Yup.string()
      .when('fundingType', {
        is: 'check',
        then: Yup.string()
          .test('test_zip_code', '', (value, context) => {
            const { parent, createError } = context
            if (parent.checkCountry == 'US') {
              if (!RegExp(ZIP_CODE_REGEX).test(value)) {
                return createError({
                  message: 'Must be valid 5 or 9 digit US zip code!',
                })
              }
            }
            if (parent.checkCountry == 'CA') {
              if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
                return createError({
                  message: 'Must be a valid six-character CA code!',
                })
              }
            }
            return true
          })
          .when('requireAll', {
            is: true,
            then: Yup.string().required('Required'),
          }),
      })
      .default(undefined),

    // ach validations

    ACHTransitABANumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .min(9, 'Must be exactly 9 digits')
          .max(9, 'Must be exactly 9 digits')
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .required('Required'),
      })
      .default(undefined),
    ACHBankAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .max(17, 'Cannot be greater than 17 digits')
          .test('test_uniqueness', '', (value, context) => {
            const { parent, createError } = context
            if (parent.ACHTransitABANumber == value) {
              return createError({
                message:
                  'The Account Number must be unique from the Routing number. ',
              })
            }
            return true
          })
          .matches(/^[0-9]*$/, 'Must be only numbers')
          .required('Required'),
      })
      .default(undefined),
    ACHBankName: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .max(80, 'Too Long')
          .required('Required'),
      })
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Must be only letters'
      )
      .default(undefined),
    ACHAccountName: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .max(80, 'Too Long')
          .required('Required'),
      })
      .matches(
        /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi,
        'Must be only letters'
      )
      .default(undefined),
    ACHBankPhone: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string()
          .matches(
            RegExp(PHONE_NUMBER_REGEX),
            'Please enter valid phone number'
          )
          .required('Required'),
      })
      .default(undefined),
    ACHForFurtherCreditAccountNumber: Yup.string()
      .when('fundingType', {
        is: 'ach',
        then: Yup.string().optional(),
      })
      .matches(/^[0-9]*$/, 'Must be only numbers')
      .max(17, 'Cannot be greater than 17 digits')
      .default(undefined),
  })

  if (!fundingInstructions) return <Spinner />

  return (
    <FundingInstructionsContent
      handleUpdateSubmit={handleUpdateSubmit}
      handleNewSubmit={handleNewSubmit}
      fundingInstructionsValidationSchema={fundingInstructionsValidationSchema}
      isNotEditable={false}
      initialValues={initialValues}
      FlowActions={FlowActions}
    />
  )
}

FundingInstructions.propTypes = propTypes

FundingInstructions.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    fundingInstructions: selectors.fundingInstructions(state),
  }
}

const mapDispatchToProps = {
  updateFundingInstructions: apiActions.updateAssetFundingInstructions,
  storeFundingInstructionsPayload: actions.storeFundingInstructionsPayload,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FundingInstructions
)
