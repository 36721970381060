import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useParams, useHistory } from 'react-router-dom'
import { FlowActions } from '../components'
import { Formik, Form } from 'formik'
import {
  TransactionConfirmationDetailsFields,
  FromOriginalTransactionFields,
  ToNewTransactionFields,
  ValueDifferenceFields,
} from '../forms'
import * as Yup from 'yup'
import {
  EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING,
  VALUATION_METHODOLOGY,
} from 'config'
import { getInspiraFBOFormat } from 'utils'
import { reduce, isNaN, isEmpty } from 'lodash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'

const propTypes = {
  exchangeConfirmation: PropTypes.object.isRequired,
  updateExchangeConfirmation: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
}

const defaultProps = {}

function ExchangeConfirmation({
  exchangeConfirmation,
  updateExchangeConfirmation,
  flashErrorMessage,
}) {
  const { exchangeID } = useParams()
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleSubmit = async (values) => {
    const payload = {
      ...exchangeConfirmation,
      effectiveDate:
        values.transactionConfirmationDetails.exchangeEffectiveDate,
      valueDifference: values.valueDifference.value,
      sponsorComments: values.valueDifference.comment,
      differencePaymentMethod: values.valueDifference.method,
      lineItems: [
        ...Object.values(values.fromOriginal).map((transaction) => {
          return {
            ...transaction,
            actualAmount: transaction.actualAmount.toString(),
          }
        }),
        ...Object.values(values.toNew).map((transaction) => {
          return {
            ...transaction,
            actualAmount: transaction.actualAmount.toString(),
          }
        }),
      ],
    }

    try {
      await updateExchangeConfirmation(payload)
      history.push(`/confirm-exchange/${exchangeID}/sign`)
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
    }
  }

  const initialValues = {
    transactionConfirmationDetails: {
      requestDate: exchangeConfirmation.documentSentDate || '',
      investorRegistration: getInspiraFBOFormat(
        exchangeConfirmation.clientFBOName
      ),
      exchangeEffectiveDate: exchangeConfirmation.effectiveDate || '',
    },
    toNew: {},
    fromOriginal: {},
    valueDifference: {
      value: exchangeConfirmation.valueDifference || '',
      method: exchangeConfirmation.differencePaymentMethod || '',
      comment: exchangeConfirmation.sponsorComments || '',
    },
  }

  const lineItems = exchangeConfirmation?.lineItems

  // set up initial values nested objects
  lineItems?.map((lineItem) => {
    const currentValues =
      initialValues[
        EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING[
          lineItem.lineItemRecordType
        ]
      ]
    initialValues[
      EXCHANGE_CONFIRMATION_RECORD_TYPE_FORM_MAPPING[
        lineItem.lineItemRecordType
      ]
    ] = {
      ...currentValues,
      [lineItem.investmentLineItemDirectionID]: {
        ...lineItem,
      },
    }
  })

  const fromOriginalTransactions = lineItems?.filter(
    (item) => item.lineItemRecordType === 'Divestment'
  )
  const toNewTransactions = lineItems?.filter(
    (item) => item.lineItemRecordType === 'Investment'
  )

  const lazyValidation = Yup.lazy((values) => {
    const toNewTotal = Object.values(values.toNew).reduce(
      (acc, transaction) => {
        const numberToAdd = isNaN(parseFloat(transaction.actualAmount))
          ? 0
          : parseFloat(transaction.actualAmount)
        acc += numberToAdd
        return acc
      },
      0
    )

    const fromOriginalTotal = Object.values(values.fromOriginal).reduce(
      (acc, transaction) => {
        const numberToAdd = isNaN(parseFloat(transaction.actualAmount))
          ? 0
          : parseFloat(transaction.actualAmount)
        acc += numberToAdd
        return acc
      },
      0
    )

    const differenceTotal = isNaN(parseFloat(values?.valueDifference?.value))
      ? 0
      : parseFloat(values?.valueDifference?.value)

    const newPlusDifferenceTotal = toNewTotal + differenceTotal

    const validationObj = {
      transactionConfirmationDetails: Yup.object({
        exchangeEffectiveDate: Yup.string().required('Required'),
      }),
      fromOriginal: Yup.object(
        reduce(
          initialValues.fromOriginal,
          (result, transaction) => {
            return {
              ...result,
              [transaction.investmentLineItemDirectionID]: Yup.object({
                actualAmount: Yup.string()
                  .required('Required')
                  .nullable(),
                valuationMethodology: Yup.string().required('Required'),
                sponsorShares: Yup.string().when('valuationMethodology', {
                  is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
                  then: Yup.string().required('Required'),
                }),
                sponsorPrice: Yup.string().when('valuationMethodology', {
                  is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
                  then: Yup.string().required('Required'),
                }),
              }),
            }
          },
          {}
        )
      ),
      toNew: Yup.object(
        reduce(
          initialValues.toNew,
          (result, transaction) => {
            return {
              ...result,
              [transaction.investmentLineItemDirectionID]: Yup.object({
                actualAmount: Yup.string()
                  .required('Required')
                  .nullable(),
                valuationMethodology: Yup.string().required('Required'),
                sponsorShares: Yup.string().when('valuationMethodology', {
                  is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
                  then: Yup.string().required('Required'),
                }),
                sponsorPrice: Yup.string().when('valuationMethodology', {
                  is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
                  then: Yup.string().required('Required'),
                }),
                physicalSecurity: Yup.string().required('Required'),
              }),
            }
          },
          {}
        )
      ),
      valueDifference: Yup.object({
        method: Yup.string().when('value', {
          is: (val) => !isEmpty(val),
          then: Yup.string().required('Required'),
        }),
      }),
      totals: Yup.number().test(
        'toNew+difference=fromOriginal',
        `New Investments plus value difference must be within $10 of the original investments.`,
        () => Math.abs(fromOriginalTotal - newPlusDifferenceTotal) <= 10
      ),
    }

    const validationObject = Yup.object(validationObj)

    return validationObject
  })

  return (
    <Formik
      onSubmit={handleSubmit}
      validationSchema={lazyValidation}
      initialValues={initialValues}
      enableReinitialize={true}
    >
      {({ errors, isSubmitting }) => (
        <Form>
          <div className="flow-card-container">
            <div className="card">
              <div className="form-card-content">
                <div>
                  <h4>Transaction Confirmation Details</h4>
                  <h4>Confirmation of Exchange – REQUIRED ACTION</h4>
                </div>
                <div className="bulleted-circles">
                  <div className="bulleted-circle-item">
                    <div className="bulleted-circle-contents">
                      Once the exchange is executed, please proceed with this
                      confirmation. Exchanges should be confirmed within 48
                      hours of trade execution.
                    </div>
                  </div>
                  <div className="bulleted-circle-item">
                    <div className="bulleted-circle-contents">
                      Review the transaction details provided below. Several
                      fields have been prepopulated based upon the exchange
                      documents originally submitted to you. Please review
                      carefully to ensure the pre-populated information agrees
                      with your records. If an update to a pre-populated field
                      is required, please overwrite the existing information and
                      include a Comment at the bottom of this form explaining
                      the change. Inspira may contact you if we need additional
                      information.
                    </div>
                  </div>
                  <div className="bulleted-circle-item">
                    <div className="bulleted-circle-contents">
                      Complete any blank fields.
                    </div>
                  </div>
                </div>
                <div className="transaction-confirmation-details-form-bottom form-area">
                  <h4>
                    To proceed to the next section, you must complete this form
                    in its entirety.
                  </h4>
                  <TransactionConfirmationDetailsFields />
                </div>
              </div>
            </div>
            <div className="from-original-transactions form-area">
              <FromOriginalTransactionFields
                transactions={fromOriginalTransactions}
              />
            </div>
            <div className="to-new-transactions form-area">
              <ToNewTransactionFields transactions={toNewTransactions} />
            </div>
            <div className="value-difference form-area">
              <ValueDifferenceFields />
            </div>
            <div className="error-message">{errors.totals}</div>
            <FlowActions prevStep="document-upload" submitting={isSubmitting} />
          </div>
        </Form>
      )}
    </Formik>
  )
}

ExchangeConfirmation.propTypes = exact(propTypes)
ExchangeConfirmation.defaultProps = defaultProps

function mapStateToProps() {}

const mapDispatchToProps = {
  updateExchangeConfirmation: apiActions.updateExchangeConfirmation,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExchangeConfirmation
)
