import React, { useState } from 'react'
import PropTypes from 'prop-types'

import moment from 'moment'
import { Formik, Form } from 'formik'
import { preformInputOperation, convertStringToFixedFloat } from 'utils'
import { FlowActions } from '../components'
import {
  LabelWithTooltip,
  CurrencyInput,
  ValuationMethodModal,
  ACPInputField,
  ACPDateInputField,
} from 'components'
import { isEmpty, map } from 'lodash'
import * as Yup from 'yup'
import { VALUATION_METHODOLOGY } from 'config'
import InvestorsTable from '../components/InvestorsTable'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

const defaultProps = {}

function PurchaseDetailsForm({ handleSubmit, initialValues }) {
  const [showValMethodModal, setShowValMethodModal] = useState(false)
  const [confirmValMethod, setConfirmValMethod] = useState(false)
  const isPurchaseConfirm = true
  const validationSchema = Yup.object().shape({
    managementFee: Yup.string().required('Required'),
    valuationMethodology: Yup.string()
      .oneOf(
        map(VALUATION_METHODOLOGY, (option) => option.value),
        'Required'
      )
      .required('Required'),
    price: Yup.string().when('valuationMethodology', {
      is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
      then: Yup.string()
        .test('test_price', '', (value, context) => {
          const { createError } = context
          if (value > 0) return true
          return createError({
            message: 'Price per Share must be greater than zero.',
          })
        })
        .required('Required'),
      otherwise: Yup.string().notRequired(),
    }),
    effectiveDate: Yup.date().required(
      'Please enter the transaction effective date (mm/dd/yyyy).'
    ),
    isTherePhysicalSecurity: Yup.bool().required('Required'),
    investors: Yup.lazy((value) => {
      if (!isEmpty(value)) {
        const validationObject = {
          managementFee: Yup.string().required('Required'),
          effectiveDate: Yup.date().required(
            'Please enter the transaction effective date (mm/dd/yyyy).'
          ),
        }
        const newEntries = Object.keys(value).reduce(
          (acc, val) => ({
            ...acc,
            [val]: Yup.object(validationObject),
          }),
          {}
        )

        return Yup.object().shape(newEntries)
      }
      return Yup.mixed().notRequired()
    }),
  })

  return (
    <div className="flow-card-container">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, errors }) => {
          const valuationMethodologyOptions = map(
            VALUATION_METHODOLOGY
          ).reverse()
          const isPricePerShare =
            values.valuationMethodology ===
            VALUATION_METHODOLOGY.PRICE_PER_SHARE.value
          return (
            <>
              <Form>
                <div className="card">
                  <div className="form-card-content">
                    <h4 className="flow-section-header bold-18">
                      Provide Purchase Details
                    </h4>
                    <div className="help-text-first">
                      The information you provide will apply to all investors
                      listed below.
                    </div>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          name="assetName"
                          label="Asset Name"
                          disabled={true}
                        />
                      </div>
                      <div className="one-half column">
                        <CurrencyInput
                          name="totalAmount"
                          label="Total Amount of Purchase ($)"
                          labelComponent={LabelWithTooltip}
                          tooltipContent="This amount includes investments from all investors listed on this page. It represents the total amount authorized to be sent to the investment sponsor by the investor(s), from their Inspira account. It does not include any management fees."
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          type="number"
                          name="managementFee"
                          label="Management Fee (%)"
                          placeholder="Enter percentage"
                          tooltipContent="Enter the fee as a percentage of the asset. We’ll use that percentage to calculate the fee (in dollars) for each investor. That dollar amount will be deducted from the funds received. Be sure to review the management fee for each investor. If you need to change the amount for a specific investor, you can do that in the table below."
                          onChange={(e) => {
                            const decimalValue = preformInputOperation(
                              e.target.value,
                              100,
                              'divide'
                            )

                            const obj = Object.keys(values.investors).reduce(
                              (acc, key) => {
                                acc[key] = {
                                  ...values.investors[key],
                                  managementFee: preformInputOperation(
                                    values.investors[key].purchaseAmount,
                                    decimalValue,
                                    'multiply'
                                  ),
                                }
                                return acc
                              },
                              {}
                            )

                            setFieldValue('investors', obj)
                          }}
                          onBlur={(e) => {
                            const num = convertStringToFixedFloat(
                              e.target.value,
                              2
                            )
                            setFieldValue('managementFee', num)
                          }}
                          maskOptions={{
                            numeral: true,
                            numeralDecimalScale: 2,
                          }}
                        />
                      </div>
                      <div className="one-half column">
                        <ACPDateInputField
                          name="effectiveDate"
                          label="Effective Date"
                          tooltipContent="This is the date that the investment sponsor executed the transaction on behalf of the investor(s). If you need to change the date for a specific investor, you can do that in the table below."
                          parse={(val) => moment(val).format('MM/DD/YYYY')}
                          onChange={(e) => {
                            const obj = Object.keys(values.investors).reduce(
                              (acc, key) => {
                                acc[key] = {
                                  ...values.investors[key],
                                  effectiveDate: e.target.value,
                                }
                                return acc
                              },
                              {}
                            )

                            setFieldValue('investors', obj)
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          name="valuationMethodology"
                          radioOptions={valuationMethodologyOptions}
                          onChange={() => setShowValMethodModal(true)}
                          label="Valuation Method"
                          tooltipContent="This is how the asset’s value is reflected on the investment sponsor’s books and records. It may be the number of shares times the Price Per Share or Capital Value (i.e., total dollar value of the asset"
                        />
                      </div>
                      {isPricePerShare && (
                        <div className="one-half column">
                          <CurrencyInput
                            name="price"
                            label="Price Per Share ($) *"
                            placeholder="Enter amount per share"
                            onChange={(e) => {
                              const obj = Object.keys(values.investors).map(
                                (key) => ({
                                  ...values.investors[key],
                                  shares:
                                    e.target.value > 0
                                      ? preformInputOperation(
                                          values.investors[key].purchaseAmount,
                                          e.target.value,
                                          'divide'
                                        )
                                      : 0.0,
                                  price: e.target.value,
                                })
                              )

                              setFieldValue('investors', obj)
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="one-half column">
                      <ACPInputField
                        name="isTherePhysicalSecurity"
                        label="Will a physical security be issued for this asset?"
                        radioOptions={[
                          { key: 'Yes', value: true },
                          { key: 'No', value: false },
                        ]}
                      />
                    </div>
                    <div className="one-half column"></div>
                  </div>
                  <div className="row">
                    <ACPInputField
                      name="comments"
                      label="Comments"
                      placeholder="Enter any comments or questions you may have about the transaction."
                      onChange={(e) => {
                        const obj = Object.keys(values.investors).map(
                          (key) => ({
                            ...values.investors[key],
                            comments: e.target.value,
                          })
                        )
                        setFieldValue('investors', obj)
                      }}
                      textarea
                      required={false}
                    />
                  </div>
                </div>
                <br />
                <InvestorsTable
                  investors={values.investors}
                  isPricePerShare={isPricePerShare}
                  setFieldValue={setFieldValue}
                  errors={errors}
                />
                <FlowActions prevStep="uploads" submitting={isSubmitting} />
              </Form>
              {showValMethodModal && !confirmValMethod && (
                <ValuationMethodModal
                  onClose={() => setShowValMethodModal(false)}
                  fieldName={'valuationMethodology'}
                  setFieldValue={setFieldValue}
                  setFieldValueOnCancel={setFieldValue}
                  setConfirmValMethod={setConfirmValMethod}
                  initialValue={initialValues.valuationMethodology}
                  currentValue={values.valuationMethodology}
                  isPurchaseConfirm={isPurchaseConfirm}
                />
              )}
            </>
          )
        }}
      </Formik>
    </div>
  )
}

PurchaseDetailsForm.propTypes = propTypes
PurchaseDetailsForm.defaultProps = defaultProps

export default PurchaseDetailsForm
