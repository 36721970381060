import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { InvestorSelectionTable } from '../components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { Redirect, useParams } from 'react-router'
import FlowActions from '../components/FlowActions'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
const propTypes = {
  clientAccounts: PropTypes.arrayOf(PropTypes.object),
  fetchClientAccounts: PropTypes.func,
  capitalCommitment: PropTypes.object,
  storeCapitalCommitment: PropTypes.func,
  clearClientAccounts: PropTypes.func,
}

const defaultProps = {}

function InvestorSelection({
  clientAccounts,
  fetchClientAccounts,
  capitalCommitment,
  storeCapitalCommitment,
  clearClientAccounts,
}) {
  const [error, setError] = useState()
  const [selectedClients, setSelectedClients] = useState(
    capitalCommitment?.selectedClients || []
  )

  let { assetID } = useParams()
  const history = useHistory()

  let numSelectedInvestors = selectedClients.length

  useEffect(() => {
    return () => clearClientAccounts()
  }, [])

  useEffect(() => {
    fetchClientAccounts(assetID).catch((e) => setError(e))
  }, [assetID])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (error) {
    return (
      <div>
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMessage:
                error.errors.message ||
                'Something went wrong, please try again.',
            },
          }}
        />
      </div>
    )
  }
  return (
    <div className="container flow-card-container">
      <div className="num-investors-counter">
        {numSelectedInvestors > 0
          ? `(${numSelectedInvestors}) Investor(s) Selected`
          : 'Please select each investor for this capital call.'}
      </div>
      <InvestorSelectionTable
        clientAccounts={clientAccounts}
        setSelectedClients={setSelectedClients}
        selectedClientAccountIDs={selectedClients.map(
          (client) => client.clientAccountID
        )}
      />
      <FlowActions
        prevStep="/assets/capital-commitments"
        onContinue={() => {
          storeCapitalCommitment({
            assetID: assetID,
            assetName: clientAccounts[0].assetName,
            selectedClients: selectedClients,
          })
          history.push({
            pathname: `/capital-call/${assetID}/capital-call-amount`,
          })
        }}
        isDisabled={isEmpty(selectedClients)}
      />
    </div>
  )
}

InvestorSelection.propTypes = exact(propTypes)
InvestorSelection.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    clientAccounts: selectors.clientAccounts(state),
    capitalCommitment: selectors.capitalCommitment(state),
  }
}

const mapDispatchToProps = {
  fetchClientAccounts: apiActions.fetchClientAccounts,
  clearClientAccounts: actions.clearClientAccounts,
  storeCapitalCommitment: actions.storeCapitalCommitment,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InvestorSelection
)
