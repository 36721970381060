import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

const propTypes = {
  data: PropTypes.array.isRequired,
  listLimit: PropTypes.number,
}

const defaultProps = {}

function CollapsableList({ data, listLimit }) {
  const [showMore, setShowMore] = useState(false)

  if (!data || data.length < 1) {
    return ''
  }

  return (
    <>
      {!showMore && (
        <ul className="cell-list">
          {data.slice(0, listLimit).map((asset, index) => {
            return (
              <li className="cell-list-item" key={index}>
                {asset}
              </li>
            )
          })}
        </ul>
      )}
      {showMore && (
        <ul className="scrollable-cell-list">
          {data.map((asset, index) => {
            return (
              <li className="cell-list-item reg-14" key={index}>
                {asset}
              </li>
            )
          })}
        </ul>
      )}

      {!showMore && data.length > listLimit && (
        <li>
          <button
            className="collapse-list-buttons"
            onClick={() => setShowMore(true)}
          >
            {`Show +${data.length - listLimit} more`}
          </button>
        </li>
      )}
      {showMore && (
        <li>
          <button
            className="collapse-list-buttons"
            onClick={() => setShowMore(false)}
          >
            {`Collapse List`}
          </button>
        </li>
      )}
    </>
  )
}

CollapsableList.propTypes = exact(propTypes)
CollapsableList.defaultProps = defaultProps

export default CollapsableList
