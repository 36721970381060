import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { LiquidationDetailsForm } from '../forms'
import { mapValues } from 'lodash'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { useParams, useHistory } from 'react-router-dom'
import { DocumentSubGroups, LIQUIDATION_TYPES } from 'config'
import {
  convertStringToFixedFloat,
  getInspiraFBOFormat,
  bulkPromiseUploads,
  createFileErrors,
} from 'utils'

const propTypes = {
  liquidation: PropTypes.object.isRequired,
  updateLiquidation: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  updateLiquidationDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

const convertToString = (val) => {
  if (val === null || val === undefined) return ''
  if (val === false) return 'false'
  return val
}
function LiquidationDetails({
  liquidation,
  updateLiquidationDocument,
  updateLiquidation,
  flashErrorMessage,
}) {
  const history = useHistory()
  const { liquidationID } = useParams()
  const shares = convertStringToFixedFloat(liquidation.shares, 4, 0)

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const handleSubmit = async (values, { setErrors }) => {
    {
      if (shouldUploadDocument) {
        const fileUploadPromises = Object.entries(values)
          .filter((e) => e[1].fileName && e[1].documentID)
          .map((e) => ({
            fileName: e[1].fileName,
            fileContents: e[1].fileContents,
            fileType: e[1].fileType,
            documentID: e[1].documentID,
          }))
          .map((doc) => {
            const formData = new FormData()
            formData.append(
              'fileContents',
              new Blob([doc.fileContents], {
                type: doc.fileType,
              }),
              doc.fileName
            )
            return updateLiquidationDocument(liquidationID, doc, formData)
          })
        const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)
        if (rejectedUploads.length) {
          const errors = createFileErrors(rejectedUploads)
          setErrors(errors)
        } else {
          try {
            await updateLiquidation(values)
            history.push(`/confirm-liquidation/${liquidationID}/sign2`)
          } catch (e) {
            flashErrorMessage(
              e.errors.message || 'Something went wrong, please try again.'
            )
          }
        }
      } else {
        try {
          await updateLiquidation(values)
          history.push(`/confirm-liquidation/${liquidationID}/sign2`)
        } catch (e) {
          flashErrorMessage(
            e.errors.message || 'Something went wrong, please try again.'
          )
        }
      }
    }
  }

  const initialValues = {
    ...mapValues(liquidation, convertToString),
    assetValuationDocument: '',
    clientFBOName: getInspiraFBOFormat(liquidation.clientFBOName),
    shares: shares,
  }
  const assetValuationDocument = liquidation.dd.find(
    (doc) => doc.documentSubGroup === DocumentSubGroups.LIQUIDATION_PART_2
  )
  const shouldUploadDocument =
    liquidation.liquidationType === LIQUIDATION_TYPES.Full

  return (
    <LiquidationDetailsForm
      initialValues={initialValues}
      assetValuationDocument={assetValuationDocument}
      shouldUploadDocument={shouldUploadDocument}
      handleSubmit={handleSubmit}
    />
  )
}

LiquidationDetails.propTypes = propTypes
LiquidationDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {
  updateLiquidation: apiActions.updateLiquidation,
  updateLiquidationDocument: apiActions.updateLiquidationDocument,
  flashErrorMessage: flashActions.flashErrorMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  LiquidationDetails
)
