import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
import { createSelector } from 'reselect'
import * as contactProfileActions from './actions'
import { CONTACT_CLASSIFICATION } from 'config'
import { hasValue, sortRoleNames } from 'utils'

const reducerKey = 'contactProfile'
const slice = 'root.contactProfile'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchContactsList]: setOnSuccess('contactsList'),
    [apiActions.fetchContactProfile]: setOnSuccess('contact'),
    [contactProfileActions.clearContactProfile]: unsetState('contact'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  contact: select('contact'),
  contactsList: select('contactsList'),
}

selectors.assignedRoles = createSelector([selectors.contact], (contact) => {
  if (!contact) return
  const roleNames = contact.roleNames.map((roleName) => roleName.role)
  return sortRoleNames(roleNames)
})

selectors.contactType = createSelector(
  [selectors.contact, selectors.contactsList],
  (contact, contactsList) => {
    if (!contact || !contactsList) return

    const dateAdded = contactsList.find(
      (item) =>
        item.contactDetails.contactID === contact.contactDetails.contactID
    ).dateAdded

    const isACPUser = hasValue(dateAdded)

    return isACPUser
      ? CONTACT_CLASSIFICATION.ACP_USER
      : CONTACT_CLASSIFICATION.ASSET_CONTACT
  }
)

export { reducer, selectors, reducerKey }
