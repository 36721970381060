import React, { useState } from 'react'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import ContactModal from './ContactModal'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const propTypes = {
  expanded: PropTypes.bool.isRequired,
}

const defaultProps = {}

function Footer({ expanded }) {
  const [modalShown, setModalShown] = useState(false)

  return (
    <footer className={classNames('footer', { expand: !expanded })}>
      <section>
        <ul className="is-inline">
          <li>
            <button
              className="link-default"
              onClick={() => setModalShown(true)}
            >
              Contact Us
            </button>
          </li>
          <li>
            <a
              href="https://inspirafinancial.com/privacy-policy/terms/terms-of-use"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Use/Service
            </a>
          </li>
          <li>
            <a
              href="https://inspirafinancial.com/privacy-policy/privacy/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy
            </a>
          </li>
          <li>
            <a
              href="https://inspirafinancial.com/privacy-policy/security/security"
              target="_blank"
              rel="noopener noreferrer"
            >
              Security
            </a>
          </li>
        </ul>
      </section>
      <section>
        <p>©{new Date().getFullYear()} Inspira Financial Trust, LLC</p>
        <p>
          Inspira Financial Trust, LLC and its affiliates perform the duties of
          a directed custodian and/or an administrator of consumer directed
          benefits and, as such, do not provide due diligence to third parties
          on prospective investments, platforms, sponsors, or service providers,
          and do not offer or sell investments or provide investment, tax, or
          legal advice.
        </p>
        <p>
          Inspira and Inspira Financial are trademarks of Inspira Financial
          Trust, LLC.
        </p>
      </section>
      {modalShown && <ContactModal onClose={() => setModalShown(false)} />}
    </footer>
  )
}

Footer.propTypes = exact(propTypes)
Footer.defaultProps = defaultProps

export default pure(Footer)
