import { isEmpty } from 'lodash'

const areAllAssetsSelected = (assets, selectedIDs) => {
  if (isEmpty(selectedIDs)) return false

  const selectedMap = selectedIDs.reduce((acc, id) => {
    acc[id] = true
    return acc
  }, {})

  return assets.every((asset) => selectedMap[asset.assetID])
}

export default areAllAssetsSelected
