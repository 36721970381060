import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'

import { compose } from 'recompose'
import { connect } from 'react-redux'

import * as apiActions from 'api-actions'

import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { NotificationsStatusMarker } from 'components'

import { displayDateTime } from 'utils'

const propTypes = {
  isRead: PropTypes.bool,
  notification: PropTypes.object,
  updateNotification: PropTypes.func,
  removeNotification: PropTypes.func,
}

const defaultProps = {}

function NotificationsItem({
  isRead,
  notification,
  updateNotification,
  removeNotification,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMarkAsRead = () => {
    const notificationUpdate = {
      ...notification,
      isRead: true,
    }
    handleClose()
    updateNotification(notificationUpdate)
  }

  const handleRemove = () => {
    const notificationUpdate = {
      ...notification,
      isRemoved: true,
    }
    handleClose()
    removeNotification(notificationUpdate)
  }

  return (
    <>
      <ListItem>
        {!isRead && (
          <ListItemAvatar>
            <NotificationsStatusMarker />
          </ListItemAvatar>
        )}
        <ListItemText
          primary={notification.subject}
          secondary={
            notification.createdDate
              ? displayDateTime(notification.createdDate)
              : 'Formatted Timestamp goes here'
          }
        />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {!isRead && (
            <MenuItem onClick={handleMarkAsRead}>Mark As Read</MenuItem>
          )}
          <MenuItem onClick={handleRemove}>Remove This notification</MenuItem>
        </Menu>
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            id="notifications-expand-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  )
}

NotificationsItem.propTypes = exact(propTypes)
NotificationsItem.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateNotification: apiActions.updateNotification,
  removeNotification: apiActions.removeNotification,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  NotificationsItem
)
