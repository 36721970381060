import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { PurchaseDetailsForm } from '../forms'
import { Redirect, useHistory } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import { Spinner } from 'lp-components'
import { isEmpty } from 'lodash'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  investorsData: PropTypes.array.isRequired,
  purchaseIDs: PropTypes.array.isRequired,
  fetchInvestorsData: PropTypes.func.isRequired,
  clearInvestorsData: PropTypes.func.isRequired,
  updatePurchase: PropTypes.func.isRequired,
}

const defaultProps = {}
function PurchaseDetails({
  flashErrorMessage,
  investorsData,
  purchaseIDs,
  clearInvestorsData,
  fetchInvestorsData,
  updatePurchase,
}) {
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (isEmpty(purchaseIDs)) return <Redirect to="/transactions/pending" />

  useEffect(() => {
    fetchInvestorsData(purchaseIDs.toString(), 'data')
    return () => {
      clearInvestorsData()
    }
  }, [])

  if (!investorsData || !investorsData[0].purchaseAmount) return <Spinner />

  const investors = investorsData.reduce((acc, investor) => {
    acc[investor.purchaseID] = investor
    return acc
  }, {})

  const initialValues = {
    assetName: investorsData[0].assetName,
    totalAmount: investorsData
      .map((investor) => investor.purchaseAmount)
      .reduce((sum, a) => sum + parseFloat(a), 0),
    isTherePhysicalSecurity: Boolean(investorsData[0].isTherePhysicalSecurity),
    effectiveDate: '',
    managementFee: '0.00',
    valuationMethodology: investorsData[0].valuationMethodology,
    investors: investors,
    comments: investorsData[0].comments,
    price: investorsData[0].price,
  }

  return (
    <PurchaseDetailsForm
      initialValues={initialValues}
      handleSubmit={async (values) => {
        const decomposedInvestors = Object.keys(values.investors).map((key) => {
          return {
            ...values.investors[key],
            valuationMethodology: values.valuationMethodology,
          }
        })
        try {
          await updatePurchase(decomposedInvestors)
          history.push(`/bulk-confirmations/sign`)
        } catch (e) {
          flashErrorMessage(
            e.errors.message || 'Something went wrong, please try again.'
          )
        }
      }}
    />
  )
}

PurchaseDetails.propTypes = propTypes
PurchaseDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    purchaseIDs: globalSelectors.purchaseIDs(state),
    investorsData: selectors.investorsData(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
  updatePurchase: apiActions.updatePurchase,
  clearInvestorsData: actions.clearInvestorsData,
  fetchInvestorsData: apiActions.fetchInvestorsData,
  storePurchaseIDs: actions.storePurchaseIDs,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PurchaseDetails
)
