import { throttle, noop } from 'lodash'

const TICK_INTERVAL = 1000
const DEFAULT_TIMEOUT = 900000 // 15 minutes

function setIdleTimer({
  onIdle = noop,
  timeout = DEFAULT_TIMEOUT,
  resume = false,
}) {
  function startTimer(persistTime = false) {
    persistTime ? tick() : setTimerStartDate(new Date())
    if (!window.idleInterval) {
      addEventListeners()
      window.idleInterval = setInterval(tick, TICK_INTERVAL)
    }
  }

  function stopTimer() {
    clearInterval(window.idleInterval)
    window.idleInterval = false
    removeEventListeners()
  }

  function restartTimer() {
    stopTimer()
    startTimer()
  }

  function tick() {
    const timerStartDate = getTimerStartDate()
    const timeElapsed = Math.ceil(
      new Date().getTime() - timerStartDate.getTime()
    )
    if (timeElapsed > timeout) {
      stopTimer()
      return onIdle()
    }
  }

  // Throttle timer reset to 1 call/sec
  const handleEvent = throttle(restartTimer, 1000)

  function addEventListeners() {
    document.addEventListener('keydown', handleEvent)
    document.addEventListener('mousemove', handleEvent)
    document.addEventListener('touchstart', handleEvent)
  }

  function removeEventListeners() {
    document.removeEventListener('keydown', handleEvent)
    document.removeEventListener('mousemove', handleEvent)
    document.removeEventListener('touchstart', handleEvent)
  }

  startTimer(resume)
}

// Local storage helpers

const TIMER_START_DATE_KEY = 'timer-start-date'

function setTimerStartDate(timerStartDate) {
  return localStorage.setItem(TIMER_START_DATE_KEY, timerStartDate)
}

function getTimerStartDate() {
  return new Date(localStorage.getItem(TIMER_START_DATE_KEY))
}

export default setIdleTimer
