import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import * as actions from '../actions'
import { useParams } from 'react-router'
import InvestorDetailsTable from '../components/InvestorDetailsTable'
import { Card } from 'components'
import { Spinner } from 'lp-components'
import NumberOfInvestors from 'images/number_investors.svg'
import TotalMarketValue from 'images/total_Market_value.svg'
import {
  INVESTOR_DETAILS_VALUATION_METHODOLOGY,
  USER_CLASSIFICATION,
} from 'config'
import { formatNumberAsCurrency } from 'utils'
import { isEmpty } from 'lodash'
import ViewFundingInstructionsButton from '../components/ViewFundingInstructionsButton'

const propTypes = {
  currentUser: PropTypes.object,
  assetDetails: PropTypes.shape({
    assetName: PropTypes.string,
    valuationMethodology: PropTypes.string,
    priceDate: PropTypes.string,
    totalHolders: PropTypes.number,
    unitPrice: PropTypes.number,
    assetValue: PropTypes.number,
    cusip: PropTypes.string,
  }),
  fetchAssetDetails: PropTypes.func.isRequired,
  clearAssetDetails: PropTypes.func.isRequired,
  fetchFundingInstructions: PropTypes.func.isRequired,
  fundingInstructions: PropTypes.object,
  fetchUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {
  assetDetails: {
    assetName: '',
    valuationMethodology: '',
    priceDate: '',
    totalHolders: 0,
    unitPrice: 0,
    assetValue: 0,
  },
}

function InvestorDetails({
  assetDetails,
  fetchAssetDetails,
  clearAssetDetails,
  fundingInstructions,
  fetchFundingInstructions,
  fetchUserProfile,
  currentUser,
}) {
  const params = useParams()
  const { assetID } = params

  useEffect(() => {
    fetchUserProfile()
    fetchAssetDetails(assetID)
    fetchFundingInstructions(assetID)
    return () => clearAssetDetails()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const isGroup1User =
    currentUser.userClassification === USER_CLASSIFICATION.GROUP_1

  if (!assetDetails) {
    return <Spinner />
  }

  const { valuationMethodology } = assetDetails
  const isPricePerShare =
    valuationMethodology?.toLowerCase() ===
      INVESTOR_DETAILS_VALUATION_METHODOLOGY.PRICE_PER_SHARE.toLowerCase() ||
    valuationMethodology?.toLowerCase() ===
      INVESTOR_DETAILS_VALUATION_METHODOLOGY.PRICE_PER_SHARE_UNIT.toLowerCase()

  return (
    <div className="investor-details">
      <div className="investor-header">
        <h2 className="width-fc">{assetDetails.assetName}</h2>
        <div className="investor-header-button-area">
          {isGroup1User && (
            <ViewFundingInstructionsButton
              fundingInstructions={fundingInstructions}
              assetName={assetDetails.assetName}
              assetID={assetID}
            />
          )}
        </div>
      </div>
      <div className="container">
        <div className="one-half twelve column">
          <Card className="investors">
            <div className="heading">
              <img src={NumberOfInvestors} alt="" />
              <h3>Number of Investors</h3>
            </div>
            <p>{assetDetails.totalHolders}</p>
          </Card>
        </div>
        <div className="one-half twelve column">
          <Card className="market-value">
            <div className="heading">
              <img src={TotalMarketValue} alt="" />
              <div className="asset-details">
                <h3>Total Market Value</h3>
                {!isEmpty(assetDetails.priceDate) &&
                  !isEmpty(valuationMethodology) &&
                  isPricePerShare && (
                    <p className="date">As of {assetDetails.priceDate}</p>
                  )}
                {isPricePerShare && (
                  <p className="price">
                    Price Per Share:{' '}
                    <span>
                      {formatNumberAsCurrency(assetDetails.unitPrice)}
                    </span>
                  </p>
                )}
              </div>
            </div>
            <p className="asset-value">
              {formatNumberAsCurrency(assetDetails.assetValue)}
            </p>
          </Card>
        </div>
      </div>
      <br />
      <InvestorDetailsTable
        assetID={assetID}
        assetCusip={assetDetails.cusip}
        assetName={assetDetails.assetName}
        isPricePerShare={isPricePerShare}
      />
    </div>
  )
}

InvestorDetails.propTypes = propTypes
InvestorDetails.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    assetDetails: selectors.assetDetails(state),
    fundingInstructions: selectors.fundingInstructions(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchAssetDetails: apiActions.fetchAssetDetails,
  clearAssetDetails: actions.clearAssetDetails,
  fetchFundingInstructions: apiActions.fetchAssetFundingInstructions,
  fetchUserProfile: apiActions.fetchUserProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  InvestorDetails
)
