import React, { useState, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  Card,
  CustomAGCellTooltip,
  ACPTable,
  ACPFilterPicklist,
  StatusHeader,
  StatusKeyModal,
} from 'components'
import { TransactionStatusRenderer } from '../components'
import { Spinner } from 'lp-components'
import {
  createStatusList,
  createStatusOptions,
  ascendingAlphaSort,
  mapTaskToType,
  namedRoute,
  dateComparator,
  stringArrayComparator,
} from 'utils'
import FilterIcon from 'images/filter-icon.svg'
import {
  TRANSACTION_STATUSES,
  TRANSACTION_TYPE,
  EXCHANGE_CONFIRMATION_RECORD_TYPE_MAPPING,
  EXCHANGE_CONFIRMATION_DISPLAY_TYPE,
  LIQUIDATION_CONFIRMATION_STEPS,
} from 'config'
import { uniq, isEmpty } from 'lodash'
import { selectors as globalSelectors } from 'global-reducer'
import * as apiActions from 'api-actions'
import { Link } from 'react-router-dom'
import { Form, Formik } from 'formik'

const propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  tasks: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  transactions: null,
}

const loadingOverlay = `<div class="search-results-helper">
<div class="overlay-text-title med-18">No results found</div>
<span class="overlay-text reg-14">It looks like you don't have any transactions yet.</span>
<span class="overlay-text reg-14">To get started, please initiate a transaction.</span>
</div>`

const TASK_RESOLUTION_PATH = '/tasks/:taskId/resolve/:subType/:recordId'

function TransactionTable({ transactions, tasks }) {
  const [picklistVisible, setPicklistVisible] = useState(false)
  const [filterStatuses, setFilterStatuses] = useState([])
  const [filterTransactionName, setFilterTransactionName] = useState('')
  const [showStatusKeyModal, setShowStatusKeyModal] = useState(false)

  const filterCount = filterStatuses.length

  const uniqStatuses = uniq(
    transactions?.filter((t) => t.status).map((t) => t.status)
  )
  const uniqStatusList = createStatusList(uniqStatuses)
  const statusOptions = createStatusOptions(uniqStatusList)

  const gridRef = useRef()
  const gridStyle = useMemo(() => ({ height: '518px', width: '100%' }), [])

  const columnDefs = [
    {
      field: 'accountName',
      headerName: 'Investor Name',
      headerTooltip: 'Investor Name: The name of the Investor.',
      sortable: true,
      cellRenderer: 'agGroupCellRenderer',
      menuTabs: [],
      minWidth: 200,
      resizable: true,
      wrapText: true,
      autoHeight: true,
      headerClass: 'ag-investor-name-header',
      cellClass: 'ag-investor-name-trans',
    },
    {
      field: 'status',
      sortable: true,
      menuTabs: [],
      cellRenderer: TransactionStatusRenderer,
      headerTooltip: 'Status: The current status of the transaction.',
      resizable: true,
      minWidth: 212,
      wrapText: true,
      autoHeight: true,
      cellClass: 'ag-status-cell',

      headerComponent: (props) => (
        <StatusHeader
          props={props}
          showModal={() => setShowStatusKeyModal(true)}
        />
      ),
      tooltipValueGetter: (params) => {
        const status = TRANSACTION_STATUSES.find(
          (status) => status.name === params.value
        )
        if (!status) {
          return params.value
        }
        return `${status.name}: ${status.description}`
      },
    },
    {
      field: 'assetName',
      sortable: true,
      menuTabs: [],
      headerTooltip:
        'Asset Name: The legal name as stated in the offering document.',
      resizable: true,
      wrapText: true,
      autoHeight: true,
      minWidth: 200,
      filter: 'agTextColumnFilter',
      cellClass: 'ag-asset-name-cell',
      headerClass: 'ag-asset-name-header',
    },
    {
      field: 'amount',
      menuTabs: [],
      headerClass: 'ag-asset-amount-header',
      minWidth: 140,
      headerTooltip: 'Amount: The amount of the transaction.',
      cellClass: 'ag-amount-cell table-right-align',
    },
    {
      field: 'type',
      headerName: 'Transaction Type',
      sortable: true,
      headerTooltip: 'Type: The type of transaction.',
      menuTabs: [],
      minWidth: 110,
      headerClass: 'ag-transaction-type-header',
      cellClass: 'ag-transaction-type-cell',
    },
    {
      field: 'transactionDate',
      sortable: true,
      minWidth: 105,
      menuTabs: [],
      headerTooltip:
        'Transaction Date: The date that Inspira processed this transaction.',
      comparator: dateComparator,
      headerClass: 'ag-transaction-date-header',
      cellClass: 'ag-transaction-date-cell',
    },
    {
      field: 'effectiveDate',
      headerName: 'Effective Date',
      sortable: true,
      minWidth: 100,
      menuTabs: [],
      headerTooltip:
        'Effective Date: The date this transaction is effective on the books and records of the investment sponsor.',
      comparator: dateComparator,
      headerClass: 'ag-effective-date-header',
      cellClass: 'ag-effective-date-cell',
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: true,
      menuTabs: [],
      minWidth: 116,
      wrapText: true,
      autoHeight: true,
      headerClass: 'ag-action-header',
      cellClass: 'ag-action-cell',
      headerTooltip: 'Action: Transactions available to confirm.',
      sort: 'asc', //Set default sort of action as ascending.
      valueGetter: (params) => {
        const associatedTask = tasks?.filter(
          (task) => task.recordID === params.data.transactionID
        )[0]
        if (!associatedTask) {
          return params.column.sort === 'asc'
            ? 'z'
            : params.column.sort === 'desc'
            ? 'a'
            : ' '
        }
        const {
          isConfirmPurchase,
          isConfirmLiquidation,
          isConfirmExchange,
          isResolution,
        } = mapTaskToType(associatedTask)

        if (isConfirmLiquidation || isConfirmExchange) {
          return 'c'
        }
        if (isResolution) {
          return 's'
        }
        if (isConfirmPurchase) {
          return 'b'
        } else
          return params.column.sort === 'asc'
            ? 'z'
            : params.column.sort === 'desc'
            ? 'a'
            : ' '
      },
      comparator: stringArrayComparator,
      cellRenderer: ({ data }) => {
        const associatedTask = tasks?.filter(
          (task) => task.recordID === data.transactionID
        )[0]
        if (!associatedTask) {
          return ''
        }
        const {
          isConfirmPurchase,
          isConfirmLiquidation,
          isConfirmExchange,
          isResolution,
        } = mapTaskToType(associatedTask)
        if (isConfirmPurchase) {
          return (
            <span className="purchase-action-links">
              <Link
                to={{
                  pathname: `/bulk-confirmations/templates`,
                  state: {
                    selectedInvestors: [
                      { transactionID: associatedTask.recordID },
                    ],
                  },
                }}
              >
                Confirm
              </Link>
              <Link
                to={{
                  pathname: `/documents/view/purchases/confirmation-pending/${associatedTask.recordID}/downloads`,
                  state: {
                    subtitle: `View Documents`,
                    breadcrumb: {
                      to: '/transactions/view-all',
                      title: 'Transactions',
                    },
                  },
                }}
              >
                View Documents
              </Link>
            </span>
          )
        }
        if (isConfirmLiquidation) {
          const isPartTwo =
            associatedTask.subType ===
            LIQUIDATION_CONFIRMATION_STEPS.LIQUIDATION_SECOND_STEP
          const endpoint = isPartTwo ? 'details' : 'templates'
          const path = `/confirm-liquidation/${associatedTask.recordID}/${endpoint}`
          return (
            <span className="liquidation-action-links">
              <Link to={path}>Confirm</Link>
            </span>
          )
        }
        if (isConfirmExchange) {
          const path = `/confirm-exchange/${associatedTask.recordID}/exchange-authorization`
          return (
            <span className="exchange-action-links">
              <Link to={path}>Confirm</Link>
            </span>
          )
        }
        if (isResolution) {
          const path = namedRoute(TASK_RESOLUTION_PATH, {
            subType: associatedTask.subType?.toLowerCase(),
            recordId: associatedTask.recordID,
            taskId: associatedTask.taskID,
          })
          const state = {
            subtitle: `Resolve ${associatedTask.subType} Submission Documents`,
            breadcrumb: {
              to: '/tasks',
              title: 'Tasks',
            },
          }
          return (
            <span className="resolution-action-links">
              <Link to={{ pathname: path, state }}>Start</Link>
            </span>
          )
        }
        return ''
      },
    },
  ]

  const filteredTransactions = useMemo(() => {
    if (isEmpty(transactions)) return []
    return transactions.filter((transaction) => {
      return (
        (filterCount > 0
          ? filterStatuses.some((status) => transaction.status === status)
          : true) &&
        (filterTransactionName
          ? transaction?.assetName
              .toLowerCase()
              .includes(filterTransactionName?.toLowerCase())
          : true)
      )
    })
  }, [transactions, filterStatuses, filterTransactionName])

  const handleExternalFilterChange = (newValue) => {
    setFilterStatuses(newValue)
  }

  const onFilterTextBoxChanged = (e) => {
    setFilterTransactionName(e.target.value)
  }

  const applyFilters = (values) => {
    handleExternalFilterChange(values.filter)
    setPicklistVisible(false)
  }

  const TransactionTableActions = (
    <div className="table-actions">
      <div className="table-actions-left">
        <span>
          <input
            type="text"
            className="search-items"
            id="filter-text-box"
            placeholder="Search Asset Name"
            onInput={onFilterTextBoxChanged}
          />
        </span>
      </div>
      <div className="table-actions-right">
        <button
          onClick={() => {
            setPicklistVisible(!picklistVisible)
          }}
          type="button"
        >
          <img alt="filter" src={FilterIcon} />
          Filters: {filterCount > 0 ? 'Statuses ' + filterCount : 'None'}
        </button>
      </div>
    </div>
  )

  if (!transactions) return <Spinner />

  return (
    <div className="transactions-table all-transactions-table">
      <Card label="My Transactions" actions={TransactionTableActions}>
        <ACPTable
          rowData={filteredTransactions}
          columnDefs={columnDefs}
          overrideDefColWidth={50}
          masterDetail={true}
          rowHeight={90}
          detailCellRendererParams={{
            detailGridOptions: {
              tooltipComponent: CustomAGCellTooltip,
              suppressContextMenu: true,
              columnDefs: [
                {
                  field: 'lineItemRecordType',
                  headerName: 'Exchange Type',
                  valueFormatter: function(params) {
                    return EXCHANGE_CONFIRMATION_RECORD_TYPE_MAPPING[
                      params.data.lineItemRecordType
                    ]
                  },
                  sortable: true,
                  menuTabs: [],
                  type: 'leftAligned',
                  maxWidth: 150,
                },
                {
                  field: 'lineItemAssetName',
                  headerName: 'Asset Name',
                  sortable: true,
                  type: 'leftAligned',
                  menuTabs: [],
                  resizable: true,
                  wrapText: true,
                  minWidth: 150,
                },
                {
                  field: 'requestedType',
                  headerName: 'Amount Type',
                  type: 'leftAligned',
                  sortable: true,
                  menuTabs: [],
                  valueFormatter: ({ data: { requestedType } }) =>
                    EXCHANGE_CONFIRMATION_DISPLAY_TYPE[requestedType] ??
                    requestedType,
                  maxWidth: 150,
                },
                {
                  field: 'requestedValue',
                  headerName: 'Amount',
                  cellClass: 'table-right-align',
                  menuTabs: [],
                  type: 'rightAligned',
                },
              ],
              defaultColDef: { flex: 1 },
            },
            getDetailRowData: function(params) {
              const sortedToFirst = params.data.lineItems.sort((a, b) => {
                return ascendingAlphaSort(
                  b.lineItemRecordType,
                  a.lineItemRecordType
                )
              })
              params.successCallback(sortedToFirst)
            },
          }}
          isRowMaster={function(dataItem) {
            return dataItem.type === TRANSACTION_TYPE.EXCHANGE
          }}
          isAnyFilterPresent={true}
          paginationPageSize="10"
          overrideGridRef={gridRef}
          additionalClassName="ag-gridStyle"
          overrideGridStyle={gridStyle}
          overlayLoadingTemplate={loadingOverlay}
        />
      </Card>
      {showStatusKeyModal && (
        <StatusKeyModal
          onClose={() => setShowStatusKeyModal(false)}
          title="Transaction Status Key"
          statuses={TRANSACTION_STATUSES}
        />
      )}
      <Formik
        onSubmit={applyFilters}
        initialValues={{
          filter: [],
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            {picklistVisible && (
              <Form>
                <ACPFilterPicklist
                  handleExternalFilterChange={handleExternalFilterChange}
                  setPicklistVisible={setPicklistVisible}
                  currentFilter={filterStatuses}
                  values={values}
                  setFieldValue={setFieldValue}
                  options={statusOptions}
                  onClose={applyFilters}
                  title={'Transaction Status'}
                />
              </Form>
            )}
          </>
        )}
      </Formik>
    </div>
  )
}

TransactionTable.propTypes = exact(propTypes)
TransactionTable.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    transactions: globalSelectors.transactions(state),
    tasks: globalSelectors.tasks(state),
  }
}

const mapDispatchToProps = {
  fetchTransactions: apiActions.fetchTransactions,
  fetchTasks: apiActions.fetchTasks,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TransactionTable
)
