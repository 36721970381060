import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { UnmountClosed } from 'react-collapse'
import { Card } from 'components'
import classnames from 'classnames'
import PlusIcon from 'images/utility-icons/plus_icon.svg'
import MinusIcon from 'images/utility-icons/minus.svg'
import { isEmpty, noop } from 'lodash'

const propTypes = {
  children: PropTypes.node.isRequired,
  errorMessage: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onToggle: PropTypes.func,
  className: PropTypes.string,
}

const defaultProps = {
  onToggle: noop,
  errorMessage: '',
  label: '',
}

function CollapsableCardContainer({
  children,
  errorMessage,
  isExpanded,
  label,
  onToggle,
  className,
}) {
  const collapsableCardActions = (
    <div className="table-actions">
      <div className="table-actions-right">
        {!isExpanded && !isEmpty(errorMessage) && (
          <div className="error-message collapsable-card-error">
            {errorMessage}
          </div>
        )}
        <button
          type="button"
          onClick={() => {
            onToggle()
          }}
        >
          {isExpanded ? (
            <img src={MinusIcon} alt="collapse card" />
          ) : (
            <img src={PlusIcon} alt="expand card" />
          )}
        </button>
      </div>
    </div>
  )

  return (
    <Card
      label={label}
      actions={collapsableCardActions}
      className={classnames(
        className ? `collapsable-card ${className}` : 'collapsable-card',
        { 'is-open': isExpanded }
      )}
    >
      <UnmountClosed isOpened={isExpanded}>{children}</UnmountClosed>
    </Card>
  )
}

CollapsableCardContainer.propTypes = exact(propTypes)
CollapsableCardContainer.defaultProps = defaultProps

export default CollapsableCardContainer
