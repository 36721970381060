import React from 'react'
import { Link } from 'react-router-dom'
import { getInspiraFBOFormat } from 'utils'
import NestedDownloadDocumentsTable from '../main/bulk-confirmations/components/NestedDownloadDocumentsTable'
import Card from './Card'
import DownloadIconButton from './DownloadIconButton'
import CollapsableCardContainer from './CollapsableCardContainer'
import { PURCHASE_EXECUTION_INSTRUCTIONS } from '../config'
import HelpIcon from 'images/help-information-blue.svg'

export function DownloadDocumentContent({
  isExpandedShared,
  setIsExpandedShared,
  fetchDocument,
  purchaseID,
  investorsData,
  isExpandedLegal,
  setIsExpandedLegal,
  arrayOfSharedDocuments,
}) {
  return (
    <div className="bulk-confirmations-downloads flow-card-container">
      <CollapsableCardContainer
        className="bulk-confirmations-label"
        label="Download Documents and Execute Purchase"
        isExpanded={isExpandedShared}
        onToggle={() => setIsExpandedShared(!isExpandedShared)}
      >
        <div className="flow-header-main-text">
          <p className="main-subtext reg-16">
            You recently received funds from us for the investor(s) listed
            below. Here’s what you need to do:
          </p>

          <ol className="instruction-list">
            {PURCHASE_EXECUTION_INSTRUCTIONS.map((instruction, index) => {
              return (
                <li className="num-list" key={index + 1}>
                  {instruction}
                </li>
              )
            })}
          </ol>
          <div className="info-alert-wrapper">
            <div className="info-results">
              <div className="info-msg">
                <span className="info-icon-span">
                  <div>
                    <img alt="Info Icon" className="info-icon" src={HelpIcon} />
                  </div>
                </span>
                <span className="info-msg-span">
                  <p className="info-msg-text">
                    If you’ve completed the steps above, you can continue on to
                    confirm the transaction(s).
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
        <hr className="solid"></hr>
        <div className="flow-header-">
          <h6 className="flow-header-help-text-title med-16">
            What you need to know
          </h6>
          <ul className="bulk-transaction-bullets">
            <li className="reg-16">
              It’s important to review the investor registration, wire
              instructions and contact information. That information is critical
              for processing and our ability to custody the asset.
            </li>
            <li className="reg-16">
              <div className="reg-text-top">
                You must register the investment(s) to:{' '}
              </div>
              <b className="registration-text">
                {investorsData.length > 1
                  ? `Inspira Financial Custodian FBO {Client Name - Account Type - Last 5 Digits of Inspira Account Number}`
                  : `${getInspiraFBOFormat(
                      investorsData[0].clientFBOName
                    )}`}{' '}
                2001 Spring Road, Suite 700 Oak Brook, IL 60523-51221
              </b>
            </li>
            <li className="reg-16">
              You can find the investor Tax ID on the W-9 Form. Inspira’s tax ID
              should be used for all investments in retirement accounts.
            </li>
          </ul>
        </div>
        {arrayOfSharedDocuments.length > 0 && (
          <div className="mtc-flow-line">
            <hr />
          </div>
        )}
        <table className="download-documents-content">
          <tbody>
            {arrayOfSharedDocuments.length > 0 && (
              <tr height="68px">
                <td colSpan={2} align="left" className="downloadInstructions">
                  Download Inspira Instructions and Documents
                </td>
              </tr>
            )}
            {arrayOfSharedDocuments.map((doc) => {
              return (
                <tr key={doc.requiredDocumentID}>
                  <td>
                    <p>{doc.requiredDocumentName}</p>
                  </td>
                  <td className="download-table-data">
                    <DownloadIconButton
                      onClick={() =>
                        fetchDocument(
                          purchaseID,
                          doc.requiredDocumentID,
                          doc.requiredDocumentName
                        )
                      }
                    />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </CollapsableCardContainer>
      <Card
        className="download-investor-documents font-18px"
        label="Download Investor Documents"
      >
        <p className="flow-header-help-text investor-docs-text">
          These documents below include the executed subscription documents.
          They supersede any subscription documents you received from the
          investor. If you already received and processed subscription documents
          from the investor,{' '}
          <b>update your records with the documents below.</b> If you requested
          the original versions of the subscription documents, you’ll receive
          those by mail.
        </p>
        <NestedDownloadDocumentsTable investorsData={investorsData} />
      </Card>
      <CollapsableCardContainer
        label="Review Requirements from Inspira"
        className="tax-requirements-card"
        isExpanded={isExpandedLegal}
        onToggle={() => setIsExpandedLegal(!isExpandedLegal)}
      >
        <div>
          <p className="main-subtext-remit req-subheader reg-16">
            To help the investor(s) avoid unintended tax consequences and ensure
            accurate IRS reporting, do these three things.
          </p>
          <ol className="instruction-list">
            <div className="subsection">
              <li className="num-list-remit med-14">Remit Funds to Inspira</li>
              <p className="reg-14">
                To remit funds, you should follow Inspira’s delivery
                instructions listed below. The funds related to redemption
                proceeds, dividends and returns of capital should not be sent to
                a client. Such direct payments will result in incorrect
                investment reporting and may result in taxable distributions and
                IRS penalties to the beneficiary of an IRA. Additionally,
                special service fees and charges to the account holder may
                apply.
              </p>
            </div>
            <div className="subsection">
              <li className="num-list-remit">Update Asset Value Annually</li>
              <p className="reg-14">
                You must provide any valuation updates for the asset at least
                annually to maintain accurate reporting and comply with IRS
                requirements. You can{' '}
                <Link to="/documents/add-document/new">
                  upload statements online
                </Link>{' '}
                or mail to:
              </p>
            </div>
            <div className="address subsection med-14">
              <div>Inspira Financial</div>
              <div>2001 Spring Rd, Suite 700</div>
              <div>Oak Brook IL 60523</div>
            </div>
            <div className="subsection">
              <li className="num-list-remit">
                Send Tax Forms, Notices and Other Documents
              </li>
              <p className="reg-14">
                You should send all tax forms, corporate action notices and any
                other informational documents to Inspira. You can{' '}
                <Link className="font-16px" to="/documents/add-document/new">
                  upload documents online
                </Link>{' '}
                or mail to:
              </p>
            </div>
            <div className="address subsection med-14">
              <div>Inspira Financial</div>
              <div>2001 Spring Rd, Suite 700</div>
              <div>Oak Brook IL 60523</div>
            </div>
          </ol>
        </div>
      </CollapsableCardContainer>
    </div>
  )
}

export default DownloadDocumentContent
