import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { SidebarNavItem, SidebarLogoBadge } from 'components'
import HomeIcon from 'images/home-icon.svg'
import AssetIcon from 'images/asset-icon.svg'
import ExpandIcon from 'images/expand-arrows.svg'
import CollapseIcon from 'images/collapse-arrows.svg'
import DocumentsIcon from 'images/documents-icon.svg'
import ContactsCircleIcon from 'images/contacts-circle.svg'
import TransactionsIcon from 'images/transactions-icon.svg'
import ResourcesIcon from 'images/resources.svg'
import { USER_CLASSIFICATION } from 'config'

const propTypes = {
  userClassification: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
}

const defaultProps = {}

function Sidebar({ userClassification, expanded, setExpanded }) {
  return (
    <div className={classnames('sidebar', { collapsed: !expanded })}>
      <div id="sidebar-tabs-area">
        <SidebarLogoBadge expanded={expanded} />
        <nav id="sidebar-nav">
          <ul id="sidebar-list">
            <SidebarNavItem
              icon={HomeIcon}
              label="Home"
              expanded={expanded}
              href="/home"
            />
            <SidebarNavItem
              icon={AssetIcon}
              label="Assets"
              expanded={expanded}
              href="/assets"
            />
            <SidebarNavItem
              href="/transactions"
              expanded={expanded}
              icon={TransactionsIcon}
              label="Transactions"
            />
            <SidebarNavItem
              icon={DocumentsIcon}
              label="Documents"
              expanded={expanded}
              href="/documents"
              disabled={false}
            />
            {userClassification === USER_CLASSIFICATION.GROUP_1 && (
              <SidebarNavItem
                icon={ContactsCircleIcon}
                label="Contacts"
                expanded={expanded}
                href="/contacts"
                disabled={false}
              />
            )}
            <SidebarNavItem
              icon={ResourcesIcon}
              label="Resources"
              expanded={expanded}
              href="/resources"
            />
          </ul>
        </nav>
      </div>
      <div
        className={classnames('expand-button-area', { collapsed: !expanded })}
      >
        {expanded ? (
          <button
            onClick={() => {
              setExpanded(false)
            }}
          >
            <img alt="Collapse" src={CollapseIcon} />
          </button>
        ) : (
          <button
            onClick={() => {
              setExpanded(true)
            }}
          >
            <img alt="Expand" src={ExpandIcon} />
          </button>
        )}
      </div>
    </div>
  )
}

Sidebar.propTypes = exact(propTypes)
Sidebar.defaultProps = defaultProps

export default React.memo(Sidebar)
