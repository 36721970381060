import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { NavLink } from 'react-router-dom'

const propTypes = {
  expanded: PropTypes.bool,
  label: PropTypes.string,
  icon: PropTypes.string,
  href: PropTypes.string,
  disabled: PropTypes.bool,
}

const defaultProps = {
  disabled: false,
}

function SidebarNavItem({ expanded, label, icon, href, disabled }) {
  const handler = (e) => {
    if (disabled) e.preventDefault()
  }

  let className = 'sidebar-nav-item'
  if (disabled) className += ' is-disabled'

  return (
    <li>
      <NavLink to={href} href={href} className={className} onClick={handler}>
        <img alt="" src={icon} />
        {expanded && label}
      </NavLink>
    </li>
  )
}

SidebarNavItem.propTypes = exact(propTypes)
SidebarNavItem.defaultProps = defaultProps

export default React.memo(SidebarNavItem)
