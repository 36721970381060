import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  DocumentUploadInput,
  ACPInputField,
  ACPDateInputField,
  ProgressBar,
} from 'components'
import { SubmitButton } from 'lp-components'
import moment from 'moment'
import {
  bulkPromiseUploads,
  scrollToTop,
  createFileErrors,
  numberOfSecondsByBytes,
} from 'utils'
import { selectors as globalSelectors } from 'global-reducer'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { LETTER_UPLOAD_TYPES } from 'config'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import * as flashActions from 'redux-flash'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  uploadValuationDocument: PropTypes.func.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  updateTask: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {}

function ValuationUpdate({
  flashErrorMessage,
  flashSuccessMessage,
  uploadValuationDocument,
  updateTask,
  fetchTasks,
  tasks,
}) {
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  let { assetID } = useParams()
  const history = useHistory()
  const location = useLocation()
  const [needsResubmission, setNeedsResubmission] = useState(false)
  const [failedSubmissionDocuments, setFailedSubmissionDocuments] = useState([])
  const [isFirstDateUpdate, setIsFirstDateUpdate] = useState(true)
  const [numberOfSecondsToComplete, setNumberOfSecondsToComplete] = useState(0)

  const selectedInvestors = location?.state?.selectedInvestors
  const multipleInvestors = selectedInvestors?.length > 1
  const assetCusip = location?.state?.assetCusip
  const assetName = location?.state?.assetName
  const multipleValuationDateInitialValues = selectedInvestors.reduce(
    (acc, investor) => {
      acc[investor.holdingID] = ''
      return acc
    },
    {}
  )

  useEffect(() => {
    fetchTasks()
  }, [])

  const investorInitialValues = {
    documents: {
      [LETTER_UPLOAD_TYPES.SINGLE]: {
        [selectedInvestors[0]?.holdingID]: '',
      },
      [LETTER_UPLOAD_TYPES.MULTIPLE]: {},
    },
    valuationDateMultiple: multipleValuationDateInitialValues,
    valuationDateSingle: '',
  }

  if (multipleInvestors) {
    selectedInvestors?.forEach((investor) => {
      investorInitialValues.documents.multiple[investor.holdingID] = ''
    })
  }

  const initialValues = {
    letterUploadType: !multipleInvestors ? LETTER_UPLOAD_TYPES.SINGLE : '',
    ...investorInitialValues,
  }

  const lazyValidation = Yup.lazy((values) => {
    const letterUploadType = values.letterUploadType
    const isSingle = letterUploadType === LETTER_UPLOAD_TYPES.SINGLE

    const multipleDocumentValidation = selectedInvestors.reduce(
      (acc, investor) => {
        acc[investor.holdingID] = Yup.object().required(
          'Please attach the required document'
        )
        return acc
      },
      {}
    )

    const multipleValuationDateValidation = selectedInvestors.reduce(
      (acc, investor) => {
        acc[investor.holdingID] = Yup.date().required(
          'Please enter the valuation date (mm/dd/yyyy).'
        )
        return acc
      },
      {}
    )

    const validationObject = Yup.object({
      letterUploadType: Yup.string().required('Required'),
      valuationDateSingle: isSingle
        ? Yup.date().required('Please enter the valuation date (mm/dd/yyyy).')
        : Yup.date().notRequired(),
      valuationDateMultiple: !isSingle
        ? Yup.object().shape({
            ...multipleValuationDateValidation,
          })
        : '',
      documents: Yup.object().shape({
        ...(isSingle
          ? {
              single: Yup.object().shape({
                [selectedInvestors[0]?.holdingID]: Yup.object().required(
                  'Please attach the required document'
                ),
              }),
            }
          : {
              multiple: Yup.object().shape({
                ...multipleDocumentValidation,
              }),
            }),
      }),
    })

    return validationObject
  })

  const handleSubmit = async (params, { setErrors }) => {
    const letterUploadType = params.letterUploadType
    var numberOfBytesUploaded = 0
    try {
      const isSingleDocument = letterUploadType === LETTER_UPLOAD_TYPES.SINGLE

      const filterCondition = needsResubmission
        ? (e) =>
            e[1].fileName &&
            e[1].documentID &&
            failedSubmissionDocuments.includes(e[1].documentID)
        : (e) => e[1].fileName && e[1].documentID

      const updatedInvestors = selectedInvestors.map((investor) => {
        if (isSingleDocument) {
          return { ...investor, valuationDate: params.valuationDateSingle }
        } else {
          return {
            ...investor,
            valuationDate: params.valuationDateMultiple[investor.holdingID],
          }
        }
      })

      const fileUploadPromises = Object.entries(
        params.documents[letterUploadType]
      )
        .filter(filterCondition)
        .map((e) => {
          return {
            fileName: e[1].fileName,
            fileContents: e[1].fileContents,
            fileType: e[1].fileType,
            documentID: e[1].documentID,
            selectedInvestors: !isSingleDocument
              ? updatedInvestors.filter(
                  (investor) => investor.holdingID === e[1].documentID
                )
              : updatedInvestors,
          }
        })
        .map((doc) => {
          const formData = new FormData()
          formData.append(
            'fileContents',
            new Blob([doc.fileContents], {
              type: doc.fileType,
            }),
            doc.fileName
          )
          formData.append(
            'metadata',
            `'${JSON.stringify({
              isMultipleAccounts: isSingleDocument && multipleInvestors,
              assetID: assetID,
              assetCusip: assetCusip,
              assetName: assetName,
              investorList: doc.selectedInvestors,
            })}'`
          )
          if (numberOfBytesUploaded < doc.fileContents.byteLength) {
            numberOfBytesUploaded = doc.fileContents.byteLength
          }
          return uploadValuationDocument(doc, formData)
        })
      //This mathematical function is derived from MS and SF analytics which we are using here to define an average time to upload. It is marginally correct.
      setNumberOfSecondsToComplete(
        numberOfSecondsByBytes(numberOfBytesUploaded)
      )
      const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)
      if (rejectedUploads.length) {
        const errors = isSingleDocument
          ? createFileErrors(rejectedUploads, 'singleDocumentError')
          : createFileErrors(rejectedUploads)
        setNeedsResubmission(true)
        setErrors(errors)
        setFailedSubmissionDocuments(Object.keys(errors))
        setNumberOfSecondsToComplete(0)
        scrollToTop()
      } else {
        var taskID = tasks.find((task) => task.recordID === assetID)?.taskID
        if (taskID) {
          await updateTask([{ taskID: taskID, status: 'Completed' }])
        }
        flashSuccessMessage('We received your valuation documents.')
        history.push(location?.state?.target)
      }
    } catch (e) {
      flashErrorMessage(
        e?.errors?.message || 'Something went wrong, please try again.'
      )
    }
  }

  return (
    <Card label={numberOfSecondsToComplete ? '' : 'Update Valuations'}>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={lazyValidation}
        initialValues={initialValues}
      >
        {({
          errors,
          touched,
          setTouched,
          setFieldValue,
          isSubmitting,
          values,
          resetForm,
        }) => {
          if (numberOfSecondsToComplete) {
            return <ProgressBar milliseconds={numberOfSecondsToComplete} />
          } else {
            return (
              <Form className="investor-valuations-update-form">
                {
                  <>
                    {multipleInvestors && (
                      <>
                        <ACPInputField
                          name="letterUploadType"
                          label="Would you like to upload your valuations as a single file or multiple files?"
                          radioOptions={[
                            {
                              key: 'Single File',
                              value: LETTER_UPLOAD_TYPES.SINGLE,
                            },
                            {
                              key: 'Multiple Files',
                              value: LETTER_UPLOAD_TYPES.MULTIPLE,
                            },
                          ]}
                          onChange={(e) => {
                            setNeedsResubmission(false)
                            setFailedSubmissionDocuments([])
                            setIsFirstDateUpdate(true)
                            resetForm({
                              values: {
                                ...initialValues,
                                letterUploadType: e.target.value,
                              },
                            })
                            setTouched({})
                          }}
                          disabled={isSubmitting}
                        />
                        <hr />
                      </>
                    )}
                    {values.letterUploadType === LETTER_UPLOAD_TYPES.SINGLE ? (
                      <div className="row">
                        <DocumentUploadInput
                          keyName={`documents.single.${selectedInvestors[0]?.holdingID}`}
                          document={{
                            isDocumentRequired: true,
                            requiredDocumentID: selectedInvestors[0]?.holdingID,
                            name:
                              selectedInvestors.length === 1
                                ? selectedInvestors[0]?.clientFBOName
                                : '',
                          }}
                          setFieldValue={setFieldValue}
                          error={
                            touched?.documents && errors?.documents
                              ? errors.documents?.single?.[
                                  selectedInvestors[0]?.holdingID
                                ]
                              : errors['singleDocumentError']
                          }
                        />
                        <div className="one-half column valuation-date">
                          <ACPDateInputField
                            name="valuationDateSingle"
                            label="Valuation Date"
                            parse={(val) => moment(val).format('MM/DD/YYYY')}
                            maxDate={moment()}
                          />
                        </div>
                      </div>
                    ) : values.letterUploadType ===
                      LETTER_UPLOAD_TYPES.MULTIPLE ? (
                      <div className="invite-investors-upload-area">
                        {selectedInvestors?.map((investor) => {
                          return (
                            <div
                              key={investor?.holdingID}
                              className="row document-input-row"
                            >
                              <div className="column invite-investors-uploads">
                                <DocumentUploadInput
                                  keyName={`documents.multiple.${investor?.holdingID}`}
                                  document={{
                                    isDocumentRequired: true,
                                    requiredDocumentID: investor?.holdingID,
                                    name: investor.clientFBOName,
                                  }}
                                  setFieldValue={setFieldValue}
                                  error={
                                    touched.documents && errors?.documents
                                      ? errors.documents.multiple?.[
                                          investor?.holdingID
                                        ]
                                      : errors[investor?.holdingID]
                                  }
                                  isSubmitted={
                                    needsResubmission &&
                                    !failedSubmissionDocuments.includes(
                                      investor?.holdingID
                                    )
                                  }
                                />
                                {(!needsResubmission ||
                                  failedSubmissionDocuments.includes(
                                    investor?.holdingID
                                  )) && (
                                  <div className="one-half column valuation-date">
                                    <ACPDateInputField
                                      name={
                                        'valuationDateMultiple.' +
                                        investor.holdingID
                                      }
                                      label="Valuation Date"
                                      parse={(val) =>
                                        moment(val).format('MM/DD/YYYY')
                                      }
                                      maxDate={moment()}
                                      onChange={(e) => {
                                        if (isFirstDateUpdate) {
                                          Object.keys(
                                            values.documents?.multiple
                                          )?.forEach((key) =>
                                            setFieldValue(
                                              `valuationDateMultiple.${key}`,
                                              e.target.value
                                            )
                                          )
                                        }
                                        setIsFirstDateUpdate(false)
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                  </>
                }
                <div className="valuation-update-submit-area">
                  <SubmitButton
                    className="form-button"
                    submitting={isSubmitting}
                  >
                    Submit
                  </SubmitButton>
                </div>
              </Form>
            )
          }
        }}
      </Formik>
    </Card>
  )
}

ValuationUpdate.propTypes = propTypes
ValuationUpdate.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    tasks: globalSelectors.tasks(state),
  }
}

const mapDispatchToProps = {
  uploadValuationDocument: apiActions.uploadValuationDocument,
  updateTask: apiActions.updateTask,
  fetchTasks: apiActions.fetchTasks,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ValuationUpdate
)
