import React, { useState, useEffect } from 'react'
import exact from 'prop-types-exact'
import { MultiSelectConfirmationsTable } from '../components'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Formik, Form } from 'formik'
import { SubmitButton, Spinner } from 'lp-components'
import { TRANSACTION_TYPE } from 'config'
import { ACPInputField } from 'components'
import classnames from 'classnames'

const propTypes = {
  fetchAllBulkPurchaseConfirmations: PropTypes.func.isRequired,
  fetchBulkPurchaseConfirmationByAsset: PropTypes.func.isRequired,
  pendingConfirmations: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const defaultProps = {}

function PendingConfirmations({
  pendingConfirmations,
  fetchAllBulkPurchaseConfirmations,
  fetchBulkPurchaseConfirmationByAsset,
}) {
  const [showTable, setShowTable] = useState(false)
  const [currentAssetSelectionID, setCurrentAssetSelectionID] = useState()
  const [filteredConfirmations, setFilteredConfirmations] = useState()
  const [isFetching, setIsFetching] = useState(false)
  const [isTypeSelected, setIsTypeSelected] = useState(false)
  const [isAssetSelected, setIsAssetSelected] = useState(false)

  useEffect(() => {
    fetchAllBulkPurchaseConfirmations()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const typeOptions = Object.values(TRANSACTION_TYPE).filter(
    (type) => type === TRANSACTION_TYPE.PURCHASE
  )

  const handleSubmission = async () => {
    setIsFetching(true)
    setShowTable(true)
    const assetId = currentAssetSelectionID
    const confirmationPayload = await fetchBulkPurchaseConfirmationByAsset(
      assetId
    )
    setFilteredConfirmations(confirmationPayload)
    setIsFetching(false)
  }

  if (!pendingConfirmations) {
    return <Spinner />
  }

  return (
    <div className="pending-confirmations-wrapper">
      <div className="query-container">
        <div className="card">
          <div className="row full-card">
            <h4>Confirm Pending Transactions</h4>
            <p>
              To view and confirm transactions for multiple investors in a
              single asset, select the transaction type. Then choose an asset
              name.
            </p>
            <Formik
              initialValues={{}}
              enableReinitialize={true}
              onSubmit={async (values, { resetForm }) => {
                await handleSubmission()
                setIsAssetSelected(false)
                resetForm()
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="row input-select-view">
                    <div className="one-third-col column transaction-type-col">
                      <ACPInputField
                        name="transactionType"
                        label="Transaction Type"
                        enablePlaceholderOption={true}
                        placeholder="Select the Type"
                        selectOptions={typeOptions}
                        onChange={() => {
                          setIsTypeSelected(true)
                        }}
                      />
                    </div>
                    <div className="one-third-col column">
                      <ACPInputField
                        name="assetName"
                        label="Asset Name"
                        enablePlaceholderOption={true}
                        placeholder="Select the Asset"
                        selectOptions={pendingConfirmations.map(
                          (asset) => asset.assetName
                        )}
                        onChange={(e) => {
                          const selectedAsset = e.target.value
                          const filteredAsset = pendingConfirmations.filter(
                            (option) => {
                              return option.assetName === selectedAsset
                            }
                          )
                          setCurrentAssetSelectionID(filteredAsset[0].assetID)
                          setIsAssetSelected(true)
                        }}
                      />
                    </div>
                    <div className="column button-submit-container">
                      <SubmitButton
                        className={classnames(
                          'button-primary apply-trans-button',
                          {
                            'is-disabled': !(isTypeSelected && isAssetSelected),
                          }
                        )}
                        submitting={isSubmitting}
                        disabled={!(isTypeSelected && isAssetSelected)}
                      >
                        Apply
                      </SubmitButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="pending-transactions-table-container">
            {showTable && !isFetching && (
              <div>
                <hr className="solid"></hr>
                <MultiSelectConfirmationsTable
                  transactions={filteredConfirmations}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

PendingConfirmations.propTypes = exact(propTypes)
PendingConfirmations.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pendingConfirmations: selectors.pendingConfirmations(state),
  }
}

const mapDispatchToProps = {
  fetchAssets: apiActions.fetchAssets,
  fetchTransactions: apiActions.fetchTransactions,
  fetchAllBulkPurchaseConfirmations:
    apiActions.fetchAllBulkPurchaseConfirmations,
  fetchBulkPurchaseConfirmationByAsset:
    apiActions.fetchBulkPurchaseConfirmationByAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PendingConfirmations
)
