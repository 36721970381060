import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams } from 'react-router-dom'
import { flattenDeep, uniqBy } from 'lodash'
import { Spinner } from 'lp-components'
import * as actions from '../actions'
import { selectors } from '../reducer'
import { selectors as globalSelectors } from 'global-reducer'
import { DownloadDocumentContent } from 'components'

const propTypes = {
  fetchDocument: PropTypes.func.isRequired,
  fetchInvestorsData: PropTypes.func.isRequired,
  investorsData: PropTypes.array.isRequired,
  clearInvestorsData: PropTypes.func.isRequired,
}

const defaultProps = {}

function PendingDocuments({
  fetchDocument,
  fetchInvestorsData,
  investorsData,
  clearInvestorsData,
}) {
  const params = useParams()
  const { transactionID } = params
  const [isExpandedShared, setIsExpandedShared] = React.useState(true)
  const [isExpandedLegal, setIsExpandedLegal] = React.useState(true)

  const investorPurchaseIDs = transactionID

  //Need to pass the investorPurchaseIDs into the fetchInvestorsData call.
  useEffect(() => {
    fetchInvestorsData(investorPurchaseIDs.toString(), 'download')
    return () => {
      clearInvestorsData()
    }
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!investorsData || !investorsData[0].downloadDocuments) return <Spinner />

  const purchaseID = investorsData[0].purchaseID
  const arrayOfSharedDocuments = uniqBy(
    flattenDeep(
      investorsData.map((investorData) => investorData.downloadDocuments)
    ).filter((doc) => doc.isSharedDocument),
    'requiredDocumentID'
  )

  return (
    <DownloadDocumentContent
      isExpandedShared={isExpandedShared}
      setIsExpandedShared={setIsExpandedShared}
      arrayOfSharedDocuments={arrayOfSharedDocuments}
      fetchDocument={fetchDocument}
      purchaseID={purchaseID}
      investorsData={investorsData}
      isExpandedLegal={isExpandedLegal}
      setIsExpandedLegal={setIsExpandedLegal}
      investorPurchaseIDs={investorPurchaseIDs}
    />
  )
}

PendingDocuments.propTypes = propTypes
PendingDocuments.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    investorsData: selectors.investorsData(state),
    purchaseIDs: globalSelectors.purchaseIDs(state),
  }
}

const mapDispatchToProps = {
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
  fetchInvestorsData: apiActions.fetchInvestorsData,
  clearInvestorsData: actions.clearInvestorsData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PendingDocuments
)
