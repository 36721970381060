import { TaskFilterConversions } from 'config'

function getTaskType(selection) {
  switch (selection) {
    case TaskFilterConversions.PENDING_ASSET_SUBMISSION:
      return TaskFilterConversions.SUBMIT_NEW_ASSET
    case TaskFilterConversions.LIQUIDATION:
      return TaskFilterConversions.LIQUIDATION_TRANSACTION
    case TaskFilterConversions.PURCHASE:
      return TaskFilterConversions.PURCHASE_TRANSACTION
    case TaskFilterConversions.RESOLUTION_TYPE:
      return TaskFilterConversions.RESOLUTION_TYPE
    case TaskFilterConversions.EXCHANGE:
      return TaskFilterConversions.EXCHANGE_TRANSACTION
    default:
      return selection
  }
}

export default getTaskType
