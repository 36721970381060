import React from 'react'
import App from './App'
import ReactDOM from 'react-dom'

import * as FullStory from '@fullstory/browser'
import { MTC_FULLSTORY_ORG_ID, isFullStoryEnabled } from '../config'

if (isFullStoryEnabled()) {
  FullStory.init({
    orgId: MTC_FULLSTORY_ORG_ID,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))
