import { handleActions } from 'redux-actions'
import { selectorForSlice } from 'lp-redux-utils'
import { setOnSuccess } from 'lp-redux-api'
import * as apiActions from 'api-actions'

const reducerKey = 'resources'
const slice = 'root.resources'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchResources]: setOnSuccess('resources'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  resources: select('resources'),
}

export { reducer, selectors, reducerKey }
