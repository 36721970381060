import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'lp-components'
import exact from 'prop-types-exact'
import { useHistory } from 'react-router-dom'
import { stage } from 'types'
import BlueBackArrow from 'images/blue-arrow-left.svg'

const propTypes = {
  stages: PropTypes.arrayOf(stage),
}

const defaultProps = {}

function BackButton({ stages }) {
  const history = useHistory()
  const {
    location: { pathname },
  } = history
  const pathSlug = pathname.split('/').pop()
  const allPaths = stages
    .map((stage) => {
      return stage.routes.map((route) => route.pathname)
    })
    .flat()

  return (
    <Button
      onClick={() => {
        if (allPaths.length > 1) {
          const pathPrefix = pathname.slice(0, pathname.lastIndexOf('/'))

          allPaths.forEach((path, index) => {
            if (path.slice(1) === pathSlug) {
              history.push(pathPrefix + allPaths[index - 1])
            }
          })
        }
      }}
      className="button-secondary button-back"
      disabled={allPaths.length < 2}
    >
      <img alt="back-arrow" src={BlueBackArrow} className="back-arrow-blue" />{' '}
      Back
    </Button>
  )
}

BackButton.propTypes = exact(propTypes)
BackButton.defaultProps = defaultProps

export default React.memo(BackButton)
