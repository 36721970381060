import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router'
import { useParams } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors } from './reducer'
import * as apiActions from 'api-actions'
import { getStageLabel, scrollToTop } from 'utils'
import { EXCHANGE_CONFIRMATION_STAGES } from 'flow-stages'
import withPermission from 'utils/withPermission'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  exchangeConfirmation: PropTypes.object.isRequired,
  fetchExchangeConfirmation: PropTypes.func.isRequired,
}

const defaultProps = {
  exchangeConfirmation: null,
}
function Layout({
  flashMessages,
  currentStage,
  children,
  fetchExchangeConfirmation,
  exchangeConfirmation,
}) {
  const { exchangeID } = useParams()
  const [error, setError] = useState(null)

  useEffect(() => {
    scrollToTop()
    fetchExchangeConfirmation(exchangeID).catch((e) => setError(e))
  }, [exchangeID])

  if (error) {
    return (
      <div>
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMessage:
                error.errors.message ||
                'Something went wrong, please try again.',
            },
          }}
        />
      </div>
    )
  }

  if (!exchangeConfirmation) return <Spinner />
  if (!withPermission('confirm-exchange')) {
    return <Redirect path="*" to="/home" />
  }

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title={'Exchange Confirmation'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title no-print">
            {getStageLabel(EXCHANGE_CONFIRMATION_STAGES, currentStage)}
          </h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={EXCHANGE_CONFIRMATION_STAGES}
            currentStage={currentStage}
          />
          {React.cloneElement(children, {
            exchangeConfirmation: exchangeConfirmation,
          })}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    exchangeConfirmation: selectors.exchangeConfirmation(state),
  }
}

const mapDispatchToProps = {
  fetchExchangeConfirmation: apiActions.fetchExchangeConfirmation,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
