import React from 'react'
import exact from 'prop-types-exact'
import InspiraLogo from 'images/InspiraLogo.svg'
import PropTypes from 'prop-types'
import InspiraIcon from 'images/InspiraIcon.svg'

const propTypes = {
  expanded: PropTypes.bool.required,
}

const defaultProps = {}

function SidebarLogoBadge({ expanded }) {
  return expanded ? (
    <div id="sidebar-logo-badge">
      <img alt="Inspira Logo" src={InspiraLogo} />
    </div>
  ) : (
    <div id="sidebar-logo-icon">
      <img alt="Inspira Icon" src={InspiraIcon} />
    </div>
  )
}

SidebarLogoBadge.propTypes = exact(propTypes)
SidebarLogoBadge.defaultProps = defaultProps

export default React.memo(SidebarLogoBadge)
