import React, { useState } from 'react'
import { Redirect } from 'react-router'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { CAPITAL_COMMITMENT_STAGES } from 'flow-stages'
import { withPermission } from 'utils'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
}

const defaultProps = {}

function Layout({ flashMessages, currentStage, children }) {
  const [disableNavigation, setDisableNavigation] = useState(false)
  if (!withPermission('add-capital-call'))
    return <Redirect path="*" to="/home" />

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title="Initiate Capital Call" />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">
            {
              CAPITAL_COMMITMENT_STAGES.find(
                (stage) => stage.name === currentStage
              ).label
            }
          </h1>
        </div>
        <div className="content-div cap-call-resp">
          <FlowStatusTracker
            stages={CAPITAL_COMMITMENT_STAGES}
            currentStage={currentStage}
            disableNavigation={disableNavigation}
          />
          {React.cloneElement(children, {
            setDisableNavigation,
          })}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
