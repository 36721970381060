import React from 'react'
import PropTypes from 'prop-types'
import { FlowActions } from '../components'
import { Formik, Form } from 'formik'
import { ACPInputField } from 'components'
import { Checkbox as BaseCheckbox } from 'lp-components'
import { withFormikAdapter } from 'utils'
import * as Yup from 'yup'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
}

const defaultProps = {}

function SignatureForm({ handleSubmit }) {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .when('requireAll', {
        is: true,
        then: Yup.string().required('Required'),
      }),
    doesAcknowledge: Yup.bool().when('requireAll', {
      is: true,
      then: Yup.bool().oneOf(
        [true],
        'Please check this box if you want to proceed'
      ),
    }),
  })
  return (
    <div>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          title: '',
          doesAcknowledge: false,
        }}
        enableReinitialize={true}
      >
        {({ setFieldValue, isSubmitting }) => (
          <Form>
            <h4>E-Signature</h4>
            <div className="row">
              <div className="one-half column">
                <ACPInputField
                  label="First Name"
                  placeholder="Enter First Name"
                  name="firstName"
                />
              </div>
              <div className="one-half column">
                <ACPInputField
                  placeholder="Enter Last Name"
                  label="Last Name"
                  name="lastName"
                />
              </div>
            </div>
            <div className="row">
              <div className="one-half column">
                <ACPInputField
                  placeholder="Enter Title"
                  label="Enter Title"
                  name="title"
                />
              </div>
            </div>
            <div className="row">
              <div className="checkbox-line-item twelve columns">
                <Checkbox
                  className="checkbox no-background"
                  name="doesAcknowledge"
                  label="By checking this box, I agree that I am signing these Acknowledgments, which I intend to be my legally binding signature on behalf of the Issuer. I also certify that the Issuer acknowledges and agrees and that I am authorized to and this e-signature does bind the Issuer named above to the terms of this Acknowledgment."
                />
              </div>
            </div>

            <FlowActions
              prevStep="exchange-confirmation"
              onContinue={() => setFieldValue('requireAll', true, false)}
              submitting={isSubmitting}
              submitContent="Submit"
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

SignatureForm.propTypes = propTypes
SignatureForm.defaultProps = defaultProps

export default SignatureForm
