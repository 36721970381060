import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import { FlowActions } from '../components'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { isEmpty, uniqBy } from 'lodash'
import { getSessionToken } from 'auth'
import {
  countries,
  PHONE_NUMBER_REGEX,
  usStates,
  canadianProvinces,
  ZIP_CODE_REGEX,
  CANADIAN_ZIP_CODE_REGEX,
} from 'config'
import { ascendingAlphaSort } from 'utils'
import { SearchableSelect, LabelWithTooltip, ACPInputField } from 'components'
import * as Types from 'types'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  tpaCompanies: PropTypes.arrayOf(Types.company).isRequired,
  currentUser: Types.user.isRequired,
}
const defaultProps = {
  tpaCompanies: [],
}

const ColumnOneHalf = ({ children }) => (
  <div className="one-half column">{children}</div>
)

const ColumnOneThird = ({ children }) => (
  <div className="one-third column">{children}</div>
)

const ColumnFull = ({ children }) => (
  <div className="twelve columns">{children}</div>
)

const normalizeOptionsForSearchableSelect = (array) => {
  // transform payload values to react-select options
  return array.map((value) => ({
    key: value.companyName,
    value: value.companyID,
  }))
}

function ContactInformationForm({
  handleSubmit,
  initialValues,
  tpaCompanies,
  currentUser,
}) {
  const defaultCompany = {
    companyName: currentUser.accountName,
    companyID: currentUser.accountID,
  }

  const contactInformationValidation = Yup.object().shape({
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(80, 'Too Long!')
      .required('Required'),
    phone: Yup.string()
      .matches(RegExp(PHONE_NUMBER_REGEX), 'Please enter valid phone number')
      .required('Required'),
    email: Yup.string()
      .email('Must enter valid email!')
      .test(
        'emailValidationCheck',
        'This email is already associated with a contact for Asset Custody Platform. Please use another email address.',
        (email) =>
          fetch(`${process.env.API_URL}/emailAddresses?email=${email}`, {
            method: 'GET',
            headers: {
              Accept: `application/json`,
              Authorization: `Bearer ${getSessionToken()}`,
            },
          }).then(async (res) => {
            const data = await res.json()
            if (data.isValid) {
              return true
            } else {
              return false
            }
          })
      )
      .required('Required'),
    streetAddress: Yup.string()
      .min(2, 'Too Short!')
      .max(100, 'Too Long!')
      .required('Required'),
    city: Yup.string()
      .min(2, 'Too Short!')
      .max(40, 'Too Long!')
      .required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zipcode: Yup.string()
      .required('Required')
      .test('test_zip_code', '', (value, context) => {
        const { parent, createError } = context
        if (parent.country == 'US') {
          if (!RegExp(ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be valid 5 or 9 digit US zip code!',
            })
          }
        }
        if (parent.country == 'CA') {
          if (!RegExp(CANADIAN_ZIP_CODE_REGEX).test(value)) {
            return createError({
              message: 'Must be a valid six-character CA code!',
            })
          }
        }
        return true
      }),
    accountID: Yup.string().required('Required'),
  })

  // get alpha sorted list of unique TPAs relevant to contact
  const filteredTPACompanies = uniqBy(tpaCompanies, 'companyID')
    .filter((company) => company.companyID !== defaultCompany.companyID)
    .sort(ascendingAlphaSort('companyName'))

  // first display default company as preselected given accountID initial value, then alpha sorted list of other TPA options
  const companyOptions = normalizeOptionsForSearchableSelect([
    defaultCompany,
    ...filteredTPACompanies,
  ])

  const hasOnlyDefaultCompany = isEmpty(filteredTPACompanies)

  return (
    <div className="flow-card-container">
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={contactInformationValidation}
      >
        {({ isSubmitting, values }) => {
          const isUsOrCanada =
            values.country === 'US' || values.country === 'CA'
          return (
            <Form>
              <div className="card">
                <div className="form-card-content">
                  <div className="row">
                    <ColumnOneHalf>
                      <ACPInputField
                        name="firstName"
                        label="First Name"
                        placeholder="Enter first name"
                      />
                    </ColumnOneHalf>
                    <ColumnOneHalf>
                      <ACPInputField
                        name="lastName"
                        label="Last Name"
                        placeholder="Enter last name"
                      />
                    </ColumnOneHalf>
                  </div>
                  <div className="row">
                    <ColumnOneHalf>
                      <ACPInputField
                        label="Phone"
                        name="phone"
                        type="tel"
                        placeholder="Enter phone"
                        maskOptions={{
                          numericOnly: true,
                          blocks: [0, 3, 0, 3, 4],
                          delimiters: ['(', ')', ' ', '-'],
                        }}
                      />
                    </ColumnOneHalf>
                    <ColumnOneHalf>
                      <ACPInputField
                        name="email"
                        label="Email"
                        placeholder="Enter email"
                      />
                    </ColumnOneHalf>
                  </div>
                  <div className="row">
                    <ColumnFull>
                      {hasOnlyDefaultCompany ? (
                        <LabelWithTooltip
                          label="Company"
                          tooltipContent="This contact will be added to the company below."
                        >
                          {companyOptions[0].key}
                        </LabelWithTooltip>
                      ) : (
                        <SearchableSelect
                          name="accountID"
                          label="Company *"
                          tooltipContent="Select the company for this contact."
                          options={companyOptions}
                          placeholder={'Select'}
                          isClearable
                        />
                      )}
                    </ColumnFull>
                  </div>
                  <div className="row">
                    <ColumnFull>
                      <ACPInputField
                        name="streetAddress"
                        label="Address"
                        placeholder="Enter street address"
                      />
                    </ColumnFull>
                  </div>
                  <div className="row">
                    <ColumnFull>
                      <ACPInputField
                        name="country"
                        label="Country"
                        selectOptions={countries}
                        enablePlaceholderOption={true}
                        placeholder="Select country"
                      />
                    </ColumnFull>
                  </div>
                  <div className="row">
                    <ColumnOneThird>
                      <ACPInputField
                        name="city"
                        label="City"
                        placeholder="Enter city"
                      />
                    </ColumnOneThird>
                    <ColumnOneThird>
                      {isEmpty(values.country) || isUsOrCanada ? (
                        <ACPInputField
                          name="state"
                          label="State/Province/Region"
                          selectOptions={
                            values.country === 'US'
                              ? usStates
                              : canadianProvinces
                          }
                          enablePlaceholderOption={true}
                          placeholder="Select State/Province/Region"
                          readOnly={!isUsOrCanada}
                        />
                      ) : (
                        <ACPInputField
                          name="state"
                          label="State/Province/Region"
                          enablePlaceholderOption={true}
                          placeholder="Enter State/Province/Region"
                          readOnly={isEmpty(values.country) || isUsOrCanada}
                        />
                      )}
                    </ColumnOneThird>
                    <ColumnOneThird>
                      <ACPInputField
                        name="zipcode"
                        label="Zip Code"
                        placeholder="Enter zip code"
                      />
                    </ColumnOneThird>
                  </div>
                </div>
              </div>
              <FlowActions submitting={isSubmitting} />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

ContactInformationForm.propTypes = propTypes
ContactInformationForm.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ContactInformationForm
)
