const stringArrayComparator = (a, b) => {
  if (!a[0]) {
    return -1
  } else if (!b[0]) {
    return 1
  } else {
    return a[0].localeCompare(b[0])
  }
}

export default stringArrayComparator
