import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import exact from 'prop-types-exact'
import AccountCircleIcon from 'images/account-circle.svg'
import { useClickAway } from 'react-use'
import { useAuth0 } from '@auth0/auth0-react'
import { clearSessionToken } from '../services/auth'

const propTypes = {}

const defaultProps = {}

function AccountDetails() {
  const [isExpanded, setExpanded] = useState(false)
  const ref = useRef(null)
  const { logout } = useAuth0()

  useClickAway(
    ref,
    () => {
      setExpanded(false)
    },
    ['click']
  )

  const handleProfileClick = () => {
    setExpanded(false)
  }

  function handleLogout() {
    setExpanded(false)
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  return (
    <div className="AccountDetails" ref={ref}>
      <img
        alt="account-details-icon"
        className="AccountDetails-icon"
        src={AccountCircleIcon}
        onClick={() => {
          setExpanded(true)
        }}
      />

      {isExpanded && (
        <div className="AccountDetails--dropdown">
          <div className="AccountDetails-profile-link">
            <Link to="/profile" onClick={handleProfileClick}>
              Profile
            </Link>
          </div>

          <button onClick={handleLogout}>Log Out</button>
        </div>
      )}
    </div>
  )
}

AccountDetails.propTypes = exact(propTypes)
AccountDetails.defaultProps = defaultProps

export default React.memo(AccountDetails)
