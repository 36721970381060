import React, { useState } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import * as flashActions from 'redux-flash'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import { Button } from 'lp-components'
import DeactivateModal from './DeactivateModal'
import ContactProfileModal from './ContactProfileModal'
import { isEmpty } from 'lodash'
import classnames from 'classnames'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { ROLE_IDS, countries } from 'config'
import { getRemainingContactOptions } from 'utils'
import { useHistory } from 'react-router-dom'
import * as Types from 'types'
import WarningIcon from 'images/warning.svg'
import moment from 'moment'

const propTypes = {
  contact: PropTypes.object.isRequired,
  contactsList: PropTypes.arrayOf(Types.contact).isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  inviteLegacyContact: PropTypes.func.isRequired,
}

const defaultProps = {}

function ProfileFields({
  contact,
  contactsList,
  flashErrorMessage,
  flashSuccessMessage,
  inviteLegacyContact,
}) {
  const [showContactProfileModal, setShowContactProfileModal] = useState(false)
  const [showDeactivateModal, setShowDeactivateModal] = useState(false)
  const [invitationSubmitting, setInvitationSubmitting] = useState(false)

  const history = useHistory()

  const { contactDetails, roleNames, dateAdded, lastUpdated } = contact
  const target = moment(lastUpdated)
  const sixMonthsAgo = moment().subtract(6, 'months')
  const needsReviewNew = !dateAdded
  const newdsReviewOld = (dateAdded && !lastUpdated) || sixMonthsAgo > target
  const isManagingMemberOrAdminContact = roleNames.some(
    (roleName) =>
      roleName.role === ROLE_IDS.managingMember ||
      roleName.role === ROLE_IDS.adminContact
  )

  const contactOptions = getRemainingContactOptions(
    contactDetails,
    contactsList
  )

  const handleInviteLegacyContact = async () => {
    setInvitationSubmitting(true)
    try {
      await inviteLegacyContact(contactDetails.contactID)
      flashSuccessMessage('Your invitation has been successfully sent.')
      setInvitationSubmitting(false)
      history.push('/contacts')
    } catch (e) {
      flashErrorMessage(
        e.errors.message || 'Something went wrong, please try again.'
      )
      setInvitationSubmitting(false)
    }
  }

  const isContactPlatformUser = !isEmpty(contact.dateAdded)

  return (
    <>
      <div className="contact-edit-profile-fields">
        <div className="contact-profile-button-wrapper">
          <h4>{contactDetails.firstName + ' ' + contactDetails.lastName}</h4>
          <div className="button-block--inline-contact-profile">
            <div
              className={
                !isManagingMemberOrAdminContact || !isContactPlatformUser
                  ? 'borderBox'
                  : ''
              }
            >
              <button
                className="button-secondary edit-button"
                onClick={() => setShowContactProfileModal(true)}
                type="button"
              >
                Edit Contact Information
              </button>
            </div>
            {!isContactPlatformUser && (
              <div
                className={!isManagingMemberOrAdminContact ? 'borderBox' : ''}
              >
                <Button
                  className={classnames('button-secondary')}
                  onClick={handleInviteLegacyContact}
                  submitting={invitationSubmitting}
                >
                  Send Invite
                </Button>
              </div>
            )}

            {!isManagingMemberOrAdminContact && (
              <button
                className="button-secondary"
                onClick={() => setShowDeactivateModal(true)}
                type="button"
              >
                Deactivate Contact
              </button>
            )}
          </div>
        </div>
        {needsReviewNew && (
          <div className="banner-icon-large">
            <img
              className="urgent-icon med-14"
              src={WarningIcon}
              alt="Urgent"
            />
            <span>
              Review the contact information, role(s), and asset assignment(s)
              and confirm if it’s correct. You can edit, if needed. Once you
              confirm a contact is correct, we’ll send them an invite to this
              platform. If this person should no longer have access to this
              platform, deactivate the contact.
            </span>
          </div>
        )}
        {newdsReviewOld && (
          <div className="banner-icon">
            <img
              className="urgent-icon med-14"
              src={WarningIcon}
              alt="Urgent"
            />
            <span>Please confirm or update contact information.</span>
          </div>
        )}
        <p>{contactDetails?.accountName}</p>
        <p>{contactDetails?.phone}</p>
        <p>{contactDetails?.email}</p>
        <p>
          {contactDetails?.streetAddress +
            ', ' +
            contactDetails?.city +
            ', ' +
            contactDetails?.state +
            ' ' +
            contactDetails?.zipcode +
            ', ' +
            countries.find(
              (country) => country.value === contactDetails?.country
            )?.key}
        </p>
      </div>
      {showContactProfileModal && (
        <ContactProfileModal
          setShowContactProfileModal={setShowContactProfileModal}
          contactDetails={contactDetails}
        />
      )}
      {showDeactivateModal && (
        <DeactivateModal
          onClose={() => setShowDeactivateModal(false)}
          contact={contact}
          contactOptions={contactOptions}
        />
      )}
    </>
  )
}

ProfileFields.propTypes = exact(propTypes)
ProfileFields.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    contact: selectors.contact(state),
    contactsList: selectors.contactsList(state),
  }
}

const mapDispatchToProps = {
  flashSuccessMessage: flashActions.flashSuccessMessage,
  flashErrorMessage: flashActions.flashErrorMessage,
  inviteLegacyContact: apiActions.inviteLegacyContact,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ProfileFields
)
