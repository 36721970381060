import { ADD_ASSET_STAGE, isAddAssetScreenshotEnabled } from 'config'
import {
  AssetDetails,
  ContactDetails,
  InvestmentDetails,
  FundingDetails,
  PurchaseRequirements,
  FundingInstructions,
  ValuationRequirements,
  DocumentUpload,
  ESignAndConfirm,
} from './add-asset-flow/views'
import {
  DocumentsToDownload,
  ProcessableConfirmation,
  ESignAndSubmit,
  LiquidationDetails,
} from './liquidation-confirmation/views'
import {
  InvestorSelection,
  CapitalCallAmount,
  CapitalCallLetterUpload,
} from './capital-commitments-flow/views'
import {
  ExchangeAuthorization,
  TransactionDocuments,
  DocumentUpload as ExchangeDocumentUpload,
  ExchangeConfirmation,
  ESign,
} from './exchange-confirmation/views'

import {
  ContactInformation,
  RoleAndAssetAssignment,
} from './contacts-management/views'
import {
  Templates as BulkConfirmationTemplates,
  DocumentUpload as BulkConfirmationUploads,
  Esign as BulkConfirmationEsign,
  PurchaseDetails as BulkConfirmationPurchaseDetails,
} from './bulk-confirmations/views'
import {
  FundingInstructions as FundingInstructionDetails,
  ESign as FundingInstructionESign,
} from './funding-instructions/views'

import {
  AssetDetails as OnboardingAssetDetails,
  ContactDetails as OnboardingContactDetails,
} from './onboarding/views'

import { ContactRoleReplacements } from './contact-role-replacements/views'

export const ADD_ASSET_STAGES = [
  {
    name: ADD_ASSET_STAGE.ASSET_DETAILS,
    label: 'Asset & Managing Member Details',
    routes: [
      {
        pathname: '/new',
        component: AssetDetails,
        preventNavigate: true,
      },
      {
        pathname: '/asset-details',
        component: AssetDetails,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.CONTACT_DETAILS,
    label: 'Contact Details',
    routes: [
      {
        pathname: '/contact-details',
        component: ContactDetails,
      },
      {
        pathname: '/funding-details',
        component: FundingDetails,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.INVESTMENT_DETAILS,
    label: 'Investment Details',
    routes: [
      {
        pathname: '/investment-details',
        component: InvestmentDetails,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
    label: 'Funding Details',
    routes: [
      {
        pathname: '/funding-instructions',
        component: FundingInstructions,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.REQUIREMENT_DETAILS,
    label: 'Transaction Details',
    routes: [
      {
        pathname: '/purchase-requirements',
        component: PurchaseRequirements,
      },
      {
        pathname: '/valuation-requirements',
        component: ValuationRequirements,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.DOCUMENT_UPLOAD,
    label: 'Document Upload',
    routes: [
      {
        pathname: '/documents',
        component: DocumentUpload,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.E_SIGN,
    label: isAddAssetScreenshotEnabled()
      ? 'Review & E-Sign'
      : 'E-Sign & Submit',
    routes: [
      {
        pathname: '/e-sign',
        component: ESignAndConfirm,
      },
    ],
  },
]

export const LIQUIDATION_CONFIRMATION_STAGES_PART_ONE = [
  {
    name: 'download',
    label: 'Transaction Documents',
    routes: [
      {
        pathname: '/templates',
        component: DocumentsToDownload,
      },
    ],
  },
  {
    name: 'confirmation',
    label: 'Liquidation Form',
    routes: [
      {
        pathname: '/confirmation-form',
        component: ProcessableConfirmation,
      },
    ],
  },
  {
    name: 'sign',
    label: 'E-Sign & Submit',
    routes: [
      {
        pathname: '/sign',
        component: ESignAndSubmit,
      },
    ],
  },
]

export const LIQUIDATION_CONFIRMATION_STAGES_PART_TWO = [
  {
    name: 'details',
    label: 'Liquidation Form',
    routes: [
      {
        pathname: '/details',
        component: LiquidationDetails,
      },
    ],
  },
  {
    name: 'sign',
    label: 'E-Sign & Submit',
    routes: [
      {
        pathname: '/sign2',
        component: ESignAndSubmit,
      },
    ],
  },
]

export const CAPITAL_COMMITMENT_STAGES = [
  {
    name: 'investorSelection',
    label: 'Investor Capital Call',
    routes: [
      {
        pathname: '/investor-selection',
        component: InvestorSelection,
      },
    ],
  },
  {
    name: 'capitalCallAmount',
    label: 'Capital Call Amount',
    routes: [
      {
        pathname: '/capital-call-amount',
        component: CapitalCallAmount,
      },
    ],
  },
  {
    name: 'capitalCallLetterUpload',
    label: 'Upload, E-Sign & Submit',
    routes: [
      {
        pathname: '/call-letter-and-esign',
        component: CapitalCallLetterUpload,
      },
    ],
  },
]

export const EXCHANGE_CONFIRMATION_STAGES = [
  {
    name: 'exchangeAuthorization',
    label: 'Exchange Authorization',
    routes: [
      {
        pathname: '/exchange-authorization',
        component: ExchangeAuthorization,
      },
    ],
  },
  {
    name: 'transactionDocuments',
    label: 'Transaction Documents',
    routes: [
      {
        pathname: '/transaction-documents',
        component: TransactionDocuments,
      },
    ],
  },
  {
    name: 'documentUpload',
    label: 'Upload Documents',
    routes: [
      {
        pathname: '/document-upload',
        component: ExchangeDocumentUpload,
      },
    ],
  },
  {
    name: 'exchangeConfirmation',
    label: 'Exchange Confirmation',
    routes: [
      {
        pathname: '/exchange-confirmation',
        component: ExchangeConfirmation,
      },
    ],
  },
  {
    name: 'sign',
    label: 'E-Sign & Submit',
    routes: [
      {
        pathname: '/sign',
        component: ESign,
      },
    ],
  },
]

export const ADD_NEW_CONTACT_STAGES = [
  {
    name: 'contactInformation',
    label: 'New Contact',
    routes: [
      {
        pathname: '/contact-information',
        component: ContactInformation,
      },
    ],
  },
  {
    name: 'roleAndAssetAssignment',
    label: 'Role and Asset Assignment',
    routes: [
      {
        pathname: '/role-assignments',
        component: RoleAndAssetAssignment,
      },
    ],
  },
]

export const ROLE_REPLACEMENTS_STAGES = [
  {
    name: 'contactRoleReplacements',
    label: 'Role and Asset Assignments',
    routes: [
      {
        pathname: '/contact-role-replacements',
        component: ContactRoleReplacements,
      },
    ],
  },
]

export const BULK_CONFIRMATION_STAGES = [
  {
    name: 'download',
    label: 'Download Documents',
    headerLabel: 'Transaction Documents',
    routes: [
      {
        pathname: '/templates',
        component: BulkConfirmationTemplates,
      },
    ],
  },
  {
    name: 'upload',
    label: 'Upload Signature Page',
    headerLabel: 'Confirm Purchase',
    routes: [
      {
        pathname: '/uploads',
        component: BulkConfirmationUploads,
      },
    ],
  },
  {
    name: 'details',
    label: 'Provide Purchase Details',
    headerLabel: 'Confirm Purchase',
    routes: [
      {
        pathname: '/details',
        component: BulkConfirmationPurchaseDetails,
      },
    ],
  },
  {
    name: 'sign',
    label: 'Review & Submit',
    headerLabel: 'Confirm Purchase',
    routes: [
      {
        pathname: '/sign',
        component: BulkConfirmationEsign,
      },
    ],
  },
]

export const FUNDING_INSTRUCTIONS_STAGES = [
  {
    name: 'details',
    label: 'Funding Details',
    routes: [
      {
        pathname: '/details',
        component: FundingInstructionDetails,
      },
    ],
  },
  {
    name: 'esign',
    label: 'Review & E-Sign',
    routes: [
      {
        pathname: '/esign',
        component: FundingInstructionESign,
      },
    ],
  },
]

export const ONBOARDING_STAGES = [
  {
    name: 'asset-details',
    label: 'Asset & Managing Member Details',
    routes: [
      {
        pathname: '/asset-details',
        component: OnboardingAssetDetails,
      },
    ],
  },
  {
    name: 'contact-details',
    label: 'Contact Details',
    routes: [
      {
        pathname: '/contact-details',
        component: OnboardingContactDetails,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.INVESTMENT_DETAILS,
    label: 'Investment Details',
    routes: [
      {
        pathname: '/investment-details',
        component: InvestmentDetails,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.FUNDING_INSTRUCTIONS,
    label: 'Funding Details',
    routes: [
      {
        pathname: '/funding-instructions',
        component: FundingInstructions,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.REQUIREMENT_DETAILS,
    label: 'Transaction Details',
    routes: [
      {
        pathname: '/purchase-requirements',
        component: PurchaseRequirements,
      },
      {
        pathname: '/valuation-requirements',
        component: ValuationRequirements,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.DOCUMENT_UPLOAD,
    label: 'Document Upload',
    routes: [
      {
        pathname: '/documents',
        component: DocumentUpload,
      },
    ],
  },
  {
    name: ADD_ASSET_STAGE.E_SIGN,
    label: isAddAssetScreenshotEnabled()
      ? 'Review & E-Sign'
      : 'E-Sign & Submit',
    routes: [
      {
        pathname: '/e-sign',
        component: ESignAndConfirm,
      },
    ],
  },
]
