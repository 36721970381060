import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
}

const defaultProps = {}

function InsufficientFundsModal({ onClose, resetForm }) {
  return (
    <Modal id="valuation-method-modal" onClose={onClose}>
      <div className="valuation-method-container">
        <h2>Not enough funds for this request</h2>
        <p>
          If you are requesting a capital call that is more than the amount
          available in the investor balance, the investor will need to supply a
          new investment direction.
        </p>
        <hr />
      </div>
      <div className="button-block--inline">
        <button
          className="button-primary"
          onClick={() => {
            resetForm()
            onClose()
          }}
          type="button"
        >
          Back to Capital Call Amount
        </button>
      </div>
    </Modal>
  )
}

InsufficientFundsModal.propTypes = exact(propTypes)
InsufficientFundsModal.defaultProps = defaultProps

export default React.memo(InsufficientFundsModal)
