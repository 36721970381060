import React from 'react'
import PropTypes from 'prop-types'
import { InviteInvestorsHeader, FlowActions } from './components'

const propTypes = {
  children: PropTypes.node.isRequired,
}

const defaultProps = {}

function Layout({ children }) {
  return (
    <>
      <InviteInvestorsHeader title="Invite New Investor" />
      <div className="invitation-wrapper">{children}</div>
      <FlowActions />
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

export default Layout
