import React, { useState, useCallback, useMemo, useRef } from 'react'
import 'ag-grid-community'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Card, ACPTable } from 'components'
import { Spinner } from 'lp-components'
import * as moment from 'moment'
import * as apiActions from 'api-actions'
import {
  formatWithNullValues,
  dateComparator,
  stringArrayComparator,
} from 'utils'
import CollapsableList from './CollapsableList'

const propTypes = {
  downloadUploadedDocuments: PropTypes.func.isRequired,
  uploads: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  downloadTransactionType: '',
}

const LinkCellRenderer = (params, downloadUploadedDocuments) => {
  const { data } = params
  const [buttonState, setButtonState] = useState('loaded')
  const documentName = data.documentName
    .replace(/[^\w.]+/g, '')
    .replaceAll('_', '')
  const originalDocumentName = data.documentName
  const laserFischeId = data.hyperLinkID

  const onClick = () =>
    downloadUploadedDocuments(laserFischeId, originalDocumentName)

  const asyncOnClick = async () => {
    setButtonState('loading')
    await new Promise((r) => setTimeout(r, 2000))
    setButtonState('loaded')
    await onClick()
  }

  return (
    <button type="button" className="card-upload-link" onClick={asyncOnClick}>
      {buttonState === 'loaded' ? (
        <span>{documentName}</span>
      ) : (
        <Spinner className="spinnerSize" />
      )}
    </button>
  )
}

const loadingOverlay = `<div class="search-results-helper">
  <div class="overlay-text-title med-18">No results found</div>
  <span class="overlay-text reg-14">It looks like you don't have any documents yet.</span>
  <span class="overlay-text reg-14">To get started, please select "Upload Documents".</span>
  </div>`

function UploadsTable({ downloadUploadedDocuments, uploads }) {
  const [rowFilterSize, setRowFilterSize] = useState(null)
  const gridRef = useRef()
  const gridStyle = useMemo(() => ({ height: '518px', width: '100%' }), [])

  const transformedUploads = uploads.map((doc) => {
    /**
     ***Tech Note: 1st conditional is obsolete in future version of upload
     ***objects indexed at 6-7-2023.
     **/
    if (
      Array.isArray(doc.assetList) &&
      doc.assetList.length > 0 &&
      typeof doc.assetList[0] === 'object'
    ) {
      const assetLabels = doc.assetList.map((asset) => asset?.label)
      doc.assetList = assetLabels
    } else if (doc.assetList === null || doc.assetList === '') {
      doc.assetList = []
    }
    return {
      ...doc,
    }
  })

  const customComparator = (valueA, valueB) => {
    return valueA
      .toLowerCase()
      .replace(/\s/g, '')
      .localeCompare(valueB.toLowerCase().replace(/\s/g, ''))
  }

  const columnDefs = [
    {
      field: 'documentName',
      headerName: 'Document Name',
      minWidth: 350,
      cellClass: 'upload-doc-table-doc-name',
      headerClass: 'upload-doc-table-doc-name-header',
      wrapText: true,
      sortable: true,
      comparator: customComparator,
      cellRenderer: (params) =>
        LinkCellRenderer(params, downloadUploadedDocuments),
      headerTooltip: 'Document Name: The name of the document sent to Inspira.',
      filter: 'agTextColumnFilter',
    },
    {
      field: 'clientFullName',
      headerName: 'Client Name',
      minWidth: 200,
      sortable: true,
      wrapText: true,
      headerTooltip: 'Client Name: The name of the client.',
      valueFormatter: (params) => {
        return formatWithNullValues(params.data.clientFullName)
      },
    },
    {
      field: 'assetList',
      headerName: 'Asset Name(s)',
      minWidth: 350,
      autoHeight: true,
      cellRenderer: (params) => {
        return <CollapsableList data={params.data.assetList} />
      },
      valueGetter: (params) => {
        const assetList = params.data.assetList
        if (
          !assetList ||
          !Array.isArray(assetList) ||
          !assetList?.length ||
          (assetList?.length === 1 && assetList[0] === '')
        ) {
          return params.column.sort === 'asc'
            ? ['z']
            : params.column.sort === 'desc'
            ? ['a']
            : []
        }
        return assetList?.sort((a, b) => {
          if (!a) {
            return 1
          } else if (!b) {
            return -1
          }
          return a.toLowerCase().localeCompare(b.toLowerCase())
        })
      },
      comparator: stringArrayComparator,
      sortable: true,
    },
    {
      field: 'documentType',
      headerName: 'Document Type',
      minWidth: 150,
      wrapText: true,
      sortable: true,
      headerTooltip: 'Document Type: The type of document sent to Inspira.',
    },
    {
      field: 'dateSent',
      headerName: 'Date Sent',
      minWidth: 100,
      sortable: true,
      headerTooltip: 'Date Sent: The date the document was sent to Inspira.',
      valueFormatter: function(params) {
        return moment(params.value).format('MM/DD/YYYY')
      },
      comparator: dateComparator,
    },
  ]

  const onFilterTextBoxChanged = useCallback(() => {
    const model = {
      documentName: {
        filterType: 'text',
        type: 'contains',
        filter: document.getElementById('filter-text-box').value,
      },
    }
    gridRef.current.api.setFilterModel(model)
    getDisplayedRowCount()
  }, [])

  const getDisplayedRowCount = useCallback(() => {
    const count = gridRef.current.api.getDisplayedRowCount()
    setRowFilterSize(count)
  }, [])

  const showNoResultsOverlay = useCallback(() => {
    gridRef.current.api.showNoRowsOverlay()
  }, [])

  const onBtHide = useCallback(() => {
    gridRef.current.api.hideOverlay()
  }, [])

  const DocumentsTableActions = (
    <div id="uploads-table" className="documents-table">
      <div className="table-actions">
        <div className="table-actions-single">
          <span className="input-wrapper">
            <input
              type="text"
              id="filter-text-box"
              placeholder="Search Document Name"
              onInput={onFilterTextBoxChanged}
            />
            {rowFilterSize === 0 && showNoResultsOverlay()}
            {rowFilterSize > 0 && onBtHide()}
          </span>
        </div>
      </div>
    </div>
  )

  if (!uploads) {
    return (
      <Card label="Documents">
        <Spinner />
      </Card>
    )
  }

  return (
    <>
      <Card label="Uploaded Documents" actions={DocumentsTableActions}>
        {uploads && (
          <ACPTable
            columnDefs={columnDefs}
            paginationPageSize="10"
            rowData={transformedUploads}
            additionalDefaultColDef={{ sortable: true }}
            overrideGridStyle={gridStyle}
            overrideGridRef={gridRef}
            overlayLoadingTemplate={loadingOverlay}
          />
        )}
      </Card>
    </>
  )
}

UploadsTable.propTypes = propTypes
UploadsTable.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  downloadUploadedDocuments: apiActions.downloadUploadedDocuments,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UploadsTable
)
