import React from 'react'
import { useLocation } from 'react-router'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import EmptyCircleIcon from 'images/form-status-circle.svg'
import CompletedCircleIcon from 'images/form-status-circle-complete.svg'
import EmptyCircleVisitedIcon from 'images/form-status-circle-visited.svg'
import classnames from 'classnames'
import { Link } from 'react-router-dom'

const propTypes = {
  visited: PropTypes.bool.isRequired,
  complete: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  finalStage: PropTypes.bool,
  disableNavigation: PropTypes.bool,
}

const defaultProps = {
  finalStage: false,
  disableNavigation: false,
}

function CategoryStatusBreadcrumbTab({
  visited,
  complete,
  label,
  pathname,
  finalStage,
  disableNavigation,
}) {
  const location = useLocation()
  const newRoute =
    location.pathname.slice(0, location.pathname.lastIndexOf('/')) + pathname

  const path =
    (complete && newRoute) || (!complete && visited && newRoute) || ''

  return (
    <>
      <Link
        className={classnames(
          'category-status-container',
          {
            visited,
            complete,
          },
          {
            'is-disabled': disableNavigation,
          }
        )}
        to={disableNavigation ? '#' : path}
        aria-disabled={(!complete && !visited) || disableNavigation}
      >
        <img
          src={
            complete
              ? CompletedCircleIcon
              : visited
              ? EmptyCircleVisitedIcon
              : EmptyCircleIcon
          }
          alt={`${
            complete
              ? 'completed step'
              : !complete && visited
              ? 'current step'
              : 'incomplete step'
          }`}
        />
        {label}
      </Link>
      {!finalStage && (
        <div className={classnames('spacer', { complete: complete })}></div>
      )}
    </>
  )
}

CategoryStatusBreadcrumbTab.propTypes = exact(propTypes)
CategoryStatusBreadcrumbTab.defaultProps = defaultProps

export default React.memo(CategoryStatusBreadcrumbTab)
