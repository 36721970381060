import React, { useEffect, useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as actions from '../actions'
import * as apiActions from 'api-actions'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import exact from 'prop-types-exact'
import { Formik, Form } from 'formik'
import { FlowActions } from '../components'
import {
  DocumentUploadInput,
  LabelWithTooltip,
  ACPInputField,
} from 'components'
import { find, isEmpty } from 'lodash'
import { Spinner, Checkbox as BaseCheckbox } from 'lp-components'
import {
  bulkPromiseUploads,
  createFileErrors,
  scrollToTop,
  formatNumberAsCurrency,
  withFormikAdapter,
} from 'utils'

import * as Yup from 'yup'
import * as flashActions from 'redux-flash'
import { LETTER_UPLOAD_TYPES } from 'config'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  storeCapitalCommitment: PropTypes.func.isRequired,
  capitalCommitment: PropTypes.object,
  createCapitalCommitment: PropTypes.func.isRequired,
  fetchCapitalCommitment: PropTypes.func.isRequired,
  uploadCapitalCommitmentDocument: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  setDisableNavigation: PropTypes.func.isRequired,
}

const defaultProps = {}

function CapitalCallLetterUpload({
  capitalCommitment,
  createCapitalCommitment,
  storeCapitalCommitment,
  fetchCapitalCommitment,
  uploadCapitalCommitmentDocument,
  flashErrorMessage,
  flashSuccessMessage,
  setDisableNavigation,
}) {
  const [submitting, setSubmitting] = useState(false)
  const [needsResubmission, setNeedsResubmission] = useState(false)
  const [failedSubmissionDocuments, setFailedSubmissionDocuments] = useState([])
  const history = useHistory()
  useEffect(() => {
    if (isEmpty(capitalCommitment?.selectedClients) && !submitting) {
      history.push('investor-selection')
    }
  }, [capitalCommitment])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!capitalCommitment) {
    return <Spinner />
  }

  const childAccounts = capitalCommitment?.childAccounts
  const multipleAccounts = childAccounts?.length > 1
  const percentageContribution = capitalCommitment?.capitalCallPercentage * 100
  const requestedAmount = capitalCommitment?.capitalCallDollarAmount
  const selectedClients = capitalCommitment?.selectedClients

  const accountInitialValues = {
    documents: {
      [LETTER_UPLOAD_TYPES.SINGLE]: {
        [capitalCommitment?.assetID]: '',
      },
      [LETTER_UPLOAD_TYPES.MULTIPLE]: {},
    },
  }

  if (multipleAccounts) {
    childAccounts?.forEach((account) => {
      accountInitialValues.documents.multiple[account.clientAccountID] = ''
    })
  }

  const initialValues = {
    letterUploadType: !multipleAccounts ? LETTER_UPLOAD_TYPES.SINGLE : '',
    firstName: '',
    lastName: '',
    title: '',
    signature: '',
    ...accountInitialValues,
  }

  const lazyValidation = Yup.lazy((values) => {
    const letterUploadType = values.letterUploadType
    const isSingle = letterUploadType === LETTER_UPLOAD_TYPES.SINGLE

    const multipleDocumentValidation = childAccounts.reduce((acc, account) => {
      acc[account.clientAccountID] = Yup.object().required(
        'Please attach the required document'
      )
      return acc
    }, {})

    const validationObject = Yup.object({
      letterUploadType: Yup.string().required('Required'),
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      title: Yup.string().required('Required'),
      signature: Yup.bool()
        .oneOf([true], 'Required')
        .required('Required'),
      documents: Yup.object().shape({
        ...(isSingle
          ? {
              single: Yup.object().shape({
                [capitalCommitment?.assetID]: Yup.object().required(
                  'Please attach the required document'
                ),
              }),
            }
          : {
              multiple: Yup.object().shape({
                ...multipleDocumentValidation,
              }),
            }),
      }),
    })

    return validationObject
  })

  const handleSubmit = async (params, { setErrors }) => {
    setSubmitting(true)
    const letterUploadType = params.letterUploadType
    const payload = {
      parentInvestmentID: '',
      assetID: capitalCommitment.assetID,
      dueDate: capitalCommitment.dueDate,
      childAccounts: childAccounts.map((account) => {
        return {
          childInvestmentID: '',
          clientAccountID: account.clientAccountID,
          dueDate: capitalCommitment.dueDate,
          requestedAmount: account.requestedAmount,
          percentage: account.percentage,
        }
      }),
      eSignFirstName: params.firstName,
      eSignLastName: params.lastName,
      eSignTitle: params.title,
      eSignDate: Date.now(),
    }

    try {
      const isSingleDocument = letterUploadType === LETTER_UPLOAD_TYPES.SINGLE
      const filterCondition = needsResubmission
        ? (e) =>
            e[1].fileName &&
            e[1].documentID &&
            failedSubmissionDocuments.includes(e[1].documentID)
        : (e) => e[1].fileName && e[1].documentID
      var generatedCapitalCommitment = capitalCommitment

      if (!needsResubmission) {
        const createResponse = await createCapitalCommitment(payload)
        generatedCapitalCommitment = await fetchCapitalCommitment(
          createResponse.parentInvestmentID
        )
      }

      const fileUploadPromises = Object.entries(
        params.documents[letterUploadType]
      )
        .filter(filterCondition)
        .map((e) => {
          return {
            fileName: e[1].fileName,
            documentID: isSingleDocument
              ? generatedCapitalCommitment.parentInvestmentID
              : find(generatedCapitalCommitment.childAccounts, {
                  clientAccountID: e[1].documentID,
                }).childInvestmentID,
            fileContents: e[1].fileContents,
            fileType: e[1].fileType,
            // If it is not a single document upload, find the associated
            // selected client to the documentID. Otherwise for single
            // document uploads, when a single account is selected, set
            // the account number for the selected account. For multiple
            // selected accounts for a single upload, set nothing. Setting
            // nothing will cause the backend to default the account number
            // to the string "MULTIPLES".
            clientAccountID: e[1].documentID,
            accountNumber: !isSingleDocument
              ? selectedClients.find(
                  (client) => client.clientAccountID === e[1].documentID
                ).clientAccountNumber
              : selectedClients.length === 1
              ? selectedClients[0].clientAccountNumber
              : '',
          }
        })
        .map((doc) => {
          const formData = new FormData()
          formData.append(
            'fileContents',
            new Blob([doc.fileContents], {
              type: doc.fileType,
            }),
            doc.fileName
          )
          formData.append(
            'metadata',
            `'${JSON.stringify({ accountNumber: doc.accountNumber })}'`
          )
          return uploadCapitalCommitmentDocument(doc, formData)
        })

      const [, rejectedUploads] = await bulkPromiseUploads(fileUploadPromises)
      if (rejectedUploads.length) {
        const errors = isSingleDocument
          ? createFileErrors(rejectedUploads, 'singleDocumentError')
          : createFileErrors(rejectedUploads)
        storeCapitalCommitment({
          ...capitalCommitment,
          childAccounts: generatedCapitalCommitment.childAccounts,
          parentInvestmentID: generatedCapitalCommitment.parentInvestmentID,
          eSignDate: generatedCapitalCommitment.eSignDate,
          eSignFirstName: generatedCapitalCommitment.eSignFirstName,
          eSignLastName: generatedCapitalCommitment.eSignLastName,
          eSignTitle: generatedCapitalCommitment.eSignTitle,
        })
        setNeedsResubmission(true)
        setDisableNavigation(true)
        setErrors(errors)
        setFailedSubmissionDocuments(Object.keys(errors))
        scrollToTop()
      } else {
        flashSuccessMessage('We received your capital commitment.')
        history.push('/assets/capital-commitments')
      }
    } catch (e) {
      flashErrorMessage(
        e?.errors?.message || 'Something went wrong, please try again.'
      )
    }
    setSubmitting(false)
  }

  const childAccountBadges = childAccounts?.map((account) => {
    const selected = selectedClients.find(
      (client) => client.clientAccountID === account.clientAccountID
    )
    return (
      <li className="investor-review-badge" key={selected.clientAccountID}>
        <div className="investor-review-name">{selected.clientAccountName}</div>
        <div>
          {`Individual Capital Call Amount: ${formatNumberAsCurrency(
            account.requestedAmount
          )}`}
        </div>
      </li>
    )
  })

  return (
    <div className="flow-card-container">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={lazyValidation}
        initialValues={initialValues}
      >
        {({
          errors,
          touched,
          setTouched,
          setFieldValue,
          isSubmitting,
          values,
          resetForm,
        }) => {
          return (
            <Form className="asset-form asset-details-form capital-call-details-form">
              <div className="card">
                <div className="capital-call-review-header">
                  <h4>{capitalCommitment?.assetName}</h4>
                </div>
                {
                  <>
                    {multipleAccounts && (
                      <>
                        <ACPInputField
                          name="letterUploadType"
                          label="Do you want to load your letters individually or in a single file?"
                          radioOptions={[
                            {
                              key: 'Single Letter Upload',
                              value: LETTER_UPLOAD_TYPES.SINGLE,
                            },
                            {
                              key: 'Multiple Letter Upload',
                              value: LETTER_UPLOAD_TYPES.MULTIPLE,
                            },
                          ]}
                          onChange={(e) => {
                            resetForm({
                              values: {
                                ...values,
                                letterUploadType: e.target.value,
                              },
                            })
                            setTouched({})
                          }}
                          disabled={isSubmitting}
                        />
                        <hr />
                      </>
                    )}
                    {values.letterUploadType === LETTER_UPLOAD_TYPES.SINGLE ? (
                      <div className="capital-call-single-letter-upload-area">
                        <LabelWithTooltip
                          label="Single Letter Upload"
                          tooltipContent="Input for single letter upload"
                        />
                        <DocumentUploadInput
                          keyName={`documents.single.${capitalCommitment?.assetID}`}
                          document={{
                            isDocumentRequired: true,
                            requiredDocumentID: capitalCommitment?.assetID,
                          }}
                          setFieldValue={setFieldValue}
                          error={
                            touched?.documents && errors?.documents
                              ? errors.documents?.single?.[
                                  capitalCommitment?.assetID
                                ]
                              : errors['singleDocumentError']
                          }
                        />
                      </div>
                    ) : values.letterUploadType ===
                      LETTER_UPLOAD_TYPES.MULTIPLE ? (
                      <div className="capital-call-multiple-letter-upload-area">
                        <div className="multiple-call-letter-open-panel">
                          <LabelWithTooltip
                            label="Multiple Letter Upload"
                            tooltipContent="Input for multiple letter upload"
                          ></LabelWithTooltip>
                        </div>
                        {childAccounts?.map((child) => {
                          const selectedClient = capitalCommitment?.selectedClients.find(
                            (client) =>
                              client.clientAccountID === child.clientAccountID
                          )
                          return (
                            <div
                              key={child.childAccountID}
                              className="row document-input-row"
                            >
                              <div className="one-half column">
                                {selectedClient.clientAccountName}
                              </div>
                              <div className="one-half column half-width-files">
                                <DocumentUploadInput
                                  keyName={`documents.multiple.${child?.clientAccountID}`}
                                  document={{
                                    isDocumentRequired: true,
                                    requiredDocumentID: child?.clientAccountID,
                                  }}
                                  setFieldValue={setFieldValue}
                                  error={
                                    touched.documents && errors?.documents
                                      ? errors.documents.multiple?.[
                                          child?.clientAccountID
                                        ]
                                      : errors[child?.clientAccountID]
                                  }
                                  isSubmitted={
                                    needsResubmission &&
                                    !failedSubmissionDocuments.includes(
                                      child?.clientAccountID
                                    )
                                  }
                                />
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    ) : null}
                  </>
                }
                <div className="capital-call-review-section">
                  <h4>Review Your Information</h4>
                  <p>Investor(s)</p>
                  <div className="investors-review-section">
                    <ul>{childAccountBadges}</ul>
                  </div>
                  <div className="capital-call-amount-review-section">
                    <p>Capital Call Amount</p>
                    {percentageContribution ? (
                      <div className="capital-call-amount-line-item">
                        <span className="is-semibold">Percentage: </span>{' '}
                        {percentageContribution} %
                      </div>
                    ) : (
                      <div className="capital-call-amount-line-item">
                        <span className="capital-call-amount-type">
                          Total Amount:{' '}
                        </span>
                        <span className="capital-call-amount-value">
                          {formatNumberAsCurrency(requestedAmount)}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <hr />
                <div className="esign-section">
                  <h4>Acknowledgments</h4>
                  <h4>
                    Please review the following acknowledgments below and e-sign
                    at the bottom
                  </h4>
                  <p className="agreement-text">
                    I certify that I have processed this Capital Call in
                    accordance with instructions received from Inspira
                    Financial. I understand that holdback funds, once available,
                    should be sent directly to Inspira and should not be
                    directed to the account owner or another entity.
                  </p>
                  <h4>E-Signature</h4>
                  <fieldset className="read-only-wrapper">
                    <legend className="visually-hidden">
                      Signature details
                    </legend>
                    <div className="row">
                      <div className="six columns">
                        <ACPInputField
                          name="firstName"
                          label="First Name"
                          placeholder="Enter first name"
                        />
                      </div>
                      <div className="six columns">
                        <ACPInputField
                          name="lastName"
                          label="Last Name"
                          placeholder="Enter last name"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="twelve columns">
                        <ACPInputField
                          name="title"
                          label="Title"
                          placeholder="Enter title"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="checkbox-line-item twelve columns">
                        <Checkbox
                          name="signature"
                          label="By checking this box, I agree that I am signing these Acknowledgments, which I intend to be my legally binding signature on behalf of the Issuer. I also certify that the Issuer acknowledges and agrees and that I am authorized to and this e-signature does bind the Issuer named above to the terms of this Acknowledgment."
                        />
                      </div>
                    </div>
                  </fieldset>
                </div>
                <FlowActions
                  prevStep={needsResubmission ? null : 'capital-call-amount'}
                  submitting={isSubmitting}
                  submitContent="Submit"
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

CapitalCallLetterUpload.propTypes = exact(propTypes)
CapitalCallLetterUpload.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    capitalCommitment: selectors.capitalCommitment(state),
  }
}

const mapDispatchToProps = {
  storeCapitalCommitment: actions.storeCapitalCommitment,
  createCapitalCommitment: apiActions.createCapitalCommitment,
  fetchCapitalCommitment: apiActions.fetchCapitalCommitment,
  uploadCapitalCommitmentDocument: apiActions.uploadCapitalCommitmentDocument,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CapitalCallLetterUpload
)
