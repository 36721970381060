import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Button, SubmitButton } from 'lp-components'
import { useHistory } from 'react-router-dom'
import { noop } from 'lodash'
import classnames from 'classnames'
import { BackButton } from 'components'
import { CAPITAL_COMMITMENT_STAGES } from '../../flowStages'
import RightArrow from 'images/right-arrow-icon.svg'

const propTypes = {
  prevStep: PropTypes.string,
  onContinue: PropTypes.func,
  submitting: PropTypes.bool,
  isDisabled: PropTypes.bool,
  submitContent: PropTypes.node,
}

const defaultProps = {
  prevStep: null,
  onContinue: noop,
  submitting: false,
  isDisabled: false,
  submitContent: 'Continue',
}

function FlowActions({
  prevStep,
  onContinue,
  submitting,
  isDisabled,
  submitContent,
}) {
  const history = useHistory()

  return (
    <div className="flow-button-container">
      <div className="flow-button-inner">
        <Button
          className={classnames('button-secondary quit-button', {
            'is-disabled': submitting,
          })}
          onClick={() => history.push('/home')}
          disabled={submitting}
        >
          Quit & Exit
        </Button>
        {prevStep && <BackButton stages={CAPITAL_COMMITMENT_STAGES} />}
        <SubmitButton
          disabled={isDisabled}
          className={classnames({ 'is-disabled': isDisabled })}
          onClick={onContinue}
          submitting={submitting}
        >
          {submitContent}{' '}
          {!submitting && (
            <img
              src={RightArrow}
              className="right-arrow-icon"
              alt="Right Arrow"
            />
          )}
        </SubmitButton>
      </div>
    </div>
  )
}

FlowActions.propTypes = exact(propTypes)
FlowActions.defaultProps = defaultProps

export default React.memo(FlowActions)
