import React, { useState } from 'react'
import IndigoDownloadIcon from 'images/document-download-icon.svg'
import GrayDownloadIcon from 'images/document-download-icon-grey.svg'
import { isEmpty, noop } from 'lodash'
import { Spinner } from 'lp-components'

const DownloadIconButton = ({
  label = '',
  onClick = noop,
  isSecondary = false,
  children,
}) => {
  const [buttonState, setButtonState] = useState('loaded')

  const asyncOnClick = async () => {
    setButtonState('loading')
    await onClick()
    await new Promise((r) => setTimeout(r, 2000))
    setButtonState('loaded')
  }

  return (
    <button className="download-icon-button" onClick={asyncOnClick}>
      {!isEmpty(label) && buttonState === 'loaded' && (
        <div className="download-icon-button-label">{label}</div>
      )}
      {buttonState === 'loaded' ? (
        <img
          id="download"
          src={isSecondary ? GrayDownloadIcon : IndigoDownloadIcon}
          style={{ width: '16px', height: '16px', cursor: 'pointer' }}
          alt="download"
        />
      ) : (
        <Spinner className="spinnerSize" />
      )}
      {children}
    </button>
  )
}

export default DownloadIconButton
