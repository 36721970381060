import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice } from 'lp-redux-utils'

const reducerKey = 'fundingInstructions'
const slice = 'root.fundingInstructions'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchAssetFundingInstructions]: setOnSuccess(
      'fundingInstructions'
    ),
    [apiActions.fetchAssetDetails]: setOnSuccess('assetDetails'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  fundingInstructions: select('fundingInstructions'),
  assetDetails: select('assetDetails'),
}

export { reducer, selectors, reducerKey }
