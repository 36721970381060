const copyToClipboard = (element, contentType, onSuccess) => {
  const content = document.getElementsByClassName(element)[0].innerHTML

  if (contentType === 'text/html') {
    const blob = new Blob([content], { type: 'text/html' })

    var data = [new window.ClipboardItem({ 'text/html': blob })]

    navigator.clipboard.write(data).then(function() {
      onSuccess()
    })
  } else {
    navigator.clipboard.writeText(content).then(function() {
      onSuccess()
    })
  }
}

export default copyToClipboard
