import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import Badge from '@material-ui/core/Badge'
import IconButton from '@material-ui/core/IconButton'
import NotificationsIcon from 'images/notifications-2.svg'
import { NotificationsList } from 'components'

const propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.object),
}

const defaultProps = {
  notifications: null,
}

function NotificationsBadge({ notifications }) {
  const [showList, setShowList] = useState(false)
  const [numUnreadNotifications, setNumUnreadNotifications] = useState()

  useEffect(() => {
    setNumUnreadNotifications(
      notifications?.filter(({ isRead }) => !isRead).length
    )
  }, [notifications])

  return (
    <>
      <IconButton
        onClick={() => setShowList(!showList)}
        aria-label={`${notifications?.length} notifications`}
        className="notification-badge"
      >
        <Badge badgeContent={numUnreadNotifications} color="primary">
          <img alt="Notification" src={NotificationsIcon} />
        </Badge>
      </IconButton>
      <NotificationsList
        showList={showList}
        closeList={() => setShowList(false)}
        notifications={notifications}
      />
    </>
  )
}

NotificationsBadge.propTypes = exact(propTypes)
NotificationsBadge.defaultProps = defaultProps

export default React.memo(NotificationsBadge)
