import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { ADD_ASSET_STAGES } from 'flow-stages'
import { useParams, Link } from 'react-router-dom'
import editIcon from 'images/edit_icon.svg'

const propTypes = {
  stageName: PropTypes.string.isRequired,
}

const defaultProps = {}

/* A Review Header renders the stage label and the "Edit Details" link:
 clicking the link navigates the user to the corresponding stage in the flow.
 
 Important: if a stage consists of more than one step (e.g., Transaction Details),
 one review header is rendered for the entire stage and clicking the link takes the user to the *first* step of the stage
 (e.g., 'Transaction Details' stage -> 'Purchase Requirements' step)

 One exception is the very first stage of the add-asset-flow: 'Asset Details':
 its first "step" defined in ADD_ASSET_STAGES is for the specific case in which the asset has not been created (pathname = "/new")
 We can assume the asset exists when the user is on the review step and point to the next step outlined in ADD_ASSET_STAGES
 -> see: function getFirstStepIdx
*/

function getStageByName(stages, stageName) {
  return stages.find((stage) => stage.name === stageName)
}

function getFirstStepIdx(stages, stage) {
  if (stages.indexOf(stage) === 0) return 1
  return 0
}

const joinPath = (...arr) => ('/' + arr.join('/')).replace(/\/\//g, '/')

function ReviewHeader({ stageName }) {
  const { assetID } = useParams()
  const stage = getStageByName(ADD_ASSET_STAGES, stageName)
  const firstStepIdx = getFirstStepIdx(ADD_ASSET_STAGES, stage)
  const path = stage.routes[firstStepIdx].pathname

  return (
    <div className="u-cf u-full-width review-header" data-html2canvas-ignore>
      <Link
        className="edit-details u-pull-right"
        to={joinPath('add-asset', assetID, path)}
      >
        <img src={editIcon} alt="" />
        Edit Details
      </Link>
      <h4 className="title u-pull-left">{stage.label}</h4>
    </div>
  )
}

ReviewHeader.propTypes = exact(propTypes)
ReviewHeader.defaultProps = defaultProps

export default React.memo(ReviewHeader)
