/* eslint-disable */
import React from 'react'
import exact from 'prop-types-exact'
import { ACPInputField } from 'components'
import { convertOptionsToDisplayOptions } from 'utils'
import { IS_NOT_PROCESSABLE_OPTIONS } from 'config'

const propTypes = {}

const defaultProps = {}

function IsNotProcessableForm({
  namespace,
  setErrors,
  setTouched,
  isSubmitting,
}) {
  return (
    <div className="container">
      <div className="row">
        <div className="twelve column">
          <ACPInputField
            name={`${namespace}.reason`}
            label="Please select the reason why it can’t be processed:"
            selectOptions={convertOptionsToDisplayOptions(
              IS_NOT_PROCESSABLE_OPTIONS
            )}
            enablePlaceholderOption={true}
            placeholder="Select Reason"
            onChange={() => {
              setErrors({})
              setTouched({})
            }}
            disabled={isSubmitting}
          />
        </div>
      </div>
    </div>
  )
}

IsNotProcessableForm.propTypes = exact(propTypes)
IsNotProcessableForm.defaultProps = defaultProps

export default React.memo(IsNotProcessableForm)
