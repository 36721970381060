import React from 'react'
import exact from 'prop-types-exact'
import { ACPInputField, ACPDateInputField } from 'components'

const propTypes = {}

const defaultProps = {}

function TransactionConfirmationDetailsFields() {
  return (
    <div>
      <div className="row">
        <div className="one-half column">
          <ACPDateInputField
            name="transactionConfirmationDetails.requestDate"
            label="Request Date"
            placeholderText="mm/dd/yyyy"
            readOnly
          />
        </div>
        <div className="one-half column">
          <ACPDateInputField
            name="transactionConfirmationDetails.exchangeEffectiveDate"
            label="Exchange Effective Date"
            tooltipContent="The date the exchange is effective."
          />
        </div>
      </div>
      <div className="row">
        <div className="twelve columns">
          <ACPInputField
            name="transactionConfirmationDetails.investorRegistration"
            label="Investor Registration"
            tooltipContent="The full name of the registered investor, including Custodian Name, Client Name, Account Type, and Account Number."
            disabled={true}
          />
        </div>
      </div>
    </div>
  )
}

TransactionConfirmationDetailsFields.propTypes = exact(propTypes)
TransactionConfirmationDetailsFields.defaultProps = defaultProps

export default React.memo(TransactionConfirmationDetailsFields)
