import React, { useState } from 'react'
import exact from 'prop-types-exact'
import PropTypes, { oneOfType } from 'prop-types'
import {
  CollapsableCardContainer,
  RoleDefinitionModal,
  ACPInputField,
  MultiSelect,
} from 'components'
import { withFormikAdapter } from 'utils'
import { Checkbox as BaseCheckbox } from 'lp-components'
import { isEmpty, map, truncate } from 'lodash'
import { ROLE_DEFINITION } from 'config'
import { useFormikContext } from 'formik'
import { components } from 'react-select'
import classnames from 'classnames'
import pluralize from 'pluralize'

const Checkbox = withFormikAdapter()(BaseCheckbox)

const propTypes = {
  assetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  arrayHelpers: PropTypes.object.isRequired,
  role: oneOfType([PropTypes.string, PropTypes.object]),
  rolesList: PropTypes.arrayOf(PropTypes.object).isRequired,
  roleOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  defaultChooseRole: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
}

const defaultProps = {}

const ColumnFull = ({ children }) => (
  <div className="twelve columns">{children}</div>
)

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          disabled={props.isDisabled}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.role}</span>
  </components.MultiValue>
)

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props
  const values = getValue()

  const valuesWithoutAll = values.filter((value) => value.value !== '*')
  const numberOfValues = valuesWithoutAll.length
  const isSelectAll = values[0] === '*'

  const valueMessage = hasValue
    ? valuesWithoutAll
        .slice(0, 3)
        .map((value) => truncate(value.label, { length: 24 }))
        .join(', ')
    : null

  const trailingMessage =
    numberOfValues > 3 &&
    `, ...${numberOfValues - 3} other ${pluralize(
      'item',
      numberOfValues - 3
    )} selected`

  return (
    <components.ValueContainer {...props}>
      {isSelectAll && 'All Assets Selected'}
      {!isSelectAll && valueMessage && valueMessage}
      {!isSelectAll && trailingMessage && trailingMessage}
      {children}
    </components.ValueContainer>
  )
}

function RoleAndAssetAssignmentFormFields({
  assetOptions,
  index,
  arrayHelpers,
  role,
  rolesList,
  roleOptions,
  defaultChooseRole,
  currentUser,
}) {
  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const isRoleNotSelected = isEmpty(role?.role)
  const canAddRole = index === rolesList.length - 1 // only the last card can add new role
  const canRemoveRole = rolesList.length > 1 || index !== 0

  const formikContext = useFormikContext()

  const handleRemoveRole = () => {
    arrayHelpers.remove(index)
  }

  const handleAddRole = () => {
    setIsExpanded(false)
    arrayHelpers.push(defaultChooseRole) // add new choose role to end of list
  }

  const getAvailableRoles = () => {
    const currentRole = role.role === 'Choose Role' ? '' : role.role
    const activeRoles = map(rolesList, 'role')

    const availableRoles = roleOptions.filter(
      (roleOption) =>
        (!activeRoles.includes(roleOption) && roleOption !== 'Choose Role') ||
        roleOption === currentRole
    )

    return availableRoles
  }

  const checkForManagingMember = (availableRolesArray) => {
    if (availableRolesArray.includes('Managing Member')) {
      const formattedRoles = availableRolesArray.filter(
        (role) => role !== 'Managing Member'
      )
      return formattedRoles
    } else {
      let formattedRoles = availableRolesArray
      return formattedRoles
    }
  }

  const formattedAvailableRoles = checkForManagingMember(getAvailableRoles())

  const checkForAdminContact = (availableRolesArray) => {
    if (
      availableRolesArray.includes('Administrative Contact') &&
      !currentUser.roleNames.find(
        (roleName) => roleName.role == 'Managing Member'
      )
    ) {
      const formattedRoles = availableRolesArray.filter(
        (role) => role !== 'Administrative Contact'
      )
      return formattedRoles
    } else {
      let formattedRoles = availableRolesArray
      return formattedRoles
    }
  }

  const availableRolesFormatted = checkForAdminContact(formattedAvailableRoles)

  const anyErrorsMessage =
    !isEmpty(formikContext.errors?.roles?.[index]) &&
    !isEmpty(formikContext.touched?.roles?.[index])
      ? 'Please review assignments'
      : ''

  const disableAddRole =
    isEmpty(role?.role) ||
    isEmpty(role?.assignments) ||
    roleOptions.length === rolesList.length

  const handleCollapse = () => {
    formikContext.setFieldTouched(`roles[${index}].role`, true)
    formikContext.setFieldTouched(`roles[${index}].assignments`, true)
  }

  const handleToggle = () => {
    setIsExpanded(!isExpanded)
    handleCollapse()
  }

  const assignmentsHasError =
    !isEmpty(formikContext.errors?.roles?.[index]?.assignments) &&
    formikContext.touched?.roles?.[index]?.assignments

  return (
    <CollapsableCardContainer
      className="add-contact-roles-card"
      errorMessage={anyErrorsMessage}
      label={role?.role || 'Choose Role'}
      isExpanded={isExpanded}
      onToggle={handleToggle}
    >
      <div className="row">
        <ColumnFull>
          <ACPInputField
            name={`roles[${index}].role`}
            label={'Role(s)'}
            selectOptions={availableRolesFormatted}
            placeholder="Select Role"
          />
          {role?.role == 'Administrative Contact' && (
            <div className="add-contacts-checkbox">
              <div className="checkbox-line-item">
                <Checkbox
                  name={`roles[${index}].isAuthorizedSigner`}
                  label="By checking this box, the issuer authorizes the Administrative Contact listed above to sign all agreements, forms, and other documents related to the (asset/investment) on behalf of the issuer."
                />
              </div>
            </div>
          )}
          <span>
            To learn more about roles,{' '}
            <button
              className="modal-button-link"
              type="button"
              onClick={() => setShowRoleDefinitionModal(true)}
            >
              click here.
            </button>
          </span>
        </ColumnFull>
      </div>
      <div className="row">
        <ColumnFull>
          <MultiSelect
            role={role}
            rolesList={rolesList}
            label="Asset Assignment(s)"
            name={`roles[${index}].assignments`}
            options={assetOptions}
            onBlur={formikContext.setFieldTouched}
            components={{
              Option,
              MultiValue,
              ValueContainer,
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            overridePlaceholder={
              role?.assignments?.length === 0 && 'Search Assets'
            }
            hasError={assignmentsHasError}
            disabled={isRoleNotSelected}
            index={index}
          />
        </ColumnFull>
      </div>
      <div className="add-another-role-row">
        <div>
          {canRemoveRole && (
            <button
              type="button"
              className="button-primary"
              onClick={handleRemoveRole} // remove a role from the list
            >
              Remove Role
            </button>
          )}
          {canAddRole && (
            <button
              type="button"
              className={classnames('add-role-button button-primary', {
                'is-disabled': disableAddRole,
              })}
              onClick={handleAddRole} // insert an empty string at a position
              disabled={disableAddRole}
            >
              <span aria-hidden="true">+ </span>Add Another Role
            </button>
          )}
        </div>
      </div>
      {showRoleDefinitionModal && (
        <RoleDefinitionModal
          onClose={() => setShowRoleDefinitionModal(false)}
          title="Definition of Roles"
          roles={ROLE_DEFINITION}
        />
      )}
    </CollapsableCardContainer>
  )
}

RoleAndAssetAssignmentFormFields.propTypes = exact(propTypes)
RoleAndAssetAssignmentFormFields.defaultProps = defaultProps

export default React.memo(RoleAndAssetAssignmentFormFields)
