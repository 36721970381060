import React from 'react'
import exact from 'prop-types-exact'
import AlertImage from 'images/alert.svg'
import { useLocation } from 'react-router-dom'

const propTypes = {}

const defaultProps = {}

function ErrorPage() {
  const { state } = useLocation()
  return (
    <div className="error-page">
      <img src={AlertImage} alt="Alert" />
      <h4>{state.errorMessage}</h4>
      <a className="button button-primary" href="/">
        Return To Dashboard
      </a>
    </div>
  )
}

ErrorPage.propTypes = exact(propTypes)
ErrorPage.defaultProps = defaultProps

export default React.memo(ErrorPage)
