import React from 'react'
import PropTypes from 'prop-types'
import * as Types from 'types'
import exact from 'prop-types-exact'
import { CategoryStatusBreadcrumbTab } from 'components'
import { last } from 'lodash'

const propTypes = {
  stages: PropTypes.arrayOf(Types.stage).isRequired,
  currentStage: PropTypes.string.isRequired,
  disableNavigation: PropTypes.bool,
}

const defaultProps = {
  disableNavigation: false,
}

const getStageStepByName = (stages, name) => {
  return stages.findIndex((stage) => stage.name === name)
}
const isStageComplete = (stages, stageName, currentStageStep) => {
  const step = getStageStepByName(stages, stageName)
  return step < currentStageStep
}
const isStageActive = (stages, stageName, currentStageStep) => {
  const step = getStageStepByName(stages, stageName)
  return step <= currentStageStep
}

function FlowStatusTracker({ stages, currentStage, disableNavigation }) {
  const currentStageStep = getStageStepByName(stages, currentStage)
  return (
    <div className="status-tracker-wrapper no-print">
      {stages.map((stage) => (
        <CategoryStatusBreadcrumbTab
          key={stage.name}
          visited={isStageActive(stages, stage.name, currentStageStep)}
          complete={isStageComplete(stages, stage.name, currentStageStep)}
          label={stage.label}
          pathname={
            stage.routes.find((route) => !route.preventNavigate).pathname
          }
          finalStage={last(stages) === stage}
          disableNavigation={disableNavigation}
        />
      ))}
    </div>
  )
}

FlowStatusTracker.propTypes = exact(propTypes)
FlowStatusTracker.defaultProps = defaultProps

export default React.memo(FlowStatusTracker)
