import { camelCase } from 'lodash'

/* Given an array of statuses, create an object with camelized keys
  example:
  statuses = [ 'Pending Review', 'Confirmed' ]
  statusList = { pendingReview: 'Pending Review', confirmed: 'Confirmed' }
*/

function createStatusList(statuses) {
  return statuses.reduce((acc, status) => {
    acc[camelCase(status)] = status
    return acc
  }, {})
}

export default createStatusList
