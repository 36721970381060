import React, { useEffect } from 'react'
import exact from 'prop-types-exact'
import { NestedTransactionsTable } from '../components'

const propTypes = {}

const defaultProps = {}

function AllTransactions() {
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])
  return (
    <div>
      <NestedTransactionsTable />
    </div>
  )
}

AllTransactions.propTypes = exact(propTypes)
AllTransactions.defaultProps = defaultProps

export default React.memo(AllTransactions)
