import React, { useState } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as routerSelectors from 'connected-react-router'
import PropTypes from 'prop-types'
import { NavLink, useHistory } from 'react-router-dom'
import ContactModal from 'components/ContactModal'

const propTypes = {
  children: PropTypes.node.isRequired,
  pathname: PropTypes.string.isRequired,
  forDownload: PropTypes.bool.isRequired,
  defaultPath: PropTypes.string.isRequired,
}

const defaultProps = {}

function UploadDocuments() {
  const history = useHistory()
  return (
    <button
      className="button-primary documents"
      onClick={() => {
        history.push({
          pathname: '/documents/add-document/new',
          state: {
            target: location.pathname,
            goBackDashboard: true,
            backTo: 'Documents',
          },
        })
      }}
    >
      Upload Documents
    </button>
  )
}

function Layout({ children, pathname, defaultPath, forDownload }) {
  const [modalShown, setModalShown] = useState(false)
  const uploadPath = '/documents/uploads'

  return !forDownload ? (
    <div className="documents-layout">
      <div className="assets-navigation-menu">
        <NavLink
          to="/documents/available-documents"
          isActive={(match) => {
            if (match) return true
            return pathname === defaultPath
          }}
        >
          Available to Download
        </NavLink>
        <NavLink to="/documents/uploads">My Uploads</NavLink>
        {pathname === uploadPath && <UploadDocuments />}
      </div>

      <div className="navigation-line"></div>
      {modalShown && <ContactModal onClose={() => setModalShown(false)} />}
      {children}
    </div>
  ) : (
    <div className="content-wrapper">{children}</div>
  )
}

Layout.propTypes = propTypes
Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    pathname: routerSelectors.getLocation(state).pathname,
  }
}

const mapDispatchToProps = {}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
