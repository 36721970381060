import React from 'react'
import { ExternalLink } from 'components'
import { YoutubeVideo } from '../components'
import PropTypes from 'prop-types'
import moment from 'moment'
import NewIcon from 'images/new_callout.svg'

const propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  length: PropTypes.string.isRequired,
  uploadDate: PropTypes.string.isRequired,
}

const defaultProps = {}

function ResourceCard({ url, title, description, length, uploadDate }) {
  const isOld = moment(uploadDate).isBefore(moment().subtract(90, 'd'))

  return (
    <div className="resources-card-container">
      <div className="resources-card-video">
        <YoutubeVideo url={url} />
      </div>
      <div className="resources-card-header">
        <ExternalLink link={url} className="youtube-link">
          {title}
        </ExternalLink>
      </div>
      <div className="resources-card-content">
        <p>{description}</p>
      </div>
      <div className="resources-card-footer">
        <p>{length}</p>
        {!isOld ? <img src={NewIcon} className="logo" alt="Logo" /> : ''}
      </div>
    </div>
  )
}

ResourceCard.propTypes = propTypes

ResourceCard.defaultProps = defaultProps

export default ResourceCard
