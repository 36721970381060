import React, { useEffect } from 'react'

import { compose } from 'recompose'
import { connect } from 'react-redux'
import { SignatureForm } from '../forms'
import { useHistory } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import PropTypes from 'prop-types'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { selectors as globalSelectors } from 'global-reducer'

const propTypes = {
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  fundingInstructions: PropTypes.object,
  updateFundingInstructions: PropTypes.func.isRequired,
  fundingInstructionsPayload: PropTypes.object,
  fetchTasks: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.object),
  updateTask: PropTypes.func.isRequired,
}

const defaultProps = {}

function ESign({
  flashSuccessMessage,
  flashErrorMessage,
  fundingInstructions,
  updateFundingInstructions,
  fundingInstructionsPayload,
  tasks,
  fetchTasks,
  updateTask,
}) {
  const history = useHistory()

  useEffect(() => {
    fetchTasks()
  }, [])

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const instructionName = fundingInstructionsPayload?.isACH
    ? ' - ACH Instruction'
    : fundingInstructionsPayload?.isCheck
    ? ' - Check Instruction'
    : fundingInstructionsPayload?.isBankTransfer
    ? ' - Bank Transfer Instruction'
    : ''

  const handleESign = async (params) => {
    const payload = {
      assetID: fundingInstructions?.assetID,
      name: fundingInstructions?.name,
      fundingInstructions: fundingInstructionsPayload,
      instructionName: instructionName,
      eSign: {
        FirstName: params.firstName,
        LastName: params.lastName,
        Title: params.title,
        Date: Date.now(),
      },
    }
    await updateFundingInstructions(payload)
  }

  return (
    <div className="flow-card-container">
      <div className="card funding-esign-card">
        <div className="form-card-content esign-card-content">
          <div className="funding-esign-form-header-wrapper">
            <h4 className="funding-esign-form-header">Review & Submit</h4>
            <p className="funding-esign-form-header-text">
              Review the acknowledgments, electronically sign and submit to
              confirm the update the funding instructions for{' '}
              <b>{fundingInstructions?.assetName}</b>.
            </p>
          </div>
          <div className="funding-acknowledgement-subheader">
            <h4 className="funding-acknowledgement-subheader-text">
              Acknowledgments
            </h4>
            <p className="funding-acknowledgement-subheader-summary">
              Please review the following acknowledgments below and e-sign at
              the bottom.
            </p>
          </div>
          <ul className="funding-confirm-list">
            <li className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I understand that by changing or adding the funding instructions
                for this asset, all current and future funds from Inspira or an
                associated Third-Party Affiliate will be disbursed via the most
                current instructions on file.
              </div>
            </li>
            <li className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I attest by signing below, the funding instructions are correct,
                and I am authorized to make these changes in accordance with my
                organizational structure.
              </div>
            </li>
          </ul>
        </div>
        <hr />
        <div>
          <SignatureForm
            className="esign-form"
            handleSubmit={async (params) => {
              try {
                await handleESign(params)
                var task = tasks.find(
                  (task) => task.recordID === fundingInstructions?.assetID
                )

                if (task?.taskID && task?.subType === 'Funding') {
                  await updateTask([
                    { taskID: task?.taskID, status: 'Completed' },
                  ])
                }
                flashSuccessMessage(
                  `You updated the funding instructions for ${fundingInstructions.name}.`
                )
                history.push('/assets')
              } catch (e) {
                flashErrorMessage(
                  e.errors.message || 'Something went wrong, please try again.'
                )
              } finally {
                history.push('/assets')
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

ESign.propTypes = propTypes
ESign.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    fundingInstructions: selectors.fundingInstructions(state),
    fundingInstructionsPayload: globalSelectors.fundingInstructionsPayload(
      state
    ),
    tasks: globalSelectors.tasks(state),
  }
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  fetchTasks: apiActions.fetchTasks,
  updateTask: apiActions.updateTask,
  updateFundingInstructions: apiActions.updateAssetFundingInstructions,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ESign)
