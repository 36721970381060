import { useEffect } from 'react'
import { useFormikContext } from 'formik'

const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = '', result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key]
      if (!value) return

      const nextKey = prefix ? `${prefix}.${key}` : key
      if (typeof value === 'object') {
        transformObjectToDotNotation(value, nextKey, result)
      } else {
        result.push(nextKey)
      }
    })

    return result
  }

  return transformObjectToDotNotation(formikErrors)
}

const ScrollToFieldError = () => {
  const { submitCount, isValid, errors, isSubmitting } = useFormikContext()
  useEffect(() => {
    if (isValid) return

    const fieldErrorNames = getFieldErrorNames(errors)

    if (fieldErrorNames.length <= 0) return

    const element = document.querySelector(
      `input[name='${fieldErrorNames[0]}']`
    )

    if (element && element.type !== 'file' && element.type !== 'hidden') {
      setTimeout(
        element.scrollIntoView({ behavior: 'smooth', block: 'center' }),
        0
      )
    } else {
      const container = document.querySelector(
        `div[name='${fieldErrorNames[0]}']`
      )
      if (!container) return

      setTimeout(
        container.scrollIntoView({ behavior: 'smooth', block: 'center' }),
        0
      )
    }
  }, [submitCount, isSubmitting])

  return null
}

export default ScrollToFieldError
