import React, { useState, useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as actions from '../actions'
import { selectors } from '../reducer'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { useHistory } from 'react-router-dom'
import {
  Card,
  CurrencyInput,
  LabelWithTooltip,
  ACPDateInputField,
  ACPInputField,
} from 'components'
import {
  SelectedClientBadge,
  InsufficientFundsModal,
  FlowActions,
} from '../components'
import { Formik, Form } from 'formik'
import { isEmpty } from 'lodash'
import * as Yup from 'yup'
import currency from 'currency.js'

const propTypes = {
  storeCapitalCommitment: PropTypes.func,
  capitalCommitment: PropTypes.object,
}

const defaultProps = {}

function CapitalCallAmount({ capitalCommitment, storeCapitalCommitment }) {
  const history = useHistory()
  const selectedClients = capitalCommitment?.selectedClients
  const numClients = selectedClients?.length
  const singleClientSelected = numClients === 1

  const [submitting, setSubmitting] = useState(false)
  const [error, setError] = useState()
  const [insufficientFundsModalOpen, setInsufficientFundsModalOpen] = useState(
    false
  )

  useEffect(() => {
    if (isEmpty(capitalCommitment?.selectedClients)) {
      history.push('investor-selection')
    }
  }, [capitalCommitment])

  /* eslint-disable */
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])
  /* eslint-enable */

  const totalBalanceOfSelectedClients = selectedClients?.reduce(
    (accumulator, client) => currency(client.balanceAmount).value + accumulator,
    0
  )

  const initialValues = {
    dueDate: '',
    capitalCallPercentage: '',
    capitalCallDollarAmount: '',
    capitalCallAmount: '',
  }

  const capitalCallAmountValidationSchema = Yup.object().shape(
    {
      capitalCallPercentage: Yup.string()
        .when('capitalCallDollarAmount', {
          is: (value) => !isEmpty(value),
          then: Yup.string().notRequired(),
          otherwise: Yup.string()
            .required('Required')
            .test(
              'between 0 and 100',
              `Percentage must be between 0 and 100`,
              (value) => parseFloat(value) > 0 && parseFloat(value) <= 100
            ),
        })
        .nullable(),
      capitalCallDollarAmount: Yup.string().when('capitalCallPercentage', {
        is: (value) => isEmpty(value) && singleClientSelected,
        then: Yup.string().required('Required'),
      }),
      dueDate: Yup.date()
        .min(new Date(Date.now() - 864e5), 'Due Date cannot be in the past')
        .required('Required'),
    },
    ['capitalCallPercentage', 'capitalCallDollarAmount']
  )

  const handleSubmit = async (values) => {
    setSubmitting(true)

    const capitalCallPercentage = values.capitalCallPercentage / 100
    const capitalCallAmount = values.capitalCallDollarAmount

    const childAccounts = selectedClients.map((client) => ({
      childInvestmentID: '',
      clientAccountID: client.clientAccountID,
      dueDate: values.dueDate,
      // if given a purchase amount take that, otherwise calculate by percentage
      requestedAmount: capitalCallAmount
        ? currency(capitalCallAmount).value.toFixed(2)
        : currency(client.balanceAmount)
            .multiply(capitalCallPercentage)
            .value.toFixed(2),
      percentage: capitalCallPercentage ? capitalCallPercentage * 100 : null,
    }))

    const payload = {
      parentInvestmentID: '',
      assetID: capitalCommitment.assetID,
      assetName: capitalCommitment.assetName,
      dueDate: values.dueDate,
      capitalCallPercentage: capitalCallPercentage,
      capitalCallDollarAmount: values.capitalCallDollarAmount,
      capitalCallAmount: values.capitalCallAmount,
      childAccounts: [...childAccounts],
    }

    try {
      storeCapitalCommitment({ ...payload, selectedClients })
      history.push('call-letter-and-esign')
    } catch (e) {
      setError(e)
    }
  }

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="flow-card-container">
      <Card label={capitalCommitment?.assetName}>
        <div className="client-badges">
          <ul>
            {selectedClients?.map((client) => (
              <SelectedClientBadge
                key={client.assetID}
                clientName={client.clientAccountName}
                totalAmount={client.balanceAmount}
              />
            ))}
          </ul>
        </div>
        <div className="capital-call-form">
          <Formik
            onSubmit={handleSubmit}
            validationSchema={capitalCallAmountValidationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
          >
            {({ setFieldValue, values, resetForm }) => {
              return (
                <>
                  <Form>
                    <div className="capital-call-form-container">
                      <div className="form-card-content">
                        <div className="row">
                          <div className="column">
                            <ACPInputField
                              name="holdBack"
                              label="Is the capital call a percentage or dollar amount? *"
                              onChange={() => {
                                setFieldValue('capitalCallDollarAmount', '')
                                setFieldValue('capitalCallPercentage', '')
                              }}
                              radioOptions={[
                                {
                                  key: 'Percentage',
                                  value: 'holdbackPercentage',
                                },
                                {
                                  key: 'Dollar Amount',
                                  value: 'holdbackAmount',
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="one-half cap-call-half-col column">
                            {values.holdBack === 'holdbackPercentage' && (
                              <>
                                <CurrencyInput
                                  name="capitalCallPercentage"
                                  label="Percentage *"
                                  prefix={null}
                                  suffix="%"
                                  labelComponent={LabelWithTooltip}
                                  tooltipContent="Percentage of capital to be called in relation to the investor’s balance committed to the asset."
                                  onChange={(e) => {
                                    const capitalCallPercentage =
                                      parseFloat(e.target.value) / 100

                                    const sum =
                                      totalBalanceOfSelectedClients *
                                      capitalCallPercentage

                                    if (isEmpty(e.target.value)) {
                                      setFieldValue('capitalCallAmount', '')
                                    } else {
                                      setFieldValue('capitalCallAmount', sum)
                                    }
                                  }}
                                />
                              </>
                            )}
                            {values.holdBack === 'holdbackAmount' && (
                              <CurrencyInput
                                name="capitalCallDollarAmount"
                                label="Dollar Amount *"
                                labelComponent={LabelWithTooltip}
                                tooltipContent="Specific dollar amount of capital to be called for the asset."
                              />
                            )}
                          </div>
                          <div className="one-half date-half-column column">
                            <ACPDateInputField
                              name="dueDate"
                              label="Due Date"
                              tooltipContent="The due date for the investor to submit the capital for the asset."
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <FlowActions
                      prevStep="investor-selection"
                      submitting={submitting}
                      onContinue={() => {
                        const capitalCallAmount = values.capitalCallDollarAmount

                        if (
                          capitalCallAmount >
                          currency(selectedClients[0].balanceAmount).value
                        ) {
                          setInsufficientFundsModalOpen(true)
                          resetForm()
                        }
                      }}
                    />
                  </Form>
                  {insufficientFundsModalOpen && (
                    <InsufficientFundsModal
                      onClose={() => setInsufficientFundsModalOpen(false)}
                      fieldName={'capitalCallDollarAmount'}
                      setFieldValue={setFieldValue}
                      resetForm={resetForm}
                    />
                  )}
                </>
              )
            }}
          </Formik>
        </div>
      </Card>
    </div>
  )
}

CapitalCallAmount.propTypes = exact(propTypes)
CapitalCallAmount.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    capitalCommitment: selectors.capitalCommitment(state),
  }
}

const mapDispatchToProps = {
  storeCapitalCommitment: actions.storeCapitalCommitment,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CapitalCallAmount
)
