import { usStateCodes } from 'config'
import { startCase } from 'lodash'

// takes a value given from BE, checks if it's a state full name and returns the full name
// else it checks if it's a state code and returns the full name if it is
// else it returns "" so that initial values will prepopulate to empty selection, raising validation

const arrayOfStateNames = Object.values(usStateCodes).map((name) =>
  name.toLowerCase()
)

const arrayOfStateCodes = Object.keys(usStateCodes).map((code) =>
  code.toLowerCase()
)

function convertUSStateCodeToFullName(value) {
  if (arrayOfStateNames.includes(value.toLowerCase())) {
    return startCase(value)
  }

  if (arrayOfStateCodes.includes(value.toLowerCase())) {
    return usStateCodes[value.toUpperCase()]
  } else {
    // if it's not a state code and not a valid state name, then it's bad data and should be cleared so dropdown will appear blank
    return ''
  }
}

export default convertUSStateCodeToFullName
