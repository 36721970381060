import React from 'react'
import exact from 'prop-types-exact'
import PropTypes from 'prop-types'

const propTypes = {
  api: PropTypes.object.isRequired,
  color: PropTypes.string,
  rowIndex: PropTypes.number.isRequired,
}

const defaultProps = {
  color: 'white',
}

function CustomAGCellTooltip({ color, api, rowIndex }) {
  const data = api.getDisplayedRowAtIndex(rowIndex).data
  return (
    <div style={{ backgroundColor: color }}>
      <p>{data}</p>
    </div>
  )
}

CustomAGCellTooltip.propTypes = exact(propTypes)
CustomAGCellTooltip.defaultProps = defaultProps

export default CustomAGCellTooltip
