import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { getMatchedKeyReset } from 'utils'
import { keys } from 'lodash'
import { TitleWithTooltip, ACPInputField } from 'components'
import ContactSearchAndSelect from './ContactSearchAndSelect'
import * as Types from 'types'

const propTypes = {
  values: PropTypes.object.isRequired,
  resetForm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keyName: PropTypes.string.isRequired,
  conditionalKey: PropTypes.string.isRequired,
  customEqualValueSuffix: PropTypes.string,
  contactsList: PropTypes.arrayOf(Types.contact).isRequired,
  isNotEditable: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ConditionalContactSubForm({
  values,
  title,
  description,
  keyName,
  conditionalKey,
  customEqualValueSuffix,
  resetForm,
  contactsList,
  isNotEditable,
}) {
  const resetValues = {}
  keys(values.keyName).forEach((key) => (resetValues[key] = ''))
  const equalValueSuffix =
    customEqualValueSuffix ||
    `Equal${keyName.charAt(0).toUpperCase() + keyName.slice(1)}`
  const matchedKeyReset = getMatchedKeyReset(values, equalValueSuffix)
  return (
    <div className="asset-form asset-details-form contact-upload-container">
      <div className="container">
        <div className="additional-form-header">
          <TitleWithTooltip title={title || 'Contact'} content={description} />
        </div>
        <div className="row">
          <div className="twelve columns">
            <ACPInputField
              name={conditionalKey}
              radioOptions={[
                { key: 'Yes', value: 'true' },
                { key: 'No', value: 'false' },
              ]}
              label={`Is there a ${title}?`}
              onChange={(e) => {
                // get keys containing equal[keyName] and clear'
                if (e.target.value === 'false') {
                  resetForm({
                    values: {
                      ...values,
                      [keyName]: {
                        ...resetValues,
                      },
                      [conditionalKey]: e.target.value,
                      ...matchedKeyReset,
                    },
                  })
                }
              }}
              disabled={isNotEditable}
            />
          </div>
        </div>

        {values[conditionalKey] === 'true' && (
          <ContactSearchAndSelect
            contactType={keyName}
            isNotEditable={isNotEditable}
            contactsList={contactsList}
          />
        )}
      </div>
    </div>
  )
}

ConditionalContactSubForm.propTypes = exact(propTypes)
ConditionalContactSubForm.defaultProps = defaultProps

export default React.memo(ConditionalContactSubForm)
