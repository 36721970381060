import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
}

const defaultProps = {}

function ContactModal({ onClose }) {
  return (
    <Modal onClose={onClose}>
      <h2>Contact Us</h2>
      <p>
        Questions? We're here to help. Our Client Service team can be reached
        Monday - Friday 7:00 AM - 6:00 PM C.T. at (800) 258-7878.
      </p>
      <hr></hr>
      <div className="button-wrapper">
        <button className="button-primary" onClick={onClose}>
          Close
        </button>
      </div>
    </Modal>
  )
}

ContactModal.propTypes = exact(propTypes)
ContactModal.defaultProps = defaultProps

export default React.memo(ContactModal)
