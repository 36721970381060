import React, { useRef, useMemo } from 'react'
import PropTypes, { oneOfType } from 'prop-types'
import exact from 'prop-types-exact'
//import { scrollToTop } from 'utils'
import { AgGridReact } from 'ag-grid-react'
import classNames from 'classnames'
import { TABLE_NO_ROWS_TEMPLATE } from '../config'

const propTypes = {
  columnDefs: oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  rowData: PropTypes.array.isRequired,
  onSelectionChanged: PropTypes.func,
  additionalDefaultColDef: PropTypes.object,
  rowSelection: PropTypes.string,
  pagination: PropTypes.bool,
  overrideGridStyle: PropTypes.object,
  overrideGridRef: PropTypes.object,
  overrideOnGridReady: PropTypes.func,
  isAnyFilterPresent: PropTypes.bool,
  rowHeight: PropTypes.number,
  additionalClassName: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  paginationPageSize: PropTypes.string,
  domLayout: PropTypes.string,
  isRowMaster: PropTypes.bool,
  overrideContainerStyle: PropTypes.string,
  containerClassName: PropTypes.string,
  onFirstDataRendered: PropTypes.func,
  masterDetail: PropTypes.bool,
  getContextMenuItems: PropTypes.array,
  detailCellRenderer: PropTypes.func,
  detailCellRendererParams: PropTypes.object,
  overlayNoRowsTemplate: PropTypes.string,
  overlayLoadingTemplate: PropTypes.string,
  overrideDefColWidth: PropTypes.number,
}

const defaultProps = {}

const containerStyle = { width: '100%', height: '100%' }
const gridStyle = { height: '100%', width: '100%' }

function ACPTable({
  columnDefs,
  rowData,
  onSelectionChanged = null,
  isAnyFilterPresent = false,
  additionalClassName = null,
  paginationPageSize = null,
  overrideGridStyle = null,
  overrideContainerStyle = null,
  containerClassName = null,
  onFirstDataRendered = null,
  overrideOnGridReady = null,
  overrideGridRef = null,
  pagination = true,
  rowSelection = 'single',
  rowHeight = '54',
  additionalDefaultColDef = {},
  domLayout = null,
  detailCellRenderer = null,
  getContextMenuItems = null,
  masterDetail = false,
  detailCellRendererParams = null,
  isRowMaster = false,
  overlayNoRowsTemplate = TABLE_NO_ROWS_TEMPLATE,
  overlayLoadingTemplate = null,
  overrideDefColWidth = null,
  children,
}) {
  const gridRef = useRef()

  const onPaginationChanged = () => {
    //scrollToTop()
  }

  const onGridReady = (params) => {
    if (!rowData.length) {
      params.api.showLoadingOverlay()
    }
  }

  const defaultColDef = useMemo(() => {
    const colMinWidth = overrideDefColWidth ? overrideDefColWidth : 100
    return {
      flex: 1,
      minWidth: colMinWidth,
      suppressMenu: true,
      resizable: true,
      ...additionalDefaultColDef,
    }
  }, [])

  return (
    <div
      className={containerClassName}
      style={overrideContainerStyle ? overrideContainerStyle : containerStyle}
    >
      <div
        style={overrideGridStyle ? overrideGridStyle : gridStyle}
        className={classNames('ag-theme-alpine', additionalClassName)}
      >
        {children}
        <AgGridReact
          accentedSort={true}
          cacheQuickFilter={true}
          columnDefs={columnDefs}
          domLayout={domLayout}
          defaultColDef={defaultColDef}
          onGridReady={overrideOnGridReady ? overrideOnGridReady : onGridReady}
          onPaginationChanged={onPaginationChanged}
          onFirstDataRendered={onFirstDataRendered}
          onSelectionChanged={onSelectionChanged}
          pagination={pagination}
          isAnyFilterPresent={isAnyFilterPresent}
          paginationPageSize={paginationPageSize}
          paginationAutoPageSize={paginationPageSize ? false : true}
          ref={overrideGridRef ? overrideGridRef : gridRef}
          rowHeight={rowHeight}
          rowData={rowData}
          detailRowAutoHeight={detailCellRenderer ? true : false}
          detailCellRenderer={detailCellRenderer}
          getContextMenuItems={getContextMenuItems}
          detailCellRendererParams={detailCellRendererParams}
          rowSelection={rowSelection}
          suppressContextMenu={true}
          suppressDragLeaveHidesColumns={true}
          suppressRowClickSelection={true}
          suppressRowHoverHighlight={true}
          masterDetail={masterDetail}
          tooltipShowDelay={0}
          isRowMaster={isRowMaster}
          overlayNoRowsTemplate={overlayNoRowsTemplate}
          overlayLoadingTemplate={overlayLoadingTemplate}
          overrideOnGridReady={true}
        ></AgGridReact>
      </div>
    </div>
  )
}

ACPTable.propTypes = exact(propTypes)
ACPTable.defaultProps = defaultProps

export default ACPTable
