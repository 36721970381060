import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Link } from 'react-router-dom'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import * as flashActions from 'redux-flash'
import { Modal, SubmitButton } from 'lp-components'
import { ACPInputField } from 'components'
import { cloneDeep } from 'lodash'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { formatReplacementNames } from 'utils'

const propTypes = {
  contactOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  reassignmentAsset: PropTypes.array.isRequired,
  contactID: PropTypes.string.isRequired,
  replaceContact: PropTypes.func.isRequired,
  setIsReadOnly: PropTypes.func.isRequired,
}
const defaultProps = {}

function AssetReassignmentModal({
  contactID,
  contactOptions,
  flashErrorMessage,
  flashSuccessMessage,
  onClose,
  onSubmit,
  reassignmentAsset,
  replaceContact,
  setIsReadOnly,
}) {
  const roleName = reassignmentAsset.role
  const totalRelationships = reassignmentAsset.relationships.length
  const isOneAsset = totalRelationships === 1

  const initialValues = {
    [formatReplacementNames(roleName)]: '',
    setToAll: '',
  }

  const handleReassignment = async (values, resetForm) => {
    let payload = []
    // For each contact that will inherit the new role's assets, find the
    // role in the roleNames array and set the relationshipID to an empty
    // string and the isRemoved property to false.

    Object.entries(values).map(([key, value]) => {
      if (key !== 'setToAll') {
        // For the list of options, find the option whose contact ID matches the
        // selected contact ID from the list. Then get it's account ID
        const replacementAccountID = contactOptions.find(
          (option) => option.value === value
        ).accountID
        const updatedReassignments = cloneDeep(reassignmentAsset)
        updatedReassignments.relationships.map((relationship) => {
          relationship.isRemoved = false
        })

        // Add the object for the contact that will inherit the deleted
        // contact's role and assets to the payload array
        payload.push({
          contactDetails: { accountID: replacementAccountID, contactID: value },
          roleNames: updatedReassignments,
        })
      }
    })

    try {
      await replaceContact(payload)
      flashSuccessMessage('The contact role assignments have been updated.')
      setIsReadOnly()
      onSubmit()
    } catch (e) {
      flashErrorMessage(e.message || 'Something went wrong, please try again.')
      resetForm({ ...initialValues })
    }
  }

  const lazyValidation = Yup.lazy(() => {
    const validationObj = {}

    if (isOneAsset) {
      validationObj['setToAll'] = Yup.bool().notRequired()
      validationObj[formatReplacementNames(roleName)] = Yup.string().required(
        `In order to continue, you are required to select a replacement ${roleName.toLowerCase()}.`
      )
    } else {
      validationObj['setToAll'] = Yup.bool()
        .required('Required')
        .nullable()
      validationObj[formatReplacementNames(roleName)] = Yup.string()
        .required(
          `In order to continue, you are required to select a replacement ${roleName.toLowerCase()}.`
        )
        .nullable()
    }

    const validationObject = Yup.object(validationObj)
    return validationObject
  })

  return (
    <Modal onClose={onClose}>
      <h4>{roleName}</h4>
      <p>
        {`${
          isOneAsset ? 'This asset needs' : 'These assets need'
        } a ${roleName}. Please select the new ${roleName}.`}
      </p>
      <button
        className="modal-close"
        type="button"
        onClick={() => {
          onClose()
        }}
      >
        ×
      </button>
      <Formik
        onSubmit={(values, { resetForm }) => {
          handleReassignment(values, resetForm)
        }}
        validationSchema={lazyValidation}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form>
            {!isOneAsset && (
              <ACPInputField
                name="setToAll"
                label={`Would you like to apply this to all ${totalRelationships} asset(s)?`}
                radioOptions={[
                  { key: 'Yes', value: true },
                  { key: 'No', value: false },
                ]}
                onChange={(e) => {
                  const isSetToAll = e.target.value
                  if (!isSetToAll) {
                    setFieldValue(formatReplacementNames(roleName), '', false)
                  }
                }}
              />
            )}
            {(isOneAsset || values.setToAll) && (
              <>
                <ACPInputField
                  key={roleName}
                  name={formatReplacementNames(roleName)}
                  label={`Contact to Replace ${roleName} Role `}
                  selectOptions={contactOptions}
                  enablePlaceholderOption={true}
                  placeholder="Select"
                />
                <p>
                  If you would like to add a contact that is not in the list
                  please{' '}
                  <Link
                    className="modal-button-link"
                    to="/contacts/add-contact/contact-information"
                  >
                    click here
                  </Link>
                </p>
              </>
            )}

            <hr />
            <div className="button-wrapper">
              <button
                className="button-secondary"
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
              {isOneAsset || values.setToAll ? (
                <SubmitButton
                  className="button-primary"
                  submitting={isSubmitting}
                >
                  Submit
                </SubmitButton>
              ) : (
                <Link
                  className="button-primary"
                  to={{
                    pathname: `/contact-role-replacements/${contactID}`,
                    state: {
                      reassignmentAsset,
                    },
                  }}
                >
                  Proceed to Select Role Assignment(s)
                </Link>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

AssetReassignmentModal.propTypes = exact(propTypes)
AssetReassignmentModal.defaultProps = defaultProps

function mapStateToProps(/*state*/) {
  return {}
}

const mapDispatchToProps = {
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  replaceContact: apiActions.replaceContact,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AssetReassignmentModal
)
