import { map } from 'lodash'
import getTaskType from './getTaskType'

/* Given a status object, create an array of options for radio group including the default option
  example:
  statusList = { pendingReview: 'Pending Review', confirmed: 'Confirmed' }
  statusOptions = [  { key: 'All', value: 'all' }, { key: 'Pending Review', value: 'pendingReview' }, { key: 'Confirmed', value: 'confirmed' }  ]
*/

function createStatusOptions(statusList) {
  const statusOptions = map(statusList, (displayStatus) =>
    getTaskType(displayStatus)
  )
  return [...statusOptions]
}

export default createStatusOptions
