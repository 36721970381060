import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router'
import * as Views from './views'
import Layout from './Layout'

const propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

const defaultProps = {}

function Routes({ match: { path }, location: { pathname } }) {
  const AddNewDocumentRoutes = ({ match: { path } }) => {
    return (
      <Switch>
        <Route exact path={path + '/new'} component={Views.AddNewDocument} />
        <Redirect path="*" to="/documents/available-documents" />
      </Switch>
    )
  }
  const forDownload = pathname.includes('/downloads')
  return (
    <>
      <Switch>
        <Route path={path + '/add-document'} component={AddNewDocumentRoutes} />
        <Layout forDownload={forDownload} defaultPath={path}>
          <Switch>
            <Route
              exact
              path={
                path +
                '/view/:transactionType/:transactionStatus/:transactionID/downloads'
              }
              component={Views.PendingDocuments}
            />
            <Route
              exact
              path={path + '/:transactionType/downloads/:transactionID'}
              component={Views.TransactionDocuments}
            />
            <Route exact path={path} component={Views.AvailableDocuments} />
            <Route
              exact
              path={path + '/available-documents'}
              component={Views.AvailableDocuments}
            />
            <Route exact path={path + '/uploads'} component={Views.MyUploads} />
            <Redirect from="*" to={path} />
          </Switch>
        </Layout>
      </Switch>
    </>
  )
}

Routes.propTypes = propTypes
Routes.defaultProps = defaultProps

export default Routes
