import React, { useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Formik, Form } from 'formik'
import {
  convertStringToFixedFloat,
  formatNumberAsCurrency,
  isProductOfPriceAndShares,
  preformInputOperation,
  sharesValidator,
} from 'utils'
import { FlowActions } from '../components'
import { map, toNumber } from 'lodash'
import * as Yup from 'yup'
import { VALUATION_METHODOLOGY, LIQUIDATION_TYPES } from 'config'
import {
  CurrencyInput,
  DocumentUploadInput,
  LabelWithTooltip,
  ACPInputField,
  ACPDateInputField,
} from 'components'

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  assetValuationDocument: PropTypes.object,
  shouldUploadDocument: PropTypes.bool,
}

const defaultProps = {}
function LiquidationDetailsForm({
  handleSubmit,
  initialValues,
  assetValuationDocument,
  shouldUploadDocument,
}) {
  const [holdbackAmountDisabled, setHoldbackAmountDisabled] = useState(false)
  const [holdbackPercentageDisabled, setHoldbackPercentageDisabled] = useState(
    false
  )

  const validationSchema = Yup.object().shape({
    valuationMethodology: Yup.string()
      .oneOf(map(VALUATION_METHODOLOGY, (option) => option.value))
      .required('Required'),
    shares: Yup.string().when('valuationMethodology', {
      is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
      then: Yup.string()
        .test('shares minimum', (val, { createError }) => {
          const isValidShares = sharesValidator(val)
          if (isValidShares) return true
          return createError({
            message: 'Shares total must be greater than 0.',
          })
        })
        .required('Required'),
      otherwise: Yup.string().notRequired(),
    }),
    price: Yup.string().when('valuationMethodology', {
      is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired(),
    }),
    fmvRedeem: Yup.string().when('valuationMethodology', {
      is: VALUATION_METHODOLOGY.PRICE_PER_SHARE.value,
      then: Yup.string().test(
        'total value test',
        (value, { parent, createError, path }) => {
          const fmvRedeem = toNumber(parent.fmvRedeem)
          const price = toNumber(parent.price)
          const shares = toNumber(parent.shares)
          const isValidTotal = isProductOfPriceAndShares(
            fmvRedeem,
            price,
            shares
          )
          if (isValidTotal) {
            return true
          }
          return createError({
            message: `This must equal the Total Redemption Value (= shares x price) ${formatNumberAsCurrency(
              toNumber(parent.totalRedemptionValue)
            )}.`,
            path,
          })
        }
      ),
      otherwise: Yup.string().required('Required'),
    }),
    holdBack: Yup.string().required('Required'),
    holdBackPercentage: Yup.string().when('holdBack', {
      is: 'holdBackPercentage',
      then: Yup.string()
        .test('less_than_one_hundred', '', (value, context) => {
          const { createError } = context
          if (value <= 100) return true
          return createError({
            message:
              'Holdback Percentage must be less than or equal to 100% to proceed.',
          })
        })
        .required('Required'),
      otherwise: Yup.string().notRequired(),
    }),
    holdBackAmount: Yup.string().when('holdBack', {
      is: 'holdBackAmount',
      then: Yup.string()
        .test(
          'holdback amount not greater than fmv',
          (val, { createError }) => {
            const fmvRedeem = toNumber(initialValues.fmvRedeem)
            if (val <= fmvRedeem) return true
            return createError({
              message:
                'The Holdback Amount must be less than or equal to the FMV Redeemed.',
            })
          }
        )
        .required('Required'),
      otherwise: Yup.string().notRequired(),
    }),
    assetValuationDocument: Yup.object().when('liquidationType', {
      is: LIQUIDATION_TYPES.Full,
      then: Yup.object().nullable(),
    }),
  })

  return (
    <div className="flow-card-container">
      <Formik
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        enableReinitialize={true}
      >
        {({ isSubmitting, setFieldValue, values, touched, errors }) => {
          const valuationMethodologyOptions = map(VALUATION_METHODOLOGY)
          const isPricePerShare =
            values.valuationMethodology ===
            VALUATION_METHODOLOGY.PRICE_PER_SHARE.value

          return (
            <Form>
              <div className="card">
                <div className="form-card-content">
                  <h4 className="flow-section-header">Liquidation Details</h4>
                  <div className="row">
                    <div className="twelve columns">
                      <ACPInputField
                        name="clientFBOName"
                        label="Investor Registration"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div>
                      <ACPInputField name="assetName" disabled={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="one-half column">
                      <ACPInputField
                        name="liquidationType"
                        disabled={true}
                        label="Liquidation Type"
                        tooltipContent="Either partial or full liquidation of the investor's holding in the asset."
                      />
                    </div>
                    <div className="one-half column">
                      <ACPInputField
                        name="liquidationAmountRequested"
                        disabled={true}
                        label="Liquidation Amount Requested"
                        tooltipContent="The dollar amount requested for the liquidation."
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="one-half column">
                      <ACPDateInputField
                        name="requestDate"
                        label="Transaction Date"
                        tooltipContent="The date that Inspira processed the transaction."
                        parse={(val) => moment(val).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </div>
                    <div className="one-half column">
                      <ACPDateInputField
                        name="effectiveDate"
                        label="Effective Date"
                        tooltipContent="This is the date that the investment sponsor executed the transaction on behalf of the investor."
                        parse={(val) => moment(val).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="one-half column">
                      <ACPDateInputField
                        name="sponsorExpectedFundsDate"
                        label="Expected Funds Remittance Date"
                        tooltipContent="The date Inspira is expected to receive the proceeds from the liquidation."
                        parse={(val) => moment(val).format('MM/DD/YYYY')}
                        disabled={true}
                      />
                    </div>
                    <div className="one-half column">
                      <ACPInputField
                        name="sponsorPaymentMethod"
                        label="Anticipated Payment Method"
                        tooltipContent="The payment method for the liquidation."
                        disabled={true}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="one-half column">
                      <CurrencyInput
                        name="fmvRedeem"
                        label="FMV Redeemed ($) *"
                      />
                    </div>
                    <div className="one-half column">
                      <LabelWithTooltip
                        label="Valuation Method"
                        tooltipContent="This is how the asset’s value is reflected on the investment sponsor’s books and records. It may be the number of shares times the Price Per Share or
                        Capital Value (i.e., total dollar value of the asset)."
                      ></LabelWithTooltip>
                      <ACPInputField
                        name="valuationMethodology"
                        label={false}
                        radioOptions={valuationMethodologyOptions}
                      />
                    </div>
                  </div>
                  {isPricePerShare && (
                    <div className="row">
                      <div className="one-half column">
                        <ACPInputField
                          type="number"
                          name="shares"
                          label="Shares Redeemed"
                          onChange={(e) => {
                            const totalValue = preformInputOperation(
                              e.target.value,
                              values.price,
                              'multiply'
                            )
                            setFieldValue(
                              'totalRedemptionValue',
                              totalValue,
                              false
                            )
                          }}
                          onBlur={(e) => {
                            const num = convertStringToFixedFloat(
                              e.target.value,
                              4
                            )
                            setFieldValue('shares', num)
                          }}
                          maskOptions={{
                            numeral: true,
                            numeralDecimalScale: 4,
                            numeralPositiveOnly: true,
                          }}
                        />
                      </div>
                      <div className="one-half column">
                        <CurrencyInput
                          name="price"
                          label="Price *"
                          onChange={(e) => {
                            const totalValue = preformInputOperation(
                              e.target.value,
                              values.shares,
                              'multiply'
                            )
                            setFieldValue(
                              'totalRedemptionValue',
                              totalValue,
                              false
                            )
                          }}
                        />
                      </div>
                    </div>
                  )}

                  <div className="row">
                    {isPricePerShare && (
                      <div className="one-half column">
                        <CurrencyInput name="totalRedemptionValue" readOnly />
                      </div>
                    )}
                    <div className="column">
                      <ACPInputField
                        name="holdBack"
                        label="What type of holdback does this transaction have? *"
                        radioOptions={[
                          {
                            key: 'Holdback Percentage',
                            value: 'holdBackPercentage',
                          },
                          { key: 'Holdback Amount', value: 'holdBackAmount' },
                          { key: 'No Holdback', value: 'false' },
                        ]}
                        onChange={() => {
                          setFieldValue('holdBackPercentage', '')
                          setFieldValue('holdBackAmount', '')
                          setHoldbackAmountDisabled(false)
                          setHoldbackPercentageDisabled(false)
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {values.holdBack === 'holdBackPercentage' && (
                      <div className="one-half column">
                        <CurrencyInput
                          name="holdBackPercentage"
                          label={
                            holdbackPercentageDisabled
                              ? 'Holdback Percentage'
                              : 'Holdback Percentage *'
                          }
                          prefix={null}
                          suffix="%"
                          onChange={(e) => {
                            setHoldbackAmountDisabled(!!e.target.value)
                            setFieldValue('holdBackAmount', '')
                          }}
                          disabled={holdbackPercentageDisabled}
                        />
                      </div>
                    )}
                    {values.holdBack === 'holdBackAmount' && (
                      <div className="one-half column">
                        <CurrencyInput
                          name="holdBackAmount"
                          label={
                            holdbackAmountDisabled
                              ? 'Holdback Amount'
                              : 'Holdback Amount *'
                          }
                          onChange={(e) => {
                            setHoldbackPercentageDisabled(!!e.target.value)
                            setFieldValue('holdBackPercentage', '')
                          }}
                          disabled={holdbackAmountDisabled}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <ACPInputField
                      name="comments"
                      label="Comments Regarding this Liquidation"
                      textarea
                      required={false}
                    />
                  </div>
                  {shouldUploadDocument && (
                    <div className="row">
                      <hr />
                      <h4>Asset Valuation Upload</h4>
                      <p>Please provide the Asset Valuation documentation.</p>
                      <DocumentUploadInput
                        keyName="assetValuationDocument"
                        document={assetValuationDocument}
                        setFieldValue={setFieldValue}
                        error={
                          touched?.assetValuationDocument &&
                          errors?.assetValuationDocument
                            ? errors?.assetValuationDocument
                            : errors[assetValuationDocument?.requiredDocumentID]
                        }
                        hideLabel={true}
                      />
                    </div>
                  )}
                </div>

                <FlowActions submitting={isSubmitting} />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

LiquidationDetailsForm.propTypes = propTypes
LiquidationDetailsForm.defaultProps = defaultProps

export default LiquidationDetailsForm
