import { ascendingAlphaSort } from 'utils'
import { isEmpty } from 'lodash'

const getRemainingContactOptions = (contactDetails, contactsList) => {
  const normalizedAndSortedContactOptions = contactsList
    .map((contactItem) => {
      return {
        key:
          contactItem.contactDetails.firstName +
          ' ' +
          contactItem.contactDetails.lastName +
          ' ' +
          `${
            contactItem.contactDetails.email
              ? `(${contactItem.contactDetails.email})`
              : ''
          }`,
        value: contactItem.contactDetails.contactID,
        accountID: contactItem.contactDetails.accountID,
      }
    })
    .sort(ascendingAlphaSort('key'))

  if (isEmpty(contactDetails)) return normalizedAndSortedContactOptions
  return normalizedAndSortedContactOptions?.filter(
    (contactItem) => contactDetails.contactID !== contactItem.value
  )
}

export default getRemainingContactOptions
