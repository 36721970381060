import { TASK_TYPES, TASK_SUBTYPES, RESOLUTION_TYPE } from 'config'
import { startsWith } from 'lodash'

function mapTaskToType({ taskType, subType }) {
  return {
    isConfirmExchange:
      taskType === TASK_TYPES.CONFIRMATION &&
      startsWith(subType, TASK_TYPES.EXCHANGE),
    isConfirmLiquidation:
      taskType === TASK_TYPES.CONFIRMATION &&
      startsWith(subType, TASK_TYPES.LIQUIDATION),
    isConfirmPurchase:
      taskType === TASK_TYPES.CONFIRMATION &&
      subType === TASK_SUBTYPES.PURCHASE,
    isResolution:
      taskType === RESOLUTION_TYPE &&
      (subType !== TASK_SUBTYPES.VALUATION ||
        subType !== TASK_SUBTYPES.FUNDING ||
        subType !== TASK_SUBTYPES.CONTACT),
    isValuation:
      taskType === RESOLUTION_TYPE && subType === TASK_SUBTYPES.VALUATION,
    isFunding:
      taskType === RESOLUTION_TYPE && subType === TASK_SUBTYPES.FUNDING,
    isSubmitAsset:
      taskType === TASK_TYPES.PENDING_ASSET_SUBMISSION ||
      taskType === TASK_TYPES.ASSET_RESOLUTION,
    isContactReview:
      taskType === RESOLUTION_TYPE && subType === TASK_SUBTYPES.CONTACT,
  }
}

export default mapTaskToType
