import React, { useEffect } from 'react'
import exact from 'prop-types-exact'
import { useLocation, useParams } from 'react-router-dom'
import { ContactIndividualReplacementsForm } from '../forms'
import { isEmpty } from 'lodash'
import { Redirect } from 'react-router'

const propTypes = {}

const defaultProps = {
  state: {
    reassignmentAsset: [],
  },
}

function ContactRoleReplacements() {
  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const { state } = useLocation()
  const { contactID } = useParams()

  if (isEmpty(state?.reassignmentAsset)) {
    return <Redirect to={`/profiles/${contactID}`} />
  }

  return (
    <div>
      <ContactIndividualReplacementsForm
        reassignmentAsset={state.reassignmentAsset || []}
      />
    </div>
  )
}

ContactRoleReplacements.propTypes = exact(propTypes)
ContactRoleReplacements.defaultProps = defaultProps

export default React.memo(ContactRoleReplacements)
