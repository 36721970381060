import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { ExchangeAuthorizationForm } from '../forms'

const propTypes = {
  exchangeConfirmation: PropTypes.object.isRequired,
  fromOriginalTransactions: PropTypes.object.isRequired,
  toNewTransactions: PropTypes.object.isRequired,
}
const defaultProps = {}

function ExchangeAuthorization({
  exchangeConfirmation,
  fromOriginalTransactions,
  toNewTransactions,
}) {
  const history = useHistory()
  const { exchangeID } = useParams()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    ...exchangeConfirmation,
  }

  return (
    <ExchangeAuthorizationForm
      {...{ initialValues, fromOriginalTransactions, toNewTransactions }}
      handleSubmit={async () => {
        history.push(`/confirm-exchange/${exchangeID}/transaction-documents`)
      }}
    />
  )
}

ExchangeAuthorization.propTypes = propTypes
ExchangeAuthorization.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    exchangeConfirmation: selectors.exchangeConfirmation(state),
    fromOriginalTransactions: selectors.fromOriginalTransactions(state),
    toNewTransactions: selectors.toNewTransactions(state),
  }
}

const mapDispatchToProps = {
  updatePurchase: apiActions.updatePurchase,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExchangeAuthorization
)
