function getStageLabel(stages, currentStage, flowType) {
  const stage = stages.find((stage) => stage.name === currentStage)
  // eslint-disable-next-line no-console
  if (!stage) console.error(`Stage does not exist for ${currentStage}`)
  if (flowType === 'purchaseConfirmation') {
    return stage?.headerLabel
  } else {
    return stage?.label
  }
}

export default getStageLabel
