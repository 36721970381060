import { handleActions } from 'redux-actions'
import * as apiActions from 'api-actions'
import { setOnSuccess } from 'lp-redux-api'
import { selectorForSlice, unsetState } from 'lp-redux-utils'
// import { createSelector } from 'reselect'
import * as transactionsActions from './actions'

const reducerKey = 'transactions'
const slice = 'root.transactions'

const initialState = {}

const reducer = handleActions(
  {
    [apiActions.fetchTasks]: setOnSuccess('tasks'),
    [apiActions.fetchTransactions]: setOnSuccess('transactions'),
    [apiActions.fetchAllBulkPurchaseConfirmations]: setOnSuccess(
      'pendingConfirmations'
    ),
    [transactionsActions.clearTasks]: unsetState('tasks'),
    [transactionsActions.clearTransactions]: unsetState('transactions'),
  },
  initialState
)

const select = selectorForSlice(slice)

const selectors = {
  tasks: select('tasks'),
  transactions: select('transactions'),
  pendingConfirmations: select('pendingConfirmations'),
}

export { reducer, selectors, reducerKey }
