import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors } from '../reducer'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import { FlowActions } from '../components'
import { DownloadIconButton } from 'components'
import { LIQUIDATION_CONFIRMATION_STAGES_PART_ONE } from 'flow-stages'

const propTypes = {
  liquidation: PropTypes.object.isRequired,
  fetchDocument: PropTypes.func.isRequired,
}

const defaultProps = {}

function Templates({ liquidation, fetchDocument }) {
  const history = useHistory()
  const { liquidationID } = useParams()
  const pathname = LIQUIDATION_CONFIRMATION_STAGES_PART_ONE.find(
    (stage) => stage.name === 'confirmation'
  ).routes[0].pathname

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content">
          <div className="flow-header-help-text">
            <h4 className="flow-section-headers">Documents To Download</h4>
            <p>
              Thank you for working with Inspira Financial on behalf of our
              mutual client. The below documents relate to a liquidation
              transaction. As custodian of the investment, we hereby authorize
              you to process the liquidation as detailed in the documents and
              send proceeds to us per our delivery instructions. Please download
              the documents and retain them for your records.
            </p>
            <p>
              Copies of liquidation information may be sent directly to the
              client at their home or business address. IRA payments should be
              paid to Inspira as custodian for the asset and not to the
              individual. Such direct payments will result in incorrect
              investment reporting and may result in taxable distributions and
              possible IRS penalties to the account owner of an IRA.
              Additionally, special service fees and charges to the account
              owner may apply. All funds should be remitted based on Inspira’s
              delivery instructions.
            </p>
          </div>
          <div className="mtc-flow-line">
            <hr />
          </div>
          <table className="document-content">
            <tbody>
              {liquidation.downloadDocuments.map((doc) => {
                return (
                  <tr key={doc.requiredDocumentID}>
                    <td>
                      <p>{doc.requiredDocumentName}</p>
                    </td>
                    <td className="download-table-data">
                      <DownloadIconButton
                        onClick={() =>
                          fetchDocument(
                            liquidationID,
                            doc.requiredDocumentID,
                            doc.requiredDocumentName
                          )
                        }
                      />
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
      <FlowActions
        onContinue={() => {
          history.push(`/confirm-liquidation/${liquidationID}${pathname}`)
        }}
      />
    </div>
  )
}

Templates.propTypes = propTypes
Templates.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    liquidation: selectors.liquidation(state),
  }
}

const mapDispatchToProps = {
  fetchDocument: apiActions.fetchRequiredDocumentTemplate,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Templates)
