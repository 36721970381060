import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { Modal, SubmitButton } from 'lp-components'

const propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
}

const defaultProps = {}

function ConfirmEffectiveDateModal({ onClose, onSubmit, submitting }) {
  return (
    <Modal id="confirm-effective-date-modal" onClose={onClose}>
      <button
        className="modal-close"
        onClick={() => {
          onClose()
        }}
      >
        ×
      </button>

      <h2>Confirm Effective Date</h2>
      <p>
        Effective date is more than 1 year after request date. Continue with
        selected effective date?
      </p>
      <div className="button-block--inline">
        <SubmitButton
          className="button-primary"
          onClick={() => onSubmit()}
          submitting={submitting}
        >
          Confirm & Continue
        </SubmitButton>
        <button
          className="button-secondary"
          onClick={() => onClose()}
          type="button"
        >
          Keep Editing
        </button>
      </div>
    </Modal>
  )
}

ConfirmEffectiveDateModal.propTypes = exact(propTypes)
ConfirmEffectiveDateModal.defaultProps = defaultProps

export default React.memo(ConfirmEffectiveDateModal)
