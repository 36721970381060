import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { RoleDefinitionModal, LabelWithTooltip, MultiSelect } from 'components'
import { SubmitButton } from 'lp-components'
import { ROLE_DEFINITION } from 'config'
import { useFormikContext } from 'formik'
import classnames from 'classnames'
import { components } from 'react-select'
import { unionBy, sortBy, uniqBy, truncate } from 'lodash'
import CollapsableList from '../components/CollapsableList'
import pluralize from 'pluralize'

const propTypes = {
  role: PropTypes.string,
  setCurrentRole: PropTypes.func,
  isReadOnly: PropTypes.bool,
  setIsReadOnly: PropTypes.func,
  assetAssignmentInitialValues: PropTypes.object,
  assignments: PropTypes.array,
  assetOptions: PropTypes.array,
  currentRole: PropTypes.string,
  setIsTileEditing: PropTypes.func,
  assetList: PropTypes.array,
  isRoleRestricted: PropTypes.bool,
  isTileEditing: PropTypes.bool,
  setHasEditsPending: PropTypes.func,
  cardKeyBeingEdited: PropTypes.number,
  setCardKeyBeingEdited: PropTypes.func,
  cardKey: PropTypes.number,
}

const defaultProps = {}

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          disabled={props.isDisabled}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    </div>
  )
}

const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.role}</span>
  </components.MultiValue>
)

const ValueContainer = ({ children, ...props }) => {
  const { getValue, hasValue } = props
  const values = getValue()

  const valuesWithoutAll = values.filter((value) => value.value !== '*')
  const numberOfValues = valuesWithoutAll.length
  const isSelectAll = values[0] === '*'

  const valueMessage = hasValue
    ? valuesWithoutAll
        .slice(0, 3)
        .map((value) => truncate(value.label, { length: 24 }))
        .join(', ')
    : null

  const trailingMessage =
    numberOfValues > 3 &&
    `, ...${numberOfValues - 3} other ${pluralize(
      'item',
      numberOfValues - 3
    )} selected`

  return (
    <components.ValueContainer {...props}>
      {isSelectAll && 'All Assets Selected'}
      {!isSelectAll && valueMessage && valueMessage}
      {!isSelectAll && trailingMessage && trailingMessage}
      {children}
    </components.ValueContainer>
  )
}

function RoleAssetAssignmentFields({
  role,
  cardKey,
  isRoleRestricted,
  setCurrentRole,
  isReadOnly,
  setIsReadOnly,
  assetAssignmentInitialValues,
  currentRole,
  assetOptions,
  setIsTileEditing,
  assignments,
  isTileEditing,
  assetList,
  setHasEditsPending,
  cardKeyBeingEdited,
  setCardKeyBeingEdited,
}) {
  const {
    values,
    setFieldValue,
    dirty,
    resetForm,
    errors,
    isSubmitting,
  } = useFormikContext()
  const [showRoleDefinitionModal, setShowRoleDefinitionModal] = useState(false)

  const [isInEditMode, setIsInEditMode] = useState(false)

  const setRole = async (role) => {
    await resetForm({ ...assetAssignmentInitialValues })
    setCurrentRole(role)

    const assignedAssetRelationships =
      (await sortBy(
        assignments
          ?.find((assignment) => {
            return assignment.role === role
          })
          ?.relationships.map((relationship) => {
            return {
              label: relationship.assetName,
              value: relationship.assetID,
              relationshipID: relationship.relationshipID,
            }
          }),
        'key'
      )) || []

    await setFieldValue('assetsAssigned', assignedAssetRelationships, true)
  }

  const assignedAssetRelationships =
    sortBy(
      assignments
        ?.find((assignment) => {
          return assignment.role === currentRole
        })
        ?.relationships.map((relationship) => {
          return {
            key: relationship.assetName,
            value: relationship.assetID,
            relationshipID: relationship.relationshipID,
          }
        }),
      'key'
    ) || []

  const sortedOptions = unionBy(
    uniqBy(assignedAssetRelationships, 'value'),
    sortBy(assetOptions, 'key'),
    'value'
  )

  const mappedSortedOptions = sortedOptions.map((option) => {
    return {
      label: option.key,
      value: option.value,
    }
  })

  return (
    <>
      <div>
        <div className="contact-tile-container reg-14">
          {(!isInEditMode || cardKeyBeingEdited !== cardKey) && (
            <>
              <div className="contact-tile-header">
                <div className="contact-tile-header-title">
                  <p className="bold-18">{role}</p>
                </div>
                <div className="button-wrapper-roles">
                  <div className="button-block--inline-roles">
                    {!isTileEditing && (
                      <>
                        {!isRoleRestricted && (
                          <button
                            className={classnames(
                              'button-secondary bold-14 edit-btn',
                              {
                                'is-disabled': isRoleRestricted,
                              }
                            )}
                            onClick={() => {
                              setRole(role)
                              setCardKeyBeingEdited(cardKey)
                              setIsInEditMode(true)
                              setIsTileEditing(true)
                              setHasEditsPending(false)
                            }}
                            type="button"
                            disabled={isRoleRestricted}
                          >
                            Edit
                          </button>
                        )}
                        {isRoleRestricted && (
                          <>
                            <LabelWithTooltip
                              label="Edit"
                              tooltipContent="Please contact our Client Service Team at (800) 258-7878 to update asset assignments for managing members or administrative contacts."
                            />
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="contact-tile-content">
                <CollapsableList listLimit={5} data={assetList} />
              </div>
            </>
          )}
          {cardKeyBeingEdited === cardKey && (
            <>
              <div className="contact-tile-header">
                <div className="contact-tile-header-title">
                  <p className="bold-18">{role}</p>
                </div>
              </div>
              <div className="contact-tile-content">
                <div className="select-form-content">
                  <div className="select-assets-form-wrapper">
                    <MultiSelect
                      name="assetsAssigned"
                      options={mappedSortedOptions}
                      label="Asset Assignments"
                      closeMenuOnSelect={false}
                      hideSelectedOptions={false}
                      withAllOption={true}
                      components={{
                        Option,
                        MultiValue,
                        ValueContainer,
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                      }}
                      hasErrors={errors}
                      overridePlaceholder={
                        values.assetsAssigned.length === 0 && 'Search Assets'
                      }
                      defaultValue={values.assetsAssigned}
                      onChange={() => {
                        setHasEditsPending(true)
                      }}
                      wrapStyle="nowrap"
                    />
                  </div>
                </div>
              </div>
              <div className="assign-new-assets-button-wrapper">
                <button
                  className={classnames('button-secondary tile-cancel', {
                    'is-disabled': isRoleRestricted,
                  })}
                  onClick={() => {
                    setIsReadOnly(!isReadOnly)
                    setIsInEditMode(false)
                    setIsTileEditing(false)
                    resetForm({ ...assetAssignmentInitialValues })
                    setHasEditsPending(false)
                    setCardKeyBeingEdited(-1)
                  }}
                  type="button"
                  disabled={isRoleRestricted}
                >
                  Cancel
                </button>
                <SubmitButton
                  className={classnames('button-primary tile-submit', {
                    'is-disabled': !dirty,
                  })}
                  disabled={!dirty}
                  submitting={isSubmitting}
                >
                  Save Assignments
                </SubmitButton>
              </div>
            </>
          )}
        </div>
      </div>
      {showRoleDefinitionModal && (
        <RoleDefinitionModal
          onClose={() => setShowRoleDefinitionModal(false)}
          title="Definition of Roles"
          roles={ROLE_DEFINITION}
        />
      )}
    </>
  )
}

RoleAssetAssignmentFields.propTypes = propTypes
RoleAssetAssignmentFields.defaultProps = defaultProps

export default React.memo(RoleAssetAssignmentFields)
