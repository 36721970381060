import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { Spinner } from 'lp-components'
import {
  setSessionToken,
  clearSessionToken,
  getSessionToken,
} from '../services/auth'
import * as apiActions from 'api-actions'
import { setIdleTimer } from 'utils'

const propTypes = {
  children: PropTypes.node.isRequired,
  updateLastLogin: PropTypes.func.isRequired,
  fetchUserProfile: PropTypes.func.isRequired,
}

const defaultProps = {}

function AuthorizedLayout({ children, updateLastLogin, fetchUserProfile }) {
  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading,
    getAccessTokenSilently,
    logout,
  } = useAuth0()

  const [shouldRender, setShouldRender] = useState(false)
  const [fullPageError, setFullPageError] = useState(false)

  const onIdle = () => {
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  useEffect(() => {
    if (!isAuthenticated) return
    getAccessTokenSilently()
      .then(async (token) => {
        if (!getSessionToken()) {
          setSessionToken(token)
          updateLastLogin()
        } else {
          setSessionToken(token)
        }
        await fetchUserProfile().catch(() => setFullPageError(true))
        setShouldRender(true)
        setIdleTimer({ onIdle })
      })
      .catch(() => setFullPageError(true))
  }, [isAuthenticated])

  if (fullPageError) {
    return <Redirect path="*" to="/error" />
  }

  if (isLoading)
    return (
      <div className="unauthenticated-content">
        <Spinner />
      </div>
    )

  if (!isAuthenticated) {
    clearSessionToken()
    loginWithRedirect()
    return null
  }

  if (!shouldRender)
    return (
      <div className="unauthenticated-content">
        <Spinner />
      </div>
    )

  return <>{children}</>
}

AuthorizedLayout.propTypes = exact(propTypes)
AuthorizedLayout.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateLastLogin: apiActions.updateLastLogin,
  fetchUserProfile: apiActions.fetchUserProfile,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  AuthorizedLayout
)
