import PropTypes from 'prop-types'

const componentType = PropTypes.oneOfType([PropTypes.func, PropTypes.object])

export const column = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  sortFunc: PropTypes.func,
  disabled: PropTypes.bool,
  component: componentType,
  headerComponent: componentType,
  onClick: PropTypes.func,
  format: PropTypes.func,
  valueGetter: PropTypes.func,
}

export const stage = PropTypes.shape({
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      component: PropTypes.elementType.isRequired,
    })
  ).isRequired,
})

export const asset = PropTypes.shape({
  assetID: PropTypes.string.isRequired,
  accountID: PropTypes.string.isRequired,
  assetGroupID: PropTypes.string.isRequired,
  caseID: PropTypes.string,
  stage: PropTypes.string,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string,
  entityType: PropTypes.string,
  investmentType: PropTypes.string,
  securityType: PropTypes.string,
})

export const transactions = PropTypes.shape({
  assetName: PropTypes.string.isRequired,
  accountName: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  transactionCompleteDate: PropTypes.string.isRequired,
  transactionID: PropTypes.string.isRequired,
})

export const task = PropTypes.shape({
  assetStage: PropTypes.string,
  completed: PropTypes.bool,
  createDate: PropTypes.string,
  description: PropTypes.string,
  priority: PropTypes.string,
  recordID: PropTypes.string,
  subType: PropTypes.string,
  subject: PropTypes.string,
  taskID: PropTypes.string,
  taskType: PropTypes.string,
})

export const tasks = PropTypes.arrayOf(task)

export const user = PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  userID: PropTypes.string.isRequired,
  contactID: PropTypes.string.isRequired,
  userClassification: PropTypes.string.isRequired,
  roleNames: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string,
      relationships: PropTypes.arrayOf(
        PropTypes.shape({
          relationshipID: PropTypes.string,
          assetID: PropTypes.string,
          assetName: PropTypes.string,
        })
      ),
    })
  ),
})

export const roleRelationships = PropTypes.shape({
  role: PropTypes.string,
  relationships: PropTypes.arrayOf(
    PropTypes.shape({
      relationshipID: PropTypes.string,
      assetID: PropTypes.string,
      assetName: PropTypes.string,
    })
  ),
})

export const company = PropTypes.shape({
  companyID: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
})

export const normalizedContact = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  accountID: PropTypes.string.isRequired,
})

export const contactDetails = PropTypes.shape({
  accountID: PropTypes.string,
  city: PropTypes.string,
  contactID: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phone: PropTypes.string,
  state: PropTypes.string,
  streetAddress: PropTypes.string,
  zipcode: PropTypes.string,
})

export const contact = PropTypes.shape({
  assets: PropTypes.arrayOf(PropTypes.string).isRequired,
  contactDetails: contactDetails,
  dateAdded: PropTypes.string,
  lastAccessed: PropTypes.string,
  lastUpdated: PropTypes.string,
  roles: PropTypes.arrayOf(PropTypes.string),
})
