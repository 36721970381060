import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { SignatureForm } from '../forms'
import { Redirect, useHistory } from 'react-router-dom'
import * as flashActions from 'redux-flash'
import { Spinner } from 'lp-components'
import { selectors } from '../reducer'
import * as actions from '../actions'
import { selectors as globalSelectors } from 'global-reducer'
import { isEmpty } from 'lodash'

const propTypes = {
  updatePurchase: PropTypes.func.isRequired,
  flashErrorMessage: PropTypes.func.isRequired,
  flashSuccessMessage: PropTypes.func.isRequired,
  clearInvestorsData: PropTypes.func.isRequired,
  fetchInvestorsData: PropTypes.func.isRequired,
  purchaseIDs: PropTypes.array,
  investorsData: PropTypes.array,
}

const defaultProps = {}

function Esign({
  updatePurchase,
  flashErrorMessage,
  flashSuccessMessage,
  investorsData,
  purchaseIDs,
  fetchInvestorsData,
  clearInvestorsData,
}) {
  const history = useHistory()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (isEmpty(purchaseIDs)) return <Redirect to="/transactions/pending" />

  useEffect(() => {
    fetchInvestorsData(purchaseIDs.toString(), 'data')
    return () => {
      clearInvestorsData()
    }
  }, [])

  if (!investorsData || !investorsData[0].purchaseAmount) return <Spinner />

  const handleEsign = async (params) => {
    const payload = investorsData.map((investor) => {
      return {
        ...investor,
        eSignFirstName: params.firstName,
        eSignLastName: params.lastName,
        eSignTitle: params.title,
        eSignDate: Date.now(),
        status: 'Confirmed',
      }
    })
    await updatePurchase(payload)
  }

  return (
    <div className="flow-card-container">
      <div className="card">
        <div className="form-card-content esign-card-content">
          <div className="esign-form-header-wrapper">
            <h4 className="esign-form-header">Review & Submit</h4>
            <p className="esign-form-header-text">
              Review the acknowledgments, electronically sign and submit to
              confirm the purchase of{' '}
              <span className="esign-asset-name">
                {investorsData[0].assetName}
              </span>{' '}
              for your investor(s).
            </p>
          </div>
          <div className="acknowledgement-subheader">
            <h4 className="acknowledgement-subheader-text">Acknowledgments</h4>
            <p className="acknowledgement-subheader-summary">
              Please review the following acknowledgments below and e-sign at
              the bottom.
            </p>
          </div>
          <ul className="confirm-purchase-list">
            <li className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I confirm that this purchase has been executed and properly
                registered for the investor/account owner(s) as noted in the
                purchase details.
              </div>
            </li>
            <li className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I acknowledge that all funds to and from this investment must
                flow through Inspira as Custodian. As such, I will not accept
                funds directly from or submit funds directly to the beneficial
                account owner or other third party on behalf of this investment.
              </div>
            </li>
            <li className="bulleted-circle-item">
              <div className="bulleted-circle-contents">
                I understand that in order for Inspira to continue to custody
                this asset, valuation updates must be provided at least
                annually.
              </div>
            </li>
          </ul>
        </div>
        <hr />
        <div>
          <SignatureForm
            className="esign-form"
            handleSubmit={async (params) => {
              try {
                await handleEsign(params)
                flashSuccessMessage('We received your purchase confirmation.')
                history.push('/transactions/view-all')
              } catch (e) {
                flashErrorMessage(
                  e.errors.message || 'Something went wrong, please try again.'
                )
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

Esign.propTypes = propTypes
Esign.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    investorsData: selectors.investorsData(state),
    purchaseIDs: globalSelectors.purchaseIDs(state),
  }
}

const mapDispatchToProps = {
  updatePurchase: apiActions.updatePurchase,
  flashErrorMessage: flashActions.flashErrorMessage,
  flashSuccessMessage: flashActions.flashSuccessMessage,
  clearInvestorsData: actions.clearInvestorsData,
  fetchInvestorsData: apiActions.fetchInvestorsData,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Esign)
