import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { selectors as globalSelectors } from 'global-reducer'
import { Spinner } from 'lp-components'
import InspiraLogo from 'images/InspiraLogo.svg'
import HelpCircleIconBlack from 'images/help_outline_black_24dp.svg'
import { AddAssetModal } from '../../home/components'
import { ContactModal } from 'components'
import { useAuth0 } from '@auth0/auth0-react'
import { useClickAway } from 'react-use'
import { clearSessionToken } from './../../../services/auth'
import AccountCircleIcon from 'images/account-circle.svg'

const propTypes = {
  currentUser: PropTypes.object,
}

const defaultProps = {}

function Onboarding({ currentUser }) {
  const [addAssetModalShown, setAddAssetModalShown] = useState(false)
  const [modalShown, setModalShown] = useState(false)
  const [isExpanded, setExpanded] = useState(false)

  const { logout } = useAuth0()

  const ref = useRef(null)

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  if (!currentUser) {
    return <Spinner />
  }

  useClickAway(
    ref,
    () => {
      setExpanded(false)
    },
    ['click']
  )

  function handleLogout() {
    setExpanded(false)
    clearSessionToken()
    logout({ returnTo: window.location.origin })
  }

  return (
    <>
      <div className="onboarding-header">
        <div className="help-details-container">
          <img
            className="help-icon"
            src={HelpCircleIconBlack}
            alt="help circle"
            onClick={() => {
              setModalShown(true)
            }}
          />
          {modalShown && <ContactModal onClose={() => setModalShown(false)} />}
          <div className="AccountDetails" ref={ref}>
            <img
              className="AccountDetails-icon"
              src={AccountCircleIcon}
              onClick={() => {
                setExpanded(true)
              }}
              alt="Account details"
            />

            {isExpanded && (
              <div className="AccountDetails--dropdown">
                <button onClick={handleLogout}>Log Out</button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="onboarding-container">
        {addAssetModalShown && (
          <AddAssetModal
            onClose={() => setAddAssetModalShown(false)}
            path="/onboarding-add-asset/asset-details"
          />
        )}
        <div className="onboarding-side-nav">
          <div id="sidebar-logo-badge">
            <img src={InspiraLogo} alt="Inspira Logo" />
          </div>
        </div>
        <div id="main-content-onboarding">
          <h4>
            {' '}
            Welcome, {currentUser.firstName} {currentUser.lastName}!
          </h4>
          <p className="onboarding-message">
            To get started, please submit a new asset for custody.
          </p>
          <button
            className="button-primary"
            onClick={() => setAddAssetModalShown(true)}
          >
            Add New Asset
          </button>
        </div>
      </div>
    </>
  )
}

Onboarding.propTypes = propTypes
Onboarding.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
  }
}
const mapDispatchToProps = {}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Onboarding)
