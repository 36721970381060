import React, { useEffect, useState } from 'react'
import { Redirect, useParams } from 'react-router'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { FlowStatusTracker } from 'components'
import { Spinner } from 'lp-components'
import { selectors as globalSelectors } from '../reducer'
import * as apiActions from 'api-actions'
import { withPermission, scrollToTop, getStageLabel } from 'utils'
import { ADD_ASSET_STAGES } from 'flow-stages'
import { useLocation } from 'react-router-dom'
import * as actions from './actions'
import { AddAssetHeader } from './components'

const propTypes = {
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  fetchAsset: PropTypes.func.isRequired,
  clearAsset: PropTypes.func.isRequired,
  asset: PropTypes.object,
  fetchContactsList: PropTypes.func.isRequired,
  clearContactsList: PropTypes.func.isRequired,
}

const defaultProps = {
  asset: null,
}

function Layout({
  currentStage,
  children,
  fetchAsset,
  clearAsset,
  asset,
  fetchContactsList,
  clearContactsList,
}) {
  let { assetID } = useParams()
  const { pathname } = useLocation()
  const [error, setError] = useState(null)

  useEffect(() => {
    scrollToTop()
  }, [])

  useEffect(() => {
    return () => {
      clearAsset()
      clearContactsList()
    }
  }, [])

  useEffect(() => {
    fetchContactsList()

    const currentStageFromPathname = pathname.split('/').pop()

    if (assetID) {
      fetchAsset(assetID, currentStageFromPathname).catch((e) => setError(e))
    }
  }, [assetID])

  if (error) {
    return (
      <div>
        <Redirect
          to={{
            pathname: '/error',
            state: {
              errorMessage:
                error.errors.message ||
                'Something went wrong, please try again.',
            },
          }}
        />
      </div>
    )
  }

  if (assetID && !asset) return <Spinner />
  if (!withPermission('add-asset')) return <Redirect path="*" to="/home" />

  return (
    <>
      <AddAssetHeader title={'Add New Asset'} />
      <div className="add-asset-layout-wrapper contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">
            {getStageLabel(ADD_ASSET_STAGES, currentStage)}
          </h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={ADD_ASSET_STAGES}
            currentStage={currentStage}
          />
          {React.cloneElement(children, {
            storedAsset: asset,
          })}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    currentUser: globalSelectors.currentUser(state),
    asset: globalSelectors.asset(state),
  }
}

const mapDispatchToProps = {
  fetchAsset: apiActions.fetchAsset,
  clearAsset: actions.clearAsset,
  fetchContactsList: apiActions.fetchContactsList,
  clearContactsList: actions.clearContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
