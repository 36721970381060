import moment from 'moment'

function displayDateTime(isoString, format = 'MM-DD-YYYY h:mm a') {
  if (!isoString) return

  return moment(isoString)
    .local()
    .format(format)
}

export default displayDateTime
