import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { getInspiraFBOFormat } from 'utils'
import moment from 'moment'
import { ACPInputField, ACPDateInputField } from 'components'

const propTypes = {
  namespace: PropTypes.string,
  liquidation: PropTypes.object,
  isSubmitting: PropTypes.bool,
  values: PropTypes.object,
}

const defaultProps = {}

function IsProcessableForm({ namespace, liquidation, isSubmitting, values }) {
  const dateFormat = 'MM/DD/YYYY'

  return (
    <div className="container">
      <div className="row">
        <div className="twelve column">
          <ACPInputField
            name={`${namespace}.investorRegistration`}
            label="Investor Registration"
            placeholder="Investor Registration"
            disabled={true}
            value={getInspiraFBOFormat(liquidation?.clientFBOName)}
          />
        </div>
      </div>
      <div className="row">
        <div className="twelve column">
          <ACPInputField
            name={`${namespace}.assetName`}
            label="Asset Name"
            placeholder="Asset Name"
            disabled={true}
            value={liquidation?.assetName}
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column">
          <ACPInputField
            name={`${namespace}.liquidationType`}
            label="Liquidation Type"
            tooltipContent="Either partial or full liquidation of the investor's holding in the asset."
            disabled={true}
            value={liquidation?.liquidationType}
          />
        </div>
        <div className="one-half column">
          <ACPInputField
            name={`${namespace}.liquidationAmountRequested`}
            label="Liquidation Amount Requested"
            tooltipContent="The dollar amount requested for the liquidation."
            value={liquidation?.liquidationAmountRequested || 0}
            disabled={true}
          />
        </div>
      </div>
      <div className="row">
        <div className="one-half column">
          <ACPDateInputField
            name={`${namespace}.requestDate`}
            label="Transaction Date"
            tooltipContent="The date that Inspira processed the transaction."
            parse={(val) => moment(val).format(dateFormat)}
            value={liquidation?.requestDate || ''}
            disabled={true}
          />
        </div>
        <div className="one-half column">
          <ACPDateInputField
            name={`${namespace}.effectiveDate`}
            label="Effective Date"
            tooltipContent="This is the date that the investment sponsor executed the transaction on behalf of the investor."
            parse={(val) => moment(val).format(dateFormat)}
            minDate={moment(liquidation?.requestDate).subtract(90, 'days')}
            disabled={isSubmitting}
          />
        </div>
        <div className="row">
          <div className="one-half column">
            <ACPDateInputField
              name={`${namespace}.expectedFundsRemittanceDate`}
              label="Expected Funds Remittance Date"
              tooltipContent="The date Inspira is expected to receive the proceeds from the liquidation."
              parse={(val) => moment(val).format(dateFormat)}
              value={
                values[namespace].expectedFundsRemittanceDate ||
                liquidation?.sponsorExpectedFundsDate
              }
              disabled={isSubmitting}
            />
          </div>
          <div className="one-half column">
            <ACPInputField
              name={`${namespace}.anticipatedPaymentMethod`}
              selectOptions={[
                { key: 'Wire Transfer', value: 'Wire' },
                { key: 'Check', value: 'Check' },
                { key: 'ACH', value: 'ACH' },
              ]}
              enablePlaceholderOption={true}
              placeholder="Please select the payment method"
              label="Anticipated Payment Method"
              tooltipContent="The payment method for the liquidation."
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

IsProcessableForm.propTypes = exact(propTypes)
IsProcessableForm.defaultProps = defaultProps

export default React.memo(IsProcessableForm)
