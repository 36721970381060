import React from 'react'
import PropTypes from 'prop-types'
import { LabeledField } from 'lp-components'
import BaseCurrencyInput from 'react-currency-input-field'
import { withFormikAdapter } from 'utils'

const propTypes = {
  input: PropTypes.object.isRequired,
  allowNegativeValue: PropTypes.bool,
  decimalsLimit: PropTypes.number,
  decimalScale: PropTypes.number,
  prefix: PropTypes.string,
  id: PropTypes.string,
}

const defaultProps = {
  allowNegativeValue: false,
  decimalsLimit: 2,
  decimalScale: 2,
  prefix: '$',
}

function CurrencyInput(props) {
  const {
    input: { name, value, onBlur, onChange },
    id,
    allowNegativeValue,
    decimalsLimit,
    decimalScale,
    prefix,
    ...rest
  } = props

  return (
    <LabeledField id={id || name} {...props} className="currency-input">
      <div className="input-wrapper">
        <BaseCurrencyInput
          id={id || name}
          name={name}
          defaultValue={''}
          onValueChange={(val) => onChange(val || '')}
          onBlur={onBlur}
          allowNegativeValue={allowNegativeValue}
          decimalsLimit={decimalsLimit}
          decimalScale={decimalScale}
          prefix={prefix}
          value={value}
          {...rest}
        />
      </div>
    </LabeledField>
  )
}

CurrencyInput.propTypes = propTypes
CurrencyInput.defaultProps = defaultProps

export default withFormikAdapter()(CurrencyInput)
