import React from 'react'
import PropTypes from 'prop-types'
import exact from 'prop-types-exact'
import { pure } from 'recompose'
import { useLocation, Link } from 'react-router-dom'
// import { Spinner } from 'lp-components'
import {
  AccountDetails,
  HelpDetails,
  BackToDashboard,
  NotificationsBadge,
  // SwitchEntity,
} from 'components'
import { PATHNAMES, HEADER_TITLES } from 'config'
import classNames from 'classnames'

const propTypes = {
  title: PropTypes.string,
  notifications: PropTypes.arrayOf(PropTypes.object),
  pathname: PropTypes.string.isRequired,
  expanded: PropTypes.bool.isRequired,
  // relatedEntities: PropTypes.array,
}

const defaultProps = {
  title: 'Client Template Header',
  relatedEntities: null,
}

const setTitle = (pathname) => {
  if (pathname.includes(PATHNAMES.DOCUMENTS_VIEW)) return ''
  if (pathname.includes(PATHNAMES.ASSETS)) return HEADER_TITLES.ASSETS
  if (pathname.includes(PATHNAMES.DOCUMENTS)) return HEADER_TITLES.DOCUMENTS
  if (pathname.includes(PATHNAMES.PROFILES)) return HEADER_TITLES.PROFILES
  if (pathname.includes(PATHNAMES.PROFILE)) return HEADER_TITLES.PROFILE
  if (pathname.includes(PATHNAMES.CONTACTS)) return HEADER_TITLES.CONTACTS
  if (pathname.includes(PATHNAMES.RESOURCES)) return HEADER_TITLES.RESOURCES
  if (pathname.includes(PATHNAMES.TRANSACTIONS))
    return HEADER_TITLES.TRANSACTIONS
  return
}

function Header({ title, pathname, notifications, expanded }) {
  const showBack = pathname === PATHNAMES.TASKS
  const isHome = pathname === PATHNAMES.HOME
  const showTitle = !showBack

  const location = useLocation()
  const isGoBackToDashboard = location?.state?.goBackDashboard
  const isInvestorDetails = location?.state?.isInvestorDetails

  const subtitle = location?.state?.subtitle
  const breadcrumb = location?.state?.breadcrumb

  // Can set this to true for any place a user will navigate to and we do not want to render the multiple entities dropdown
  // const noRenderEntities = location?.state?.noRenderEntities

  // if (!relatedEntities) return <Spinner />

  return (
    <header
      className={classNames('header header-layout', { expand: !expanded })}
    >
      {showTitle && !isGoBackToDashboard && !isInvestorDetails && (
        <div className="header-title-wrapper">
          <h3 id="header-title">{isHome ? title : setTitle(pathname)}</h3>
          {pathname.includes(PATHNAMES.RESOURCES) && (
            <p>
              Get started with our instructional videos for a quick walk-through
              of how to utilize the Asset Custody Platform.
            </p>
          )}
        </div>
      )}
      {subtitle && (
        <div className="subtitle">
          {breadcrumb && (
            <>
              <Link to={breadcrumb?.to}>{breadcrumb?.title}</Link>
              <span className="breadcrumb">{'>'}</span>
            </>
          )}
          <span className="to">{subtitle}</span>
        </div>
      )}
      {(showBack || isGoBackToDashboard) && <BackToDashboard />}
      <div id="header-action-area">
        <div className="header-action-area-right">
          {/* {relatedEntities && !noRenderEntities && (
            <>
              <div className="multiple-entities-dropdown">
                <SwitchEntity relatedEntities={relatedEntities} />
              </div>
            </>
          )} */}
          <HelpDetails />
          <AccountDetails />
          <NotificationsBadge notifications={notifications} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = exact(propTypes)
Header.defaultProps = defaultProps

Header.displayName = 'Header'

export default pure(Header)
