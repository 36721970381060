import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import * as apiActions from 'api-actions'
import { useParams, useHistory } from 'react-router-dom'
import {
  Card,
  CustomMultiSelect,
  ScrollToFieldError,
  ACPInputField,
} from 'components'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { usStates, ADD_ASSET_STAGE } from 'config'
import { FlowActions } from '../components'

const propTypes = {
  updateAsset: PropTypes.func.isRequired,
  storedAsset: PropTypes.object.isRequired,
  isNotEditable: PropTypes.bool,
}

const defaultProps = {
  isNotEditable: false,
}

function FundingDetails({ storedAsset, updateAsset, isNotEditable }) {
  let { assetID } = useParams()
  const history = useHistory()
  const [error, setError] = useState()

  useEffect(() => {
    window.appEventData.push({
      // eslint-disable-line
      event: 'Page Load Completed',
    })
  }, [])

  const initialValues = {
    hasFundManager: storedAsset?.fundManagerDetails?.isFundManagerAvailable,
    isIssuerFundManager: storedAsset?.fundManagerDetails?.isIssuerFundManager,
    isFundManagerRegisteredInvestmentAdvisor:
      storedAsset?.fundManagerDetails?.isFundManagerRegisteredAdvisor,
    isFundManagerRegisteredWithStates:
      storedAsset?.fundManagerDetails?.isFundManagerRegisteredState,
    fundManagerRegisteredStates:
      storedAsset?.fundManagerDetails?.fundManagerRegisteredStates
        .split(',')
        .map((state) => {
          return { label: state, value: state }
        }) || [],
  }

  // if there is an assetID then update, else post new
  const handleSubmit = async (values) => {
    const payload = {
      isFundManagerAvailable: values.hasFundManager,
      isIssuerFundManager: values.isIssuerFundManager,
      isFundManagerRegisteredAdvisor:
        values.isFundManagerRegisteredInvestmentAdvisor,
      isFundManagerRegisteredState: values.isFundManagerRegisteredWithStates,
      fundManagerRegisteredStates:
        values.fundManagerRegisteredStates
          .map((state) => state.value)
          .toString() || '',
    }

    try {
      await updateAsset({
        ...storedAsset,
        stage: values.requireAll
          ? ADD_ASSET_STAGE.INVESTMENT_DETAILS
          : ADD_ASSET_STAGE.FUNDING_DETAILS,
        saveStage: ADD_ASSET_STAGE.FUNDING_DETAILS,
        fundManagerDetails: { ...payload },
      })
      if (values.requireAll) {
        history.push(`/add-asset/${assetID}/investment-details`)
      } else {
        history.push('/home')
      }
    } catch (e) {
      setError(e)
    }
  }

  const fundingDetailsValidationSchema = Yup.object().shape({
    hasFundManager: Yup.boolean().when('requireAll', {
      is: true,
      then: Yup.boolean()
        .required('Required')
        .nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
    isIssuerFundManager: Yup.boolean().when('hasFundManager', {
      is: true,
      then: Yup.boolean()
        .required('Required')
        .nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
    isFundManagerRegisteredInvestmentAdvisor: Yup.boolean().when(
      'hasFundManager',
      {
        is: true,
        then: Yup.boolean()
          .required('Required')
          .nullable(),
        otherwise: Yup.boolean().nullable(),
      }
    ),
    isFundManagerRegisteredWithStates: Yup.boolean().when('hasFundManager', {
      is: true,
      then: Yup.boolean()
        .required('Required')
        .nullable(),
      otherwise: Yup.boolean().nullable(),
    }),
    fundManagerRegisteredStates: Yup.array()
      .nullable()
      .when('isFundManagerRegisteredWithStates', {
        is: true,
        then: Yup.array()
          .min(1, 'Must include all registered states.')
          .required('Required'),
      }),
  })

  if (error) {
    return (
      <div className="form-flow-container">
        <Card>
          <div className="form-card-content">ERROR: {error.message}</div>
        </Card>
      </div>
    )
  }

  return (
    <div className="form-flow-container">
      <div className="flow-card-container">
        <Formik
          onSubmit={handleSubmit}
          validationSchema={fundingDetailsValidationSchema}
          initialValues={initialValues}
          enableReinitialize={true}
        >
          {({ values, errors, touched, setFieldValue, setFieldTouched }) => {
            return (
              <Form className="asset-form asset-details-form">
                <ScrollToFieldError />
                <Card>
                  <div className="form-card-content">
                    <fieldset
                      className="read-only-wrapper"
                      disabled={isNotEditable}
                    >
                      <div className="container">
                        <h4 className="flow-section-headers">
                          Fund Manager Details
                        </h4>
                        <div className="row">
                          <div className="twelve columns">
                            <ACPInputField
                              name="hasFundManager"
                              radioOptions={[
                                { key: 'Yes', value: true },
                                { key: 'No', value: false },
                              ]}
                              label="Is there a fund manager?"
                              onChange={(e) => {
                                if (e.target.value === false) {
                                  setFieldValue('isIssuerFundManager', '')
                                  setFieldValue(
                                    'isFundManagerRegisteredInvestmentAdvisor',
                                    ''
                                  )
                                  setFieldValue(
                                    'isFundManagerRegisteredWithStates',
                                    ''
                                  )
                                }
                              }}
                            />
                          </div>
                        </div>
                        {values.hasFundManager === true && (
                          <>
                            <div className="row">
                              <div className="twelve columns">
                                <ACPInputField
                                  name="isIssuerFundManager"
                                  radioOptions={[
                                    { key: 'Yes', value: true },
                                    { key: 'No', value: false },
                                  ]}
                                  label="Is the issuer the Fund Manager?"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="twelve columns">
                                <ACPInputField
                                  name="isFundManagerRegisteredInvestmentAdvisor"
                                  radioOptions={[
                                    { key: 'Yes', value: true },
                                    { key: 'No', value: false },
                                  ]}
                                  label="Is the Fund Manager a Registered Investment Advisor?"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="twelve columns">
                                <ACPInputField
                                  name="isFundManagerRegisteredWithStates"
                                  radioOptions={[
                                    { key: 'Yes', value: true },
                                    { key: 'No', value: false },
                                  ]}
                                  label="Is the Fund Manager registered with any state(s)? If yes, please select the states in the field provided."
                                  onChange={() => {
                                    setFieldValue(
                                      'fundManagerRegisteredStates',
                                      []
                                    )
                                  }}
                                />
                              </div>
                            </div>
                            {values.isFundManagerRegisteredWithStates ===
                              true && (
                              <div className="row">
                                <div
                                  name="fundManagerRegisteredStates"
                                  className="twelve columns"
                                >
                                  <CustomMultiSelect
                                    name="fundManagerRegisteredStates"
                                    options={usStates.map((state) => {
                                      return { ...state, label: state.key }
                                    })}
                                    value={values.fundManagerRegisteredStates}
                                    onChange={setFieldValue}
                                    onBlur={setFieldTouched}
                                    error={errors?.fundManagerRegisteredStates}
                                    touched={
                                      touched?.fundManagerRegisteredStates
                                    }
                                    // react-select requires additional handling for disabling the field
                                    isDisabled={isNotEditable}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </fieldset>
                  </div>
                </Card>
                {!isNotEditable && <FlowActions />}
              </Form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}

FundingDetails.propTypes = propTypes

FundingDetails.defaultProps = defaultProps

function mapStateToProps() {
  return {}
}

const mapDispatchToProps = {
  updateAsset: apiActions.updateAsset,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FundingDetails
)
