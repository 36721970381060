import { PROFILE_ROLES } from 'config'

const sortRoleNames = (roles) => {
  return roles.sort((a, b) => {
    if (a == b) return 0
    if (a == PROFILE_ROLES.MANAGING_MEMBER) return -1
    if (b == PROFILE_ROLES.MANAGING_MEMBER) return 1

    if (a < b) return -1
    if (a > b) return 1
    return 0
  })
}

export default sortRoleNames
