import React, { useEffect } from 'react'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ConfirmPurchaseHeader, FlowStatusTracker } from 'components'
import { FlashMessageContainer, Spinner } from 'lp-components'
import { getFlashMessages, flashMessageType } from 'redux-flash'
import { selectors as globalSelectors } from '../reducer'
import { scrollToTop } from 'utils'
import { ROLE_REPLACEMENTS_STAGES } from 'flow-stages'
import * as apiActions from 'api-actions'
import * as actions from './actions'

const propTypes = {
  flashMessages: PropTypes.arrayOf(flashMessageType).isRequired,
  children: PropTypes.node.isRequired,
  currentStage: PropTypes.string,
  currentUser: PropTypes.object,
  fetchContactsList: PropTypes.func.isRequired,
  clearContactsList: PropTypes.func.isRequired,
}

const defaultProps = {
  currentUser: null,
  purchase: null,
}
function Layout({
  flashMessages,
  currentStage,
  children,
  currentUser,
  fetchContactsList,
  clearContactsList,
}) {
  useEffect(() => {
    fetchContactsList()
    scrollToTop()

    return () => clearContactsList()
  }, [])

  if (!currentUser?.firstName) return <Spinner />

  return (
    <>
      <FlashMessageContainer messages={flashMessages} />
      <ConfirmPurchaseHeader title={'Role and Asset Assignments'} />
      <div className="add-asset-layout-wrapper contact-role-replacements contact-wrapper-resp">
        <div className="title-div">
          <h1 className="title">Apply Assignments Individually</h1>
        </div>
        <div className="content-div">
          <FlowStatusTracker
            stages={ROLE_REPLACEMENTS_STAGES}
            currentStage={currentStage}
          />
          {children}
        </div>
      </div>
    </>
  )
}

Layout.propTypes = propTypes

Layout.defaultProps = defaultProps

function mapStateToProps(state) {
  return {
    flashMessages: getFlashMessages(state),
    currentUser: globalSelectors.currentUser(state),
  }
}

const mapDispatchToProps = {
  fetchContactsList: apiActions.fetchContactsList,
  clearContactsList: actions.clearContactsList,
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
